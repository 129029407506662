import React from 'react';
// Import Shared Components
import { Label } from 'shared-components';
// Import Component Styles
import { HeaderSection } from './styles';

export default ({ children, title, labelAlign, ...props }) => (
  <HeaderSection {...props}>
    <Label labelAlign={labelAlign}>{title}</Label>
    {children}
  </HeaderSection>
);
