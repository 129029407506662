import { withState } from 'recompose';
import { getEndOfDay, getThirtyDaysAgo } from 'shared-components/src/utils/dates';

export default withState('searchData', 'updateSearchData', {
  name: '',
  status: '',
  dateFrom: getThirtyDaysAgo(),
  dateTo: getEndOfDay(),
  amountFrom: '',
  amountTo: '',
  amount: '',
  expirationDatetime: '',
  focusedInput: null, // one of startDate, endDate, null
  timeZone: 'UTC'
});
