import React from 'react';
import PropTypes from 'prop-types';
// Import Shared Components
import {
  ActionBar,
  Button,
  Alert,
  Title,
  SubmitButton,
} from 'shared-components';
// Import Layouts
import AuthLayout from '../../layouts/AuthLayout';
import AuthButtons from '../../layouts/AuthButtons';
// Import Component Styles
import { Form, InputContainer } from './styles';

const AuthForm = ({
  title,
  submitButtonText,
  onSubmit,
  onKeyUp,
  error,
  success,
  secondaryButtonText,
  children,
  onClick,
}) => (
  <AuthLayout>
    <Title>{title}</Title>
    {error && (
      <Alert type="danger">
        <p
          dangerouslySetInnerHTML={{
            __html: error,
          }}
        />
      </Alert>
    )}
    {success && <Alert type="success">{success}</Alert>}
    <Form onSubmit={onSubmit} onKeyUp={onKeyUp}>
      <InputContainer>{children}</InputContainer>
      <ActionBar>
        <AuthButtons>
          <SubmitButton
            value={submitButtonText}
            buttonType="primary"
            size="large"
          />
          {onClick && (
            <Button variant="text" onClick={onClick}>
              {secondaryButtonText}
            </Button>
          )}
        </AuthButtons>
      </ActionBar>
    </Form>
  </AuthLayout>
);

AuthForm.propTypes = {
  title: PropTypes.string.isRequired,
  submitButtonText: PropTypes.string.isRequired,
  secondaryButtonText: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  onKeyUp: PropTypes.func,
  onClick: PropTypes.func,
  error: PropTypes.bool,
  success: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)])
};

AuthForm.defaultProps = {
  secondaryButtonText: null,
  onKeyUp: null,
  error: null,
  success: null,
};

export default AuthForm;
