import { withHandlers } from 'recompose';
// Import Utils
import { mutationErrorMessageParser } from '../../../../utils/errorLogger';

export default withHandlers({
  handlePageSizeChange: ({ updatePagination, pagination }) => (
      pageSize,
      pageIndex
  ) => {
    updatePagination({
      ...pagination,
      limit: pageSize,
      offset: pageIndex * pageSize,
    });
  },
  handlePageChange: ({ updatePagination, pagination }) => pageIndex => {
    updatePagination({
      ...pagination,
      page: pageIndex,
      offset: pageIndex * pagination.limit,
    });
  },
  updateSearchInput: ({ updateParams, searchBy }) => e => {
    const { value } = e.target;
    searchBy === 'name'
      ? updateParams({
        [searchBy]: value,
        email: '',
        status: '',
      })
      : updateParams({
        [searchBy]: value,
        name: '',
        status: '',
      });
  },
  updateSearchStatus: ({ updateParams }) => key => {
    updateParams({
      status: key,
      name: '',
      email: '',
    });
  },
  updateSearchByState: ({ updateSearchBy }) => key => {
    updateSearchBy(key);
  },
  searchTransactions: ({
    updateSearchData,
    params,
    updateShowResetButton,
  }) => () => {
    updateSearchData({
      name: (params.name.length !== 0 && params.name) || null,
      status: (params.status.length !== 0 && params.status) || null,
      email: (params.email.length !== 0 && params.email) || null,
    });
    updateShowResetButton(true);
  },
  resetSearch: ({
    updateParams,
    updateSearchData,
    updateSearchBy,
    updateShowResetButton,
  }) => () => {
    updateParams({
      name: '',
      status: '',
      email: '',
    });
    updateSearchData({
      name: '',
      status: '',
      email: '',
    });
    updateSearchBy('');
    updateShowResetButton(false);
  },
  resendEmail: ({
    resendPaymentRedemptionEmail,
    updateSuccess,
    updateError,
  }) => id => {
    resendPaymentRedemptionEmail(id)
      .then(res => {
        const body = res.data.resendPaymentRedemptionEmail;
        updateSuccess(body);
      })
      .catch(res => {
        const errorMessage = mutationErrorMessageParser(
          res,
          'Error resending Email'
        );
        updateError(errorMessage);
      });
  },
  cancelTransaction: ({ cancelPayment, updateSuccess, updateError }) => id => {
    if (window.confirm('Are you sure you want to cancel this Payment?')) {
      cancelPayment(id)
        .then(() => {
          updateSuccess('Payment has been cancelled successfully.');
        })
        .catch(res => {
          const errorMessage = mutationErrorMessageParser(
            res,
            'Failed to cancel Payment'
          );
          updateError(errorMessage);
        });
    }
  },
});
