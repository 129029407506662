import styled from 'styled-components';

export default styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;

  & > *:first-child {
    flex: 1;
  }

  & > *:not(:first-child) {
    flex: 1;
    margin-top: 15px;
  }

  ${({ theme }) => theme.media.desktopUp`
    flex-direction: row;
    align-items: stretch;

    & > *:not(:first-child) {
      margin-left: 15px;
      margin-top: 0;
    }

  `}
`;
