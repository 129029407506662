import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
// Import Utils
import { isDate } from 'shared-components/src/utils/dates';
import { convertTimeToISO } from 'shared-components/src/utils/formatDateTime';

const QUERY = gql`
  query(
    $name: String
    $latestStatus: [String]
    $dateFrom: String
    $dateTo: String
    $amountFrom: String
    $amountTo: String
    $expirationDatetime: String
    $limit: Int
    $offset: Int
  ) {
    viewer {
      id
      company {
        id
      }
      transfersSearch(
        name: $name
        latestStatus: $latestStatus
        date_from: $dateFrom
        date_to: $dateTo
        amount_from: $amountFrom
        amount_to: $amountTo
        expirationDatetime: $expirationDatetime
        limit: $limit
        offset: $offset
      ) {
        count
        data {
          id
          name
          latestStatus
          insertedAt
          expirationDatetime
          recipientsCount
          amount
          currency
        }
      }
    }
  }
`;



export default graphql(QUERY, {
  options: ({ searchData, pagination }) => ({
    variables: {
      name: searchData.name || '',
      latestStatus: searchData.status.split(',') || '',
      dateFrom:
        (searchData.dateFrom && convertTimeToISO(searchData.dateFrom, searchData.timeZone)) || '',
      dateTo: (searchData.dateTo && convertTimeToISO(searchData.dateTo, searchData.timeZone)) || '',
      amountFrom: searchData.amountFrom || '',
      amountTo: searchData.amountTo || '',
      expirationDatetime:
        (isDate(searchData.expirationDatetime) &&
          searchData.expirationDatetime.toISOString()) ||
        '',
      limit: pagination.limit,
      offset: pagination.offset
    },
    fetchPolicy: 'cache-and-network'
  })
});
