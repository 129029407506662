import React from 'react';
import { Alert, Card, Button, ActionBar, Title } from 'shared-components';
// Import Global Components
import Table from '../../../../components/Table';
import InfoTooltip from '../../../../components/InfoTooltip';
// Import Component Styles
import { KeysTable, TableHeadingContainer } from './styles';

export default ({ columns, title, data, tokenType, onClick, info }) => (
  <Card>
    <KeysTable>
      <TableHeadingContainer>
        <Title>{title}</Title>
        <InfoTooltip
          content={info}
        />
      </TableHeadingContainer>
      {data.length === 0 ? (
        <Alert type="info">
          There are no {title} generated for this account.
        </Alert>
      ) : (
        <Table
          columns={columns}
          data={data}
          defaultSorted={[
            {
              id: 'insertedAt',
              asc: false,
            },
          ]}
        />
      )}
      <ActionBar>
        <Button type="primary" onClick={() => onClick(tokenType)}>
          Generate New Key
        </Button>
      </ActionBar>
    </KeysTable>
  </Card>
);
