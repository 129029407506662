import { withHandlers } from 'recompose';

import { isEmailValid } from '../../../utils/validations';
import { login, forgotPassword } from '../../../utils/auth';

export default withHandlers({
  onUpdatePassword: ({ updatePassword, updateFormError }) => e => {
    e.preventDefault();
    updateFormError(false);
    updatePassword(e.target.value);
  },
  onUpdateEmail: ({ updateEmail, updateFormError }) => e => {
    e.preventDefault();
    updateFormError(false);
    updateEmail(e.target.value.trim());
  },
  onUpdatePasswordConfirm: ({
    updatePasswordConfirm,
    updateFormError,
  }) => e => {
    e.preventDefault();
    updateFormError(false);
    updatePasswordConfirm(e.target.value);
  },
  onLoginSubmit: ({
    email,
    password,
    updateLoading,
    updateFormError,
  }) => async e => {
    e.preventDefault();
    await preformLogin(email, password, updateLoading, updateFormError);
  },
  handleLoginKeyUp: ({
    email,
    password,
    updateLoading,
    updateFormError,
  }) => async e => {
    if (e.keyCode === 13)
      await preformLogin(email, password, updateLoading, updateFormError);
  },
  forgotPassword: ({
    updateFormError,
    email,
    emailSent,
    updateEmailSent,
    updateAlert,
  }) => async e => {
    e.preventDefault();
    email = email.trim();

    if (!isEmailValid(email))
      return updateFormError('Please enter a valid email');

    try {
      if (emailSent) updateAlert('Email has been resent!');
      updateFormError(null);
      await forgotPassword(email, emailSent, updateEmailSent);
    } catch (error) {
      updateFormError(error.message);
    }
  },
});

async function preformLogin(email, password, updateLoading, updateFormError) {
  email = email.trim().toLowerCase();

  if (!isEmailValid(email))
    return updateFormError('Please enter a valid email');

  if (password.length < 8) return updateFormError('Invalid password format');

  try {
    updateLoading(true);
    updateFormError(false);
    await login(email, password);
  } catch (error) {
    updateLoading(false);
    updateFormError(error.message);
  }
}
