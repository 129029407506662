import { useState } from 'react';

const useTablePagination = () => {
  const initialPaginationState = {
    limit: 10,
    offset: 0,
    page: 0,
  };
  const [pagination, setPagination] = useState(initialPaginationState);
  const handlePageSizeChange = (pageSize, pageIndex) => {
    setPagination({
      ...pagination,
      limit: pageSize,
      offset: pageIndex * pageSize,
    });
  };

  const handlePageChange = pageIndex => {
    setPagination({
      ...pagination,
      page: pageIndex,
      offset: pageIndex * pagination.limit,
    });
  };
  return {
    pagination,
    handlePageChange,
    handlePageSizeChange,
  };
};

export default useTablePagination;