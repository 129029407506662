import React from 'react';
import { withTheme } from 'styled-components';
import { Breadcrumbs } from 'shared-components';
import { Helmet } from 'react-helmet';

export default withTheme(({ theme, children, title, breadcrumbs }) => (
  <>
    <Helmet key="helmet">
      <link rel="shortcut icon" href={theme.brand.favicon} />
      <title>{title}</title>
    </Helmet>
    <Breadcrumbs breadcrumbs={breadcrumbs} />
    {children}
  </>
));
