import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
// Import Shared Components
import { Alert, CardGroup, Loader, Modal } from 'shared-components';
// Import Common Hooks
import useTellerApi from '../../../hooks/useTellerApi';
// Import Global Components
import ClientErrorComponent from '../../../components/ClientErrorComponent';
// Import Layouts
import CreateCSV from './layouts/CreateCSV';
import ReviewCSV from './layouts/ReviewCSV';
// Import Local Components
import SelectProgramModal from './components/SelectProgramModal';
import KycAttestation from './components/KycAttestation';
// Import Utils
import { post, get, postFormData } from '../../../utils/http';
import {
  parse,
  parseCreateCardholderCardRows,
  FILE_UPLOAD_LENGTH_LIMIT,
} from '../../../utils/csv';
import { mutationErrorMessageParser } from '../../../utils/errorLogger';

// Misc
import { StyledModalForm } from '../../../components/ModalForm';

export default withRouter(({ history }) => {
  // Component States
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const [cardholderStep, setCardholderStep] = useState(1);
  const [changeProgram, setChangeProgram] = useState(false);
  const [validationError, setValidationError] = useState(false);
  const [kycAttestationModal, setKycAttestationModal] = useState(false);
  // Data States
  const [program, setProgram] = useState(null);
  const [cardholders, setCardholders] = useState([]);
  const [erroredCardholderInputs, setErroredCardholderInputs] = useState([]);
  const [programKycConfig, setProgramKycConfig] = useState({});
  const [bulker, setBulker] = useState(false);
  const [file, setFile] = useState(null);
  // KYC Attestation Form
  const [agreeCollect, setAgreeCollect] = useState(false);
  const [agreeValidateStore, setAgreeValidateStore] = useState(false);
  const [agreeProvide, setAgreeProvide] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');

  useEffect(()=> {
    if (history.location.state && history.location.state.bulker) {
      setBulker(history.location.state.bulker);
    }
  }, [history.location.state]);

  // Get list of programs for company on mount
  let path = (history.location.state && history.location.state.bulker) ? '/api/v1/client_portal/card_issuing/programs?bulker_enabled=true' : '/api/v1/client_portal/card_issuing/programs';
  const [
    {
      data: { data: programs },
      isLoading,
      isError,
    },
  ] = useTellerApi(path, {
    count: 0,
    data: [],
    limit: 100,
    offset: 0,
  });

  if (isLoading || loading) return <Loader />;
  if (isError) return <ClientErrorComponent data={{}} />;

  const validateCsv = async data => {
    try {
      const res = await post(
        '/api/v1/client_portal/card_issuing/cardholders/validate',
        {
          program_id: program.id,
          cardholders: data,
        }
      );

      // If data returned is not empty, we should handle the returned errors for each cardholder input
      if (res.data.length === 0) {
        setSuccess(
          'CSV has been validated successfully. Please review your submission and click \'Create Cardholders\' to begin processing.'
        );
        setLoading(false);
        return;
      }

      setErroredCardholderInputs(res.data);
      setValidationError(true);
      setError(
        'One or more of the cardholders you wish to create are invalid. Please review the errors below in order to make changes to your upload file. You may try again after fixing the errors.'
      );
    } catch (e) {
      setError(e.message);
    }
    setLoading(false);
  };

  // Function to handle parsed and validated CSV data
  const handleUploadedCardholders = data => {
    setCardholders(data);
    setError(null);
    setCardholderStep(3);
    validateCsv(data);
  };

  const handleCSVParse = async e => {
    // Get file and clear file input
    const file = e.target.files[0];
    setFile(file);
    e.target.value = null;

    try {
      setLoading(true);
      // Need to fetch kyc config to determine if kyc attestation allowed/required
      const kycConfig = await get(
        `/api/v1/client_portal/card_issuing/programs/${program.id}/kyc_config`
      );
      setProgramKycConfig(kycConfig);
      // parse csv file
      const rows = await parse(file);

      // run csv file data through validations
      // Ensure that CSV file contains atleast one row of data
      if (rows.data.length <= 1) {
        throw new Error('Uploaded file does not contain any data');
      }

      if (rows.data.length > FILE_UPLOAD_LENGTH_LIMIT) {
        throw new Error(
          `Uploaded file contains more than ${FILE_UPLOAD_LENGTH_LIMIT} items, please remove some items from the file and try again`
        );
      }

      const data = parseCreateCardholderCardRows(rows.data.slice(1), program);

      // all rows have passed validations
      handleUploadedCardholders(data);
    } catch (err) {
      setLoading(false);
      setFile(null);
      const message = mutationErrorMessageParser(err, 'Error uploading CSV.');
      setError(message);
    }
  };

  const handleSelectProgram = programId => {
    // find program data based on program Id
    const [program] = programs.filter(p => p.id === programId);
    setProgram(program);
    setCardholderStep(2);
  };

  const handleChangeSelectedProgram = programId => {
    const [program] = programs.filter(p => p.id === programId);
    // set new program
    setProgram(program);
    // close change program modal
    setChangeProgram(!changeProgram);
  };

  // Create call to Teller to create cardholders
  const createCardholders = async () => {
    if(bulker){

      try
      {        
        let cardholdersPayload = cardholders;
        if (programKycConfig.data.enabled && programKycConfig.data.require_client_attestation) {
          cardholdersPayload = cardholders.map(cardholder => ({
            ...cardholder,
            kyc_attestation: {
              agree_collect: agreeCollect,
              agree_validate_store: agreeValidateStore,
              agree_provide: agreeProvide,
              first_name: firstName,
              last_name: lastName,
              email,
              phone,
            },
          }));
        }
        
        let payload = new FormData(); 
        payload.append("program_id", program.id);
        payload.append("client_cardholders", JSON.stringify(cardholdersPayload));
        payload.append("description", 'Description');
        payload.append("file", file);

        const orderResponse = await postFormData('/api/v1/client_portal/card_issuing/cardholders/bulk', payload);      
       
        // Clear Cardholders from State
        setCardholders([]);
        if(orderResponse.id) {
          // Send User to order details page. Idk about the message
          history.push({
            pathname: `/card-issuing/orderDetails/${orderResponse.id}`,
            state: {
              success:
                'Cardholders order have been accepted and will begin processing.',
            },
          });
        } else if(orderResponse.err_message){
          // Send User to index page, with error message
          setSuccess(null);
          setLoading(false);
          return setError(`Error: Failed to create order-${orderResponse.err_message}`);
        }
      }
      catch (e) {           
      setSuccess(null);
      setLoading(false);
      // Display Error and keep Cardholders in State to allow user to try again
      return setError(e.message);
      }   

    }
    else
    {
      try {
        // Don't care about response since as long as the cardholders where accepted by Teller
        // the process is async from there on
        let payload = cardholders;
        if (programKycConfig.data.enabled && programKycConfig.data.require_client_attestation) {
          payload = cardholders.map(cardholder => ({
            ...cardholder,
            kyc_attestation: {
              agree_collect: agreeCollect,
              agree_validate_store: agreeValidateStore,
              agree_provide: agreeProvide,
              first_name: firstName,
              last_name: lastName,
              email,
              phone,
            },
          }));
        } 
          await post('/api/v1/client_portal/card_issuing/cardholders', {
          program_id: program.id,
          cardholders: payload,
        });
      
        // Clear Cardholders from State
        setCardholders([]);
        // Send User to Cardholders list page, with success message
        history.push({
          pathname: '/card-issuing/cardholders',
          state: {
            success:
              'Cardholders have been accepted and will begin processing. Cardholders will appear in the list below as they are processed.',
          },
        });
      } catch (e) {
        setSuccess(null);
        // Display Error and keep Cardholders in State to allow user to try again
        return setError(e.message);
      }
    }   
    setLoading(false);
  };

  // Function to handle submission of Cardholders to Teller
  const handleCardholderSubmit = e => {
    e.preventDefault();
    if (
      programKycConfig.data.enabled &&
      programKycConfig.data.require_client_attestation
    ) {
      setKycAttestationModal(true);
    } else {
      setLoading(true);
      createCardholders();
    }
  };

  const handleKYCAttestationCardholderSubmit = e => {
    e.preventDefault();
    setLoading(true);
    createCardholders();
  };

  // Only display active programs in this list
  const programOptions = programs
    .filter(p => p.status === 'active')
    .map(p => {
      return { key: p.id, val: p.name };
    });

  return (
    <>
      {error && (
        <Alert type="danger">
          {/* Inline style is for showing line breaks, which would be ignored otherwise */}
          <div style={{ whiteSpace: 'pre-line' }}>{error}</div>
        </Alert>
      )}
      {success && <Alert type="success">{success}</Alert>}

      {cardholderStep === 1 && (
        <SelectProgramModal
          updateItem={handleSelectProgram}
          options={programOptions}
          onClick={() => 
            {
              if(bulker){
                history.push('/bulker/card-issuing/cardholders')
              }
              else
              {
                history.push('/card-issuing/cardholders')
              }
            }
            }
        />
      )}
      {changeProgram && (
        <SelectProgramModal
          changeProgram
          updateItem={handleChangeSelectedProgram}
          options={programOptions}
          onClick={() => setChangeProgram(!changeProgram)}
        />
      )}

      <CardGroup>
        {cardholderStep === 2 && (
          <CreateCSV
            setChangeProgram={setChangeProgram}
            program={program}
            setCardholderStep={setCardholderStep}
            parseCsv={handleCSVParse}
          />
        )}
        {cardholderStep === 3 && (
          <>
            {kycAttestationModal && (
              <Modal key="selectProgramModal">
                <StyledModalForm>
                  <KycAttestation
                    setKycAttestationModal={setKycAttestationModal}
                    setAgreeCollect={setAgreeCollect}
                    setAgreeValidateStore={setAgreeValidateStore}
                    setAgreeProvide={setAgreeProvide}
                    setFirstName={setFirstName}
                    setLastName={setLastName}
                    setEmail={setEmail}
                    setPhone={setPhone}
                    handleCardholderSubmit={
                      handleKYCAttestationCardholderSubmit
                    }
                    agreeCollect={agreeCollect}
                    agreeValidateStore={agreeValidateStore}
                    agreeProvide={agreeProvide}
                    firstName={firstName}
                    lastName={lastName}
                    email={email}
                    phone={phone}
                  />
                </StyledModalForm>
              </Modal>
            )}

            <ReviewCSV
              cardholders={cardholders}
              setCardholders={setCardholders}
              setCardholderStep={setCardholderStep}
              setSuccess={setSuccess}
              setError={setError}
              setLoading={setLoading}
              program={program}
              validationError={validationError}
              setValidationError={setValidationError}
              setErroredCardholderInputs={setErroredCardholderInputs}
              erroredCardholderInputs={erroredCardholderInputs}
              handleCardholderSubmit={handleCardholderSubmit}
            />
          </>
        )}
      </CardGroup>
    </>
  );
});