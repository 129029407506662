import React from 'react';
import { useQuery } from '@apollo/react-hooks';
// Import Shared Components
import { Card, Title, Link, Loader } from 'shared-components';
// Import Shared Utlis
import {
  getSevenDaysAgo,
  getEndOfDay,
} from 'shared-components/src/utils/dates';
// Import Graphql Query
import GET_TRANSACTIONS from '../../../../graphql/queries/transactionsQuery';
// Import Global Components
import ClientErrorComponent from '../../../../components/ClientErrorComponent';
// Import Local Components
import Legend from './Legend';
import Chart from './Chart';
// Import Component Styles
import { ChartContainer, ChartHeader, LegendContainer } from './styles';
// Import Utils
import { getWeeksTransactionData } from '../../../../utils/charts';
// Import Hooks
import { useTimeZone } from '../../../../hooks/useTimeZone';

export default () => {
  // To Do: Ideally, users should be able to see transaction activities within different date ranges.
  // Input fields and/or buttons allowing the selection of date ranges need to be implemented
  // and hooked up to the commented out state below
  // 
  // const [searchParams, setSearchParams] = useState({
  //   dateFrom: getSevenDaysAgo(),
  //   dateTo: getEndOfDay(),
  // });

  // Note: currently the data range is hardcoded to last seven days.
  const { loading, error, data } = useQuery(GET_TRANSACTIONS, {
    variables: {
      dateFrom: getSevenDaysAgo().toISOString(),
      dateTo: getEndOfDay().toISOString(),
    },
  });
  const [timeZone] = useTimeZone();

  if (loading)
    return (
      <Card>
        <Loader />
      </Card>
    );
  if (error) return <ClientErrorComponent data={{ error }} />;

  const chartData = getWeeksTransactionData(data.viewer.transactions.data, timeZone);

  return (
    <Card>
      <Title>Direct Send - Transaction Activity For the Past Week</Title>
      <ChartContainer>
        <ChartHeader>
          <LegendContainer>
            <Legend />
            <p>
              <i>Pushes and pulls created that did not error</i>
            </p>
          </LegendContainer>
        </ChartHeader>
        <Chart dataSet={chartData} />
        <Link type="primary" to="direct-send/reports">
          See More Transaction Activity
        </Link>
      </ChartContainer>
    </Card>
  );
};
