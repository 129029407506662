import React from 'react';
// Import Global Components
import RegisterForm from '../RegisterForm';

export default ({ user, resetPassword, ...props }) => (
  <RegisterForm
    email={user.email}
    title="Reset Password"
    onClick={resetPassword}
    btnText="Reset"
    {...props}
  />
);
