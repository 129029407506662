import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

export const QUERY = gql`
  query {
    viewer {
      id
      company {
        id
        name
        email
        latestStatus
      }
    }
  }
`;

export default graphql(QUERY, {
  fetchPolicy: 'cache-and-network'
});
