import React from 'react';
import PropTypes from 'prop-types';

// Import Shared Components
import { IconButton } from 'shared-components';

// Import Component Styles
import { TableRow, TableElement, Prefix, Input } from './styles';

const createElements = (
  id,
  recipient,
  error,
  updateRecipient,
  columns,
  prefixes
) =>
  columns.map((column, idx) => {
    const prefix = prefixes[idx];
    return (
      <TableElement key={String(id).concat(column)}>
        {prefix && (
          <Prefix error={error[column.concat('Error')]}>{prefix}</Prefix>
        )}
        <Input
          onChange={e => updateRecipient(id, column, e.target.value)}
          placeholder="Add Content"
          value={recipient[column]}
          prefix={prefix}
          error={error[column.concat('Error')]}
          required
        />
      </TableElement>
    );
  });

const CollectionsTableRow = ({
  id,
  recipient,
  error,
  updateRecipient,
  deleteRecipient,
  columns,
  prefixes,
}) => {
  return (
    <TableRow>
      {createElements(id, recipient, error, updateRecipient, columns, prefixes)}
      <TableElement>
        <IconButton
          iconType="delete"
          type="danger"
          variant="text"
          iconSize={15}
          onClick={() => {
            deleteRecipient(id);
          }}
        />
      </TableElement>
    </TableRow>
  );
};

CollectionsTableRow.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  id: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  recipient: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  error: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  columns: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  prefixes: PropTypes.array.isRequired,
  updateRecipient: PropTypes.func.isRequired,
  deleteRecipient: PropTypes.func.isRequired,
};

export default CollectionsTableRow;
