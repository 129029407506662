import { withHandlers } from 'recompose';

import { isPasswordValid } from '../../../../../utils/validations';
import { resetPassword, login } from '../../../../../utils/auth';

export default withHandlers({
  resetPassword: ({
    user: { email, token },
    password,
    passwordConfirm,
    updateFormError,
    updateLoading,
    history,
  }) => async e => {
    e.preventDefault();

    if (!isPasswordValid(password.trim())) {
      updateFormError(
        '<p><b>Password does not meet requirements</b></p><br/> <ul> <li style="list-style-type:disc;margin-left:15px">Password must be longer than 8 characters</li><li style="list-style-type:disc;margin-left:15px">Must contain one capital letter</li><li style="list-style-type:disc;margin-left:15px">Must contain one number</li><li style="list-style-type:disc;margin-left:15px">Must contain one symbol (ex: ! @ # $ *)</li></ul>'
      );
      return;
    }

    if (password !== passwordConfirm) {
      updateFormError('Passwords do not match');
      return;
    }

    // Create initial body here
    const payload = { token, email, password };

    try {
      updateLoading(true);
      await resetPassword(payload);
      await login(email, password);
    } catch (error) {
      updateLoading(false);
      updateFormError(error.message);
    }
  },
});
