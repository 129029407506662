import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
// Import Shared Components
import {
  Card,
  Title,
  Button,
  IconButton,
  ButtonGroup,
  Loader,
  ActionBar,
  Alert,
} from 'shared-components';
// Import Shared Utils
import { humanizeString, capitalize } from 'shared-components/src/utils/string';
// Import Global Components
import ClientErrorComponent from '../../../../components/ClientErrorComponent';
import ConfirmDelete from '../../../../components/ConfirmDelete';
import Table from '../../../../components/Table';
// import IconButton from '../../../../components/IconButton';
// Import Common Hooks
import useTellerApi from '../../../../hooks/useTellerApi';
// Import Common Utils
import { deleteHTTP } from '../../../../utils/http';

//role context
import {RoleContext} from '../../../../context/RoleContext';

export default withRouter(({ program, history, createSuccess }) => {
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const [removeSubscriptionInfo, setRemoveSubscriptionInfo] = useState(null);
  const [success, setSuccess] = useState(createSuccess);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Get role from context
  const userRole = React.useContext(RoleContext);  


  const [
    {
      data: { data: reports },
      isLoading,
      isError,
    },
    setUrl,
    setForceRetch,
  ] = useTellerApi(
    `/api/v1/client_portal/card_issuing/programs/${program.id}/reporting`,
    []
  );

  const handleRemoveSubscription = async () => {
    try {
      setLoading(true);
      await deleteHTTP(
        `/api/v1/client_portal/card_issuing/programs/${program.id}/reporting/${removeSubscriptionInfo.id}`,
        {}
      );
      setSuccess('Report Subscription has been removed');
      setRemoveSubscriptionInfo({});
      setConfirmDeleteModal(false);
      // Refetch Updated User's List
      setUrl(
        `/api/v1/client_portal/card_issuing/programs/${program.id}/reporting`
      );
      setForceRetch({});
      setLoading(false);
    } catch (e) {
      setSuccess(null);
      setConfirmDeleteModal(false);
      setError('Failed to Delete Report Subscription');
      setLoading(false);
    }
  };

  if (isLoading || loading) return <Loader />;
  if (isError) return <ClientErrorComponent data={{}} />;

  const handleOpenConfirmDeleteModal = value => {
    setRemoveSubscriptionInfo(value);
    setConfirmDeleteModal(true);
  };

  const columns = [
    {
      Header: 'ID',
      accessor: 'id',
    },
    {
      Header: 'Report Type',
      accessor: 'report',
      Cell: ({ value }) => humanizeString(value),
    },
    {
      Header: 'Email',
      accessor: 'email',
    },
    {
      Header: 'Frequency',
      accessor: 'frequency',
      Cell: ({ value }) => capitalize(value.slice(1)),
    },
    {
      Header: 'Time Range',
      accessor: 'time_range',
      Cell: ({ value }) => humanizeString(value),
    },
    {
      Header: 'Last Report Sent',
      accessor: 'last_run_time',
    },
    {
      Header: 'Reporting Method',
      accessor: 'reporting_method',
    },
    {
      Header: 'Actions',
      maxWidth: 90,
      style: { margin: 'auto 0' },
      accessor: item => item,
      id: 'delete',
      Cell: ({ value }) => (
        <ButtonGroup>
          <IconButton
            iconType="delete"
            variant="text"
            disabled={userRole == 'user' }
            type="danger"
            iconSize={16}
            onClick={() => handleOpenConfirmDeleteModal(value)}
          />
        </ButtonGroup>
      ),
    },
  ];

  return (
    <>
      <Card>
        <Title>Reporting Subscriptions</Title>
        {success && <Alert type="success">{success}</Alert>}
        {error && <Alert type="danger">{error}</Alert>}
        <Table columns={columns} data={reports} />
        <ActionBar>
          <Button
            type="primary"
            disabled={userRole == 'user' }
            onClick={() =>
              history.push({
                pathname: `/card-issuing/programs/${program.id}/reports/`,
                state: { program },
              })
            }>
            New Report Subscription
          </Button>
        </ActionBar>
      </Card>
      {confirmDeleteModal && (
        <ConfirmDelete
          onClick={() => handleRemoveSubscription()}
          closeModal={() => setConfirmDeleteModal(!confirmDeleteModal)}
          removeValue={`ID ${removeSubscriptionInfo.id}`}
        />
      )}
    </>
  );
});
