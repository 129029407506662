import React from 'react';
// Import Global Components
import Table from '../../../../../../components/Table';
// Import Local Components
import Section from '../../Section';

export default ({ columns, limits }) => (
  <div>
    <Section>
      <p>
        This limit applies globally on a <strong>per Transaction</strong> basis
      </p>
    </Section>
    <Section>
      <p>
        These limits restrict the amount of any given Transaction for a specific{' '}
        <strong>Payment Network</strong>, <strong>Product</strong> and{' '}
        <strong>Transaction Type</strong>
      </p>
    </Section>
    <div className="section2">
      <p>Transaction Network Limits</p>
      <ul className="list">
        {/* <li>
          <p>EFT (BAMBORA) - Your maximum single transaction size is $50,000.00</p>
        </li>
        */}
        <li>
          <p>
            Visa - Your maximum <strong>PUSH</strong> transaction size is
            $50,000.00 for Disbursements
          </p>
        </li>
        <li>
          <p>
            Visa - Your maximum <strong>PUSH</strong> transaction size is
            $10,000.00 for Person to Person
          </p>
        </li>
      </ul>
    </div>
    <Table data={limits} columns={columns} sortable={false} />
  </div>
);
