import React from 'react';
// Import Shared Components
import { Card, Table, Title, Link, Loader } from 'shared-components';
// Import Global Components
import ClientErrorComponent from '../../../components/ClientErrorComponent';
// Import Component Styles
import { Container } from '../styles';
// Import Common Hooks
import useTellerApi from '../../../hooks/useTellerApi';

export default () => {

  const [{ 
    data: {
      data: programs, 
    }, isLoading, isError }] = useTellerApi('/api/v1/client_portal/card_issuing/programs', {
    count: 0, data: [], limit: 100, offset: 0});

  if (isLoading) return <Loader />;
  if (isError) return <ClientErrorComponent data={{}} />;

  const tableData = programs;

  const columns = [
    {
      Header: 'Program ID',
      accessor: 'id',
    },
    {
      Header: 'Program Name',
      accessor: 'name',
    },
    {
      Header: 'Status',
      accessor: 'status',
    },
    {
      Header: 'Master Funding Account #',
      accessor: 'master_funding_account_number',
    },
    {
      Header: 'Created At',
      accessor: 'created_at',
    },
  ];

  return (
    <Card>
      <Container>
        <div>
          <Title>Card Issuing - Programs</Title>
          <Table data={tableData} columns={columns} />
        </div>
        <Link type="primary" to="card-issuing/programs">
          See More Program Details
        </Link>
      </Container>
    </Card>
  );
};
