import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import { QUERY } from '../../../../../graphql/queries/accountHoldersQuery';

const MUTATION = gql`
  mutation updateCustomer(
    $id: Int!
    $type: AccountHolderType!
    $firstName: String
    $lastName: String
    $email: String!
    $phone: String
  ) {
    updateCustomer(
      id: $id
      input: {
        type: $type
        firstName: $firstName
        lastName: $lastName
        email: $email
        phone: $phone
      }
    )
  }
`;

export default graphql(MUTATION, {
  props: ({ mutate }) => ({
    updateAccountHolder: ({ id, type, firstName, lastName, email, phone }) =>
      mutate({ variables: { id, type, firstName, lastName, email, phone } })
  }),
  options: {
    refetchQueries: [{ query: QUERY }],
    fetchPolicy: 'no-cache'
  }
});
