import { get, set, del } from './storage';

export const TOKEN_KEY = 'bkl_tkn';
export const EXP_KEY = 'bkl_exp';

export const setToken = (t, e) => {
  set(TOKEN_KEY, t);
  set(EXP_KEY, e);
};

export const getToken = () => {
  const token = get(TOKEN_KEY) || null;
  const exp = get(EXP_KEY) || null;
  return { token, exp };
};

export default (() => {
  return {
    isAuthenticated: () => {
      const { token, exp } = getToken();
      return token && exp && Math.floor(+Date.now() / 1000) < parseInt(exp, 10);
    },
    logout: () => {
      del(TOKEN_KEY);
      del(EXP_KEY);
      return Promise.resolve();
    },
  };
})();

// The interval in milliseconds that we check the expiry value of the token
// NOTE: This is a sync call and will block the UI so we should not do this too often
export const TOKEN_EXPIRY_CHECK_INTERVAL = 10000;

// The number of seconds before the token expires that the warning modal is displayed to a user, to allow them to refresh their token
export const SECONDS_TILL_LOGOUT_WARNING = 90;
