import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

const QUERY = gql`
  query(
    $id: String
    $status: String
    $dateFrom: String
    $dateTo: String
    $amountFrom: String
    $amountTo: String
  ) {
    viewer {
      id
      company {
        id
        tokenizationKey
      }
      customer(id: $id) {
        ... on CustomerIndividual {
          id
          email
          contactDetails {
            fullName
          }
          paymentSearch(
            status: $status
            date_from: $dateFrom
            date_to: $dateTo
            amount_from: $amountFrom
            amount_to: $amountTo
          ) {
            data {
              id
              latestStatus
              notes
              amount
              currency
              transferId
              insertedAt
            }
          }
        }
        ... on CustomerCompany {
          id
          email
          contactDetails {
            fullName
          }
          paymentSearch(
            status: $status
            date_from: $dateFrom
            date_to: $dateTo
            amount_from: $amountFrom
            amount_to: $amountTo
          ) {
            data {
              id
              latestStatus
              notes
              amount
              currency
              transferId
              insertedAt
            }
          }
        }
      }
    }
  }
`;

export default graphql(QUERY, {
  options: ({ match: { params: { id } }, searchData }) => ({
    variables: {
      id,
      status: searchData.status || null,
      dateFrom:
        (searchData.dateFrom && searchData.dateFrom.toISOString()) || null,
      dateTo: (searchData.dateTo && searchData.dateTo.toISOString()) || null,
      amountFrom:
        (searchData.amountFrom && parseInt(searchData.amountFrom, 10)) || null,
      amountTo:
        (searchData.amountTo && parseInt(searchData.amountTo, 10)) || null,
    },
    fetchPolicy: 'cache-and-network',
  }),
});
