import React from 'react';
// Import Component Styles
import { formatCurrencyV } from 'shared-components/src/utils/formatCurrency';
import { ValueUnloadDetails, ValueUnloadDetail } from './styles';
// Import Utils

export default ({ valueUnloadDetails }) => (
  <ValueUnloadDetails>
    <ValueUnloadDetail>
      <p>Total Value Loads</p>
      <span>{valueUnloadDetails.unloads}</span>
    </ValueUnloadDetail>
    <ValueUnloadDetail>
      <p>Total Unload Amount</p>
      <span>
        {formatCurrencyV(valueUnloadDetails.amount)}
      </span>
    </ValueUnloadDetail>
  </ValueUnloadDetails>
);
