import styled from 'styled-components';

export const CardholderDetail = styled.div`
  display: flex;
  flex-direction: row;

  &:not(:first-child) {
    margin-top: 15px;
  }

  span {
    margin-left: 15px;
    font-weight: bold;
  }

  ${({ theme }) => theme.media.tabletPortraitUp`
  flex-direction: column;

  &:not(:first-child) {
    margin-top: 0;
    margin-left: 15px;
  }

    span {
      margin-left: 0;
    }

    &:last-child {
      text-align: right;
    }
  `}
`;

export const TitleContainer = styled.div`
  margin-bottom: 10px;
`;
