import React, { useState, useEffect } from 'react';
// Import Shared Components
import {
  Card,
  CardGroup,
  // DatePicker,
  Loader,
  Alert,
} from 'shared-components';
import {
  getEndOfDay,
  subtractMonth,
  currentDateTime,
  // getStartAndEndDates,
} from 'shared-components/src/utils/dates';
import { useUpdateStateObject } from 'shared-components/src/hooks/useUpdateStateObject';
// Import Global Components
import ClientErrorComponent from '../../../components/ClientErrorComponent';
// Import Utils
import {
  getStartDate,
  // getDisabledDays,
  // getTimeRangeStartDate,
  ReportTypeOptions,
  reportingMethods,
} from '../../../utils/reports';
import { get } from '../../../utils/http';
// Import Local Components
import ReportSubscriptionForm from './components/ReportSubscriptionForm';

/* eslint react/prop-types: 0 */
export default ({
  history,
  location: {
    state: { company },
  },
}) => {
  // Set States
  const initialReportFormState = {
    reportType: null,
    email: null,
    frequency: null,
    timeRange: null,
    reportingMethod: null,
    startDate: getStartDate(
      company.inserted_at,
      subtractMonth(currentDateTime(), 1)
    ),
    endDate: getEndOfDay(),
  };

  // Set States
  const [loading, setLoading] = useState(null);
  const [error, setError] = useState(null);
  const [reportTypeOptions, setReportTypeOptions] = useState([]);
  const [reportingMethodOptions, setReportingMethodOptions] = useState(
    reportingMethods
  );
  const [submitError, setSubmitError] = useState(null);
  // Create Reducer
  const [reportInfo, setReportInfo] = useUpdateStateObject(
    initialReportFormState
  );

  // Get report configuration for program
  useEffect(() => {
    const getReportConfigs = async () => {
      setLoading(true);
      try {
        const data = await get(
          `/api/v1/client_portal/direct-send/reporting_config`
        );
        // Filter report options based on program's report configuration
        const options = ReportTypeOptions.filter(
          reportType => data[reportType.key]
        );
        setReportTypeOptions(options);
        setLoading(false);
      } catch (e) {
        setError(e);
        setLoading(false);
      }
    };
    setReportingMethodOptions([{ key: 'email', val: 'Email' }]);

    getReportConfigs();
  }, [company.id]);

  if (loading) return <Loader />;
  if (error) return <ClientErrorComponent data={{ error }} />;

  const allowReportSubscriptionCreation = reportTypeOptions.length !== 0;

  return (
    <CardGroup>
      <Card data-id="reporting-form-container">
        {allowReportSubscriptionCreation ? (
          <ReportSubscriptionForm
            reportInfo={reportInfo}
            setLoading={setLoading}
            history={history}
            reportTypeOptions={reportTypeOptions}
            setReportInfo={setReportInfo}
            submitError={submitError}
            setSubmitError={setSubmitError}
            reportingMethodOptions={reportingMethodOptions}
          />
        ) : (
          <Alert type="info">
            There are currently no reports available for your company, please
            contact Berkeley Payments if you would like to receive reports.
          </Alert>
        )}
      </Card>
    </CardGroup>
  );
};