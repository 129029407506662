import React,{ useState, useEffect } from "react";

import {
    Alert,
    Title,    
    Table,
    Card,
    CardGroup,
    Loader,
    Details,
    DetailsGroup,
    Button,    
} from 'shared-components';

import { formatTime } from 'shared-components/src/utils/formatDateTime';
// Import Global Components
import ClientErrorComponent from '../../../components/ClientErrorComponent';
// Import Common Hooks
import useTellerApi from '../../../hooks/useTellerApi';
import { useTimeZone } from '../../../hooks/useTimeZone';
import { getProgramColumns } from './components/detailsColumns';
import { ActionButtons } from './components/actionButtons';
import { CustomButtonGroup } from './components/style';


export default ({ history,
    match: {
        params: { id: orderid },
      },
    }) => {
    const [
      {
        data: { data: orderDetail },
        isLoading,
        isError,
      },
      ,
      setForceRetch,
    ] = useTellerApi(`/api/v1/client_portal/card_issuing/bulk/orders/${orderid}`, {
      count: 0,
      data: [],
      limit: 100,
      offset: 0,
    });

    const [timeZone] = useTimeZone();
    const [success, setSuccess] = useState(null); 
    const [error, setError] = useState(null);

    // Display success message on page if required
  useEffect(() => {    
    if (history.location.state && history.location.state.success) {
      setSuccess(history.location.state.success);
      // Remove success message from state

      const clearSuccess = setTimeout(() => {
        // Clear Success message after 5 seconds
        setSuccess(null);
      }, 5000);
      // clean up timer before useEffect and when component unmounts
      return () => {
        setSuccess(null);
        clearTimeout(clearSuccess);
      };
    }

    return () => setSuccess(null);
  }, [history.location.state]);

    const columns = [
        {
            Header: 'Status',
            accessor: 'status',
        },
        {
           Header: 'Date',
           accessor: 'created_at',
           Cell: ({ value }) => formatTime(value, 'dateTime', timeZone),
        },
        {
            Header: 'Response Code',
            accessor: 'code',
        },
        {
            Header: 'Response Message',
            accessor: 'me',
        },       
      ];
    
  if (isLoading) return <Loader />;
  if (isError) return <ClientErrorComponent data={{}} />;

  return (
    <>
    {error && (
        <Alert type="danger">
          {/* Inline style is for showing line breaks, which would be ignored otherwise */}
          <div style={{ whiteSpace: 'pre-line' }}>{error}</div>
        </Alert>
      )}
    <CardGroup>
        {success && <Alert type="success">{success}</Alert>}
        <Card>
            <Title>Order Details</Title>
            <DetailsGroup>
                {getProgramColumns(timeZone).map(c => (
                <Details
                    key={c[0].header}
                    columns={c}
                    data={orderDetail}
                />
                ))}
            </DetailsGroup>
        </Card>    

        <CustomButtonGroup>
          <Button                
            type="primary"
            onClick={() => {                 
                history.push(`/card-issuing/orderDetails/orderItems/${orderid}`)
            }}                  
          >
            Show Order Items
          </Button>
        </CustomButtonGroup>

        <Card>                                   
          <Title>Order History</Title>
            <Table
            columns={columns}
            data={orderDetail.history}                 
            />
        </Card>
        <CustomButtonGroup>
          <ActionButtons
            order={orderDetail}
            callback={({data, error})=>{
              if(error) {
                setError(error.message)
              } else if(data){
                setForceRetch({});
                history.push({
                  pathname: `/card-issuing/orderDetails/${orderid}`,
                  state: {
                    success:
                      'Order update has been received.',
                  },
                });
              }
            }}
          ></ActionButtons>
        </CustomButtonGroup>
    </CardGroup>
    </>
  );};