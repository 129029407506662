import styled from 'styled-components';

export default styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  & > *:not(:last-child) {
    margin-bottom: 15px;
  }

  & > *:only-child {
    margin-bottom: 0;
  }

  .form-row-text-button {
    align-self: start;
    padding-left: 0;
  }

  ${({ theme }) => theme.media.tabletPortraitUp`
    flex-direction: row;

    & > *:not(:last-child) {
      margin-bottom: 0;
    }

    .form-row-text-button {
      margin-top: 20px;
      padding-left: 16px;
      padding-right: 0;
    }

    & > *:not(:last-child) {
      margin-right: 10px;
    }
  `}
`;
