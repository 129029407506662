import React from 'react';
import { Button } from 'shared-components';
import { put } from '../../../../utils/http';

const retryActions = {
  "failed": ["retry"],
  "partial_failure": ["retry"],
  "canceled": ["retry"]
};
const cancelActions = {
  "initializing": ["cancel", "manual_fail"],
  "validating": ["cancel", "manual_fail"],
  "validation_failed": ["cancel", "override_validation"],
  "accepted": ["cancel", "push", "manual_fail"],
  "on_hold": ["cancel", "release_hold", "manual_fail"],
  "waiting": ["cancel", "release_wait", "manual_fail"],
  "processing": ["manual_fail", "manual_complete"]
};

export const ActionButtons = ({ order, callback }) => {
  let actions = [];

  if (order.status in retryActions) {
    actions = retryActions[order.status];
  } else if (order.status in cancelActions) {
    actions = cancelActions[order.status];
  }

  // If the order status doesn't match any condition, or actions array is empty, we don't render the buttons
  if (!actions.length) {
    return null;
  }

  const handleAction = async (action) => {
    const body = {
      action: action
    };

    try {
      const response = await put(`/api/v1/client_portal/card_issuing/bulk/orders/${order.id}`, body);
      callback(response);
    } catch (error) {
      callback(error);
    }
  };

  return (
    <>
      {actions.map((action, index) => (
        <Button 
          key={index}
          type="primary" 
          onClick={() => handleAction(action)}>
          {action.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase())}
        </Button>
      ))}
    </>
  );
};
