import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// Import Shared Components
import {
  ActionBar,
  Button,
  ButtonGroup,
  Modal,
  Alert,
  Input,
  Card,
  CardGroup,
  Loader,
  ClientErrorComponent,
} from 'shared-components';
// Import Shared Validations
import {
  isNameValid,
  isAmountValid,
} from 'shared-components/src/validations/collectionValidations';
import { isEmailValid } from 'shared-components/src/validations/transferValidations';
import { validateExternalTag } from 'shared-components/src/validations/valueLoadValidations';
// Import Shared Utils
import {
  parseAmountToCents,
  formatCurrency,
  centsToDollars,
} from 'shared-components/src/utils/currency';
// Import Global Components
import HeaderSection from '../../../components/HeaderSection';
import HeaderBar from '../../../components/HeaderBar';
import CollectionTotal from '../../../components/TransferTotal';
// Import Common Hooks
import useTellerApi from '../../../hooks/useTellerApi';
// Import Common Utils
import { post } from '../../../utils/http';
import { MAX_RECIPIENT_COUNT } from '../../../utils/config';
import { getTotalAmount } from '../../../utils/collections';
// Import Local Components
import ConfirmCollection from './components/ConfirmCollectionModal';
import LoadingCollection from './components/LoadingCollectionModal';
import CreateCollectionForm from './components/CreateCollectionForm';
// Import Defaults
import {
  DEFAULT_RECIPIENT_ERROR,
  DEFAULT_RECIPIENT_DATA,
  COLUMNS,
  PREFIXES,
} from './defaults';

const CreateCollection = ({ history }) => {
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const [total, setTotal] = useState(0);
  const [valid, setValid] = useState(false);
  const [externalTag, setExternalTag] = useState('');
  const [externalTagError, setExternalTagError] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);

  const [numRecipients, setNumRecipients] = useState(1);
  // const [lastRecipientId, setLastRecipientId] = useState(1);
  const [recipients, setRecipients] = useState({
    [numRecipients]: {
      data: { ...DEFAULT_RECIPIENT_DATA },
      error: { ...DEFAULT_RECIPIENT_ERROR },
      valid: false,
    },
  });

  const currency = 'CAD';

  useEffect(() => {
    // update total amount
    setTotal(getTotalAmount(recipients));

    // update global valid status to disable submission
    const externalTagValid = validateExternalTag(externalTag);
    setExternalTagError(!externalTagValid);

    const validArray = Object.keys(recipients).map(id => recipients[id].valid);

    !externalTagValid || validArray.includes(false)
      ? setValid(false)
      : setValid(true);
  }, [recipients, externalTag]);

  const handleAddRecipient = () => {
    // Update the number of recipients
    const newNumRecipients = numRecipients + 1;

    setNumRecipients(newNumRecipients);
    // setLastRecipientId(newNumRecipients);

    // Use updated number of recipients to add new recipient to recipients
    setRecipients({
      ...recipients,
      [newNumRecipients]: {
        data: { ...DEFAULT_RECIPIENT_DATA },
        error: { ...DEFAULT_RECIPIENT_ERROR },
        valid: false,
      },
    });
  };

  const deleteRecipient = id => {
    const newRecipients = { ...recipients };
    delete newRecipients[id];

    setNumRecipients(numRecipients - 1);
    setRecipients(newRecipients);
  };

  const checkRecipientValid = recipientData => {
    const firstNameValid = isNameValid(recipientData.firstName);
    const lastNameValid = isNameValid(recipientData.lastName);
    const emailValid = isEmailValid(recipientData.email);
    const amountValid = isAmountValid(recipientData.amount);
    const accountRefValid = validateExternalTag(recipientData.accountRef);

    const error = {
      firstNameError: recipientData.firstName === '' ? false : !firstNameValid,
      lastNameError: recipientData.lastName === '' ? false : !lastNameValid,
      emailError: recipientData.email === '' ? false : !emailValid,
      amountError: recipientData.amount === '' ? false : !amountValid,
      accountRefError:
        recipientData.accountRef === '' ? false : !accountRefValid,
    };

    let recipientValid = false;

    if (
      firstNameValid &&
      lastNameValid &&
      emailValid &&
      amountValid &&
      accountRefValid
    ) {
      recipientValid = true;
    }

    return [recipientValid, error];
  };

  const updateRecipient = (id, field, value) => {
    // create updated data for recipient
    const data = { ...recipients[id].data };
    data[field] = value;

    // create updated validation and errors object for recipient
    const [valid, error] = checkRecipientValid(data);

    setRecipients({
      ...recipients,
      [id]: { data, valid, error },
    });
  };

  const toggleModal = () => setConfirmModal(!confirmModal);

  const sendCollection = async body => {
    try {
      const response = await post('/api/v1/client_portal/collections', body);
      if (response.data && response.data.length > 0) {
        setLoadingModal(false);

        const errMsg = response.data.reduce((acc, recipient) => {
          return acc.concat(recipient.error.join(', '));
        }, '');

        setError(` One or more collections failed to process: ${errMsg}.`);

        const newRecipients = response.data.reduce((acc, recipient, idx) => {
          return {
            ...acc,
            [idx]: {
              data: {
                ...recipient,
                firstName: recipient.first_name,
                lastName: recipient.last_name,
                externalTag: recipient.external_tag,
                accountRef: recipient.account_ref,
                amount: centsToDollars(recipient.amount),
              },
              error: { ...DEFAULT_RECIPIENT_ERROR },
              valid: false,
            },
          };
        }, {});

        setRecipients(newRecipients);
      } else {
        setSuccess('Collection(s) created successfully');
        // Transfer has completed so we can push user to transfer details screen
        setTimeout(() => history.push('/collections/collections'), 1000);
      }
    } catch (err) {
      // Remove loading modal and render error
      setError(err);
    }

    setLoadingModal(false);
  };

  const handleSubmit = () => {
    // Remove Confirm Modal from Screen
    toggleModal();

    // Render Loading Modal to Screen
    setLoadingModal(true);

    const data = {
      collections: Object.keys(recipients).map(id => {
        const {
          email,
          firstName,
          lastName,
          amount,
          currency,
          accountRef,
        } = recipients[id].data;

        const formattedAmount = parseAmountToCents(
          formatCurrency(parseFloat(amount), currency)
        );

        return {
          email,
          first_name: firstName,
          last_name: lastName,
          amount: formattedAmount,
          external_tag: externalTag,
          account_ref: accountRef,
          currency,
        };
      }),
    };

    sendCollection(data);
  };

  // Check if this feature is available for this company!
  // Call to teller API for company configs would be sufficient - or could get fancier
  const [{ data: activeSubscription, isLoading, isError }] = useTellerApi(
    '/api/v1/client_portal/collection_config',
    null
  );

  if (isLoading) {
    return (
      <Card>
        <Loader />
      </Card>
    );
  }

  if (isError) {
    return (
      <Card>
        <ClientErrorComponent />
      </Card>
    );
  }

  return (
    <>
      {!activeSubscription.data && (
        <div style={{ marginBottom: '20px' }} key="disabled-block">
          <Alert type="warn">
            Please subscribe to collections before attempting to create a
            collection.
          </Alert>
        </div>
      )}
      {success && <Alert type="success">{success}</Alert>}
      <CardGroup>
        <Card>
          <HeaderBar key="create-collection-header">
            <HeaderSection
              spaceOut
              title="External-Tag (applied to all collections):"
            >
              <Input
                className="collectionName"
                value={externalTag}
                onChange={e => setExternalTag(e.target.value)}
                error={externalTagError}
                name="collection-externalTag"
              />
            </HeaderSection>
            <HeaderSection spaceOut title="Total:" alignItems="flex-end">
              <CollectionTotal total={total} currency={currency} />
            </HeaderSection>
          </HeaderBar>
        </Card>

        <Card>
          <CreateCollectionForm
            recipients={recipients}
            updateRecipient={updateRecipient}
            deleteRecipient={deleteRecipient}
            columns={COLUMNS}
            prefixes={PREFIXES}
          />
          {error && (
            <Alert type="danger">
              Error:
              {error}
            </Alert>
          )}
          <ButtonGroup justifyContent="flex-start">
            <Button
              type="primary"
              onClick={handleAddRecipient}
              disabled={numRecipients >= MAX_RECIPIENT_COUNT}
            >
              Add Collection
            </Button>
          </ButtonGroup>
          <ActionBar>
            <Button
              type="primary"
              size="large"
              onClick={() => {
                setError(null);
                toggleModal();
              }}
              disabled={!valid}
            >
              Send Collection
            </Button>
          </ActionBar>
        </Card>
      </CardGroup>

      {confirmModal && (
        <Modal key="confirmCollectionModal">
          <ConfirmCollection
            total={total}
            currency={currency}
            recipients={Object.keys(recipients).map(
              id => recipients[id].data.email
            )}
            sendCollection={handleSubmit}
            closeModal={toggleModal}
            error={!valid}
          />
        </Modal>
      )}
      {loadingModal && (
        <Modal key="loadingCollectionModal">
          <LoadingCollection />
        </Modal>
      )}
    </>
  );
};

CreateCollection.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  history: PropTypes.any.isRequired,
};

export default CreateCollection;
