import React from 'react';
// Import Shared Components
import { Modal } from 'shared-components';
// Import Local Components
import ModalForm from '../ModalForm';

export default props => (
  <Modal key="edit-account-holder-modal">
    <ModalForm title="Edit" {...props} page="edit-account-holder" disabled />
  </Modal>
);
