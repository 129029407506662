import React from 'react';
// Import Global Components
import Table from '../../../../../../../components/Table';
// Import Local Components
import Section from '../../../Section';
// Import Component Styles
import { SecondSection } from './styles';

export default ({ columns, limits }) => (
  <>
    <Section>
      <p>
        This limit applies per <strong>Payment Instrument</strong> over a{' '}
        <strong>Specific Number of Days</strong>
      </p>
    </Section>
    <Section>
      <p>
        These limits restrict the <strong>Amount/Count</strong> that Pungle can{' '}
        <strong>Push</strong> to a specific <strong>Payment Instrument</strong>{' '}
        on the <strong>Selected Network</strong> over the{' '}
        <strong>Specific Number of Days</strong>
      </p>
    </Section>
    <SecondSection>
      <p>
        {' '}
        <strong>Note:</strong> This applies over all Companies (Merchants) and
        such this limits may be reached by a subset of Merchants who Transact
        using this Payment Instrument
      </p>
    </SecondSection>
    <Table data={limits} columns={columns} sortable={false} />
  </>
);
