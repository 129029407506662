import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import { AccountHolder } from '../../../../graphql/fragments/accountHolderFragments';
import { Viewer } from '../../../../graphql/fragments/viewerFragments';

export const QUERY = gql`
  query($limit: Int, $offset: Int) {
    viewer {
      id
      ...TokenizationKey
      customerSearch(limit: $limit, offset: $offset) {
        count
        data {
          ... on CustomerIndividual {
            id
            type
            email
            defaultFinancialAccountId
            latestStatus
            ...ContactDetailsIndividual
            ...AccountsIndividual
          }
          ... on CustomerCompany {
            id
            type
            email
            defaultFinancialAccountId
            latestStatus
            ...ContactDetailsCompany
            ...AccountsCompany
          }
        }
      }
    }
  }
  ${Viewer.fragments.tokenizationKey}
  ${AccountHolder.fragments.contactDetailsIndividual}
  ${AccountHolder.fragments.contactDetailsCompany}
  ${AccountHolder.fragments.accountsIndividual}
  ${AccountHolder.fragments.accountsCompany}
`;

export default graphql(QUERY, {
  options: props => ({
    fetchPolicy: 'cache-and-network',
    variables: {
      limit: props.pagination ? props.pagination.limit : 10,
      offset: props.pagination ? props.pagination.offset : 0
    }
  })
});
