import styled from 'styled-components';

// Card that contains redemption information
export const Base = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  margin: 25px 0 10px;

  ${({ theme }) => theme.media.tabletPortraitUp`
    width: auto;
    min-width: 500px;
    max-width: 800px;
  `}
`;
