import styled from 'styled-components';

export const KeysTable = styled.div`
  flex-direction: column;
  padding: 10px 30px;
  flex-grow: 1;
`;

export const TableHeadingContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: baseline;

  & > *:last-child {
    margin-left: 15px;
  }
`;
