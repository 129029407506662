import React from 'react';
import { Button, Title } from 'shared-components';
import { ButtonsContainer, ButtonWrapper } from './styles';
import VISA from './icons/visa-logo.png';
import PLUS from './icons/PLUS_Logo.svg';
import OTHERDEBIT from './icons/otherdebit.png';
import EFT from './icons/bank-icon.png';

const possibleNetworks = ['visa', 'eft', 'etransfer'];

export default ({ networks, setPaymentMethod }) => (
  <div>
    <Title>
      Select Redemption Method / Sélectionnez la méthode de remboursement
    </Title>
    <ButtonsContainer>
      {networks.includes(possibleNetworks[0]) && (
        <Button
          variant="text"
          size="large"
          onClick={() => setPaymentMethod(possibleNetworks[0])}
        >
          <ButtonWrapper prominent main>
            <div>
              <img src={VISA} alt="Visa" />
              <img src={PLUS} alt="Plus" />
            </div>
            <span>
              Receive funds instantly using your Visa Plus enabled card
            </span>
            <span>
              Recevez des fonds instantanément si vous avez un logo Visa ou Plus
              sur votre carte
            </span>
          </ButtonWrapper>
        </Button>
      )}
    </ButtonsContainer>
    <ButtonsContainer>
      {networks.includes(possibleNetworks[1]) && (
        <Button
          variant="text"
          size="large"
          onClick={() => setPaymentMethod(possibleNetworks[1])}
        >
          <ButtonWrapper>
            <img src={EFT} alt="Bank Transfer" />
            <span>Receive funds through Bank Transfer</span>
          </ButtonWrapper>
        </Button>
      )}
      {networks.includes(possibleNetworks[2]) && (
        <Button
          variant="text"
          size="large"
          onClick={() => setPaymentMethod(possibleNetworks[2])}
        >
          <ButtonWrapper>
            <img src={OTHERDEBIT} alt="Other Debit Cards" />
            <span>
              Receive funds if you don’t have a Visa or Plus logo on your card
            </span>
            <span>
              Recevez des fonds si vous n'avez pas de logo Visa ou Plus sur
              votre carte
            </span>
          </ButtonWrapper>
        </Button>
      )}
    </ButtonsContainer>
  </div>
);
