import React, { useState } from 'react';
// Import Shared Components
import { Modal } from 'shared-components';
// Import Local Components
import ModalForm from '../ModalForm';
// Import Utils
import { put } from '../../../../../utils/http';

export default ({
  closeModal,
  setError,
  setSuccess,
  userInfo,
  setUserInfo,
  validationErrors,
  setValidationErrors,
  refetchUsers,
}) => {
  const [formError, setFormError] = useState(null);

  // Mutation for editing User
  const handleEditUser = async () => {
    if (
      Object.keys(validationErrors).find(
        item => validationErrors[item] === true
      )
    ) {
      return setFormError(
        'Please correct invalid or blank fields and resubmit.'
      );
    }

    try {
      await put(`/api/v1/client_portal/users/${userInfo.id}`, {
        user_params: {
          email: userInfo.email,
          first_name: userInfo.firstName,
          last_name: userInfo.lastName,
          role: userInfo.role,
        }
      });
      setFormError(null);
      setSuccess('Team Member has been updated successfully');
      refetchUsers();
      closeModal();
    } catch (e) {
      setSuccess(null);
      closeModal();
      return setError('Error Updating Team Member');
    }
  };

  return (
    <Modal key="edit-user-modal">
      <ModalForm
        closeModal={closeModal}
        error={formError}
        userInfo={userInfo}
        setUserInfo={setUserInfo}
        validationErrors={validationErrors}
        setValidationErrors={setValidationErrors}
        submit={handleEditUser}
        title="Edit Team Member"
        page="edit-user"
      />
    </Modal>
  );
};
