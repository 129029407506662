import React, {useEffect, useState } from 'react';
import { get, post } from '../../../../utils/http';
import {
  ButtonGroup,
  Loader,
  ClientErrorComponent,
  Details,
  Alert,
  ActionBar,
  SubmitButton,
  InputGroup,
} from 'shared-components';
import { useUpdateStateObject } from 'shared-components/src/hooks/useUpdateStateObject';
import { isSshKeyValid } from '../../../../utils/validations';

//role context
import {RoleContext} from '../../../../context/RoleContext';

export default ({ id }) => {
  const [sftpConfig, setSftpConfig] = useState(null);
  const [sftpUserConfig, setSftpUserConfig] = useState(null);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [alert, setAlert] = useState(null);
  const initialReportFormState = {
    ssh_key: '',
  };
  const [sftpUserInput, setSftpUserInput] = useUpdateStateObject(
    initialReportFormState
  );
  const sshKeyPlaceholder =
    'ssh-rsa AAAAB3NzaC1yc2EAAAADAQABAAAAgQCG3BVBJHAXCtJqqch/HJ+wEb8jEm6MiXc3LTN6zkfT1sj/ciscxiDvzApTc2SdOTqcc4jUONAfldBcLQa5NnV+oP/IKSSDdQ3TPW6d3wotszuE970iAT30b4CtS2P67xOQ3COfk6lHj4ue3UvowBH4/BXesGs1KrdfbzjE10mSkw= username@desktop';

  // Get role from context
  const userRole = React.useContext(RoleContext); 
  useEffect(() => {
    if (!id) return;

    const fetch = async () => {
      setIsError(false);
      setIsLoading(true);
      // get funtion in utils http file
      try {
        const [response, res2] = await Promise.all([
          get('/api/v1/client_portal/card_issuing/sftp?company_id=' + id),
          get('/api/v1/client_portal/card_issuing/sftpuser?company_id=' + id),
        ]);
        setSftpConfig(response.data);
        setSftpUserConfig(res2.data);
        if (response.data === null){      
          setAlert({
            type: "warn",
            message: "Company has not been configured for SFTP. Please contact Berkeley Payments if you would like to implement this feature.",
          });
        }
      } catch (error) {
        setAlert({
          type: 'danger',
          message: error.message,
        });
        setIsError(true);
      }

      setIsLoading(false);
    };

    fetch();
  }, [id]);

  const createSftpUserConfig = async (params) => {
    try {
      // create external account
      const response = await post(
        '/api/v1/client_portal/card_issuing/sftpuser',
        params
      );

      setSftpUserConfig(response.data);
      setIsLoading(false);
    } catch (e) {
      setAlert({ type: 'danger', message: e.message });
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setAlert(null);
    if (!isSshKeyValid(sftpUserInput.ssh_key)) {
      setAlert({
        type: 'danger',
        message: "SSH public key is invalid. Must start with 'ssh'",
      });
      setIsLoading(false);
      return;
    }
    const params = {
      sftp_user_config: {
        company_id: id,
        ssh_key: sftpUserInput.ssh_key,
      },
    };

    createSftpUserConfig(params);
  };

  const columns = [
    { accessor: 'user', header: 'Username:' },
    { accessor: 'sshKey', header: 'SSH Public Key:' },
  ];

  const renderBody = () => {
    if (isError) return <ClientErrorComponent />;
    if (isLoading) return <Loader />;
    if (sftpConfig && sftpUserConfig) {
      const data = {
        user: sftpConfig.sftp_path,
        sshKey: sftpUserConfig.ssh_key,
      };
      return (
        <div>
          <Details columns={columns} data={data} />
        </div>
      );
    }
    if (sftpConfig && !sftpUserConfig)
      return (
        <div>
          <form onSubmit={(e) => handleSubmit(e)}>
            {alert && <Alert type={alert.type}>{alert.message}</Alert>}
            <InputGroup
              label="SSH Public Key:"
              as="textarea"
              id="sshKey"
              value={sftpUserInput.ssh_key}
              onChange={(e) =>
                setSftpUserInput({
                  key: 'ssh_key',
                  value: e.target.value.trim(),
                })
              }
              cols="100"
              rows="7"
              placeholder={sshKeyPlaceholder}
              required
            />
            <ActionBar>
              <ButtonGroup reverse>
                <SubmitButton disabled={userRole == 'user'} />
              </ButtonGroup>
            </ActionBar>
          </form>
        </div>
      );
    if (!sftpConfig && !sftpUserConfig)
      return (
        <div>{alert && <Alert type={alert.type}>{alert.message}</Alert>}</div>
      );
    return null;
  };

  return <div>{renderBody()}</div>;
};
