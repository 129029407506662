import React from 'react';
// Import Shared Components
import {
  Card,
  Title,
  Details,
  Loader,
} from 'shared-components';
// Import Global Components
import ClientErrorComponent from '../../../../components/ClientErrorComponent';
// Import Local Components
import { escrowColumns } from '../columns';
// Import Common Hooks
import useTellerApi from '../../../../hooks/useTellerApi';

export default ({
  programID
}) => {

  const [{
    data: {
      data: mfaBalance,
    }, isLoading, isError }] = useTellerApi(`/api/v1/client_portal/card_issuing/programs/${programID}/master_funding_account_balance`, []);

  if (isLoading) return <Loader />;
  if (isError) return <ClientErrorComponent data={{}} />;

  return (
    <Card>
      <Title>Escrow Account</Title>
      <Details columns={escrowColumns} data={mfaBalance} />
    </Card>
  );
};
