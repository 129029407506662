import React, { useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';
// Import Contexts
import auth from 'shared-components/src/utils/auth';
import { ServiceConfigContext } from '../../context/ServiceConfigContext';
import { RoleContext } from '../../context/RoleContext';
// Import Utils

export default ({
  priv = false,
  authorize = false,
  allowedRoles = [],
  service,
  reedemPayoutPage,
  ...props
}) => {
  const serviceConfiguration = useContext(ServiceConfigContext);
  const userRole = useContext(RoleContext);

  const isAuthenticated = !!auth.isAuthenticated();
  const notAuthorized = authorize && !allowedRoles.includes(userRole);

  // Checks for Authentication and redirects to login if not authenticated
  if (priv && !isAuthenticated) return <Redirect to="/auth/login" />;

  // If on a pre-authenticated page and authenticated, redirect to homepage
  if (!priv && isAuthenticated && !reedemPayoutPage) return <Redirect to="/" />;

  // Check for Authorization
  // Check if user role is allowed to access the page
  if (notAuthorized) return <Redirect to="/" />;

  // Check if service they're trying to access is configured for their company
  if (service && !serviceConfiguration[service]) return <Redirect to="/" />;

  return <Route {...props} />;
};
