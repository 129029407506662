import { compose } from 'recompose';
import {
  renderWhileLoading,
  setRefetchProp,
  renderForError,
} from '../../../state/apolloRecompose';

import AccountHolderDetails from './AccountHolderDetails';

import withAccountHolderQuery from './enhancers/withAccountHolderQuery';
import withSearchDataState from './enhancers/withSearchDataState';
import withParamsState from './enhancers/withParamsState';
import withSearchByState from './enhancers/withSearchByState';
import withFilterButtonState from './enhancers/withFilterButtonState';
import withSearchFilterState from './enhancers/withSearchFilterState';
import withHandlers from './enhancers/withHandlers';

export default compose(
  withSearchDataState,
  withParamsState,
  withSearchByState,
  withFilterButtonState,
  withSearchFilterState,
  withAccountHolderQuery,
  withHandlers,
  renderWhileLoading,
  setRefetchProp,
  renderForError
)(AccountHolderDetails);
