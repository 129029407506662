import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import { QUERY } from '../../../../../graphql/queries/accountHoldersQuery';

const MUTATION = gql`
  mutation removeCustomer($id: String!) {
    removeCustomer(id: $id)
  }
`;

export default graphql(MUTATION, {
  props: ({ mutate }) => ({
    removeAccountHolder: ({ id }) =>
      mutate({
        variables: { id }
      })
  }),
  options: {
    refetchQueries: [{ query: QUERY }],
    fetchPolicy: 'no-cache'
  }
});
