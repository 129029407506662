export const getSectionOpen = pathname => {
  if (pathname === '/') return 0;
  if (pathname === '/api-keys') return 3;
  if (pathname === '/help') return 5;

  const section = pathname.split('/')[1];

  switch (section) {
  case 'direct-send':
    return 1;
  case 'card-issuing':
    return 2;
  case 'settings':
    return 4;
  default:
    return 0;
  }
};
