import styled from 'styled-components';

export const SuccessCheck = styled.div`
  height: 70px;

  @keyframes scaleAnimation {
    0% {
      opacity: 0;
      transform: scale(1.5);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }

  @keyframes drawCircle {
    0% {
      stroke-dashoffset: 151px;
    }
    100% {
      stroke-dashoffset: 0;
    }
  }

  @keyframes drawCheck {
    0% {
      stroke-dashoffset: 36px;
    }
    100% {
      stroke-dashoffset: 0;
    }
  }

  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  #successAnimationCircle {
    stroke-dasharray: 151px 151px;
    stroke: ${({ theme }) => theme.colors.green};
  }

  #successAnimationCheck {
    stroke-dasharray: 36px 36px;
    stroke: ${({ theme }) => theme.colors.green};
  }

  #successAnimationResult {
    fill: ${({ theme }) => theme.colors.green};
    opacity: 0;
  }

  #successAnimation.animated {
    animation: 1s ease-out 0s 1 both scaleAnimation;

    #successAnimationCircle {
      animation: 1s cubic-bezier(0.77, 0, 0.175, 1) 0s 1 both drawCircle,
        0.3s linear 0.9s 1 both fadeOut;
    }

    #successAnimationCheck {
      animation: 1s cubic-bezier(0.77, 0, 0.175, 1) 0s 1 both drawCheck,
        0.3s linear 0.9s 1 both fadeOut;
    }

    #successAnimationResult {
      animation: 0.3s linear 0.9s both fadeIn;
    }
  }
`;
