// Function for determining the correct CSV requirements for activate cards
export const getCardsCSVFieldDetails = () => {

  let baseDetails = [
    {
      field: 'ID',
      required: 'Yes',
      requirements: 'Numbers only.',
      notes:
        'Order item ID',
      example: '14563',
    },
    {
      field: 'Qty',
      required: 'Yes',
      requirements:
        'Numbers only.',
      notes: 'Quantity of cards',
      example: '42',
    },
    {
      field: 'First name',
      required: 'Yes',
      requirements:
        '1-40 characters; letters, dashes, periods, spaces and apostrophes.',
      notes: 'First Name of cardholder.',
      example: 'John',
    },
    {
      field: 'Last name',
      required: 'Yes',
      requirements:
        '1-40 characters; letters, dashes, periods, spaces and apostrophes.',
      notes: 'Last Name of cardholder.',
      example: 'Smith',
    },
    {
      field: 'Fourth Line Embossing',
      required: 'No',
      requirements:
        '',
      notes: '',
      example: 'Sample Text',
    },
    {
      field: 'Address Line 1',
      required: 'Yes',
      requirements:
        'Maximum length of 40 characters. Cannot be a P.O. Box',
      notes: 'Cardholder\'s address line 1',
      example: '35 Main Street',
    },
    {
      field: 'Address Line 2',
      required: 'No',
      requirements:
        'Maximum length of 30 characters.',
      notes: 'Cardholder\'s address line 2',
      example: 'Apt.4B',
    },
    {
      field: 'City',
      required: 'Yes',
      requirements:
        'Letters, dashes and spaces, maximum length of 20 characters',
      notes: 'Cardholder\'s city.',
      example: 'Toronto',
    },
    {
      field: 'Province/ State',
      required: 'Yes',
      requirements:
        'Valid 2 character state abbreviation.',
      notes: 'Cardholder\'s state (US) or province (CA).',
      example: 'ON',
    },
    {
      field: 'Country',
      required: 'Yes',
      requirements:
        'Valid 2 character country abbreviation.',
      notes: 'Cardholder\'s country.',
      example: 'CA',
    },
    {
      field: 'Post/ Zip code',
      required: 'Yes',
      requirements:
        '12345 or 12345-1234 (US), M5V-2M7 or M5V2M7 (CA).',
      notes: 'Cardholder\'s postal or zip code.',
      example: 'M5V2M7',
    },
    {
      field: 'Shipping To',
      required: 'Yes',
      requirements:
        'Maximum length of 150 characters.',
      notes: 'Full shipping address.',
      example: '33 Main Street, Apt 4B, Toronto, M5V2M7, ON, CA',
    },
  ];
  return baseDetails;
};
