import { graphql } from 'react-apollo';

import { QUERY } from '../../../../../../../graphql/queries/accountHoldersQuery';
import { MUTATION } from '../../../../../../../graphql/mutations/updateFinancialAccount';

export default graphql(MUTATION, {
  props: ({ mutate }) => ({
    updateFinancialAccount: ({ accountHolderId, token, fsId }) =>
      mutate({
        variables: {
          accountHolderId: parseInt(accountHolderId, 10),
          token,
          fsId
        }
      })
  }),
  options: {
    refetchQueries: [{ query: QUERY }],
    fetchPolicy: 'no-cache'
  }
});
