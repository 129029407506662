import React from 'react';
// Import Shared Components
import { Alert, Table } from 'shared-components';
import { capitalize } from 'shared-components/src/utils/string';

// TABLE COLUMNS
const columns = [
  {
    Header: 'ID',
    accessor: 'id',
    show: false,
  },
  {
    Header: 'Type',
    accessor: item => capitalize(item.type),
    id: 'type',
  },
  {
    Header: 'First Name',
    accessor: 'firstName',
  },
  {
    Header: 'Last Name',
    accessor: 'lastName',
  },
  {
    Header: 'Email',
    accessor: 'email',
  },
  {
    Header: 'Phone Number',
    accessor: 'phone',
  },
  {
    Header: 'Error',
    style: {
      color: '#a94442',
      backgroundColor: '#f2dede',
      border: '1px solid #ebccd1',
    },
    accessor: 'error',
    sortable: false,
  },
];

export default ({ data }) => (
  <div>
    <Alert type="danger">
      Error creating one or more Account Holders from uploaded CSV. Please try
      entries below again.
    </Alert>
    <Table columns={columns} data={data} key="errored-account-holders-table" />
  </div>
);
