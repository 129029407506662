import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
// Import Shared Components
import {
  Card,
  CardGroup,
  Table,
  Title,
  CSVButton,
  Loader, 
  Input,
  Button,
} from 'shared-components';
import {
  getStartOfMonth,
  getEndOfMonth,
  subtractMonth,
  setStartOfMonth,
  setEndOfMonth,
  getMonthLongFormat,
} from 'shared-components/src/utils/dates';
import {
  useUpdateStateObject,
  updateManyValuesInStateObject,
} from 'shared-components/src/hooks/useUpdateStateObject';
// Import Graphql Query
import GET_REPORT from '../../../graphql/queries/reportsQuery';
// Import Global Components
import ClientErrorComponent from '../../../components/ClientErrorComponent';
// Import Local Components
import Header from './components/Header';
import getColumns from './components/columns';
import HEADERS from './components/csvHeaders';
// Import Utils
import { getReportsCsvData, fileDate } from '../../../utils/csv';
// Import Hooks
import { useTimeZone } from '../../../hooks/useTimeZone';
// Import Component Styles
import { TableHeader, FiltersGroup } from './styles';
import ReportingSubscriptions from './components/ReportingSubscriptions';

export default ({
  history: { location },
}) => {
  // Define states
  const [report, setReport] = useState('current');
  const [timeZone] = useTimeZone();
  // Define initial state objects
  const initialPagintaionState = {
    limit: 10,
    offset: 0,
    page: 0,
  };
  const initialSearchDataState = {
    dateFrom: getStartOfMonth(),
    dateTo: getEndOfMonth(),       
  };

  const [searchExternalTag, setSearchExternalTag] = useState(null);
  const [searchField, setSearchField] = useState(null);

  // Create states and update functions using custom reducer
  const [pagination, setPagination] = useUpdateStateObject(
    initialPagintaionState
  );
  const [searchData, setSearchData] = useUpdateStateObject(
    initialSearchDataState
  );

  // Query for searching cardholders
  const { loading, error: graphqlError, data: graphqlData } = useQuery(
    GET_REPORT,
    {
      variables: {
        dateTo: searchData.dateTo.toISOString(),
        dateFrom: searchData.dateFrom.toISOString(),
        limit: pagination.limit ? pagination.limit : 10,
        offset: pagination.offset ? pagination.offset : 0, 
        external: searchExternalTag,      
      },
      fetchPolicy: 'network-only',
    }
  );

  if (loading) return <Loader />;
  if (graphqlError) return <ClientErrorComponent />;

  // Retrieve necessary data from query
  const { data, count } = graphqlData.viewer.transactions;
  // Take current transaction data and return data that can be used to create a downloadable csv file
  const csvData = getReportsCsvData(data, timeZone);
  // Get the month of which the current displayed transactions for reporting are being displayed.
  const month = getMonthLongFormat(searchData.dateTo);

  // Handler functions
  const handlePageSizeChange = (pageSize, pageIndex) => {
    const newValues = [
      { key: 'limit', value: pageSize },
      { key: 'offset', value: pageIndex * pageSize },
    ];
    return updateManyValuesInStateObject(newValues, setPagination);
  };

  const handlePageChange = pageIndex => {
    const newValues = [
      { key: 'page', value: pageIndex },
      { key: 'offset', value: pageIndex * pagination.limit },
    ];
    return updateManyValuesInStateObject(newValues, setPagination);
  };

  const toggleButton = key => {
    setReport(key);

    if (key === 'current') {
      const newValues = [
        { key: 'dateFrom', value: getStartOfMonth() },
        { key: 'dateTo', value: getEndOfMonth() },
      ];
      return updateManyValuesInStateObject(newValues, setSearchData);
    }

    const newValues = [
      {
        key: 'dateFrom',
        value: setStartOfMonth(subtractMonth(new Date(), 1)),
      },
      {
        key: 'dateTo',
        value: setEndOfMonth(subtractMonth(new Date(), 1)),
      },
    ];
    return updateManyValuesInStateObject(newValues, setSearchData);
  };

  const executeSearch = () => {   
    setSearchExternalTag(searchField);
  };

  return (
    <CardGroup>
      <Card>
        <ReportingSubscriptions createSuccess={(location.state && location.state.success) || null}/> 
      </Card>
      <FiltersGroup>
        <Card>
          <Header toggleButton={toggleButton} month={month} report={report} />
        </Card>        
        <Card>
        <Input
           value={searchField}
           onChange={e =>  setSearchField(e.target.value)}
          placeholder="Search by external tag"
        />
         <Button
            variant="text"
            type="primary"
            size="small"
            onClick={() => executeSearch()}
          >
            Search
          </Button>
        </Card>
      </FiltersGroup>
      <Card>
        <TableHeader>
          <Title>Report Details</Title>
          <CSVButton
            data={csvData}
            headers={HEADERS}
            filename={`report-${month}-${fileDate}`}
          />
        </TableHeader>
        <Table
          columns={getColumns(timeZone)}
          data={data}
          showPagination={count > pagination.limit && true}
          pages={Math.ceil(count / pagination.limit)}
          page={pagination.page}
          manual
          onPageSizeChange={(pageSize, pageIndex) =>
            handlePageSizeChange(pageSize, pageIndex)}
          onPageChange={pageIndex => handlePageChange(pageIndex)}
          defaultSorted={[
            {
              id: 'insertedAt',
              desc: true,
            },
          ]}
        />
      </Card>
    </CardGroup>
  );
};
