import React, { useState } from 'react';
// Import Shared Components
import {
  Card,
  CardGroup,
  Title,
  Button,
  ButtonGroup,
  ActionBar,
  UploadButton,
  Link,
  Alert,
  CSVButton,
  IconButton,
} from 'shared-components';
// Import Global Components
import Table from '../../../components/Table';
import { LinkGroup } from '../../../components/Link';
import ConfirmDelete from '../../../components/ConfirmDelete';
// Import Local Components
import Accounts from './components/Accounts';
import CreateAccountHolderModal from './components/CreateAccountHolderModal';
import EditAccountHolderModal from './components/EditAccountHolderModal';
import AccountHolderTypeTag from './components/AccountHolderTypeTag';
import {
  accountHolderHeaders,
  createAccountHolderHeaders,
} from './components/csvHeaders';
// Import Component Styles
import { NoAccountHolders, Account, TableHeader } from './styles';
// Import Util Functions
import { getCurrentAccountHolders } from '../../../utils/accountHolders';
import { getAccountHolderCSVData } from '../../../utils/csv';

export default ({
  match: { path },
  data: {
    viewer: {
      customerSearch: { data, count },
      company: { tokenizationKey },
    },
  },
  createAccountHolderModal,
  toggleCreateAccountHolderModal,
  editAccountHolderModal,
  openEditAccountHolderModal,
  closeEditAccountHolderModal,
  onCreateAccountHolder,
  onRemoveAccountHolder,
  onEditAccountHolder,
  editAccountHolderState,
  openConfirmDeleteModal,
  closeConfirmDeleteModal,
  confirmDeleteModal,
  removeAccountHolderInfo,
  uploadCsv,
  success,
  updateSuccess,
  error,
  expand,
  toggleExpand,
  openFirstExpandAndResetPagination,
  page,
  pagination,
  handlePageChange,
  handlePageSizeChange,
}) => {
  const [uploadCSV, setUploadCSV] = useState(false);
  // Get data of account holders that are active
  const currentAccountHolders = getCurrentAccountHolders(data);
  // Take current account holder data and return data that can be used to create a
  // downloadable csv file
  const csvData = getAccountHolderCSVData(currentAccountHolders);

  const columns = [
    {
      Header: 'ID',
      accessor: 'id',
      show: false,
    },
    {
      Header: 'Name',
      style: { display: 'inline' },
      accessor: 'contactDetails.fullName',
    },
    {
      Header: 'Type',
      accessor: 'type',
      maxWidth: 120,
      Cell: ({ value }) => <AccountHolderTypeTag type={value} />,
    },
    {
      Header: 'Email',
      accessor: 'email',
    },
    {
      Header: 'Phone Number',
      accessor: 'contactDetails.phone',
      Cell: ({ value }) => value || '--',
    },
    {
      Header: 'Financial Accounts',
      accessor: 'accounts',
      expander: true,
      Expander: ({ original }) => {
        // Currently count only includes Active Payment Cards
        const accounts =
          original.accounts &&
          original.accounts.filter(
            account =>
              account.latestStatus === 'active' &&
              account.type === 'payment_card'
          );
        return accounts.length > 0 ? (
          <Account accounts>{accounts.length} Accounts</Account>
        ) : (
          <Account>No Accounts</Account>
        );
      },
      sortable: false,
    },
    {
      width: 175,
      Header: 'Transfers',
      accessor: item => item,
      id: 'transactions',
      Cell: ({ value }) => (
        <LinkGroup>
          <Link
            type="primary"
            to={{
              pathname: '/direct-send/create',
              state: { accountHolder: value, type: value.type },
            }}
          >
            Create
          </Link>
          <p>&#124;</p>
          <Link type="primary" to={`${path}/${value.id}`}>
            View
          </Link>
        </LinkGroup>
      ),
      sortable: false,
    },
    {
      Header: 'Actions',
      maxWidth: 90,
      style: { margin: 'auto 0' },
      accessor: item => item,
      id: 'accountHolder',
      Cell: ({ value }) => (
        <ButtonGroup justifyContent="center">
          <IconButton
            iconType="edit"
            iconSize={16}
            type="primary"
            variant="text"
            onClick={() => openEditAccountHolderModal(value)}
          />
          <IconButton
            iconType="delete"
            iconSize={16}
            type="danger"
            variant="text"
            onClick={openConfirmDeleteModal({
              id: value.id,
              name: value.contactDetails.fullName,
            })}
          />
        </ButtonGroup>
      ),
      sortable: false,
      filterable: false,
    },
  ];

  return (
    <>
      {error && <Alert type="danger">{error}</Alert>}
      {success && <Alert type="success">{success}</Alert>}
      <CardGroup>
        <Card>
          <TableHeader>
            <Title>Account Holders</Title>
            {!(currentAccountHolders.length === 0) && (
              <CSVButton
                data={csvData}
                headers={accountHolderHeaders}
                filename={`account-holders-${new Date().toLocaleDateString()}`}
              >
                Export to CSV
              </CSVButton>
            )}
          </TableHeader>
          {currentAccountHolders.length === 0 ? (
            <NoAccountHolders>
              There are no Account Holders associated with this account yet.
            </NoAccountHolders>
          ) : (
            <Table
              columns={columns}
              data={currentAccountHolders}
              expanderDefaults={{
                width: 175,
                resizeable: true,
              }}
              expanded={expand}
              onExpandedChange={(newExpanded, index) =>
                toggleExpand(newExpanded, index)}
              SubComponent={row => (
                <Accounts
                  data={row.original}
                  tokenizationKey={tokenizationKey}
                  openFirstExpandAndResetPagination={openFirstExpandAndResetPagination}
                />
              )}
              showPagination={count > pagination.limit && true}
              pages={Math.ceil(count / pagination.limit)}
              page={page}
              manual
              onPageSizeChange={(pageSize, pageIndex) =>
                handlePageSizeChange(pageSize, pageIndex)}
              onPageChange={pageIndex => handlePageChange(pageIndex)}
            />
          )}
          <ActionBar>
            <ButtonGroup>
              <Button type="primary" onClick={toggleCreateAccountHolderModal}>
                Add Single Account Holder
              </Button>
              <CSVButton
                onClick={() => setUploadCSV(true)}
                filename={`create-account-holders-${
                  new Date().toISOString().split('T')[0]
                }`}
                type="primary"
                data={[]}
                headers={createAccountHolderHeaders}
              >
                Add Multiple Account Holders
              </CSVButton>
              {uploadCSV && (
                <UploadButton
                  type="secondary"
                  onChange={uploadCsv}
                  accept=".csv"
                >
                  Upload CSV
                </UploadButton>
              )}
            </ButtonGroup>
          </ActionBar>
        </Card>
      </CardGroup>

      {createAccountHolderModal && (
        <CreateAccountHolderModal
          closeModal={toggleCreateAccountHolderModal}
          onFormSubmit={onCreateAccountHolder}
          updateSuccess={updateSuccess}
        />
      )}
      {editAccountHolderModal && (
        <EditAccountHolderModal
          accountHolderInfo={editAccountHolderState}
          closeModal={closeEditAccountHolderModal}
          onFormSubmit={onEditAccountHolder}
          updateSuccess={updateSuccess}
        />
      )}
      {confirmDeleteModal && (
        <ConfirmDelete
          onClick={onRemoveAccountHolder}
          closeModal={closeConfirmDeleteModal}
          removeValue={removeAccountHolderInfo.name}
        />
      )}
    </>
  );
};
