import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
// Import Shared Components
import { Alert, CardGroup, Loader } from 'shared-components';
import ClientErrorComponent from '../../../components/ClientErrorComponent';
// Import Local Components
import CreateActions from './components/CreateActions';
import FieldDetails from './components/FieldDetails';
import UploadedValueUnloads from './components/UploadedValueUnloads';
// Import Component Styles
import { CreateHeader } from './styles';

// // Import Utils
import useTellerApi from '../../../hooks/useTellerApi';
import { post, postFormData } from '../../../utils/http';
import { parse, parseValueLoadRows } from '../../../utils/csv';
import { mutationErrorMessageParser } from '../../../utils/errorLogger';
import { centsToDollars } from 'shared-components/src/utils/currency';

import SelectProgramModal from '../CreateCardholders/components/SelectProgramModal';
import CreateCSV from './components/CreateCSV';

// Max number of allowable elements in file
import { VALUE_LOAD_FILE_UPLOAD_LENGTH_LIMIT } from '../../../utils/config';

export default withRouter(({ history }) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const [loadStep, setLoadStep] = useState(1);
  const [valueUnloads, setValueUnloads] = useState([]);
  const [valueUnloadDetails, setValueUnloadDetails] = useState([]);
  const [upload, setUpload] = useState(null);
  const [program, setProgram] = useState(null);
  const [changeProgram, setChangeProgram] = useState(false);
  const [bulkerStep, setBulkerStep] = useState(1);
  const [bulker, setBulker] = useState(false);

  useEffect(()=> {
    if (history.location.state && history.location.state.bulker) {
      setBulker(history.location.state.bulker);
    }
  }, [history.location.state]);
  console.log(bulker);

  // Get list of programs for company on mount
  let path = (history.location.state && history.location.state.bulker) ? '/api/v1/client_portal/card_issuing/programs?bulker_enabled=true' : '/api/v1/client_portal/card_issuing/programs';
  const [
    {
      data: { data: programs },
      isLoading,
      isError,
    },
  ] = useTellerApi(path, {
    count: 0,
    data: [],
    limit: 100,
    offset: 0,
  });
  if (isLoading) return <Loader />;
  if (isError) return <ClientErrorComponent data={{}} />;


  // Function to calculate uploaded CSV value load details
  const getValueUnloadDetails = data => {
    const amount = centsToDollars(
      data.filter(x => x.amount).reduce((x, y) => x + y.amount, 0)
    );

    return {
      unloads: data.length,
      amount
    };
  };
  // Function to handle parsed and validated CSV data
  const handleUploadedLoadValues = data => {
    setValueUnloads(data);

    const details = getValueUnloadDetails(data);
    setValueUnloadDetails(details);
    setError(null);
    setSuccess('CSV has been validated successfully. Please review your submission and click Create Value Unload to begin processing.');
    setLoadStep(2);
    setBulkerStep(3);
    setLoading(false);
  };
  // Function to parse and validate uploaded CSV data
  const handleCSVParse = async e => {
    // Get file and clear file input
    const file = e.target.files[0];
    setUpload(file);
    e.target.value = null;

    try {
      setLoading(true);
      const rows = await parse(file);

      // Ensure that CSV file contains atleast one row of data
      if (rows.data.length <= 1) {
        throw new Error('Uploaded file does not contain any data');
      }

      if (rows.data.length > VALUE_LOAD_FILE_UPLOAD_LENGTH_LIMIT) {
        throw new Error(`Upload File contains more than ${VALUE_LOAD_FILE_UPLOAD_LENGTH_LIMIT} items, please remove some items from the file and try again`);
      }

      const data = rows.data
        .slice(1)
        .map((row, id) => parseValueLoadRows(row, id));

      handleUploadedLoadValues(data);
    } catch (err) {
      setLoading(false);
      setUpload(null);
      const message = mutationErrorMessageParser(err, 'Error uploading CSV.');
      setError(message);
    }
  };

  // Function to handle submission of Value Unloads to Teller
  const handleValueUnloadSubmit = async () => {

    if(bulker)
    {
      try {
        setLoading(true);

        let payload = new FormData();    
        payload.append("program_id", program.id);
        payload.append("client_value_unloads", JSON.stringify(valueUnloads));
        payload.append("description", 'Description');
        payload.append("file", upload);

        //Bulker response returns order id, by witch we redirect to order details page
        const orderResponse = await postFormData('/api/v1/client_portal/card_issuing/bulk_value_loads/unload', payload);

        // Clear Value Unloads from State
        setValueUnloads([]);
        setUpload(null);
        setLoading(false);
        if(orderResponse.id) {
          // Send User to Value Unloads list page, with success message
          history.push({
            pathname: `/card-issuing/orderDetails/${orderResponse.id}`,
            state: { success: 'Value Unloads have been accepted and will begin processing. Value Unloads will appear in the list below as they are processed.' }
          });
        } else if(orderResponse.err_message){
          // Send User to index page, with error message
          setSuccess(null);
          return setError(`Error: Failed to create order-${orderResponse.err_message}`);
        }
      } catch (e) {
        setSuccess(null);
        setLoading(false);
        // Display Errror and keep Value Unloads in State to allow user to try again
        return setError('Error: Failed to Upload Value Unloads, please try again.');
      }
    }
    else
    {
      try {

        setLoading(true);

        // Don't care about response since as long as the Value Unloads where accepted by Teller
        // the process is async from there on
        await post('/api/v1/client_portal/card_issuing/value_loads/bulk/unload', {value_unloads: valueUnloads});

        // Clear Value Unloads from State
        setValueUnloads([]);
        setLoading(false);
        // Send User to Value Unloads list page, with success message
        history.push({
          pathname: '/card-issuing/value-loads',
          state: { success: 'Value Unloads have been accepted and will begin processing. Value Unloads will appear in the list below as they are processed.' }
        });
      } catch (e) {
        setSuccess(null);
        setLoading(false);
        // Display Errror and keep Value Unloads in State to allow user to try again
        return setError('Error: Failed to Upload Value Unloads, please try again.');
      }
    }
  };

  // If loading while csv is parsing or
  // Value Unloads are being uploaded display loader
  if (loading) return <Loader />;

  const handleSelectProgram = programId => {
    // find program data based on program Id
    const [program] = programs.filter(p => p.id === programId);
    setProgram(program);
    setBulkerStep(2);
  };

  const handleChangeSelectedProgram = programId => {
    const [program] = programs.filter(p => p.id === programId);
    // set new program
    setProgram(program);
    // close change program modal
    setChangeProgram(!changeProgram);
  };

  // Only display active programs in this list
  const programOptions = programs
  .filter(p => p.status === 'active')
  .map(p => {
    return { key: p.id, val: p.name };
  });

  if(bulker)
  {
    return(
      <>
       {error && <Alert type="danger">{error}</Alert>}
       {success && <Alert type="success">{success}</Alert>} 

       {bulkerStep === 1 && (
        <SelectProgramModal
          updateItem={handleSelectProgram}
          options={programOptions}
          onClick={() => 
            {
              history.push('/bulker/card-issuing/value-loads')              
            }
          }
        />
        )}
        {changeProgram && (
          <SelectProgramModal
            changeProgram
            updateItem={handleChangeSelectedProgram}
            options={programOptions}
            onClick={() => setChangeProgram(!changeProgram)}
          />
          )}

          <CardGroup>
            {bulkerStep === 2 && (
              <CreateCSV
                setChangeProgram={setChangeProgram}
                program={program}
                setLoadStep={loadStep}
                parseCsv={handleCSVParse}
              ></CreateCSV>
  
  
            )}  
            {loadStep === 2 &&(
              <UploadedValueUnloads
              valueUnloads={valueUnloads}
              setValueUnloads={setValueUnloads}
              setLoadStep={setLoadStep}
              setSuccess={setSuccess}
              setError={setError}
              setBulkerStep={setBulkerStep}
              handleValueUnloadSubmit={handleValueUnloadSubmit}
            />
            )}

            </CardGroup>
          </>
        ); 
      }
      else
      {
        return (
          <>
            {error && <Alert type="danger">{error}</Alert>}
            {success && <Alert type="success">{success}</Alert>}
            <CardGroup>
              <CreateHeader>
                <CreateActions
                  loadStep={loadStep}
                  valueUnloadDetails={valueUnloadDetails}
                />
              </CreateHeader>
              {loadStep === 1 ? (
                <FieldDetails
                  setLoadStep={setLoadStep}
                  parseCsv={handleCSVParse}
                />
              ) : (
                <UploadedValueUnloads
                  valueUnloads={valueUnloads}
                  setValueUnloads={setValueUnloads}
                  setLoadStep={setLoadStep}
                  setSuccess={setSuccess}
                  setError={setError}
                  setBulkerStep={setBulkerStep}
                  handleValueUnloadSubmit={handleValueUnloadSubmit}
                />
              )}
            </CardGroup>
          </>
        );
      } 
});
