import React, { useState, useContext } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
// Import Shared Components
import {
  Alert,
  Card,
  CardGroup,
  IconButton,
  Button,
  ButtonGroup,
  Loader,
} from 'shared-components';
import { formatTime } from 'shared-components/src/utils/formatDateTime';
import { capitalize } from 'shared-components/src/utils/string';
// Import Graphql Query
import GET_API_KEYS from '../../graphql/queries/apiKeyQuery';
// Import Graphql Mutation
import REMOVE_API_KEY from '../../graphql/mutations/removeApiKey';
import CREATE_API_KEY from '../../graphql/mutations/createApiKey';
// Import Global Components
import ConfirmDelete from '../../components/ConfirmDelete';
import ClientErrorComponent from '../../components/ClientErrorComponent';
// Import Local Components
import KeysTable from './components/KeysTable';
// Import Utils
import { getKeyData, tokenKeyInfo, secretKeyInfo } from '../../utils/apikey';
import { mutationErrorMessageParser } from '../../utils/errorLogger';
// Import Hook
import { useTimeZone } from '../../hooks/useTimeZone';
import CopyButton from '../../components/CopyButton';

export default () => {
  // Define states
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [removeKeyId, setRemoveKeyId] = useState(null);
  const [keyType, setKeyType] = useState('token');
  const [timeZone] = useTimeZone();

  // Query for searching cardholders
  const { loading, error: graphqlError, data } = useQuery(GET_API_KEYS);
  // Mutation for creating API Key
  const [createApiKey] = useMutation(CREATE_API_KEY);
  // Mutation for removing API Key
  const [removeApiKey] = useMutation(REMOVE_API_KEY);

  // Render states if data is loading or has errored
  if (loading) return <Loader />;
  if (graphqlError) return <ClientErrorComponent data={{ error: graphqlError }} />;

  // Retrieve key data based on key type
  const publicKeyData = getKeyData(
    data.viewer.company.apiKeys,
    'public_external'
  );
  const privateKeyData = getKeyData(
    data.viewer.company.apiKeys,
    'private_external'
  );

  // Functions to open and close confirm delete modal
  const handleOpenModal = keyId => {
    setRemoveKeyId(keyId);
    setConfirmDeleteModal(true);
  };
  const handleCloseModal = () => {
    setRemoveKeyId(null);
    setConfirmDeleteModal(false);
  };

  // Function to create api key
  const handleCreateApiKey = async tokenType => {
    setError(null);
    // Run mutation to create api key
    try {
      await createApiKey({
        variables: { type: tokenType },
        refetchQueries: [{ query: GET_API_KEYS }],
      });
      setSuccess(
        `${capitalize(keyType)} Api Key has been created successfully`
      );
    } catch (e) {
      const errorMessage = mutationErrorMessageParser(e.message);
      setSuccess(null);
      return setError(errorMessage);
    }
  };

  // Function to remove api key
  const handleRemoveApiKey = async () => {
    setError(null);
    // Run mutation to remove api key
    try {
      await removeApiKey({
        variables: { id: removeKeyId },
        refetchQueries: [{ query: GET_API_KEYS }],
      });
      handleCloseModal();
      setSuccess(
        `${capitalize(keyType)} Api Key has been removed successfully`
      );
    } catch (e) {
      const errorMessage = mutationErrorMessageParser(e.message);
      setSuccess(null);
      return setError(errorMessage);
    }
  };

  // Define api key table columns
  const columns = [
    {
      minWidth: 32,
      maxWidth: 32,
      accessor: 'token',
      Cell: ({ value }) => <CopyButton value={value} />,
      sortable: false,
      filterable: false,
    },
    {
      Header: 'Token',
      accessor: 'token',
      Cell: ({ value }) => '*'.repeat(value.length),
      sortable: false,
      filterable: false,
    },
    {
      Header: 'Date Created',
      accessor: 'insertedAt',
      maxWidth: 300,
      Cell: ({ value }) =>
        value !== '' ? formatTime(value, 'dateTime', timeZone) : '',
    },
    {
      minWidth: 32,
      maxWidth: 32,
      accessor: 'id',
      Cell: ({ value }) =>
        value !== '' && (
          <IconButton
            iconType="delete"
            type="danger"
            variant="text"
            iconSize={16}
            onClick={() => handleOpenModal(value)}
          />
        ),
      sortable: false,
      filterable: false,
    },
  ];

  return (
    <>
      {error && <Alert type="danger">{error}</Alert>}
      {success && <Alert type="success">{success}</Alert>}
      <CardGroup>
        <Card>
          <ButtonGroup>
            <Button
              type="primary"
              variant={keyType !== 'token' && 'text'}
              onClick={() => {
                setSuccess(null);
                setError(null);
                return setKeyType('token');
              }}
            >
              Token
            </Button>
            <Button
              type="primary"
              variant={keyType !== 'secret' && 'text'}
              onClick={() => {
                setSuccess(null);
                setError(null);
                return setKeyType('secret');
              }}
            >
              Secret
            </Button>
          </ButtonGroup>
        </Card>
        {keyType === 'token' ? (
          <KeysTable
            title="Token API Keys"
            columns={columns}
            data={publicKeyData}
            tokenType="PUBLIC_EXTERNAL"
            onClick={handleCreateApiKey}
            info={tokenKeyInfo}
          />
        ) : (
          <KeysTable
            title="Secret API Keys"
            columns={columns}
            data={privateKeyData}
            tokenType="PRIVATE_EXTERNAL"
            onClick={handleCreateApiKey}
            info={secretKeyInfo}
          />
        )}
      </CardGroup>
      {confirmDeleteModal && (
        <ConfirmDelete
          onClick={() => handleRemoveApiKey()}
          closeModal={() => handleCloseModal()}
          removeValue={`${capitalize(keyType)} Key`}
        />
      )}
    </>
  );
};
