import styled from 'styled-components';
import { Card as UnstyledCard } from 'shared-components';

// Wraps Card that contains authorization form and heading
export const AuthWrapper = styled.div`
  display: flex;
  width: calc(100% - 50px);
  max-width: 500px;
  margin: 60px auto 30px;
  flex: 1;
`;

// Card that contains authorization form and heading
export const Card = styled(UnstyledCard)`
  display: flex;
  flex: 1;
  padding: 60px 35px 20px;
`;

export const Auth = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 auto;
  flex: 1;

  ${({ theme }) => theme.media.tabletPortraitUp`
  max-width: 400px;
`}
`;

// Styles the form
export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex: 1;
  width: 100%;

  ${({ theme }) => theme.media.tabletPortraitUp`
    input[type="submit"] {
      width: 100%;
    }
  `}
`;
