import React from 'react';
// Import Global Components
import Header from '../../../../components/Header';

import CreateHeaderCard from '../../CreateCardholders/layouts/CreateHeaderCard';
import DownloadFile from './CreateActions/DownloadFile';
import FieldDetails from './FieldDetails';
import SelectedProgramDetails from '../../CreateCardholders/components/SelectedProgramDetails';

export default ({ setChangeProgram, program, ...props }) => {

    return(
    <>
        <Header>
            <CreateHeaderCard>
                <SelectedProgramDetails
                    changeProgram={setChangeProgram}
                    program={program}
                />
            </CreateHeaderCard>
            <CreateHeaderCard>
                <DownloadFile> </DownloadFile>
            </CreateHeaderCard>
        </Header>
        <FieldDetails
            program={program} {...props}
        ></FieldDetails>
    </>
  );
}