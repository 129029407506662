import React from 'react';
// Import Shared Components
import { Modal } from 'shared-components';
// Import Local Components
import ModalForm from '../ModalForm';

export default props => (
  <Modal key="create-account-holder-modal">
    <ModalForm title="Add New" {...props} page="create-account-holder" />
  </Modal>
);
