import React from 'react';
import { Switch } from 'react-router-dom';
// Import Local Components
import Login from './components/Login';
import ForgotPassword from './components/ForgotPassword';
import Register from './components/Register';
import ResetPassword from './components/ResetPassword';
import Route from './components/Route';
// Import Layouts
import BaseLayout from '../../layouts/Base';

export default ({
  history,
  match: { path },
  email,
  password,
  updateUser,
  updateLoading,
  updateEmail,
  onLoginSubmit,
  handleLoginKeyUp,
  forgotPassword,
  formError,
  emailSent,
  error,
  loading,
  user,
  onUpdatePassword,
  onUpdateEmail,
  updateFormError,
  updateError,
  passwordConfirm,
  onUpdatePasswordConfirm,
  alert,
  updateEmailSent,
}) => (
  <BaseLayout>
    <Switch>
      <Route
        path={`${path}/login`}
        exact
        render={props => (
          <Login
            formError={formError}
            email={email}
            password={password}
            onUpdateEmail={onUpdateEmail}
            onUpdatePassword={onUpdatePassword}
            loading={loading}
            onLoginSubmit={onLoginSubmit}
            handleLoginKeyUp={handleLoginKeyUp}
            forgotPassword={() => {
              updateFormError(null);
              return history.push('/auth/forgot-password');
            }}
          />
        )}
        title="Login"
      />
      <Route
        path={`${path}/forgot-password`}
        exact
        render={props => (
          <ForgotPassword
            formError={formError}
            email={email}
            updateEmail={updateEmail}
            forgotPassword={forgotPassword}
            emailSent={emailSent}
            goBack={() => {
              updateFormError(null);
              updateEmailSent(false);
              return history.push('/auth/login');
            }}
            alert={alert}
          />
        )}
        title="Forgot Password"
      />
      <Route
        path={`${path}/register`}
        exact
        render={props => (
          <Register
            error={error}
            formError={formError}
            location={props.location}
            loading={loading}
            user={user}
            updateUser={updateUser}
            updateLoading={updateLoading}
            password={password}
            onUpdatePassword={onUpdatePassword}
            updateError={updateError}
            updateFormError={updateFormError}
            passwordConfirm={passwordConfirm}
            onUpdatePasswordConfirm={onUpdatePasswordConfirm}
          />
        )}
        title="Register"
      />
      <Route
        path={`${path}/reset-password`}
        exact
        render={props => (
          <ResetPassword
            error={error}
            formError={formError}
            location={props.location}
            loading={loading}
            updateUser={updateUser}
            updateLoading={updateLoading}
            user={user}
            password={password}
            onUpdatePassword={onUpdatePassword}
            updateError={updateError}
            updateFormError={updateFormError}
            passwordConfirm={passwordConfirm}
            onUpdatePasswordConfirm={onUpdatePasswordConfirm}
          />
        )}
        title="Reset Password"
      />
    </Switch>
  </BaseLayout>
);
