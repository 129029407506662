export const runValidationCheck = (setValidationErrors, locationInfo, type) => {
  if (type === 'create') {
    validateCountry(setValidationErrors, locationInfo);
    validateState(setValidationErrors, locationInfo);
    validateProcessor(setValidationErrors, locationInfo);
  }

  return;
};

export const validateCountry = (setValidationErrors, locationInfo) =>
  setValidationErrors({
    key: 'countryCode',
    value: !locationInfo.countryCode,
  });

export const validateState = (setValidationErrors, locationInfo) =>
  setValidationErrors({
    key: 'state',
    value: !locationInfo.state,
  });

export const validateProcessor = (setValidationErrors, locationInfo) =>
  setValidationErrors({
    key: 'processorID',
    value: !locationInfo.processorID,
  });

export const checkForValidationErrors = (locationInfo, type) => {
  if (type === 'create') {
    return !locationInfo.countryCode || !locationInfo.state;
  } 

  return false;
};