import styled from 'styled-components';

export const StyledInputGroup = styled.div`
  position: relative;
  width: 100%;
`;

export const StyledLabelGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 5px;

  label {
    margin-right: 5px;
    margin-bottom: 0px;
  }
`;
