import React from 'react';
import { Alert } from 'shared-components';
// Import Components
import GraphQLError from './components/GraphQLError';
import NetworkError from './components/NetworkError';
// Import Utils
import { errorLogger } from '../../utils/errorLogger';

export default props => {
  if (props.data === undefined || props.data.error === undefined) {
    return <NetworkError />;
  }
  const { networkError, graphQLErrors } = props.data && props.data.error;

  if (networkError) {
    // If Network Error we should be notified since it may be an issue with the graphql server
    errorLogger('GraphQL Error occurred');
    return <NetworkError />;
  }
  if (graphQLErrors && graphQLErrors.length > 0) {
    return <GraphQLError graphQLErrors={graphQLErrors} />;
  }
  errorLogger('Unexpected GraphQL related error occurred');

  return <Alert type="danger">An Error occurred, please try again</Alert>;
};
