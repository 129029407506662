import React, { useState, useEffect } from "react";

import {
    Title,
    Alert,
    Table,
    Card,
    CardGroup,
    Loader,
} from 'shared-components';

import { formatTime } from 'shared-components/src/utils/formatDateTime';
// Import Global Components
import ClientErrorComponent from '../../../components/ClientErrorComponent';
// Import Common Hooks
import { useTimeZone } from '../../../hooks/useTimeZone';
import { get } from '../../../utils/http';

export default ({ history,
    match: {
        params: { id: orderid },
      },
    }) => {
      const initialPaginationState = {
        limit: 20,
        offset: 0,
        page: 0,
    };

    const [pagination, setPagination] = useState(initialPaginationState);
    const [orderItems, setOrdersItems] = useState({ count: 0, data: [] });
    const [isError, setError] = useState(null);
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
      const fetchData = async () => {
        try {
          const query = new URLSearchParams({
            ...pagination,            
          }).toString();

          const ordersListData = await get(`/api/v1/client_portal/card_issuing/bulk/orders/${orderid}/items?${query}`);
          setOrdersItems(ordersListData);        
        }
        catch (e){
          setError(true);
        }
        setLoading(false);
      };
      fetchData();
    }, [pagination, orderid]);

    const handlePageSizeChange = (pageSize, pageIndex) => {
      setPagination({
        ...pagination,
        limit: pageSize,
        offset: pageIndex * pageSize,
      });
    };

    const handlePageChange = pageIndex => {
      setPagination({
        ...pagination,
        page: pageIndex,
        offset: pageIndex * pagination.limit,
      });
    };

    const [timeZone] = useTimeZone();

    if (isLoading) return <Loader />;
    if (isError) return <ClientErrorComponent data={{}} />;

    const columns = [
      {
          Header: 'Item ID',
          accessor: 'id',
      },
      {
          Header: 'Order ID',
          accessor: 'order_id',
      },        
      {
          Header: 'External Tag',
          accessor: 'external_tag',
      },
      {
          Header: 'Type',
          accessor: 'type',
      },
      {
          Header: 'Status',
          accessor: 'status',
      },
      {
          Header: 'Created At',
          accessor: 'created_at',
          Cell: ({ value }) => formatTime(value, 'dateTime', timeZone),
      },   
      {
        Header: 'Updated At',
        accessor: 'updated_at',
        Cell: ({ value }) => formatTime(value, 'dateTime', timeZone),
      },       
    ];
    
  return (
    <>
      <CardGroup>
        {orderItems.data.length > 0 ? (
          <Card>
            <Title>Order Items</Title>
              <Table
                columns={columns}
                data={orderItems.data}               
                getTrProps={(state, rowInfo, column) => {
                  return {
                    style: {
                      cursor: 'pointer',
                    },
                    className: 'hoverable',              
                    onClick: () => {                      
                      history.push(
                        `/card-issuing/orderDetails/orderItemDetails/${rowInfo.original.id}`,
                      );
                    },
                  };
                }}   
                showPagination={orderItems.count > pagination.limit}
                pages={Math.ceil(orderItems.count / pagination.limit)}
                page={pagination.page}
                sortable={false}
                manual
                pageSize={pagination.limit}
                onPageSizeChange={handlePageSizeChange}        
                onPageChange={handlePageChange}                        
              />
          </Card>
        ) : (
          <Alert type="info">
            This Order does not have any items.
          </Alert>
        )}
      </CardGroup>
    </>
  );};