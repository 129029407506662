import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/react-hooks';
// Import Shared Components
// NOTE: Below code commented out for future feature where accounts have actions
// See returned components for more details
import {
  // Button,
  Table,
  Details,
  DetailsGroup,
  // ActionBar,
  // ButtonGroup,
  // ButtonDropdown,
} from 'shared-components';
// Import Graphql Query
import CHANGE_CARD_STATUS from '../../../../graphql/mutations/modifyAccountStatus';
// Import Hook
import { useTimeZone } from '../../../../hooks/useTimeZone';
// Import Local Components
import ConfirmModal from './ConfirmModal';
import ValueLoads from './ValueLoads';
import { Button } from 'shared-components';
// Import Component Styles
import { Account } from './styles';
// Import Utils
import { mutationErrorMessageParser } from '../../../../utils/errorLogger';

import getAccountColumns from './accountColumns';
import getCardColumns from './cardColumns';

export default ({
  cardholder,
  setError,
  setSuccess,
  getOrderStatus,
  orderStatus,
  resendVirtualEmail,
}) => {
  const [confirmModal, setConfirmModal] = useState(false);
  const [modifyDetails] = useState({});
  const [activeCards, setActiveCards] = useState([]);
  const [timeZone] = useTimeZone();

  // Retrieve active cards in all accounts
  useEffect(() => {
    // eslint-disable-next-line array-callback-return
    cardholder.accounts.map(acct => {
      const activeCard = acct.cards.find(card => card.cardStatus === 'N');
      const newActiveCards = activeCards.filter(
        card => card.account !== acct.id
      );
      if (activeCard)
        return setActiveCards([
          ...newActiveCards,
          { account: acct.id, card: activeCard },
        ]);
    });
  }, [activeCards, cardholder]);

  // Mutation to update a card's status within an account
  const [modifyAccountStatus] = useMutation(CHANGE_CARD_STATUS);

  // // Check if an account is active and have active cards. Disable actions on cards if not
  // const checkDisabled = accountId => {
  //   if (activeCards.find(card => card.account === accountId)) return false;

  //   return true;
  // };

  // Function to handle actions to take when modifying card status
  const handleSubmit = async () => {
    try {
      await modifyAccountStatus({
        variables: modifyDetails,
      });
      setError(null);
      setConfirmModal(!confirmModal);
      setSuccess(modifyDetails.successStatus);
    } catch (e) {
      const errorMessage = mutationErrorMessageParser(e.message);
      setSuccess(null);
      return setError(errorMessage);
    }
  };

  const addOrderStatus = (a, idx) => {
    a.cards.map(card => {
      card.order_tracking_number = orderStatus[idx]
        ? orderStatus[idx].order_tracking_number
        : '';
      card.order_status = orderStatus[idx] ? (
        orderStatus[idx].order_status
      ) : ['virtual_reloadable', 'virtual_nonreloadable'].includes(
          a.program_type
        ) ? (
        'Unavailable for this Program.'
      ) : (
        <Button onClick={() => getOrderStatus(idx, a.id)}>
          Get Order Status
        </Button>
      );
      return card;
    });
    return a.cards;
  };

  // NOTE: Feature to be added to each account on CardholderDetails
  // See below for more details
  // Function to handle opening confirm modal
  // const handleOpenModal = (status, accountId) => {
  //   // Success status to be displayed if modifying details is successful
  //   const successStatus = status.includes('suspend')
  //     ? `Card has been successfully ${status}ed`
  //     : `Card has been successfully marked as ${status}`;

  //   setModifyDetails({ status, accountId, successStatus });
  //   setConfirmModal(!confirmModal);
  // };

  return (
    <>
      <Account>
        {cardholder.accounts.map((a, idx) => {
          return (
            <div key={a.id}>
              <DetailsGroup>
                <Details
                  key={`account-details-${idx}`}
                  columns={getAccountColumns(timeZone)}
                  data={a}
                  defaultValue={'-'}
                />
                <h3>Cards</h3>
                <Table
                  key={`card-details-${idx}`}
                  columns={getCardColumns(timeZone, resendVirtualEmail, a)}
                  data={addOrderStatus(a, idx)}
                />
                <h3>Value Loads</h3>
                <ValueLoads account={a} />
              </DetailsGroup>
              {/* NOTE: Feature to be added where action can be taken for each account */}
              {/* <ActionBar>
                  <ButtonGroup>
                  <ButtonDropdown
                    caption="Mark Card..."
                    type="primary"
                    options={[
                      { key: 'lost', val: 'Mark Lost' },
                      { key: 'stolen', val: 'Mark Stolen' },
                    ]}
                    updateItem={key => handleOpenModal(key, a.id)}
                    disabled={checkDisabled(a.id)}
                  />
                  <Button
                    type="primary"
                    onClick={() => {
                      const status = getSuspensionStatus(a.id);
                      return handleOpenModal(status, a.id);
                    }}
                    disabled={checkDisabled(a.id)}
                  >
                    {capitalize(getSuspensionStatus(a.id))} Card
                  </Button>
                </ButtonGroup> 
              </ActionBar> */}
            </div>
          );
        })}
      </Account>
      {confirmModal && (
        <ConfirmModal
          status={modifyDetails.status}
          closeModal={() => setConfirmModal(!confirmModal)}
          onClick={() => handleSubmit()}
        />
      )}
    </>
  );
};
