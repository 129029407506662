export const capitalize = (str = '') =>
  str.charAt(0).toUpperCase() + str.slice(1);

// Function that depends on Browser Apis for URI decodeding
const decode = str => decodeURIComponent(str.replace(/\+/g, ' '));

// Function to pretty incoming information with snake case.
export const removeSnakeCase = str => str.replace(/_/g, ' ');

// Function to decode URI components
// NOTE: This is required since URLSearchParams doesn't work on Safari 10
export const parseURLSearchParams = searchStr => {
  let query = searchStr;

  if (query.charAt(0) === '?') query = query.slice(1);

  if (query === '') return {};

  return query.split('&').reduce((acc, curr) => {
    const index = curr.indexOf('=');
    return index > -1
      ? {
        ...acc,
        [decode(curr.slice(0, index))]: decode(curr.slice(index + 1)),
      }
      : { ...acc, [decode(curr)]: '' };
  }, {});
};

export const formatPostalCode = (str = '') =>
  `${str.slice(0, 3)} ${str.slice(3)}`.toUpperCase();

export const formatAccountString = account => {
  if (account.type === 'payment_card') {
    const { lastFourDigits } = account;
    return account.default
      ? `Payment Card ending in ${lastFourDigits} - DEFAULT`
      : `Payment Card ending in ${lastFourDigits}`;
  }
  const { lastFourDigits } = account;
  return account.default
    ? `Bank Account ending in ${lastFourDigits} - DEFAULT`
    : `Bank Account ending in ${lastFourDigits}`;
};

export const formatPaymentStatus = status => {
  switch (status) {
  case 'successful':
    return 'Successful';
  case 'processing_funding_transaction':
    return 'Processing Funding Transaction';
  case 'funding_transaction_failed':
    return 'Funding Transaction Failed';
  case 'in_progress':
    return 'In progress';
  case 'completed_with_errors':
    return 'Completed with errors';
  case 'failed':
    return 'Failed';
  default:
    return null;
  }
};

export const formatSinglePaymentStatus = status => {
  switch (status) {
  case 'pending':
    return 'Pending';
  case 'processing':
    return 'Processing';
  case 'error':
    return 'Error';
  case 'queued':
    return 'Queued';
  case 'awaiting_approval':
    return 'Awaiting approval';
  case 'notification_sent':
    return 'Notification sent';
  case 'notification_failed':
    return 'Notification Failed';
  case 'redeemed':
    return 'Redeemed';
  case 'redeeming':
    return 'Redeeming';
  case 'cancelling':
    return 'Cancelling';
  case 'canceled':
    return 'Canceled';
  default:
    return null;
  }
};

export const formatNetworkType = networkType => {
  switch (networkType) {
  case 'visa':
    return 'Visa';
  case 'eft':
    return 'EFT';
  case 'etransfer':
    return 'Interac';
  default:
    return null;
  }
};

export const humanizeString = status => {
  const newStatus = removeSnakeCase(status);
  return capitalize(newStatus);
};
