  import React, { useEffect, useState } from 'react';
  // Import Shared Components
  import {
    Title,
    Alert,
    Table,
    Card,
    CardGroup,
    Button,
    Loader,
  } from 'shared-components';
  // Important local components
  import LocationModal from './LocationModal';
  // Import Global Components
  import ClientErrorComponent from '../../../../components/ClientErrorComponent';
  // Import Common Hooks
  import useTellerApi from '../../../../hooks/useTellerApi';

  //role context
  import {RoleContext} from '../../../../context/RoleContext';

  export default ({ programID }) => {
    const [
      {
        data: { data: locations },
        isLoading,
        isError,
      },
      setUrl,
      setForceRetch,
    ] = useTellerApi(`/api/v1/client_portal/card_issuing/programs/${programID}/locations`, {});

    const refetchLocations = () => {
      setUrl(`/api/v1/client_portal/card_issuing/programs/${programID}/locations`);
      setForceRetch({});
    };

    const [modalOpen, setModalOpen] = useState(false);
    const [modalType, setModalType] = useState(null);
    const [locationInfo, setLocationInfo] = useState(null);
    const [success, setSuccess] = useState(null);
    const [error, setError] = useState(null);

    // Get role from context
    const userRole = React.useContext(RoleContext); 

    if (isLoading) return <Loader />;
    if (isError) return <ClientErrorComponent data={{}} />;

    const toggleModal = (action, location) => {
      setModalType(action);
      if (action === 'edit' || action === 'deactivate') {
        setLocationInfo(location);
      } else {
        setLocationInfo({
          name: '',
          phone: '',
          address1: '',
          address2: '',
          city: '',
          state: '',
          postalCode: '',
          countryCode: '',
          processorID: '',
        });
      }
      setModalOpen(!modalOpen);
    };
    const columns = [
      {
        Header: 'Location ID',
        accessor: 'processor_reference',
        width: 100,
      },
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Phone',
        accessor: 'phone',
      },
      {
        Header: 'Address',
        accessor: 'address1',
      },
      {
        Header: 'Address 2',
        accessor: 'address2',
      },
      {
        Header: 'City',
        accessor: 'city',
      },
      {
        Header: 'State',
        accessor: 'state',
      },
      {
        Header: 'Postal Code',
        accessor: 'postalCode',
      },
      {
        Header: 'Ref',
        accessor: 'id',
        show: false,
      },
      {
        Header: 'Store Type',
        accessor: 'store_type',
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
      {
        Header: 'Actions',
        accessor: 'action',
        width: 175, // add a width so both action buttons show
        Cell: ({ row }) => {
          return (
            <>
              <Button
                type="primary"
                disabled={userRole == 'user' }
                variant="text"
                onClick={() => toggleModal('edit', row)}
              >
                Edit
              </Button>
              <Button
                type="primary"
                disabled={userRole == 'user' }
                variant="text"
                onClick={() => toggleModal('deactivate', row)}
              >
                Delete
              </Button>
            </>
          );
        },
      },
    ];

    return (
      <>
        {success && <Alert type="success">{success}</Alert>}
        {error && <Alert type="danger">{error}</Alert>}
        <CardGroup>
          {locations.length > 0 ? (
            <Card>
              <Title>Locations</Title>
              <Table
                columns={columns}
                data={locations}
                /* eslint-disable no-unused-vars */
                getTrProps={(state, rowInfo, column) => {
                  return {
                    style: {
                      cursor: 'pointer',
                    },
                    className: 'hoverable',
                  };
                }}
                /* eslint-enable no-unused-vars */
              />
              <Button type="primary" disabled={userRole == 'user' } onClick={() => toggleModal('create')}>Create</Button>
            </Card>
          ) : (
            <Card>
              <Title>Locations</Title>
              <Alert type="info">
                Currently there are no Locations Available. Please create a new
                location.
              </Alert>
              <Button type="primary" disabled={userRole == 'user' } onClick={() => toggleModal('create')}>Create</Button>
            </Card>
          )}
        </CardGroup>
        {modalOpen && (
          <LocationModal
            type={modalType}
            closeModal={() => toggleModal(null)}
            error={null}
            setSuccess={setSuccess}
            setError={setError}
            locationInfo={locationInfo}
            setLocationInfo={setLocationInfo}
            refetchLocations={refetchLocations}
            programID={programID}
            title={modalType === 'create' ? 'Create Location' : 'Edit a Location'}
            page={`${modalType  }-location`}
          />
        )}
      </>
    );
  };
