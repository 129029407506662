import React from 'react';
import PropTypes from 'prop-types';
// Import Global Components
import Header from '../../../../components/Header';
// Import Local Components
import CardholderDetails from '../components/CardholderDetails';
import ValidUploadedCardholders from '../components/ValidUploadedCardholders';
import ErroredUploadedCardholders from '../components/ErroredUploadedCardholders';
// Import Local Layouts
import CreateHeaderCard from './CreateHeaderCard';

const ReviewCSV = ({
  setCardholderStep,
  setErroredCardholderInputs,
  setValidationError,
  validationError,
  ...props
}) => {
  if (validationError)
    return (
      <ErroredUploadedCardholders
        erroredCardholderInputs={props.erroredCardholderInputs}
        onClick={e => {
          e.preventDefault();
          props.setError(null);
          setCardholderStep(2);
          setErroredCardholderInputs(null);
          setValidationError(false);
          props.setCardholders([]);
        }}
      />
    );

  return (
    <>
      <Header>
        <CreateHeaderCard>
          <CardholderDetails cardholders={props.cardholders} />
        </CreateHeaderCard>
      </Header>
      <ValidUploadedCardholders
        onClick={() => {
          props.setSuccess(null);
          props.setError(null);
          setCardholderStep(2);
          props.setCardholders([]);
        }}
        {...props}
      />
    </>
  );
};

ReviewCSV.propTypes = {
  setCardholderStep: PropTypes.func.isRequired,
  setErroredCardholderInputs: PropTypes.func.isRequired,
  setValidationError: PropTypes.func.isRequired,
  validationError: PropTypes.bool.isRequired,
};

export default ReviewCSV;
