import React from 'react';
// Import Shared Components
import {
  Card,
  Title,
  Alert,
  ActionBar,
  Button,
  Table,
} from 'shared-components';
import { capitalize } from 'shared-components/src/utils/string';
// Import Local Components
import ErroredAccountHolders from './components/ErroredAccountHolders';

export default ({
  error,
  data,
  submit,
  back,
  erroredAccountHolders,
  uploadError,
}) => {
  const columns = [
    {
      Header: 'Type',
      accessor: item => capitalize(item.type),
      id: 'type',
    },
    {
      Header: 'First Name',
      accessor: 'firstName',
    },
    {
      Header: 'Last Name',
      accessor: 'lastName',
    },
    {
      Header: 'Email',
      accessor: 'email',
    },
    {
      Header: 'Phone Number',
      accessor: 'phone',
    },
  ];

  return (
    <Card>
      <Title>Uploaded Account Holders</Title>
      {uploadError ? (
        <ErroredAccountHolders data={erroredAccountHolders} onClick={back} />
      ) : (
        <>
          {error && (
            <Alert type="danger">
              Error:
              {error}
            </Alert>
          )}
          <Table
            columns={columns}
            data={data}
            key="uploaded-account-holders-table"
          />
          <ActionBar justifyContent="space-between">
            <Button type="secondary" onClick={back}>
              Go Back
            </Button>
            <Button type="primary" onClick={submit}>
              Confirm
            </Button>
          </ActionBar>
        </>
      )}
    </Card>
  );
};
