import styled from 'styled-components';
import { Input as input} from 'shared-components';

export const EmailConfiguration = styled.div`
  & > *:last-child {
    margin-top: 30px;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  height: 100%;
`;

export const FormGroup = styled.fieldset`
  padding: 5px;
  margin-bottom: 5px;

  & > *:nth-child(n+3) {
    border-bottom: ${({ theme }) => theme.borders.default};
  }
`;

export const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 50%
  margin: 5px;
  padding 5px 10px 10px 10px;
  
  border-bottom: ${({ theme }) => theme.borders.default};

  ${({ theme }) => theme.media.desktopUp`
    flex-direction: column;
  `}
`;

export const InputContainer = styled.div`
  display: flex;
  flex-basis: 100%
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  vertical-align: center;

  width: 100%;
  margin: 5px;
`;

export const Area = styled.textarea`
  border: ${({ theme }) => theme.borders.default};
  border-radius: 3px;
  margin-left: 10px;

  min-width: 500px;
`;

export const Input = styled(input)`
  margin-left: 10px;
`;