export default [
  {
    Header: 'Account Id',
    accessor: 'account_id',
    id: 'account_id',
    Cell: ({ value }) => (value.length > 0 ? value : '--'),
  },
  {
    Header: 'Last Four Digits',
    accessor: 'last_four_digits',
    id: 'last_four_digits',
  },
  {
    Header: 'Expiry Year',
    accessor: 'expiry_year',
    id: 'expiry_year',
  },
  {
    Header: 'Expiry Month',
    accessor: 'expiry_month',
    id: 'expiry_month',
  },
  {
    Header: 'CVV',
    accessor: 'cvv',
    id: 'cvv',
  },
];

