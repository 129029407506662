import React from 'react';
import { Alert, InputGroup, Title, Button, ActionBar } from 'shared-components';
// Import Local Components
import AuthForm from './AuthForm';
// Import Layouts
import AuthLayout from '../layouts/AuthLayout';
import AuthButtons from '../layouts/AuthButtons';

export default ({
  email,
  updateEmail,
  forgotPassword,
  formError,
  emailSent,
  goBack,
  alert,
}) => (
  <>
    {!emailSent ? (
      <AuthForm
        title="Password Reset"
        submitButtonText="Send Email"
        onSubmit={forgotPassword}
        error={formError}
        secondaryButtonText="Back"
        onClick={goBack}
      >
        <p>We will email you a link to reset your password.</p>
        <InputGroup
          label="Email:"
          labelFor="email"
          dataId="forgot-password-form-email-input"
          value={email}
          type="email"
          onChange={e => {
            e.preventDefault();
            const { value } = e.target;
            updateEmail(value);
          }}
        />
      </AuthForm>
    ) : (
      <AuthLayout>
        <Title>Email Sent</Title>
        <div>
          <p>
            An email has been sent to {email}. If you do not receive an email
            within <b>5 minutes</b> please retry.
          </p>
          {alert && <Alert type="success">{alert}</Alert>}
          <ActionBar>
            <AuthButtons>
              <Button type="primary" onClick={forgotPassword}>
                Resend Email
              </Button>
              <Button variant="text" onClick={goBack}>
                Back
              </Button>
            </AuthButtons>
          </ActionBar>
        </div>
      </AuthLayout>
    )}
  </>
);
