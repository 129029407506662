import React from 'react';
import { Title, Table, Card } from 'shared-components';

const FailedValueLoadTable = ({
  columns,
  failedValueLoads,
  failedValueLoadsCount,
  failedValueLoadsPagination,
  setFailedValueLoadsPagination,
  setFailedValueLoadsUrl,
  searchParams,
}) => {
  const handleFailedValueLoadsPageSizeChange = (pageSize, pageIndex) => {
    setFailedValueLoadsPagination({
      ...failedValueLoadsPagination,
      limit: pageSize,
      offset: pageIndex * pageSize,
    });

    const params = new URLSearchParams({
      ...searchParams,
      ...failedValueLoadsPagination,
      limit: pageSize,
      offset: pageIndex * pageSize,
    }).toString();
    setFailedValueLoadsUrl(
      `/api/v1/client_portal/card_issuing/failed_value_loads?${params}`
    );
  };

  const handleFailedValueLoadsPageChange = pageIndex => {
    setFailedValueLoadsPagination({
      ...failedValueLoadsPagination,
      page: pageIndex,
      offset: pageIndex * failedValueLoadsPagination.limit,
    });
    const params = new URLSearchParams({
      ...searchParams,
      ...failedValueLoadsPagination,
      page: pageIndex,
      offset: pageIndex * failedValueLoadsPagination.limit,
    }).toString();
    setFailedValueLoadsUrl(
      `/api/v1/client_portal/card_issuing/failed_value_loads?${params}`
    );
  };
  return (
    <Card>
      <Title>Failed Value Load List</Title>
      <Table
        columns={columns}
        data={failedValueLoads}
        noDataText="Currently there are no Failed Value Loads"
        /* eslint-disable no-unused-vars */
        showPagination={
          failedValueLoadsCount > failedValueLoadsPagination.limit && true
        }
        pages={Math.ceil(
          failedValueLoadsCount / failedValueLoadsPagination.limit
        )}
        page={failedValueLoadsPagination.page}
        manual
        onPageSizeChange={handleFailedValueLoadsPageSizeChange}
        onPageChange={handleFailedValueLoadsPageChange}
        /* eslint-enable no-unused-vars */
        sortable={false}
        defaultSorted={[{ id: 'id', desc: true }]}
      />
    </Card>
  );
};

export default FailedValueLoadTable;