import React from 'react';
// Import Shared Components
import { Title, Details, DetailsGroup } from 'shared-components';
import { formatTime } from 'shared-components/src/utils/formatDateTime';
// Import Global Components
import PaymentStatus from '../../../../components/PaymentStatus';
import TransferTotal from '../../../../components/TransferTotal';
// Import Hooks
import { useTimeZone } from '../../../../hooks/useTimeZone';

export default ({
  name,
  latestStatus,
  expirationDatetime,
  insertedAt,
  amount,
  currency,
  recipientsCount
}) => {
  const [timeZone] = useTimeZone();
  const columns = [
    [
      {
        accessor: 'name',
        header: 'Name:',
      },
      {
        accessor: 'insertedAt',
        header: 'Created At:',
        cell: value => formatTime(`${value}Z`, 'dateTime', timeZone),
      },
      {
        accessor: 'latestStatus',
        header: 'Status:',
        cell: value => <PaymentStatus status={value} />,
      },
    ],
    [
      {
        accessor: 'expirationDatetime',
        header: 'Expires:',
        cell: value => formatTime(`${value}Z`, 'longDate', timeZone),
      },
      {
        accessor: 'recipientsCount',
        header: 'Total Payments:',
      },
      {
        accessor: 'amount',
        header: 'Total Sent:',
        cell: value => (
          <TransferTotal total={value / 100} currency={currency || 'CAD'} />
        ),
      },
    ],
  ];

  const detailsData = {
    name,
    insertedAt,
    latestStatus,
    expirationDatetime,
    recipientsCount,
    amount,
  };

  return (
    <>
      <Title>Transfer Details</Title>
      <DetailsGroup>
        {columns.map(column => (
          <Details data={detailsData} columns={column} />
        ))}
      </DetailsGroup>
    </>
  );
};
