import React from 'react';
// Import Local Components
import GlobalTransactionLimits from './components/GlobalTransactionLimits';
import GlobalVelocityLimits from './components/GlobalVelocityLimits';

export default ({
  subtab,
  toggleSubtab,
  transactionLimits,
  transactionColumns,
  velocityLimits,
  velocityColumns,
}) => (
  <>
    {subtab === 'transaction' ? (
      <GlobalTransactionLimits
        limits={transactionLimits}
        columns={transactionColumns}
      />
    ) : (
      <GlobalVelocityLimits limits={velocityLimits} columns={velocityColumns} />
    )}
  </>
);
