import styled from 'styled-components';

export const PrivateBase = styled.div`
  background: transparent linear-gradient(180deg, #eeeff1 0%, #eff0fc 100%) 0%
    0% no-repeat padding-box;
`;

export const PrivateBody = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`;

export const PrivateMain = styled.div`
  flex: 7 1 0;
  height: 100%;
  min-height: 100vh;
  margin: 4.7357em 2.8571em 1.1428em;

  // We add extra margin to the left of the div with class main to account for
  // the width of the sidebar at this viewpoint.
  ${({ theme }) => theme.media.tabletPortraitUp`
    margin: 1.1428em 2.8571em 1.1428em 7em;
  `}
  ${({ theme }) => theme.media.desktopUp`
    margin: 1.1428em 2.8571em 1.1428em 21.497em;
  `};
`;
