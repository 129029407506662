import React from 'react';
import PropTypes from 'prop-types';

// Import Global Components
import Label from '../../../../../components/Label';

// Import Local Components
import Row from '../CollectionsTableRow';

// Import Component Styles
import { Table, TableHeader, TableRow, TableBody, TableColumn } from './styles';

import { createCollectionsEn } from '../../../../../i18n/routes/collections/createCollection';

const createHeader = columns => (
  <>
    {columns.map(c => {
      return (
        <TableColumn key={c}>
          <Label
            label={createCollectionsEn[`collection-create-${c}-label`]}
            labelFor={c}
            page="collections-create"
          />
        </TableColumn>
      );
    })}
    {/* self closing table column for the delete row button */}
    <TableColumn />
  </>
);

const createRows = (
  recipients,
  updateRecipient,
  deleteRecipient,
  columns,
  prefixes
) =>
  Object.keys(recipients).map(id => (
    <Row
      key={id}
      id={id}
      columns={columns}
      prefixes={prefixes}
      recipient={recipients[id].data}
      error={recipients[id].error}
      updateRecipient={updateRecipient}
      deleteRecipient={deleteRecipient}
    />
  ));

const CollectionsTable = ({
  recipients,
  updateRecipient,
  deleteRecipient,
  columns,
  prefixes,
}) => {
  return (
    <Table>
      <TableHeader>
        <TableRow>{createHeader(columns)}</TableRow>
      </TableHeader>
      <TableBody>
        {createRows(
          recipients,
          updateRecipient,
          deleteRecipient,
          columns,
          prefixes
        )}
      </TableBody>
    </Table>
  );
};

CollectionsTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  recipients: PropTypes.object.isRequired,
  updateRecipient: PropTypes.func.isRequired,
  deleteRecipient: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  columns: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  prefixes: PropTypes.array.isRequired,
};

export default CollectionsTable;
