import React from 'react';
import ModalForm from '../../../../../components/ModalForm';

const LocationForm = ({
  closeModal,
  error,
  locationInfo,
  submit,
  title,
  page
}) => {
  const handleSubmit = e => {
    e.preventDefault();
    return submit();
  };

  return (
    <ModalForm
      title={title}
      submit={handleSubmit}
      data-id={`${page}-form`}
      error={error}
      closeModal={closeModal}
      submitButtonText="Deactivate"
    >
      <p>Deactivate location?</p>
    </ModalForm>
  );
};

export default LocationForm;
