import React from 'react';
import { formatCurrencySV } from 'shared-components/src/utils/formatCurrency';
// Import Global Components
import { getAmountPercentage } from '../../../../../../../utils/limits';
// Import Local Components
import ProgressBar from '../../../ProgressBar';
// Import Component Styles
import { Status, SubText, SubTitle } from './styles';

export default ({
  original: {
    currentStanding,
    amount,
    product,
    currency,
    networkType,
    transactionType,
    trailingTimeWindow,
  },
}) => {
  const value = getAmountPercentage(currentStanding.sum, amount);

  return (
    <Status>
      <h4>Current Standing</h4>
      <h3>
        {formatCurrencySV(currentStanding.sum / 100, currency)}{' '}
        <SubText> out of {formatCurrencySV(amount / 100, currency)}</SubText>
      </h3>

      <ProgressBar usedValue={value} />
      <SubTitle>
        This is based on the amount that has been {`${transactionType}ed`} via{' '}
        {networkType === 'visa' ? 'the Visa Network' : 'EFTs'} in that past{' '}
        {trailingTimeWindow > 1
          ? `${trailingTimeWindow} days`
          : `${trailingTimeWindow} day`}
      </SubTitle>
    </Status>
  );
};
