import React, { useState } from 'react';

// Import Shared Components
import {
    Alert,
    Loader,
    CardGroup,
  } from 'shared-components';

// Import Common Hooks
import useTellerApi from '../../../hooks/useTellerApi';

// Import Global Components
import ClientErrorComponent from '../../../components/ClientErrorComponent';

// Import Local Components
import SelectProgramModal from './components/SelectProgramModal';

// Import Layouts
import CreateCSV from './layouts/CreateCSV';
import ReviewCSV from './layouts/ReviewCSV';

// Import Utils
import { postFormData } from '../../../utils/http';
import {
  parse,
  parseCloseUnloads,
  FILE_UPLOAD_LENGTH_LIMIT,
} from '../../../utils/csv';
import { mutationErrorMessageParser } from '../../../utils/errorLogger';

export default ({ history }) => {
  // Component States
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const [createCloseUnloadsStep, setCreateCloseUnloadsStep] = useState(1);
  const [changeProgram, setChangeProgram] = useState(false);
  const [validationError, setValidationError] = useState(false);
  // Data States
  const [program, setProgram] = useState(null);
  const [cards, setCards] = useState([]);
  const [upload, setUpload] = useState(null);
  const [erroredCardsInputs, setErroredCardsInputs] = useState([]);
   // Get list of programs for company on mount
  let path = '/api/v1/client_portal/card_issuing/programs?bulker_enabled=true';
   const [
     {
       data: { data: programs },
       isLoading,
       isError,
     },
   ] = useTellerApi(path, {
     count: 0,
     data: [],
     limit: 100,
     offset: 0,
   });

   if (isLoading || loading) return <Loader />;
  if (isError) return <ClientErrorComponent data={{}} />;

  const handleSelectProgram = programId => {
    // find program data based on program Id
    const [program] = programs.filter(p => p.id === programId);
    setProgram(program);
    setCreateCloseUnloadsStep(2);
  };

  const handleChangeSelectedProgram = programId => {
    const [program] = programs.filter(p => p.id === programId);
    // set new program
    setProgram(program);
    // close change program modal
    setChangeProgram(!changeProgram);
  };

  // Only display active programs in this list
  const programOptions = programs
    .filter(p => p.status === 'active')
    .map(p => {
      return { key: p.id, val: p.name };
  });

  const handleCSVParse = async e => {
    // Get file and clear file input
    const file = e.target.files[0];
    setUpload(file);
    e.target.value = null;

    try {
      setLoading(true);
      // parse csv file
      const rows = await parse(file);
      // run csv file data through validations
      // Ensure that CSV file contains atleast one row of data
      if (rows.data.length <= 1) {
        throw new Error('Uploaded file does not contain any data');
      }

      if (rows.data.length > FILE_UPLOAD_LENGTH_LIMIT) {
        throw new Error(
          `Uploaded file contains more than ${FILE_UPLOAD_LENGTH_LIMIT} items, please remove some items from the file and try again`
        );
      }

      const data = rows.data
        .slice(1)
        .map((row, id) => parseCloseUnloads(row, id));

      // all rows have passed validations
      handleUploadedCards(data);
    } catch (err) {
      setLoading(false);
      setUpload(null);
      const message = mutationErrorMessageParser(err, 'Error uploading CSV.');
      setError(message);
    }
  };

  // Function to handle parsed and validated CSV data
  const handleUploadedCards = data => {
    setCards(data);
    setError(null);
    setLoading(false);
    setCreateCloseUnloadsStep(3);
  };

  // Function to handle submission to Teller
  const handleCardsSubmit = e => {
    e.preventDefault();
      setLoading(true);
      createCloseUnloads();
  };

  // Call to Teller to close cards
  const createCloseUnloads = async () => {
    try {        
      let payload = new FormData();    
      payload.append("program_id", program.id);
      payload.append("client_close_unload", JSON.stringify(cards));
      payload.append("description", 'test');
      payload.append("file", upload);

      const orderResponse = await postFormData('/api/v1/client_portal/card_issuing/bulk/close_unload', payload);      

      // Clear Cards from State
      setCards([]);
      setUpload(null);

      // Send User to order details page.
      history.push({
        pathname: `/card-issuing/orderDetails/${orderResponse.id}`,
        state: {
          success: 'Close/Unload order has been accepted and will begin processing.',
        },
      });
  } catch (e) {           
      setSuccess(null);
      setLoading(false);
      // Display Error and keep Cards in State to allow user to try again
      return setError(e.message);
  } 
  };

  return (
    <>
      {error && (
        <Alert type="danger">
          {/* Inline style is for showing line breaks, which would be ignored otherwise */}
          <div style={{ whiteSpace: 'pre-line' }}>{error}</div>
        </Alert>
      )}
      {success && <Alert type="success">{success}</Alert>}
      {createCloseUnloadsStep === 1 && (
        <SelectProgramModal
          updateItem={handleSelectProgram}
          options={programOptions}
          onClick={() => {
           
            history.push(
              {
                pathname:'/bulker/card-issuing/close-unloads',
                state: {
                  bulker: true
                }
              }
              )
              

            }}
        />
      )}
       <CardGroup>
        {createCloseUnloadsStep === 2 && (
          <CreateCSV
            setChangeProgram={setChangeProgram}
            program={program}
            setCreateCloseUnloadsStep={setCreateCloseUnloadsStep}
            parseCsv={handleCSVParse}
          />
        )}
         {createCloseUnloadsStep === 3 && (
          <>
            <ReviewCSV
              cards={cards}
              setCards={setCards}
              setCreateCloseUnloadsStep={setCreateCloseUnloadsStep}
              setSuccess={setSuccess}
              setError={setError}
              setLoading={setLoading}
              program={program}
              validationError={validationError}
              setValidationError={setValidationError}
              setErroredCardsInputs={setErroredCardsInputs}
              erroredCardsInputs={erroredCardsInputs}
              handleCardsSubmit={handleCardsSubmit}
            />
          </>
        )}
      </CardGroup>

      {changeProgram && (
        <SelectProgramModal
          changeProgram
          updateItem={handleChangeSelectedProgram}
          options={programOptions}
          onClick={() => setChangeProgram(!changeProgram)}
        />
      )}
    </>
  );
};