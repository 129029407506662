export default [
  {
    Header: 'Account Id',
    accessor: 'account_id',
    id: 'account_id',
    Cell: ({ value }) => (value.length > 0 ? value : '--'),
  },
  {
    Header: 'Last Four Digits',
    accessor: 'last_four_digits',
    id: 'last_four_digits',
  },
  {
    Header: 'Status',
    accessor: 'status',
    id: 'status',
  },
];

