import styled from 'styled-components';

export const Table = styled.table`
  width: 100%;
  border: ${({ theme }) => theme.borders.default};
`;

export const TableHeader = styled.thead`
  border: ${({ theme }) => theme.borders.default};
`;

export const TableRow = styled.tr`
  border: ${({ theme }) => theme.borders.default};
  
  & > *:not(:first-child) {
    border-left: ${({ theme }) => theme.borders.default};
  }
`;

export const TableColumn = styled.th`
  text-align: left;
  padding: 5px 0 5px 5px;
  background: ${({ theme }) => theme.colors.tableStripedBg}

  border-bottom: ${({ theme }) => theme.borders.default};
`;

export const TableBody = styled.tbody`
`;
