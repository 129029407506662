import React from 'react';
// Import Shared Components
import { Card, Table, Title, Link, Loader } from 'shared-components';

// Import Utils
import {
  formatCurrencyV,
} from 'shared-components/src/utils/formatCurrency';

// Import Components
import ClientErrorComponent from '../../../components/ClientErrorComponent';

// Import Hooks
import useTellerApi from '../../../hooks/useTellerApi';

// Import Component Styles
import { Container } from '../styles';

export default () => {

  const [{data, loading, isError}] = useTellerApi('/api/v1/client_portal/collections?limit=10&offset=0', {data: [], count: 0, limit: 0, offset: 0});

  if (loading) {
    return (
      <Card>
        <Loader />
      </Card>
    );
  }

  if (isError) return <ClientErrorComponent data={{ error: {}}} />;

  const tableData = data.data ? data.data : [];

  const columns = [
    {
      Header: 'ID',
      accessor: 'id',
    },
    {
      Header: 'First Name',
      accessor: 'first_name',
    },
    {
      Header: 'Last Name',
      accessor: 'last_name',
    },
    {
      Header: 'Amount',
      accessor: 'amount',
      Cell: ({ value }) => `${formatCurrencyV(value / 100)}`,
    }
  ];

  return (
    <Card>
      <Container>
        <div>
          <Title>Collections</Title>
          <Table data={tableData} columns={columns} />
        </div>
        <Link type="primary" to="collections/collections">
          See More Collections Details
        </Link>
      </Container>
    </Card>
  );
};
