import gql from 'graphql-tag';

export const MUTATION = gql`
  mutation createCustomerFundingSource($token: String, $id: ID!) {
    createCustomerFundingSource(token: $token, id: $id) {
      ... on CustomerIndividual {
        id
      }
      ... on CustomerCompany {
        id
      }
      ... on CustomerSelf {
        id
      }
    }
  }
`;
