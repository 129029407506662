import React from 'react';
import { Input, Dropdown } from 'shared-components';
// Import Local Components
import AMOUNT from '../amountOptions';
import STATUS from '../statusOptions';
// Import Component Styles
import { SearchFilter } from './styles';

export default ({ updateParams, type, params, statusKey }) => {
  const updateSearchName = value => {
    return updateParams({
      ...params,
      name: value,
    });
  };
  const updateSearchStatus = key =>
    updateParams({
      ...params,
      status: key,
    });
  const updateSearchAmount = key => {
    const value = key.split(/[/]/);
    return updateParams({
      ...params,
      amountFrom: value[0],
      amountTo: value[1],
      amount: key,
    });
  };

  return (
    <SearchFilter>
      {type !== 'amount' && (
        <Dropdown
          selected={params.amount}
          updateItem={updateSearchAmount}
          options={AMOUNT}
        />
      )}
      {type !== 'status' && (
        <Dropdown
          updateItem={updateSearchStatus}
          selected={params.status}
          options={STATUS}
        />
      )}
      {type !== 'name' && (
        <Input
          value={params.name}
          onChange={e => updateSearchName(e.target.value)}
          placeholder="Search by Name"
        />
      )}
    </SearchFilter>
  );
};
