import styled from 'styled-components';

export const LinkGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};

  *:not(:last-child) {
    margin-right: 1.07em;
  }
`;
