export const ROLE_MAP = [
  { key: 'admin', val: 'Admin' },
  { key: 'user', val: 'User' },
];

export const owner = ['owner'];

export const admin = ['owner', 'admin'];

export const user = ['owner', 'admin', 'user'];

export const all = ['owner', 'admin', 'user'];
