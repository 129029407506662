import {
  validateExpiryMonth,
  validateShortExpiryYear,
} from './cardValidations';

function countDecimals(value) {
  if (Math.floor(value) === value) return 0;
  return value.toString().split('.')[1].length || 0;
}

export const isAllNumbers = /^\d+$/;

export const isCollectionNameValid = name => {
  const nameCheck = /^[a-zA-Z0-9\s-]{2,30}$/g;
  return nameCheck.test(name);
};

export const isNameValid = name => {
  const nameCheck = /^[a-zA-Z '-]{2,30}$/g;
  return nameCheck.test(name);
};

export const isCardholderNameValid = name => {
  const nameCheck = /^[a-zA-Z -]{2,26}$/g;
  return nameCheck.test(name);
};

export const isAmountValid = value => {
  const amount = parseFloat(value);
  if (Number.isNaN(amount) || amount <= 0.0) return false;
  if (countDecimals(amount) > 2) return false;
  const amountWithoutDecimal =
    typeof value === 'string'
      ? value.split('.').join('')
      : value
        .toString()
        .split('.')
        .join('');
  return isAllNumbers.test(amountWithoutDecimal);
};

export const isEmailBlurbValid = name => {
  const blurbCheck = /^[a-zA-Z0-9\s-,.!? ]{2,255}$/g;
  return blurbCheck.test(name);
};

// validate expiry for Cardholder verify card form.
// Expiry will be sent in the form of MMYY
export const validateExpiry = expiry => {
  if (expiry.length !== 4) return false;

  const expiryMatches = expiry.match(/^(\d{2})(\d{2})$/);
  const expiryMonth = expiryMatches[1];
  const expiryYear = expiryMatches[2];

  return (
    validateExpiryMonth(expiryMonth) && validateShortExpiryYear(expiryYear)
  );
};
