import React from 'react';
// Import Shared Components
import { Icon } from 'shared-components';
// Import Component Styles
import { IconWrapper } from '../styles';

export default ({ value }) =>
  value ? (
    <IconWrapper color="green">
      <Icon type="success" align="middle" />
      <p>Yes</p>
    </IconWrapper>
  ) : (
    <IconWrapper color="red">
      <Icon type="danger" align="middle" />
      <p>No</p>
    </IconWrapper>
  );
