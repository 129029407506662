// List of fields needed to activate a card.
// These will be the "headers" in the csv
export const cardHeader = [
  [
    '*Account Id',
    '*Last Four Digits',
    '*Expiry Year',
    '*Expiry Month',
    'CVV',
  ]
];
