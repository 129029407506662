import React from 'react';
// Import Shared Components
import { Card, CardGroup, Title, Details, Alert } from 'shared-components';
// Import Local Components
import CompanyStatus from './components/CompanyStatus';
import SftpComponent from './components/CompanySFTPUserDetails';

export default ({
  data: {
    viewer: {
      company: { name, email, latestStatus, id },
    },
  },
}) => {
  const columns = [
    [
      { accessor: 'name', header: 'Name:' },
      { accessor: 'email', header: 'Billing Email:' },
      {
        accessor: 'latestStatus',
        header: 'Status:',
        cell: (value) => {
          return <CompanyStatus status={value} />;
        },
      },
    ],
    [{ accessor: 'paymentExpiry', header: 'Payment Notification Expiry:' }],
    [
      { accessor: 'user', header: 'Username:' },
      { accessor: 'sshKey', header: 'SSH Public Key:' },
    ],
  ];

  const data = [{ name, email, latestStatus }, { paymentExpiry: '28 Days' }];

  return (
    <CardGroup>
      <Card>
        <Title>Company Details</Title>
        <Details columns={columns[0]} data={data[0]} />
      </Card>
      <Card>
        <Title>Direct Send Settings</Title>
        <Details columns={columns[1]} data={data[1]} />
      </Card>
      <Card>
        <Title>SFTP User Configuration</Title>
        {id ? (
          <SftpComponent id={id} /> // make useEffect api call within this component and hold sftpUserId state within it as well
        ) : (
          <Alert type="danger">
            Please contact IT to setup the SFTP reporting feature.
          </Alert>
        )}
      </Card>
    </CardGroup>
  );
};
