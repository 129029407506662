import {
  DIRECT_SEND,
  CARD_ISSUING,
  COLLECTIONS,
  BULKER,
  ACTIVATE_CARDS,
  MODIFY_CARDS,
  CLOSE_UNLOAD,
  VALUE_LOADS,
  CREATE_CARDHOLDERS,
  INSTANT_ISSUANCE_CREATE_CARDS,
  API_KEY_ENABLED,
} from 'shared-components/src/utils/services';
import { admin, user } from '../../utils/roles';
import { apiDocs } from '../../utils/apidocs';
import policyDoc from '../../assets/Berkeley Client API Keys Management Policy.pdf';

// ---------
// Sidebar Items
// ---------
// Section
// The first level values are referred to as Sections
// The role for a section should be the lowest role of the entire section
// it will show the header in the side bar for that row
// Route
// Within each Section we have specific routes.
// Each specific route can also restrict the role furthur
// this means a user with a lower role will not see the route link
export default [
  {
    name: 'Dashboard',
    icon: 'home',
    role: user,
    location: '/',
    type: 'route',
  },
  {
    name: 'Direct Send',
    icon: 'bar-chart',
    role: user,
    service: DIRECT_SEND,
    routes: [
      {
        name: 'Create a Transfer',
        location: '/direct-send/create',
        type: 'route',
        role: admin,
      },
      {
        name: 'Transfer History',
        location: '/direct-send/transfers',
        type: 'route',
        role: user,
      },
      {
        name: 'Account Holders',
        location: '/direct-send/account-holders',
        type: 'route',
        role: admin,
      },
      {
        name: 'Reporting',
        location: '/direct-send/reports',
        type: 'route',
        role: user,
      },
      {
        name: 'Limits',
        location: '/direct-send/limits',
        type: 'route',
        role: user,
      },
    ],
  },
  {
    name: 'Card Issuing',
    icon: 'credit-card',
    role: user,
    service: CARD_ISSUING,
    routes: [
      {
        name: 'Programs',
        location: '/card-issuing/programs',
        type: 'route',
        role: user,
      },
      {
        name: 'Cardholders',
        location: '/card-issuing/cardholders',
        type: 'route',
        role: user,
      },
      {
        name: 'Value Loads',
        location: '/card-issuing/value-loads',
        type: 'route',
        role: user,
      },
      {
        name: 'Update Card',
        location: '/card-issuing/update-card',
        type: 'route',
        role: admin,
      },
      {
        name: 'Activate & Load Card',
        location: '/card-issuing/activate-load-card',
        type: 'route',
        role: admin,
      },
      {
        name: 'Unload Card by Proxy',
        location: '/card-issuing/unload-card',
        type: 'route',
        role: admin,
      }
    ],
  },
  {
    name: 'Bulk Action',
    icon: 'book',
    role: admin,
    service: CARD_ISSUING,
    isEnabled: BULKER,
    routes: [
      {
        name: 'Orders',
        location: '/card-issuing/orders',
        type: 'route',
        role: user,
      },
      {
        name: 'Create Cardholders',
        location: '/bulker/card-issuing/cardholders',
        type: 'route',
        role: user,
        isEnabled: CREATE_CARDHOLDERS,
      },
      {
        name: 'Create Value Loads',
        location: '/bulker/card-issuing/value-loads',
        type: 'route',
        role: user,
        isEnabled: VALUE_LOADS,
      },
      {
        name: 'Close/Unloads',
        location: '/bulker/card-issuing/close-unloads',
        type: 'route',
        role: user,
        isEnabled: CLOSE_UNLOAD,
      },
      {
        name: 'Activate Cards',
        location: '/bulker/card-issuing/bulk-activate',
        type: 'route',
        role: user,
        isEnabled: ACTIVATE_CARDS,
      },
      {
        name: 'Modify Card Status',
        location: '/bulker/card-issuing/card-status',
        type: 'route',
        role: user,
        isEnabled: MODIFY_CARDS,
      },
      {
        name: 'Instant Issuance: Create Cards',
        location: '/bulker/card-issuing/instant-issuance/cards',
        type: 'route',
        role: user,
        isEnabled: INSTANT_ISSUANCE_CREATE_CARDS,
      },
    ],
  },
  {
    name: 'Collections',
    icon: 'send',
    role: user,
    service: COLLECTIONS,
    routes: [
      {
        name: 'Create a Collection',
        location: '/collections/create',
        type: 'route',
        role: admin,
      },
      {
        name: 'Collection History',
        location: '/collections/collections',
        type: 'route',
        role: user,
      },
      {
        name: 'Email Configuration',
        location: '/collections/configuration',
        type: 'route',
        role: admin,
      },
      {
        name: 'Reports',
        location: '/collections/reports',
        type: 'route',
        role: user,
      },
    ],
  },
  {
    name: 'API',
    icon: 'database',
    role: user,
    routes: [
      {
        name: 'Keys',
        location: '/api-keys',
        type: 'route',
        role: admin,
        isEnabled: API_KEY_ENABLED,
      },
      {
        name: 'Documentation',
        location: apiDocs,
        type: 'external',
        role: user,
      },
      {
        name: 'Keys Management Policy',
        location: policyDoc,
        type: 'external',
        role: user,
        isEnabled: API_KEY_ENABLED
      },
    ],
  },
  {
    name: 'Settings',
    icon: 'setting',
    role: user,
    routes: [
      {
        name: 'Company',
        location: '/settings/company',
        type: 'route',
        role: user,
      },
      {
        name: 'Team',
        location: '/settings/team',
        type: 'route',
        role: admin,
      },
      {
        name: 'Time Zone',
        location: '/settings/timezone',
        type: 'route',
        role: user,
      },
    ],
  },
  {
    name: 'Help',
    icon: 'user',
    role: user,
    location: '/help',
    type: 'route',
  },
];
