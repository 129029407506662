function countDecimals(value) {
  if (Math.floor(value) === value) return 0;
  return value.toString().split('.')[1].length || 0;
}

export const isEmailValid = email => {
  // HTML5 input type email regex
  // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/email#Validation
  const re = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
  return re.test(email);
};

export const isAllNumbers = /^\d+$/;

export const isPasswordValid = password => {
  const re = /^(?=\S*[a-z])(?=\S*[A-Z])(?=\S*\d)(?=\S*([^\w\s]|[_]))\S{8,}$/g;
  return re.test(password);
};

export const isCompanyNameValid = name => {
  const nameCheck = /^[a-zA-Z0-9\s-]{2,30}$/g;
  return nameCheck.test(name);
};

export const isDisbursementNameValid = name => {
  const nameCheck = /^[a-zA-Z0-9\s-]{2,30}$/g;
  return nameCheck.test(name);
};

export const isNameValid = name => {
  const nameCheck = /^[a-zA-Z '-]{2,30}$/g;
  return nameCheck.test(name);
};

export const isCardholderNameValid = name => {
  const nameCheck = /^[a-zA-Z -]{2,26}$/g;
  return nameCheck.test(name);
};

export const isPhoneNumberValid = phoneNumber => {
  const phoneCheck = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
  return phoneCheck.test(phoneNumber);
};

export const isAmountValid = value => {
  const amount = parseFloat(value);
  if (Number.isNaN(amount) || amount <= 0.0) return false;
  if (countDecimals(amount) > 2) return false;
  const amountWithoutDecimal = value.split('.').join('');
  return isAllNumbers.test(amountWithoutDecimal);
};
