import { compose, lifecycle } from 'recompose';

import { parseURLSearchParams } from 'shared-components/src/utils/string';
import ResetPassword from './ResetPassword';
import withHandlers from './enhancers/withHandlers';

export default compose(
  withHandlers,
  lifecycle({
    componentDidMount() {
      const { updateLoading, updateUser, updateError, location } = this.props;

      const params = parseURLSearchParams(location.search);
      const { pwt } = params;

      if (pwt) {
        return fetch(`/api/v1/reset_password?token=${pwt}`)
          .then(res => res.json())
          .then(body => {
            body.error
              ? updateError(body.error.message)
              : updateUser({ ...body.data.user, token: pwt });
            updateLoading(false);
          })
          .catch(err => {
            updateError('Unable to contact Pungle Platform');
            updateLoading(false);
          });
      }
      updateLoading(false);
      updateError('Unable to validate email link');
    },
  })
)(ResetPassword);
