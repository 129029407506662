import { withState } from 'recompose';
// Import Utils
import { getEndOfDay, getThirtyDaysAgo } from 'shared-components/src/utils/dates';

export default withState('searchData', 'updateSearchData', {
  status: '',
  dateFrom: getThirtyDaysAgo(),
  dateTo: getEndOfDay(),
  amountFrom: '',
  amountTo: '',
  focusedInput: null // one of startDate, endDate, null
});
