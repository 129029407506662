import React from 'react';
// Import Shared Utils
import { Title, Details } from 'shared-components';
import { formatLocalDateTime } from 'shared-components/src/utils/formatDateTime';
import { formatCurrencySVC } from 'shared-components/src/utils/formatCurrency';
import { formatNetworkType } from 'shared-components/src/utils/string';
// Import Global Components
import SuccessCheck from '../../../../components/SuccessCheck';
// Import Component Styles
import { DetailsWrapper, StyledRedeemResult, RedeemMessage } from './styles';

const RedeemResult = ({
  result: { status, network_type, last_four_digits, email },
  transaction: { sender, inserted_at, amount, currency, support_company_name, support_email, support_phone_number },
}) => {
  // Depending on the network type, we may be returned last_four_digits or email in the result
  const additionalDetailsColumn = last_four_digits
    ? {
      header: 'Last Four Digits / Quatre derniers chiffres:',
      accessor: 'last_four_digits',
    }
    : {
      header: 'Email:',
      accessor: 'email',
    };

  const additionalDetailsData = last_four_digits
    ? { last_four_digits }
    : { email };

  const columns = [
    [
      {
        header: 'From / D\'après:',
        accessor: 'sender',
      },
      {
        header: 'Amount / Montant:',
        accessor: 'amount',
        cell: value => formatCurrencySVC(value / 100, currency),
      },
      {
        header: 'Date Sent / Date D\'envoi:',
        accessor: 'inserted_at',
        cell: value => formatLocalDateTime(value),
      },
    ],
    [
      {
        header: 'Payment Network / Réseau de paiement:',
        accessor: 'network_type',
        cell: value => formatNetworkType(value),
      },
      additionalDetailsColumn,
    ],
  ];

  const renderMessage = () => {
    switch (status) {
    case 'processing':
      return (
        <RedeemMessage>
          <Title>
              Your Payment Is Being Processed! / Votre paiement est en cours de
              traitement!
          </Title>
          <p>
              Your account has been validated and the transaction is being
              processed. You will receive a notification when the transaction is
              complete.
          </p>
          <p>
              Votre compte a été validé et la transaction est en train d'être
              traitement. Vous recevrez une notification lorsque la transaction
              sera terminée.
          </p>
        </RedeemMessage>
      );

    case 'approved':
      return (
        <RedeemMessage approved>
          <SuccessCheck />
          <div>
            <Title>
                Your Payment Was Approved! / Votre paiement a été approuvé!
            </Title>
            <p>
                Your account has been validated and the transaction has been
                approved. You will receive an additional notification that the
                transaction has been completed.
            </p>
            <p>
                Votre compte a été validé et la transaction a été approuvée.
                Vous recevrez une notification supplémentaire indiquant que la
                transaction a été effectuée.
            </p>
          </div>
        </RedeemMessage>
      );

    case 'declined':
      if (support_company_name && support_email && support_phone_number){
        return (
          <RedeemMessage>
            <Title>Your Payment Was Declined / Votre paiement a été refusé</Title>
            <p>
                Your transaction was declined. Please contact {support_company_name} by email at {support_email} or by calling {support_phone_number} to re-initiate payment.
            </p>
            <p>
              Votre transaction a été refusée. Échec du remboursement des fonds. Veuillez contacter {support_company_name} par e-mail à {support_email} ou par téléphone au {support_phone_number} pour réinitialiser le paiement.
            </p>
          </RedeemMessage>
        );
      }
      else if (support_company_name && support_email){
        return (
          <RedeemMessage>
            <Title>Your Payment Was Declined / Votre paiement a été refusé</Title>
            <p>
                Your transaction was declined. Please contact {support_company_name} at {support_email} to
                re-initiate payment.
            </p>
            <p>
              Votre transaction a été refusée. Veuillez contacter {support_company_name} par e-mail à {support_email} pour réinitialiser le paiement.

            </p>
          </RedeemMessage>
        );
      }
      else if (support_company_name && support_phone_number){
        return (
          <RedeemMessage>
            <Title>Your Payment Was Declined / Votre paiement a été refusé</Title>
            <p>
                Your transaction was declined. Please contact {support_company_name} by calling {support_phone_number} to
                re-initiate payment.
            </p>
            <p>
              Votre transaction a été refusée. Veuillez contacter {support_company_name} par téléphone au {support_phone_number} pour relancer le paiement.
            </p>
          </RedeemMessage>
        );
      }
      else {
        return (
          <RedeemMessage>
            <Title>Your Payment Was Declined / Votre paiement a été refusé</Title>
            <p>
                Your transaction was declined. Please contact help@mycardholdersupport.com to
                re-initiate payment.
            </p>
            <p>
              Votre transaction a été refusée. Veuillez contacter help@mycardholdersupport.com 
              pour relancer le paiement.
            </p>
          </RedeemMessage>
        );
      }


    default:
      return null;
    }
  };

  return (
    <StyledRedeemResult>
      {renderMessage()}
      <DetailsWrapper>
        {columns.map(c => (
          <Details
            key={c[0].header}
            columns={c}
            data={{
              sender,
              amount,
              inserted_at,
              network_type,
              ...additionalDetailsData,
            }}
            row
          />
        ))}
      </DetailsWrapper>
    </StyledRedeemResult>
  );
};

export default RedeemResult;
