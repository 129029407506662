// Utility functions for Subscriptions

export const processorOptions = [
  { key: '1', val: 'Galileo' },
];

export const countryOptions = [
  { key: '124', val: 'Canada' },
  { key: '840', val: 'USA' }
];

export const usaStates = [
  { key: 'AL', val: 'Alabama' },
  { key: 'AK', val: 'Alaska' },
  { key: 'AZ', val: 'Arizona' },
  { key: 'AR', val: 'Arkansas' },
  { key: 'CA', val: 'California' },
  { key: 'CO', val: 'Colorado' },
  { key: 'CT', val: 'Connecticut' },
  { key: 'DE', val: 'Delaware' },
  { key: 'FL', val: 'Florida' },
  { key: 'GA', val: 'Georgia' },
  { key: 'HI', val: 'Hawaii' },
  { key: 'ID', val: 'Idaho' },
  { key: 'IL', val: 'Illinois' },
  { key: 'IN', val: 'Indiana' },
  { key: 'IA', val: 'Iowa' },
  { key: 'KS', val: 'Kansas' },
  { key: 'KY', val: 'Kentucky' },
  { key: 'LA', val: 'Louisiana' },
  { key: 'ME', val: 'Maine' },
  { key: 'MD', val: 'Maryland' },
  { key: 'MA', val: 'Massachusetts' },
  { key: 'MI', val: 'Michigan' },
  { key: 'MN', val: 'Minnesota' },
  { key: 'MS', val: 'Mississippi' },
  { key: 'MO', val: 'Missouri' },
  { key: 'MT', val: 'Montana' },
  { key: 'NE', val: 'Nebraska' },
  { key: 'NV', val: 'Nevada' },
  { key: 'NH', val: 'New Hampshire' },
  { key: 'NJ', val: 'New Jersey' },
  { key: 'NM', val: 'New Mexico' },
  { key: 'NY', val: 'New York' },
  { key: 'NC', val: 'North Carolina' },
  { key: 'ND', val: 'North Dakota' },
  { key: 'OH', val: 'Ohio' },
  { key: 'OK', val: 'Oklahoma' },
  { key: 'OR', val: 'Oregon' },
  { key: 'PA', val: 'Pennsylvania' },
  { key: 'RI', val: 'Rhode Island' },
  { key: 'SC', val: 'South Carolina' },
  { key: 'SD', val: 'South Dakota' },
  { key: 'TN', val: 'Tennessee' },
  { key: 'TX', val: 'Texas' },
  { key: 'UT', val: 'Utah' },
  { key: 'VT', val: 'Vermont' },
  { key: 'VA', val: 'Virginia' },
  { key: 'WA', val: 'Washington' },
  { key: 'WV', val: 'West Virginia' },
  { key: 'WI', val: 'Wisconsin' },
  { key: 'WY', val: 'Wyoming' },
];

export const canadaProvinces = [
  { key: 'AB', val: 'Alberta' },
  { key: 'BC', val: 'British Columbia' },
  { key: 'MB', val: 'Manitoba' },
  { key: 'NB', val: 'New Brunswick' },
  { key: 'NL', val: 'Newfoundland and Labrador' },
  { key: 'NT', val: 'Northwest Territories' },
  { key: 'NS', val: 'Nova Scotia' },
  { key: 'NU', val: 'Nunavut' },
  { key: 'ON', val: 'Ontario' },
  { key: 'PE', val: 'Prince Edward Island' },
  { key: 'QC', val: 'Quebec' },
  { key: 'SK', val: 'Saskatchewan' },
  { key: 'YT', val: 'Yukon' },
];