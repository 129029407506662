import React from 'react';
// Import Shared Components
import { EditableCell } from 'shared-components';
// Import Global Components
import Table from '../../../../components/Table';

export default ({ data, updateCell }) => {
  const columns = [
    {
      Header: 'Proxy Number',
      accessor: 'proxy_number',
      className: 'cell',
      minWidth: 250,
      maxWidth: 250,
      Cell: props => (
        <EditableCell
          name="proxy_number"
          updateCell={updateCell}
          className="proxy_number"
          {...props}
          type="proxy_number"
          title="Processor reference of card. Made up of only digits."
        />
      ),
    },
    {
      Header: 'Load Amount',
      accessor: 'load_amount',
      className: 'cell',
      minWidth: 175,
      maxWidth: 175,
      Cell: props => (
        <EditableCell
          name="load_amount"
          updateCell={updateCell}
          className="load_amount"
          {...props}
          prefix="$"
          title="Load amount in currency of cards program. Must be in dollars."
        />
      ),
    },
    {
      Header: 'External Tag',
      accessor: 'external_tag',
      className: 'cell',
      minWidth: 500,
      maxWidth: 500,
      Cell: props => (
        <EditableCell
          name="external_tag"
          updateCell={updateCell}
          className="external_tag"
          maxLength="255"
          {...props}
          title="External tag to link to action stored. Max 255 chars, only alphanumeric characters, \'-\', \'.\' and \'_\' are allowed."
        />
      ),
    },
  ];

  return <Table columns={columns} data={data} key="activate-load-table" />;
};
