import React, { useState } from 'react';
// Import Global Components
import CardDetailsFormV2 from '../../../../components/CardDetailsForm/CardDetailsFormV2';
// Import Local Components
import SelectRedemptionMethod from './components/SelectRedemptionMethod';
import EtransferCustomerForm from './components/EtransferCustomerForm';
import BankDetailsForm from './components/BankDetailsForm';

export default ({
  networks,
  currency,
  tokenizationKey,
  handleSubmit,
  etransferConfig,
  setSaveAsDefault,
  saveAsDefault,
}) => {
  const initialPaymentMethod = networks.length > 1 ? null : networks[0];

  const [paymentMethod, setPaymentMethod] = useState(initialPaymentMethod);

  // Function to return back to select a different payment method
  const backFunc = () => setPaymentMethod(null);

  const renderForm = () => {
    if (paymentMethod === 'visa')
      return (
        <CardDetailsFormV2
          title="Redeem with payment card / Échanger avec une carte de paiement"
          primaryBtnTitle="Redeem"
          isCanadian={currency === 'CAD'}
          tokenCreateCallback={handleSubmit}
          tokenizationKey={tokenizationKey}
          saveDefaultAccount={saveAsDefault}
          setDefaultAccount={setSaveAsDefault}
          secondaryBtnFunc={backFunc}
          secondaryButtonText="Back"
          showNamePartFields
        />
      );

    if (paymentMethod === 'etransfer')
      return (
        <EtransferCustomerForm
          tokenCreateCallback={handleSubmit}
          tokenizationKey={tokenizationKey}
          etransferConfig={etransferConfig}
          secondaryButtonFunc={backFunc}
        />
      );

    if (paymentMethod === 'eft')
      return (
        <BankDetailsForm
          tokenCreateCallback={handleSubmit}
          tokenizationKey={tokenizationKey}
          etransferConfig={etransferConfig}
          secondaryButtonFunc={backFunc}
        />
      );
  };

  return (
    <>
      {!paymentMethod ? (
        <SelectRedemptionMethod
          setPaymentMethod={setPaymentMethod}
          networks={networks}
        />
      ) : (
        <>{renderForm()}</>
      )}
    </>
  );
};
