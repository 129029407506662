import React, { useEffect, useState } from "react";

import {
    Title,
    Alert,
    Table,
    Card,
    CardGroup,
    Loader,
} from 'shared-components';

import { formatTime } from 'shared-components/src/utils/formatDateTime';
// Import Global Components
import ClientErrorComponent from '../../../components/ClientErrorComponent';
// Import Common Hooks
import { useTimeZone } from '../../../hooks/useTimeZone';
import { get } from '../../../utils/http';


export default ({ history }) => {    
  const initialPaginationState = {
    limit: 20,
      offset: 0,
      page: 0,
    };
    const [pagination, setPagination] = useState(initialPaginationState);
    const [orders, setOrders] = useState({ count: 0, data: [] });
    const [isError, setError] = useState(null);
    const [isLoading, setLoading] = useState(true);
    useEffect(() => {
      const fetchData = async () => {
        try {
          const query = new URLSearchParams({
            ...pagination,            
          }).toString();

          const ordersListData = await get(`/api/v1/client_portal/card_issuing/bulk/orders?${query}`);
          setOrders(ordersListData);        
        }
        catch (e){
          setError(true);
        }
        setLoading(false);
      };
      fetchData();
    }, [pagination]);

    const [timeZone] = useTimeZone();

    if (isLoading) return <Loader />;
    if (isError) return <ClientErrorComponent data={{}} />;

    // Handle change in table page size
    const handlePageSizeChange = (pageSize, pageIndex) => {
      setPagination({
        ...pagination,
        limit: pageSize,
        offset: pageIndex * pageSize,
      });
    };

    const handlePageChange = pageIndex => {
      setPagination({
        ...pagination,
        page: pageIndex,
        offset: pageIndex * pagination.limit,
      });
    };

  const columns = [
    {
        Header: 'Order ID',
        accessor: 'id',
    },
    {
        Header: 'Program ID',
        accessor: 'program_id',
    },        
    {
        Header: 'Description',
        accessor: 'description',
    },
    {
        Header: 'Type',
        accessor: 'type',
    },
    {
        Header: 'Status',
        accessor: 'status',
    },
    {
        Header: 'Order Items',
        accessor: 'count',
    },
    {
      Header: 'Created At',
      accessor: 'created_at',
      Cell: ({ value }) => formatTime(value, 'dateTime', timeZone),
    },       
  ];
    
  return (
    <>
      <CardGroup>
        {orders.data.length > 0 ? (
          <Card>
            <Title>Orders</Title>
            <Table
              columns={columns}
              data={orders.data}              
              getTrProps={(state, rowInfo, column) => {
                return {
                  style: {
                    cursor: 'pointer',
                  },
                  className: 'hoverable',                
                  onClick: (e, handleOriginal) => {                      
                    history.push(
                      `orderDetails/${rowInfo.original.id}`,
                    );
                  },
                };
              }}     
              showPagination={orders.count > pagination.limit}
              pages={Math.ceil(orders.count / pagination.limit)}
              page={pagination.page}
              sortable={false}
              manual
              pageSize={pagination.limit}
              onPageSizeChange={handlePageSizeChange}        
              onPageChange={handlePageChange}             
            />
          </Card>
        ) : (
          <Alert type="info">
            Currently there are no orders created. 
          </Alert>
        )}
      </CardGroup>
    </>
  );};