import styled from 'styled-components';

export const SearchFilter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;

  & > *:not(:last-child) {
    margin-bottom: 10px;
  }

  ${({ theme }) => theme.media.tabletLandscapeUp`
    flex-direction: row;

    & > *:not(:last-child) {
      margin-bottom: 0;
      margin-right: 10px;
    }
  `}
`;
