import styled from 'styled-components';

export const CreateHeader = styled.div`
  display: flex;
  flex-direction: column;

  & > div:not(:first-child) {
    margin-top: 15px;
  }

  ${({ theme }) => theme.media.desktopUp`
    flex-direction: row;

    & > div:first-child {
      flex: 1 0 0;
    }

    & > div:not(:first-child) {
      flex: 1 0 0;
      margin-left: 15px;
    }

  `}
`;
