import { withHandlers } from 'recompose';

export default withHandlers({
  // updateSubtab is resetting the state of subtab back to it's original state
  toggleTab: ({ updateTab, updateSubtab }) => tab => () => {
    updateTab(tab);
    updateSubtab('transaction');
  },
  toggleSubtab: ({ updateSubtab }) => subtab => () => {
    updateSubtab(subtab);
  }
});
