export const ACCOUNT_HOLDER_TYPES = [
  { key: 'individual', val: 'Individual' },
  { key: 'business', val: 'Business' },
];

export const getCurrentAccountHolders = accountHolders =>
  accountHolders.filter(accountHolder => accountHolder.latestStatus === 'active');

export const getTotalTransactionAmount = array =>
  array.length > 0 ? array.reduce((acc, curr) => acc + curr.amount / 100, 0) : 0;
