import React, { useContext } from 'react';
// Import Shared Components
import { Title, CardGroup } from 'shared-components';
// Import Contexts
import { ServiceConfigContext } from '../../context/ServiceConfigContext';
// Import Local Components
import Programs from './cardIssuing/Programs';
import TransferHistory from './directSend/TransferHistory';
import TransactionActivity from './directSend/TransactionActivity';
import Collections from './collections/Collections';
// Import Utils
import {
  DIRECT_SEND,
  CARD_ISSUING,
  COLLECTIONS,
} from 'shared-components/src/utils/services';
// Import Component Styles
import { DashboardGroup } from './styles';

export default () => {
  const serviceConfiguration = useContext(ServiceConfigContext);

  return (
    <CardGroup>
      {serviceConfiguration[DIRECT_SEND] && (
        <>
          <Title>Direct Send</Title>
          <DashboardGroup>
            <TransferHistory />
          </DashboardGroup>
          <DashboardGroup>
            <TransactionActivity />
          </DashboardGroup>
        </>
      )}
      {serviceConfiguration[CARD_ISSUING] && (
        <>
          <Title>Card Issuing</Title>
          <DashboardGroup>
            <Programs />
          </DashboardGroup>
        </>
      )}
      {serviceConfiguration[COLLECTIONS] && (
        <>
          <Title>Collections</Title>
          <DashboardGroup>
            <Collections />
          </DashboardGroup>
        </>
      )}
    </CardGroup>
  );
};
