import React from 'react';
// Import Global Components
import Table from '../../../../../../components/Table';
// Import Local Components
import Section from '../../Section';
import VelocityLimitsStanding from './VelocityLimitsStanding';

export default ({ columns, limits }) => (
  <>
    <Section>
      <p>
        This limit applies per <strong>Product</strong> over a <strong>Specific Number of Days</strong>
      </p>
    </Section>
    <Section>
      <p>
        These limits restrict the <strong>Amount</strong> that this <strong>Company</strong> can <strong>Pull/Push/Reverse</strong> on the <strong>Selected Network</strong> for a specific <strong>Product</strong> over the <strong>Specific Number of Days</strong>
      </p>
    </Section>
    <Table
      data={limits}
      columns={columns}
      sortable={false}
      SubComponent={({ original }) => (
        <VelocityLimitsStanding original={original} />
      )}
    />
  </>
);
