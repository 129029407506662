import React, { useState, useContext } from 'react';

import {
  Card,
  Title,
  ActionBar,
  Button,
  Alert,
  Loader,
  CardGroup
} from 'shared-components';

import UnloadByPRN from './components/UnloadByPRN';

import {validateProxyNumber, validateExternalTag, validateLoadAmount} from './validation';

import NotFound from '../../NotFound';
import {ProgramContext} from '../../../context/ProgramConfigContext';
import {post} from '../../../utils/http';
import CardBalance from './components/CardBalance';


export default () => {
  const unloadByPrnEnabled = useContext(ProgramContext).programContext.allow_value_unload_by_prn;

  const initialUnloadByPrnInput = {
    proxy_number: '',
    external_tag: '',
    unload_amount: '',
  };

  const [unloadByPrnInput, setUnloadByPrnInput] = useState(initialUnloadByPrnInput);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const updateInputs = (e, index, original, id) => {
    setUnloadByPrnInput({...unloadByPrnInput, [id]: e.target.value});
    console.log(unloadByPrnInput);
  };

  const unloadCardByPrn = async (prn, input) => {
    setIsLoading(true);

    try {
      await post(`/api/v1/client_portal/card_issuing/cardholders/${prn}/unload`, input);

      setSuccess(true);
      setUnloadByPrnInput(initialUnloadByPrnInput);
    } catch (e) {
      setError(e.message);
    }
    setIsLoading(false);
  };

  const convertToCents = (value) => {
    const amount = parseFloat(value);
    return Math.round(amount * 100);
  };

  const submitUnloadByPrn = () => {
    setSuccess(false);
    const errorProxy = validateProxyNumber(unloadByPrnInput.proxy_number);
    const errorExternalTag = validateExternalTag(unloadByPrnInput.external_tag);
    const errorLoadAmount = validateLoadAmount(unloadByPrnInput.unload_amount);

    setError(errorProxy + errorLoadAmount + errorExternalTag);

    if (errorProxy || errorExternalTag || errorLoadAmount) {
      return;
    }

    const payload = {
      'amount': convertToCents(unloadByPrnInput.unload_amount),
      'external_tag': unloadByPrnInput.external_tag
    };

    unloadCardByPrn(unloadByPrnInput.proxy_number, payload);
  };

  if (isLoading) return <Loader />;
  if (!unloadByPrnEnabled) return <NotFound />;

  return (
    <>
      <CardGroup>
        <Card>
          <CardBalance />
        </Card>
        <Card>
          {error && (
            <Alert type='danger'>
              {error}
            </Alert>
          )}
          {success && (
            <Alert type='success'>
                        The Card was successfully unloaded
            </Alert>
          )}
          <Title>Unload Card</Title>
          <UnloadByPRN
            data={[unloadByPrnInput]}
            updateCell={updateInputs}
          />
          <ActionBar>
            <Button
              type='primary'
              size='large'
              onClick={()=> submitUnloadByPrn()}
            >
                        Unload Card
            </Button>
          </ActionBar>
        </Card>
      </CardGroup>
    </>
  );
};