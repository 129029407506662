import React, { useState } from 'react';
import { Switch, withRouter } from 'react-router-dom';
// Import Shared Components
import { WithAutoLogout } from 'shared-components';
// Import Shared Utils
import auth from 'shared-components/src/utils/auth';
// Import Layouts
import PrivateLayout from '../layouts/Private';
import Route from '../layouts/components/PrivateRoute';
// Import Dashboard Route Components
import Dashboard from './Dashboard';
// Import Direct Send Route Components
import AccountHolders from './DirectSend/AccountHolders';
import AccountHolderDetails from './DirectSend/AccountHolderDetails';
import UploadAccountHolders from './DirectSend/AccountHolders/components/UploadAccountHolders';
import CreateTransfer from './DirectSend/CreateTransfer';
import Limits from './DirectSend/Limits';
import Reports from './DirectSend/Reports';
import TransferDetails from './DirectSend/TransferDetails';
import Transfers from './DirectSend/Transfers';
import CreateDirectSendReportSubscription from './DirectSend/CreateReportSubscription';
// Import Card Issuing Route Components
import Programs from './CardIssuing/Programs';
import ProgramDetails from './CardIssuing/ProgramDetails';
import CreateReportSubscription from './CardIssuing/CreateReportSubscription';
import Cardholders from './CardIssuing/Cardholders';
import CardholderDetails from './CardIssuing/CardholderDetails';
import KycDetails from './CardIssuing/KycDetails';
import CreateCardholders from './CardIssuing/CreateCardholders';
import ValueLoads from './CardIssuing/ValueLoads';
import CreateValueLoads from './CardIssuing/CreateValueLoads';
import CreateValueUnloads from './CardIssuing/CreateValueUnloads';
import UpdateCard from './CardIssuing/UpdateCard';
import ActivateLoadCard from './CardIssuing/ActivateLoadCard';
import UnloadByPRN from './CardIssuing/UnloadByPRN';
import ActivateCards from './CardIssuing/ActivateCards';
import BulkActivateCards from './CardIssuing/BulkActivateCards';
import CloseUnloads from './CardIssuing/CloseUnloads';
import CreateCloseUnloads from './CardIssuing/CreateCloseUnloads';
import CardStatus from './CardIssuing/CardStatus';
import UpdateCardStatus from './CardIssuing/UpdateCardStatus';
// Import Collections Route Components
import CreateCollection from './Collections/CreateCollection';
import CollectionDetails from './Collections/CollectionDetails';
import Collections from './Collections/Collections';
import CollectionConfiguration from './Collections/CollectionConfiguration';
import CollectionReports from './Collections/CollectionReports';
// Import Settings Route Components
import Company from './Settings/Company';
import TeamInfo from './Settings/TeamInfo';
import TimeZone from './Settings/TimeZone';
// Import Api Route Components
import Api from './Api';
// Import Help Route Components
import Help from './Help';
// Import Page Not Found Route Components
import NotFound from './NotFound';
// Import Common Utils
import { refresh } from '../utils/auth';
import { admin, user } from '../utils/roles';
import {
  DIRECT_SEND,
  CARD_ISSUING,
  COLLECTIONS,
  ACTIVATE_CARDS,
  CREATE_CARDHOLDERS,
  VALUE_LOADS,
  CLOSE_UNLOAD,
  MODIFY_CARDS,
  INSTANT_ISSUANCE_CREATE_CARDS,
  API_KEY_ENABLED,
} from 'shared-components/src/utils/services';
import Orders from './CardIssuing/Orders';
import OrderDetails from './CardIssuing/OrderDetails';
import OrderDetailsOrderedItems from './CardIssuing/OrderDetailsOrderedItems';
import OrderItemDetails from './CardIssuing/OrderItemDetails';
import InstantIssuanceCards from './CardIssuing/InstantIssuanceCards';
import InstantIssuanceCreateCards from './CardIssuing/InstantIssuanceCreateCards';

const Private = withRouter(({ history, ...props }) => {
  if (!auth.isAuthenticated) return history.push('/auth/login');
  const [pageTitle, setPageTitle] = useState('Dashboard');

  return (
    <PrivateLayout
      {...props}
      history={history}
      pageTitle={pageTitle}
      id="private"
    >
      <Switch>
        <Route
          exact
          path="/"
          allowedRoles={user}
          render={routeProps => {
            setPageTitle('Dashboard');
            return <Dashboard {...routeProps} />;
          }}
        />
        <Route
          path="/direct-send/create"
          title="Create Transfer"
          allowedRoles={user}
          service={DIRECT_SEND}
          render={routeProps => {
            setPageTitle('Create Transfer');
            return <CreateTransfer {...routeProps} />;
          }}
        />
        <Route
          exact
          path="/direct-send/transfers"
          title="Transfer History"
          allowedRoles={user}
          service={DIRECT_SEND}
          render={routeProps => {
            setPageTitle('Transfer History');
            return <Transfers {...routeProps} />;
          }}
        />
        <Route
          path="/direct-send/transfers/:id"
          title="Transfer Details"
          breadcrumbs={[
            { name: 'Transfers', location: '/direct-send/transfers' },
          ]}
          allowedRoles={user}
          service={DIRECT_SEND}
          render={routeProps => {
            setPageTitle('Transfer Details');
            return <TransferDetails {...routeProps} />;
          }}
        />
        <Route
          exact
          path="/direct-send/account-holders"
          title="Account Holders"
          allowedRoles={admin}
          service={DIRECT_SEND}
          render={routeProps => {
            setPageTitle('Account Holders');
            return <AccountHolders {...routeProps} />;
          }}
        />
        <Route
          path="/direct-send/account-holders/upload"
          title="Upload Account Holders"
          breadcrumbs={[
            {
              name: 'Account Holders',
              location: '/direct-send/account-holders',
            },
          ]}
          allowedRoles={admin}
          service={DIRECT_SEND}
          render={routeProps => {
            setPageTitle('Upload Account Holders');
            return <UploadAccountHolders {...routeProps} />;
          }}
        />
        <Route
          path="/direct-send/account-holders/:id"
          title="Account Holder Details"
          breadcrumbs={[
            {
              name: 'Account Holders',
              location: '/direct-send/account-holders',
            },
          ]}
          allowedRoles={admin}
          service={DIRECT_SEND}
          render={routeProps => {
            setPageTitle('Account Holder Details');
            return <AccountHolderDetails {...routeProps} />;
          }}
        />
        <Route
          exact
          path="/direct-send/reports"
          title="Reports"
          allowedRoles={user}
          service={DIRECT_SEND}
          render={routeProps => {
            setPageTitle('Reports');
            return <Reports {...routeProps} />;
          }}
        />
        <Route
          path="/direct-send/reports/create"
          title="Create Report Subscription"
          allowedRoles={user}
          service={DIRECT_SEND}
          render={routeProps => {
            setPageTitle('Create Report Subscription');
            return <CreateDirectSendReportSubscription {...routeProps} />;
          }}
        />
        <Route
          path="/direct-send/limits"
          title="Limits"
          allowedRoles={admin}
          service={DIRECT_SEND}
          render={routeProps => {
            setPageTitle('Limits');
            return <Limits {...routeProps} />;
          }}
        />
        <Route
          exact
          path="/card-issuing/programs"
          title="Programs"
          allowedRoles={user}
          service={CARD_ISSUING}
          render={routeProps => {
            setPageTitle('Programs');
            return <Programs {...routeProps} />;
          }}
        />
        <Route
          exact
          path="/card-issuing/programs/:id"
          title="Program Details"
          allowedRoles={user}
          service={CARD_ISSUING}
          breadcrumbs={[
            {
              name: 'Programs',
              location: '/card-issuing/programs',
            },
          ]}
          render={routeProps => {
            setPageTitle('Program Details');
            return <ProgramDetails {...routeProps} />;
          }}
        />
        <Route
          exact
          path="/card-issuing/programs/:id/reports"
          breadcrumbs={[
            {
              name: 'Programs',
              location: '/card-issuing/programs',
            },
            // TODO: Need to find solution to pass ID to breadcrumbs
            // {
            //   name: 'Program Details',
            //   location: '/card-issuing/programs/:id',
            // },
          ]}
          title="Create Report Subscription"
          allowedRoles={user}
          service={CARD_ISSUING}
          render={routeProps => {
            setPageTitle('Create Report Subscription');
            return <CreateReportSubscription {...routeProps} />;
          }}
        />
        <Route
          exact
          path="/card-issuing/cardholders"
          title="Cardholders"
          allowedRoles={user}
          service={CARD_ISSUING}
          render={routeProps => {
            setPageTitle('Cardholders');
            return (
              <Cardholders
                {...routeProps}
                bulkerSetting={{ bulkerEnabled: false }}
              />
            );
          }}
        />
        <Route
          path="/card-issuing/cardholders/create"
          breadcrumbs={[
            { name: 'Cardholders', location: '/card-issuing/cardholders' },
          ]}
          title="Create Cardholders"
          allowedRoles={user}
          service={CARD_ISSUING}
          render={routeProps => {
            setPageTitle('Create Cardholders');
            return <CreateCardholders {...routeProps} />;
          }}
        />
        <Route
          path="/card-issuing/kyc_results/:id"
          breadcrumbs={[
            { name: 'Cardholders', location: '/card-issuing/cardholders' },
          ]}
          title="KYC Details"
          allowedRoles={user}
          service={CARD_ISSUING}
          render={routeProps => {
            setPageTitle('KYC Details');
            return <KycDetails {...routeProps} />;
          }}
        />
        <Route
          path="/card-issuing/cardholders/:id"
          breadcrumbs={[
            { name: 'Cardholders', location: '/card-issuing/cardholders' },
          ]}
          title="Cardholder Details"
          allowedRoles={user}
          service={CARD_ISSUING}
          render={routeProps => {
            setPageTitle('Cardholder Details');
            return <CardholderDetails {...routeProps} />;
          }}
        />
        <Route
          exact
          path="/card-issuing/value-loads"
          title="Value Loads"
          allowedRoles={user}
          service={CARD_ISSUING}
          render={routeProps => {
            setPageTitle('Value Loads');
            return (
              <ValueLoads
                {...routeProps}
                bulkerSetting={{ bulkerEnabled: false }}
              />
            );
          }}
        />
        <Route
          path="/card-issuing/value-loads/load"
          title="Create Value Loads"
          allowedRoles={user}
          service={CARD_ISSUING}
          render={routeProps => {
            setPageTitle('Create Value Loads');
            return <CreateValueLoads {...routeProps} />;
          }}
        />
        <Route
          path="/card-issuing/value-loads/unload"
          title="Create Value Unloads"
          allowedRoles={user}
          service={CARD_ISSUING}
          render={routeProps => {
            setPageTitle('Create Value Unloads');
            return <CreateValueUnloads {...routeProps} />;
          }}
        />
        <Route
          exact
          path="/bulker/card-issuing/value-loads"
          title="Value Loads"
          allowedRoles={user}
          service={VALUE_LOADS}
          render={routeProps => {
            setPageTitle('Value Loads');
            return (
              <ValueLoads
                {...routeProps}
                bulkerSetting={{ bulkerEnabled: true }}
              />
            );
          }}
        />
        <Route
          path="/bulker/card-issuing/value-loads/load"
          title="Create Value Loads"
          allowedRoles={user}
          service={VALUE_LOADS}
          render={routeProps => {
            setPageTitle('Create Value Loads');
            return <CreateValueLoads {...routeProps} />;
          }}
        />
        <Route
          path="/bulker/card-issuing/value-loads/unload"
          title="Create Value Unloads"
          allowedRoles={user}
          service={VALUE_LOADS}
          render={routeProps => {
            setPageTitle('Create Value Unloads');
            return <CreateValueUnloads {...routeProps} />;
          }}
        />
        <Route
          exact
          path="/card-issuing/orders"
          title="Orders"
          allowedRoles={user}
          service={CARD_ISSUING}
          render={routeProps => {
            setPageTitle('Orders');
            return <Orders {...routeProps} />;
          }}
        />
        <Route
          exact
          path="/card-issuing/orderDetails/:id"
          title="Order Details"
          allowedRoles={user}
          service={CARD_ISSUING}
          render={routeProps => {
            setPageTitle('Order Details');
            return <OrderDetails {...routeProps} />;
          }}
        />
        <Route
          exact
          path="/card-issuing/orderDetails/orderItems/:id"
          title="Order Items"
          allowedRoles={user}
          service={CARD_ISSUING}
          render={routeProps => {
            setPageTitle('Order Items');
            return <OrderDetailsOrderedItems {...routeProps} />;
          }}
        />
        <Route
          exact
          path="/card-issuing/orderDetails/orderItemDetails/:id"
          title="Order Item Details"
          allowedRoles={user}
          service={CARD_ISSUING}
          render={routeProps => {
            setPageTitle('Order Item Details');
            return <OrderItemDetails {...routeProps} />;
          }}
        />
        <Route
          exact
          path="/bulker/card-issuing/bulk-activate"
          title="Cards"
          allowedRoles={user}
          service={ACTIVATE_CARDS}
          render={routeProps => {
            setPageTitle('Cards');
            return (
              <BulkActivateCards
                {...routeProps}
                bulkerSetting={{ bulkerEnabled: true }}
              />
            );
          }}
        />
        <Route
          exact
          path="/bulker/card-issuing/bulk-activate/activate-cards"
          title="Activate Cards"
          allowedRoles={user}
          service={ACTIVATE_CARDS}
          render={routeProps => {
            setPageTitle('Activate Cards');
            return (
              <ActivateCards
                {...routeProps}
                bulkerSetting={{ bulkerEnabled: true }}
              />
            );
          }}
        />
        <Route
          exact
          path="/bulker/card-issuing/card-status"
          title="Card Status"
          allowedRoles={user}
          service={MODIFY_CARDS}
          render={routeProps => {
            setPageTitle('Card Status');
            return (
              <CardStatus
                {...routeProps}
                bulkerSetting={{ bulkerEnabled: true }}
              />
            );
          }}
        />
        <Route
          exact
          path="/bulker/card-issuing/card-status/update"
          title="Modify Card Statuses"
          allowedRoles={user}
          service={MODIFY_CARDS}
          render={routeProps => {
            setPageTitle('Modify Card Statuses');
            return (
              <UpdateCardStatus
                {...routeProps}
                bulkerSetting={{ bulkerEnabled: true }}
              />
            );
          }}
        />
        <Route
          exact
          path="/bulker/card-issuing/close-unloads"
          title="Close/Unloads"
          allowedRoles={user}
          service={CLOSE_UNLOAD}
          render={routeProps => {
            setPageTitle('Close/Unloads');
            return (
              <CloseUnloads
                {...routeProps}
                bulkerSetting={{ bulkerEnabled: true }}
              />
            );
          }}
        />
        <Route
          exact
          path="/bulker/card-issuing/close-unloads/create"
          title="Create Close/Unloads"
          allowedRoles={user}
          service={CLOSE_UNLOAD}
          render={routeProps => {
            setPageTitle('Create Close/Unloads');
            return (
              <CreateCloseUnloads
                {...routeProps}
                bulkerSetting={{ bulkerEnabled: true }}
              />
            );
          }}
        />
        <Route
          exact
          path="/bulker/card-issuing/cardholders"
          title="Cardholders"
          allowedRoles={user}
          service={CREATE_CARDHOLDERS}
          render={routeProps => {
            setPageTitle('Cardholders');
            return (
              <Cardholders
                {...routeProps}
                bulkerSetting={{ bulkerEnabled: true }}
              />
            );
          }}
        />
        <Route
          path="/bulker/card-issuing/cardholders/create"
          breadcrumbs={[
            {
              name: 'Cardholders',
              location: 'bulker/card-issuing/cardholders',
            },
          ]}
          title="Create Cardholders"
          allowedRoles={user}
          service={CREATE_CARDHOLDERS}
          render={routeProps => {
            setPageTitle('Create Cardholders');
            return (
              <CreateCardholders
                {...routeProps}
                bulkerSetting={{ bulkerEnabled: true }}
              />
            );
          }}
        />
        <Route
          exact
          path="/bulker/card-issuing/instant-issuance/cards"
          title="Instant Issuance: Cards"
          allowedRoles={user}
          service={INSTANT_ISSUANCE_CREATE_CARDS}
          render={routeProps => {
            setPageTitle('Instant Issuance: Cards');
            return (
              <InstantIssuanceCards
                {...routeProps}
                bulkerSetting={{ bulkerEnabled: true }}
              />
            );
          }}
        />
        <Route
          exact
          path="/bulker/card-issuing/instant-issuance/create-cards"
          title="Instant Issuance: Create Cards"
          allowedRoles={user}
          service={INSTANT_ISSUANCE_CREATE_CARDS}
          render={routeProps => {
            setPageTitle('Instant Issuance: Create Cards');
            return (
              <InstantIssuanceCreateCards
                {...routeProps}
                bulkerSetting={{ bulkerEnabled: true }}
              />
            );
          }}
        />
        <Route
          path="/card-issuing/activate-load-card"
          title="Activate & Load Card"
          allowedRoles={admin}
          service={CARD_ISSUING}
          render={routeProps => {
            setPageTitle('Activate & Load Card');
            return <ActivateLoadCard {...routeProps} />;
          }}
        />
        <Route
          path="/card-issuing/unload-card"
          title="Unload Card by Proxy"
          allowedRoles={admin}
          service={CARD_ISSUING}
          render={routeProps => {
            setPageTitle('Unload Card by Proxy');
            return <UnloadByPRN {...routeProps} />;
          }}
        />
        <Route
          path="/card-issuing/update-card"
          title="Update card"
          allowedRoles={user}
          service={CARD_ISSUING}
          render={routeProps => {
            setPageTitle('Update card');
            return <UpdateCard {...routeProps} />;
          }}
        />
        <Route
          path="/collections/create"
          title="Create Collection"
          allowedRoles={user}
          service={COLLECTIONS}
          render={routeProps => {
            setPageTitle('Create Collection');
            return <CreateCollection {...routeProps} />;
          }}
        />
        <Route
          exact
          path="/collections/collections"
          title="Collection History"
          allowedRoles={user}
          service={COLLECTIONS}
          render={routeProps => {
            setPageTitle('Collection History');
            return <Collections {...routeProps} />;
          }}
        />
        <Route
          path="/collections/collections/:id"
          title="Collection Details"
          breadcrumbs={[
            { name: 'Collections', location: '/collections/collections' },
          ]}
          allowedRoles={user}
          service={COLLECTIONS}
          render={routeProps => {
            setPageTitle('Collection Details');
            return <CollectionDetails {...routeProps} />;
          }}
        />
        <Route
          path="/collections/configuration"
          title="Collections Configuration"
          allowedRoles={user}
          service={COLLECTIONS}
          render={routeProps => {
            setPageTitle('Collections Configuration');
            return <CollectionConfiguration {...routeProps} />;
          }}
        />
        <Route
          path="/collections/reports"
          title="Collection Reports"
          allowedRoles={user}
          service={COLLECTIONS}
          render={routeProps => {
            setPageTitle('Collections Reports');
            return <CollectionReports {...routeProps} />;
          }}
        />
        <Route
          path="/api-keys"
          allowedRoles={user}
          title="API Keys"
          service={API_KEY_ENABLED}
          render={routeProps => {
            setPageTitle('API Keys');
            return <Api {...routeProps} />;
          }}
        />
        <Route
          exact
          path="/settings/company"
          title="Company Settings"
          allowedRoles={user}
          render={routeProps => {
            setPageTitle('Company Settings');
            return <Company {...routeProps} />;
          }}
        />
        <Route
          path="/settings/team"
          title="Team Members"
          allowedRoles={admin}
          render={routeProps => {
            setPageTitle('Team Members');
            return <TeamInfo {...routeProps} />;
          }}
        />
        <Route
          path="/settings/timezone"
          title="Time Zone Settings"
          allowedRoles={user}
          render={routeProps => {
            setPageTitle('Time Zone Settings');
            return <TimeZone {...routeProps} />;
          }}
        />
        <Route
          path="/help"
          title="Contact Us"
          allowedRoles={user}
          render={routeProps => {
            setPageTitle('Contact Us');
            return <Help {...routeProps} />;
          }}
        />
        <Route
          title="Page Not Found"
          allowedRoles={user}
          render={routeProps => {
            setPageTitle('Page Not Found');
            return <NotFound {...routeProps} />;
          }}
        />
      </Switch>
    </PrivateLayout>
  );
});

export default WithAutoLogout(Private, refresh);
