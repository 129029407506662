import React from 'react';
import PropTypes from 'prop-types';
// Import Shared Components
import { Title, Details, DetailsGroup } from 'shared-components';
import { formatTime } from 'shared-components/src/utils/formatDateTime';
// Import Global Components
import TransferTotal from '../../../../components/TransferTotal';
// Import Local COmponents
import CollectionStatus from './CollectionStatus';
// Import Hooks
import { useTimeZone } from '../../../../hooks/useTimeZone';

const Header = ({ data }) => {
  const [timeZone] = useTimeZone();
  const columns = [
    [
      {
        accessor: 'name',
        header: 'Name:',
        cell: value => value,
      },
      {
        accessor: 'id',
        header: 'Collection ID:',
        cell: value => value,
      },
      {
        accessor: 'created_at',
        header: 'Created At:',
        cell: value => formatTime(value, 'dateTime', timeZone),
      },
      {
        accessor: 'status',
        header: 'Status:',
        cell: value => <CollectionStatus status={value} />,
      },
    ],
    [
      {
        accessor: 'expiry',
        header: 'Expires:',
        cell: value => formatTime(value, 'longDate', timeZone),
      },
      {
        accessor: 'txn_id',
        header: 'Transaction ID:',
        cell: value => value,
      },

      {
        accessor: 'amount',
        header: 'Amount Requested:',
        cell: value => (
          <TransferTotal
            total={value / 100}
            currency={data.currency || 'CAD'}
          />
        ),
      },
    ],
  ];

  const detailsData = {
    ...data,
    name: `${data.first_name} ${data.last_name}`,
  };

  return (
    <>
      <Title>Collection Details</Title>
      <DetailsGroup>
        {columns.map(column => (
          <Details data={detailsData} columns={column} />
        ))}
      </DetailsGroup>
    </>
  );
};

Header.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.shape({
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
    currency: PropTypes.string.isRequired,
    created_at: PropTypes.string.isRequired,
    amount: PropTypes.number.isRequired,
    status: PropTypes.string.isRequired,
  }).isRequired,
};

export default Header;
