import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

const QUERY = gql`
  query {
    viewer {
      id
      company {
        id
        latestStatus
        subscriptions {
          id
          status
          plan {
            id
            type
          }
        }
      }
    }
  }
`;

export default graphql(QUERY, {
  props: props => ({
    id: 1,
    loadingOne: props.data.loading,
    subscriptionData: props.data.viewer,
    data: { error: props.data.error },
    ...props
  }),
  options: props => ({
    fetchPolicy: 'network-only'
  })
});
