export const validateExternalTag = (value) => {
  const maxLength = 255;
  const validPattern = /^[a-zA-Z0-9\-\._]+$/;

  if (value === '') {
    return '';
  }
  
  if (value.length > maxLength) {
    return 'External tag is too long. Max 255 characters allowed.';
  }
  
  if (!validPattern.test(value)) {
    return 'External tag contains invalid characters. Only alphanumeric characters, \'-\', \'.\' and \'_\' are allowed.';
  }
  
  return '';
};
  
export const validateProxyNumber = (value) => {
  const validPattern = /^\d+$/;

  if (!value) {
    return 'Proxy number is required.';
  }
  
  if (!validPattern.test(value)) {
    return 'Proxy number can only contain digits.';
  }

  return '';
};
  
export const validateLoadAmount = (value) => {
  const minAmount = 0;

  if (!value) {
    return 'Load amount is required.';
  }

  const validPattern = /^\d+(\.\d{1,2})?$/;
  if (!validPattern.test(value)) {
    return 'Load amount must be a valid number with at most two decimal places.';
  }

  const amount = parseFloat(value);

  if (Number.isNaN(amount)) {
    return 'Load amount must be a valid number.';
  }

  if (amount <= minAmount) {
    return `Load amount must be bigger than ${minAmount}.`;
  }

  return '';
};