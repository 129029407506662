export const createCollectionsEn = {
  'collection-create-firstName-label': 'First Name',
  'collection-create-lastName-label': 'Last Name',
  'collection-create-email-label': 'Email',
  'collection-create-amount-label': 'Amount',
  'collection-create-submit-button': 'Create',
  'collection-create-externalTag-label': 'External Tag',
  'collection-create-accountRef-label': 'Account Reference',
};

export const createCollectionsFr = {
  'collection-create-firstName-label': 'First Name',
  'collection-create-lastName-label': 'Last Name',
  'collection-create-email-label': 'Email',
  'collection-create-amount-label': 'Amount',
  'collection-create-submit-button': 'Create',
  'collection-create-externalTag-label': 'External Tag',
  'collection-create-accountRef-label': 'Account Reference',
};
