import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
// Import Shared Components
import {
  Alert,
  Card,
  CardGroup,
  Search,
  Loader,
  Button,
  ButtonGroup,
  LoadPlaceholder,
} from 'shared-components';
import { formatTime } from 'shared-components/src/utils/formatDateTime';
// Import Common Hooks
import { formatCurrencyV } from 'shared-components/src/utils/formatCurrency';
import useTellerApi from '../../../hooks/useTellerApi';
import { useTimeZone } from '../../../hooks/useTimeZone';
// Import Common Utils
import { get } from '../../../utils/http';
// Import Global Components
import ClientErrorComponent from '../../../components/ClientErrorComponent';
// Import Local Components
import FailedValueLoadTable from './components/FailedValueLoadTable';
import ValueLoadTable from './components/ValueLoadTable';
// Import Component Styles
import { Header } from './styles';

import FILTER from './filter';

//role context
import {RoleContext} from '../../../context/RoleContext';

export default ({ history, bulkerSetting }) => {
  const initialPagniationState = {
    limit: 10,
    offset: 0,
    page: 0,
  };

  const [pagination, setPagination] = useState(initialPagniationState);
  const [failedValueLoadsPagination, setFailedValueLoadsPagination] = useState(
    initialPagniationState
  );

  // Set states
  const [searchBy, setSearchBy] = useState(null);
  const [searchParams, setSearchParams] = useState({});
  const [success, setSuccess] = useState(null);
  const [allowValueUnloads, setAllowValueUnloads] = useState(false);
  const [error, setError] = useState(false);
  const [fetchingCapabilities, setFetchingCapabilities] = useState(true);
  const [timeZone] = useTimeZone();
  const [bulker, setBulker] = useState(false);

  // Get role from context
  const userRole = React.useContext(RoleContext);  

  // Needs this, to ensure, that state is set correctly. Going from card-issuing
  // page to card-issuing-bulker does not set state properly without this
  useEffect(()=> {
    setBulker(bulkerSetting.bulkerEnabled)
  }, [bulkerSetting.bulkerEnabled]);
  useEffect(() => {
    if (history.location.state && history.location.state.success) {
      setSuccess(history.location.state.success);
      // Remove success message from state

      const clearSuccess = setTimeout(() => {
        // Clear Success message after 5 seconds
        setSuccess(null);
      }, 5000);

      return () => {
        setSuccess(null);
        clearTimeout(clearSuccess);
      };
    }

    return () => setSuccess(null);
  }, [history.location.state]);

  // Check if company can unload on initial
  useEffect(() => {
    const getCapabilities = async () => {
      try {
        const res = await get(
          '/api/v1/client_portal/card_issuing/company/capabilities'
        );
        setAllowValueUnloads(res.data.allow_client_unload);
      } catch (e) {
        setError(true);
      }
      setFetchingCapabilities(false);
    };
    getCapabilities();
  }, []);

  // Get value loads
  const [
    {
      data: { count, data: valueLoads },
      isLoading,
      isError,
    },
    setUrl,
  ] = useTellerApi('/api/v1/client_portal/card_issuing/value_loads', {
    count: 0,
    data: [],
    limit: pagination.limit,
    offset: pagination.offset,
  });

  // Get failed value loads
  // Temporary fix for https://berkeleypayments.atlassian.net/browse/PT-1459
  const [
    {
      data: { count: failedValueLoadsCount, data: failedValueLoads },
      isError: failedValueLoadsIsError,
    },
    setFailedValueLoadsUrl,
  ] = useTellerApi('/api/v1/client_portal/card_issuing/failed_value_loads', {
    count: 0,
    data: [],
    limit: failedValueLoadsPagination.limit,
    offset: failedValueLoadsPagination.offset,
  });

  const updateSearchParamField = (field, value) => {
    setSearchParams({
      [field]: value,
    });
  };

  const searchValueLoads = () => {
    const params = new URLSearchParams({
      ...searchParams,
      ...pagination,
      page: 0,
      offset: 0,
    }).toString();

    setUrl(`/api/v1/client_portal/card_issuing/value_loads?${params}`);
    // Reset page to 0 after user clicks search
    setPagination(prev => ({ ...prev, page: 0, offset: 0 }));

    // For Failed Value Loads
    const failedValueLoadsParams = new URLSearchParams({
      ...searchParams,
      ...failedValueLoadsPagination,
      page: 0,
      offset: 0,
    }).toString();

    setFailedValueLoadsUrl(
      `/api/v1/client_portal/card_issuing/failed_value_loads?${failedValueLoadsParams}`
    );
    setFailedValueLoadsPagination(prev => ({ ...prev, page: 0, offset: 0 }));
  };

  if (isLoading) return <Loader />;
  if (isError || error || failedValueLoadsIsError)
    return <ClientErrorComponent data={{}} />;

  const columns = [
    {
      Header: 'ID',
      accessor: 'id',
      sortType: 'basic',
    },
    {
      Header: 'Program ID',
      accessor: 'program_id',
    },
    {
      Header: 'Cardholder ID',
      accessor: 'cardholder_id',
      Cell: ({ value }) => (
        <Link to={`/card-issuing/cardholders/${value}`}>{value}</Link>
      ),
    },
    {
      Header: 'Account ID',
      accessor: 'account_id',
    },
    {
      Header: 'External Tag',
      accessor: 'external_tag',
      Cell: ({ value }) => (value && value.length > 0 ? value : '--'),
    },
    {
      Header: 'Value Load Processor Reference Number (PRN)',
      accessor: 'processor_reference',
    },
    {
      Header: 'Load Type',
      accessor: 'load_type',
      show: false,
    },
    {
      Header: 'Amount',
      accessor: 'amount',
      Cell: ({ value, row }) => {
        if (row.load_type === 'unload') {
          return formatCurrencyV(-value / 100);
        }
        return formatCurrencyV(value / 100);
      },
    },
    {
      Header: 'Created On',
      accessor: 'created_at',
      Cell: ({ value }) => formatTime(value, 'longDate', timeZone),
    },
  ];

  return (
    <CardGroup>
      {success && <Alert type="success">{success}</Alert>}
      <Header>
        <Card>
          <Search
            searchOptions={FILTER}
            updateSearchBy={setSearchBy}
            searchBy={searchBy}
            value={searchParams[searchBy]}
            onChange={e => updateSearchParamField(searchBy, e.target.value)}
            onClick={() => searchValueLoads()}
          />
        </Card>
        <Card>
          <ButtonGroup>
            {fetchingCapabilities ? (
              <>
                <LoadPlaceholder>
                  <Button type="primary">Create Value Loads</Button>
                </LoadPlaceholder>
                <LoadPlaceholder>
                  <Button type="primary" disabled={!allowValueUnloads}>
                    Create Value Unloads
                  </Button>
                </LoadPlaceholder>
              </>
            ) : (
              <>
                <Button
                  type="primary"
                  disabled={userRole == 'user' }
                  onClick={() => {
                    if(bulker){
                      history.push(
                        {
                          pathname:'/bulker/card-issuing/value-loads/load',
                          state: {
                            bulker: true
                          }
                        }
                       )
                    }
                    else
                    {
                      history.push('/card-issuing/value-loads/load')}
                    }                   
                  }
                >
                  Create Value Loads
                </Button>
                {/* only show unload btn if company has unload capability */}
                {allowValueUnloads && (
                  <Button
                    type="primary"
                    disabled={userRole == 'user' }
                    onClick={() => {
                      if(bulker){
                        history.push(
                        {
                          pathname:'/bulker/card-issuing/value-loads/unload',
                          state: {
                            bulker: true
                          }
                        })
                      }
                      else
                      {
                        history.push('/card-issuing/value-loads/unload')}
                      }         
                    }
                  >
                    Create Value Unloads
                  </Button>
                )}
              </>
            )}
          </ButtonGroup>
        </Card>
      </Header>
      <ValueLoadTable
        columns={columns}
        valueLoads={valueLoads}
        count={count}
        pagination={pagination}
        searchParams={searchParams}
        setPagination={setPagination}
        setUrl={setUrl}
      />
      <FailedValueLoadTable
        columns={columns}
        failedValueLoads={failedValueLoads}
        failedValueLoadsCount={failedValueLoadsCount}
        failedValueLoadsPagination={failedValueLoadsPagination}
        setFailedValueLoadsPagination={setFailedValueLoadsPagination}
        setFailedValueLoadsUrl={setFailedValueLoadsUrl}
        searchParams={searchParams}
      />
    </CardGroup>
  );
};
