import React from 'react';
// Import Shared Components
import { InputGroup } from 'shared-components';
// Import Global Components
import ModalForm from '../../../../../components/ModalForm';
// Import Utils
import { ACCOUNT_HOLDER_TYPES } from '../../../../../utils/accountHolders';

export default ({
  closeModal,
  error,
  email,
  firstName,
  lastName,
  companyName,
  type,
  phone,
  accountHolderInfo,
  onUpdateEmail,
  onUpdateFirstName,
  onUpdateLastName,
  onUpdateCompanyName,
  onUpdatePhone,
  onUpdateType,
  validationError,
  validateEmail,
  validatePhoneNumber,
  validateFirstName,
  validateLastName,
  validateCompanyName,
  submit,
  title,
  page,
  ...props
}) => (
  <ModalForm
    title={title}
    submit={submit}
    data-id={`${page}-form`}
    error={error}
    closeModal={closeModal}
  >
    <InputGroup
      required
      dropdown
      label="Type:"
      labelFor="type"
      dataId={`${page}-type-dropdown`}
      selected={type}
      options={ACCOUNT_HOLDER_TYPES}
      updateItem={onUpdateType}
      {...props}
    />
    {type === 'individual' && (
      <>
        <InputGroup
          required
          label="First Name:"
          labelFor="firstName"
          dataId={`${page}-first-name-input`}
          value={firstName}
          onChange={onUpdateFirstName}
          onBlur={validateFirstName}
          error={validationError.firstName}
        />
        <InputGroup
          required
          label="Last Name:"
          labelFor="lastName"
          dataId={`${page}-last-name-input`}
          value={lastName}
          onChange={onUpdateLastName}
          onBlur={validateLastName}
          error={validationError.lastName}
        />
      </>
    )}
    {type === 'business' && (
      <>
        <InputGroup
          required
          label="Company Name:"
          labelFor="companyName"
          dataId={`${page}-company-name-input`}
          value={companyName}
          onChange={onUpdateCompanyName}
          onBlur={validateCompanyName}
          error={validationError.companyName}
        />
      </>
    )}
    <InputGroup
      required
      label="Email:"
      labelFor="email"
      dataId={`${page}-email-input`}
      value={email}
      onChange={onUpdateEmail}
      onBlur={validateEmail}
      error={validationError.email}
      type="email"
    />
    <InputGroup
      required
      label="Phone Number:"
      labelFor="phoneNumber"
      dataId={`${page}-phone-input`}
      value={phone}
      onChange={onUpdatePhone}
      onBlur={validatePhoneNumber}
      error={validationError.phone}
    />
  </ModalForm>
);
