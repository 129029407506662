import React from 'react';
// Import Tooltip
import {
  formatCurrencyCode,
  formatCurrencyV,
} from 'shared-components/src/utils/formatCurrency';
import InfoTooltip from '../../../components/InfoTooltip';


export const escrowColumns = [
  {
    header: (
      <div style={{ display: 'flex'}}>
        <div>
                Total Escrow Balance:
        </div>
        <div style={{ marginLeft: '5px', display: 'flex', alignItems: 'center' }}>
          <InfoTooltip content="This represents the total available escrow balance." />
        </div>
      </div>
    ),
    accessor: 'available_balance',
    cell: val => formatCurrencyV(val),
  },
  {
    header: 'Currency:',
    accessor: 'currency',
    cell: val => formatCurrencyCode(val),
  },
];

export const interacColumns = [
  {
    header: (
      <div style={{ display: 'flex'}}>
        <div>
                    Allocation of Total Escrow Balance Available for Interac E-Transfer:
        </div>
        <div style={{ marginLeft: '5px', display: 'flex', alignItems: 'center' }}>
          <InfoTooltip content="The Interac Balance represents the portion of the Total Escrow Balance that is allocated for E-Transfers. This is not a separate balance, rather just an allocation of the Total Escrow Balance." />
        </div>
      </div>
    ),
    accessor: 'available_balance',
    cell: val => formatCurrencyV(val),
  },
  {
    header: 'Currency:',
    accessor: 'currency',
    cell: val => formatCurrencyCode(val),
  },
];
