import { compose, withHandlers } from 'recompose';
import CreateAccountHolderModal from './CreateAccountHolderModal';

// States
import withEmailState from '../../enhancers/states/withEmailState.js';
import withErrorState from '../../enhancers/states/withErrorState.js';
import withCompanyNameState from '../../enhancers/states/withCompanyNameState.js';
import withFirstNameState from '../../enhancers/states/withFirstNameState.js';
import withLastNameState from '../../enhancers/states/withLastNameState.js';
import withPhoneState from '../../enhancers/states/withPhoneNumberState.js';
import withTypeState from '../../enhancers/states/withTypeState.js';

import withValidationErrorsState from '../../enhancers/states/withValidationErrorsState';
import withValidationHandlers from '../../enhancers/withValidationHandlers';

export default compose(
  withEmailState,
  withErrorState,
  withValidationErrorsState,
  withFirstNameState,
  withCompanyNameState,
  withLastNameState,
  withPhoneState,
  withTypeState,
  withValidationHandlers,
  withHandlers({
    submit: ({
      closeModal,
      email,
      companyName,
      firstName,
      lastName,
      type,
      phone,
      onFormSubmit,
      updateError,
      emailValidate,
      firstNameValidate,
      lastNameValidate,
      phoneNumberValidate,
      validationError,
      updateSuccess,
      ...props
    }) => e => {
      e.preventDefault();

      // Check Validations and make sure fields are filled
      if (
        type === 'business' &&
        (validationError.email ||
          !email ||
          (validationError.companyName || !companyName) ||
          validationError.phone)
      ) {
        return updateError('One or more fields are incorrect please try again');
      }
      if (
        type === 'individual' &&
        (validationError.email ||
          !email ||
          (validationError.firstName || !firstName) ||
          (validationError.lastName || !lastName) ||
          validationError.phone)
      ) {
        return updateError('One or more fields are incorrect please try again');
      }

      // Ensure that the fields aren't blank

      if (type === 'business') {
        onFormSubmit({
          email: email.trim(),
          firstName: companyName.trim(),
          lastName: '   ',
          type: type.toUpperCase(), // UpperCase is Required by GraphQL
          phone: (phone && phone.trim()) || null
        });
      }

      if (type === 'individual') {
        onFormSubmit({
          email: email.trim(),
          firstName: firstName.trim(),
          lastName: lastName.trim(),
          type: type.toUpperCase(), // UpperCase is Required by GraphQL
          phone: (phone && phone.trim()) || null
        });
      }

      closeModal();
    },
    onUpdateType: ({ updateType, updateError }) => key => {
      updateType(key);
      updateError(false);
    },
    onUpdateEmail: ({ updateEmail, updateError }) => e => {
      updateEmail(e.target.value.trim());
      updateError(false);
    },
    onUpdateCompanyName: ({ updateCompanyName, updateError }) => e => {
      updateCompanyName(e.target.value);
      updateError(false);
    },
    onUpdateFirstName: ({ updateFirstName, updateError }) => e => {
      updateFirstName(e.target.value);
      updateError(false);
    },
    onUpdateLastName: ({ updateLastName, updateError }) => e => {
      updateLastName(e.target.value);
      updateError(false);
    },
    onUpdatePhone: ({ updatePhone, updateError }) => e => {
      updatePhone(e.target.value);
      updateError(false);
    }
  })
)(CreateAccountHolderModal);
