// Function for determining the correct CSV requirements for activate cards
export const getCardsCSVFieldDetails = () => {

  let baseDetails = [
    {
      field: 'Account Id',
      required: 'Yes',
      requirements: 'Account ID',
      notes:
        'Account ID.',
      example: '14563',
    },
    {
      field: 'Last Four Digits',
      required: 'Yes',
      requirements:
        "Last four digits of PAN",
      notes: "Last four digits of PAN",
      example: '0991',
    },
    {
      field: 'Expiry year',
      required: 'Yes',
      requirements:
        "Year in 4 digit format",
      notes: "Expiry year of the card",
      example: '2027',
    },
    {
      field: 'Expiry month',
      required: 'Yes',
      requirements:
        "Month in 2 digit format (01-12)",
      notes: "Expiry month of the card",
      example: '12',
    },
    {
      field: 'CVV',
      required: 'No',
      requirements:
        "3 digit number",
      notes: "Card security code",
      example: '999',
    },
    
  ];
  return baseDetails;
};
