import styled from 'styled-components';

// Contains Search Component and Filter/Reset buttons
export const SearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;

  ${({ theme }) => theme.media.desktopUp`
    flex-direction: row;
  `}
`;

// Wraps filter and reset buttons
export const FilterButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 5px;

  & > button {
    padding-bottom: 0;
  }

  & > *:not(:last-child) {
    margin-right: 5px;
  }

  ${({ theme }) => theme.media.desktopUp`
    margin-top: 0;
    margin-left: 5px;

    & > button {
      padding-bottom: 5px;
    }
  `}
`;
