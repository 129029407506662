import styled from 'styled-components';

export const ModalForm = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  min-width: 300px;

  ${({ theme }) => theme.media.tabletPortraitUp`
    min-width: 610px;
    max-width: 700px;
  `}

  ${({ scrollable }) =>
    scrollable &&
    `
  max-height: 90vh;
  overflow-y: scroll;
  padding-right: 10px;
  `}
`;

// Styles the form
export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex: 1;
  width: 100%;

  & > div:not(:last-child) {
    margin-bottom: 15px;
  }
`;
