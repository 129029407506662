import { compose, lifecycle, withState } from 'recompose';
import {
  renderWhileLoading,
  setRefetchProp,
  renderForError
} from '../../../../../state/apolloRecompose';

import UploadAccountHolders from './UploadAccountHolders';
import withHandlers from './enhancers/withHandlers';
import withCreateManyAccountHoldersMutation from './enhancers/withCreateManyAccountHoldersMutation';

export default compose(
  withState('data', 'updateData', []),
  withState('erroredAccountHolders', 'updateErroredAccountHolders', []),
  withState('uploadError', 'updateUploadError', false),
  withState('error', 'updateError', null),
  lifecycle({
    componentDidMount() {
      const { updateData } = this.props;

      this.props.location.state.data &&
        updateData([...this.props.location.state.data]);
    }
  }),
  withCreateManyAccountHoldersMutation,
  withHandlers,
  renderWhileLoading,
  setRefetchProp,
  renderForError
)(UploadAccountHolders);
