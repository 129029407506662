import styled from 'styled-components';

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
`;

// Styles the form
export const StyledForm = styled.form`
  & > *:not(:last-child) {
    margin-bottom: 15px;
  }

  & > fieldset:not(:last-child) {
    margin-bottom: 30px;
  }

  h3:not(:first-child) {
    padding-top: 20px;
  }
`;
