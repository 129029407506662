import React from 'react';
// Import Shared Components
import { EditableCell, IconButton } from 'shared-components';
// Import Global Components
import Table from '../../../../components/Table';

export default ({ data, deleteData, errors, updateCell }) => {
  const columns = [
    {
      Header: 'ID',
      accessor: 'id',
      show: false,
    },
    {
      Header: 'Email',
      accessor: 'email',
      className: 'cell',
      Cell: props => (
        <EditableCell
          name="email"
          updateCell={updateCell}
          className="email"
          error={errors[props.index][props.column.id]}
          {...props}
          type="email"
        />
      ),
    },
    {
      Header: 'First Name',
      accessor: 'firstName',
      className: 'cell',
      Cell: props => (
        <EditableCell
          name="firstName"
          updateCell={updateCell}
          className="firstName"
          {...props}
          error={errors[props.index][props.column.id]}
        />
      ),
    },
    {
      Header: 'Last Name',
      accessor: 'lastName',
      className: 'cell',
      Cell: props => (
        <EditableCell
          name="lastName"
          updateCell={updateCell}
          className="lastName"
          {...props}
          error={errors[props.index][props.column.id]}
        />
      ),
    },
    {
      Header: 'Amount',
      accessor: 'amount',
      className: 'cell',
      minWidth: 150,
      maxWidth: 150,
      Cell: props => (
        <EditableCell
          name="amount"
          updateCell={updateCell}
          className="amount"
          {...props}
          error={errors[props.index][props.column.id]}
          prefix="$"
        />
      ),
    },
    {
      Header: 'Notes',
      accessor: 'notes',
      className: 'cell',
      minWidth: 200,
      Cell: props => (
        <EditableCell
          name="notes"
          updateCell={updateCell}
          className="notes"
          maxLength="255"
          {...props}
          error={errors[props.index][props.column.id]}
        />
      ),
    },
    {
      minWidth: 32,
      maxWidth: 32,
      style: { margin: 'auto 0' },
      Cell: ({ index }) => (
        <IconButton
          iconType="delete"
          type="danger"
          variant="text"
          iconSize={15}
          onClick={deleteData.bind(this, index)}
        />
      ),
      sortable: false,
      filterable: false,
    },
  ];

  return <Table columns={columns} data={data} key="create-transfer-table" />;
};
