import React from 'react';
import PropTypes from 'prop-types';
// Import Shared Components
import { Title, Table, Button, ActionBar, Card } from 'shared-components';
// Import Shared Constants
import {
  csvCardInput,
  fieldError,
} from 'shared-components/src/constants/propTypes';
// Import Local Constants
import ERRORED_CARDHOLDER_COLUMNS from './erroredCardholderColumns';
import ERRORED_FIELD_COLUMNS from './erroredFieldColumns';

const ErroredUploadedCardholders = ({ onClick, erroredCardholderInputs }) => (
  <Card>
    <Title>Errored Cardholders</Title>
    <Table
      columns={ERRORED_CARDHOLDER_COLUMNS}
      data={erroredCardholderInputs}
      expanderDefaults={{
        width: 175,
        resizeable: true,
      }}
      SubComponent={row => {
        return (
          <div style={{ padding: '15px 20px' }}>
            <Table
              error
              columns={ERRORED_FIELD_COLUMNS}
              data={row.original.error.field_errors}
            />
          </div>
        );
      }}
    />
    <ActionBar justifyContent="space-between">
      <Button type="secondary" onClick={onClick}>
        Back
      </Button>
    </ActionBar>
  </Card>
);

ErroredUploadedCardholders.propTypes = {
  onClick: PropTypes.func.isRequired,
  erroredCardholderInputs: PropTypes.arrayOf(
    PropTypes.shape({
      card: csvCardInput.isRequired,
      error: fieldError.isRequired,
    })
  ),
};

export default ErroredUploadedCardholders;
