import styled from 'styled-components';

export const ActionsContainer = styled.div`
  min-height: 45px;
  whitespace: wrap;

  ${({ theme }) => theme.media.tabletPortraitUp`
    flex-direction: row;
  `}
`;

export const ValueUnloadDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${({ theme }) => theme.media.tabletPortraitUp`
    flex-direction: row;
  `}
`;

export const DownloadFile = styled(ValueUnloadDetails)`
  align-items: center;

  button {
    margin-top: 15px;
  }

  ${({ theme }) => theme.media.tabletPortraitUp`
    flex-direction: row;

    button {
      margin-left: 15px;
      margin-top: 0;
    }
  `}
`;

export const ValueUnloadDetail = styled.div`
  display: flex;
  flex-direction: row;

  &:not(:first-child) {
    margin-top: 15px;
  }

  span {
    margin-left: 15px;
    font-weight: bold;
  }

  ${({ theme }) => theme.media.tabletPortraitUp`
  flex-direction: column;

  &:not(:first-child) {
    margin-top: 0;
    margin-left: 15px;
  }

    span {
      margin-left: 0;
    }

    &:last-child {
      text-align: right;
    }
  `}
`;

export const RemainingBalance = styled.span`
  color: ${({ theme, balance }) =>
    balance < 0 ? theme.colors.error : theme.colors.green};
`;
