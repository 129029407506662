import React, { useEffect, useState, useRef } from 'react';
// Import Shared Components
import { Table, Alert } from 'shared-components';
import { account } from 'shared-components/src/constants/propTypes';
// Import Global Utils
import { get } from '../../../../utils/http';
// Import Local Constant
import getValueLoadColumns from './valueLoadColumns';
import { useTimeZone } from '../../../../hooks/useTimeZone';

const ValueLoads = ({ account }) => {
  // useRef used here and on searchParams to prevent unnecessary running of useEffect
  const initialPaginationState = useRef({
    limit: 5,
    offset: 0,
    page: 0,
  });
  // initialFetched ensures the initial value load useEffect fetch runs only once despite having dependencies
  const initialFetched = useRef(false);
  const initialValueLoads = { count: 0, data: [] };
  const [timeZone] = useTimeZone();

  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(null);
  // pagination params for value loads table
  const [pagination, setPagination] = useState(initialPaginationState.current);
  // state valueLoads is the response from list value loads
  const [valueLoads, setValueLoads] = useState(initialValueLoads);
  // Query params for ListValueLoads that are not pagination related
  const searchParams = { account_id: account.id };

  // Run on startup and fetch all value loads for an account
  useEffect(() => {
    async function fetchInitialValueLoads() {
      // Ensures that initial fetch does not run when either
      // - account does not exist
      // - initial already fetched
      if (!account || initialFetched.current) return;
      initialFetched.current = true;
      const queryParams = new URLSearchParams({
        ...initialPaginationState.current,
        ...searchParams,
      });
      try {
        setValueLoads(
          await get(
            `/api/v1/client_portal/card_issuing/value_loads?${queryParams}`
          )
        );
      } catch (e) {
        setIsError(e.message);
      }
      setIsLoading(false);
    }
    fetchInitialValueLoads();
  }, [account, searchParams, initialPaginationState, initialFetched]); // all dependencies shouldn't change, so only run on startup

  // React Table related variables
  const defaultSorting = [{ id: 'id', desc: true }];
  const pages = Math.ceil(valueLoads.count / pagination.limit);
  const showPagination = valueLoads.count > pagination.limit;

  const handlePageChange = async pageIndex => {
    try {
      setIsLoading(true);
      setPagination({
        ...pagination,
        page: pageIndex,
        offset: pageIndex * pagination.limit,
      });
      const params = new URLSearchParams({
        ...searchParams,
        ...pagination,
        page: pageIndex,
        offset: pageIndex * pagination.limit,
      }).toString();
      setValueLoads(
        await get(`/api/v1/client_portal/card_issuing/value_loads?${params}`)
      );
    } catch (e) {
      setIsError(e.message);
    }
    setIsLoading(false);
  };
  const handlePageSizeChange = async (pageSize, pageIndex) => {
    try {
      setIsLoading(true);
      setPagination({
        ...pagination,
        limit: pageSize,
        offset: pageIndex * pageSize,
      });
      const params = new URLSearchParams({
        ...searchParams,
        ...pagination,
        limit: pageSize,
        offset: pageIndex * pageSize,
      }).toString();
      setValueLoads(
        await get(`/api/v1/client_portal/card_issuing/value_loads?${params}`)
      );
    } catch (e) {
      setIsError(e.message);
    }
    setIsLoading(false);
  };

  return (
    <>
      {isError ? (
        <Alert type="danger">{isError}</Alert>
      ) : (
        <Table
          columns={getValueLoadColumns(timeZone)}
          data={valueLoads.data}
          defaultPageSize={5}
          defaultSorted={defaultSorting}
          loading={isLoading}
          loadingText="Loading..."
          manual
          noDataText="Currently there are no Value Loads"
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          page={pagination.page}
          pages={pages}
          showPagination={showPagination}
          sortable={false}
        />
      )}
    </>
  );
};

ValueLoads.propTypes = {
  account,
};

ValueLoads.defaultProps = {
  account: undefined,
};

export default ValueLoads;
