import { compose } from 'recompose';
import {
  renderWhileLoadingTwoQueries,
  setRefetchProp,
  renderForErrorCreateTransfer
} from '../../../state/apolloRecompose';
import CreateTransfer from './CreateTransfer';

// Enhancers
import withNameState from './enhancers/states/withNameState';
import withTransactionsState from './enhancers/states/withTransactionsState';
import withValidationState from './enhancers/states/validationState';
import withModalState from './enhancers/states/withModalState';
import withAccountHolderTypeState from './enhancers/states/withAccountHolderTypeState';
import withCurrencyState from './enhancers/states/withCurrencyState';
import withLoadingModal from './enhancers/states/withLoadingModalState';
import withSuccessState from './enhancers/states/withSuccessState';
import withErrorState from './enhancers/states/withErrorState';
import withWarningState from './enhancers/states/withWarningState';

import withSubscriptionQuery from './enhancers/withSubscriptionQuery';
import withLimitsQuery from './enhancers/withLimitsQuery';

import withCreateTransferMutation from './enhancers/mutations/withCreateTransferMutation';

import withHandlers from './enhancers/withHandlers';

export default compose(
  withTransactionsState,
  withAccountHolderTypeState,
  withSuccessState,
  withErrorState,
  withWarningState,
  withNameState,
  withModalState,
  withValidationState,
  withCurrencyState,
  withLoadingModal,
  withSubscriptionQuery,
  withLimitsQuery,
  withCreateTransferMutation,
  withHandlers,
  renderWhileLoadingTwoQueries,
  setRefetchProp,
  renderForErrorCreateTransfer
)(CreateTransfer);
