import { format } from 'date-fns';
import { fr, enUS, enCA } from 'date-fns/locale';
import { formatCurrencyNarrowSV } from './formatCurrency';
import { formatCountryCode } from './formatCountry';

const locales = {
  fr,
  'fr-CA': fr,
  frCA: fr,
  en: enUS,
  'en-US': enUS,
  'en-CA': enCA,
  enUS,
  enCA,
};

const cardStatuses = {
  A: 'Lost Card Waiting For Sufficient Funds',
  B: 'Blocked',
  C: 'Canceled',
  D: 'Disabled',
  L: 'Lost Card',
  N: 'Active',
  O: 'Operations Hold',
  Q: 'Delinquent',
  R: 'Charged Off',
  S: 'Stolen',
  V: 'Voided',
  W: 'Waiting For Payment',
  X: 'Set to Emboss',
  Y: 'Shipped/Ready to Activate',
  Z: 'Canceled without Refund',
};

const accountStatuses = {
  active: 'Active',
  not_active: 'Not Active',
  awaiting_processor: 'Awaiting Processor',
  suspended: 'Suspended',
  expired: 'Expired',
  cancelled: 'Cancelled',
  lost: 'Marked As Lost',
  stolen: 'Marked As Stolen',
  shipping: 'Shipping',
  delinquent: 'Marked As Delinquent',
  shipped: 'Shipped/Ready to Activate',
};

const kycStatuses = {
  kyc_verification_success: 'Successful',
  kyc_verification_review: 'In Review',
  kyc_verification_rejected: 'Rejected',
};

// Retrieves translated card status for a cardholder from object based on status code
export const getCardStatus = statusCode => cardStatuses[statusCode];

// Retrieves translated account status for a cardholder from object based on status code
export const getAccountStatus = statusCode => accountStatuses[statusCode];

// Retrieves translated account status for a cardholder from object based on status code
export const getKycStatus = statusCode => kycStatuses[statusCode];

// Checks
export function getCardholderStatus(cardholder) {
  if (!cardholder) {
    return 'Not Created';
  }
  return 'Created';
}

export function getCardholderID(cardholder) {
  return cardholder ? cardholder.id : '--';
}

export function getCardholderProcessorReference(cardholder) {
  return cardholder ? cardholder.primary_processor_reference : '--';
}

// Formats merchant info as name, city, country (e.g. Dollarama, Toronto, CA)
const getFormattedMerchantInfoCarta = original => {
  const {
    merchant,
    merchant: { name, city, country },
    billing_currency: billingCurrency,
    transaction_currency: transactionCurrency,
    transaction_amount: transactionAmount,
  } = original;
  // Return merchant.name if it exists
  if (merchant && merchant.name) {
    // Append the local amount in the case the transaction currency is different than the account currency
    const localAmount =
      transactionCurrency !== billingCurrency
        ? formatCurrencyNarrowSV(transactionAmount, transactionCurrency)
        : null;

    const formattedCountry = country || '';
    const formattedCity = city || '';

    // Add the country to merchant info if we can format it
    const countryFormatted = formatCountryCode(formattedCountry)
      ? `${formatCountryCode(formattedCountry)}`
      : '';

    // Format info with name, formatted city, and formatted country
    const formattedInfo = `${name}${formattedCity ? `, ${formattedCity}` : ''}${
      countryFormatted ? `, ${countryFormatted}` : ''
    }`;

    return {
      info: formattedInfo,
      localAmount,
    };
  }

  // Handle the case when merchant.name does not exist
  return { info: '---' };
};

const getFormattedMerchantInfoGalileo = original => {
  const {
    billing_currency: billingCurrency,
    transaction_currency: transactionCurrency,
    local_amount: galileoLocalAmount,
    details,
  } = original;

  if (!details) {
    return { info: '---' };
  }

  // Append the local amount in the case the transaction currency is different than the account currency
  const localAmount =
    transactionCurrency !== billingCurrency
      ? formatCurrencyNarrowSV(galileoLocalAmount, transactionCurrency)
      : null; 
  
  const result = { info: details, localAmount };
  return result;
};

export function getFormattedMerchantInfo(original) {
  return original.amount || original.auth_amount
    ? getFormattedMerchantInfoGalileo(original)
    : getFormattedMerchantInfoCarta(original);
}

// Returns a date in format dd-Mon-yyyy (e.g. 03-May-2023)
export function getFormattedTransactionDate(rawDate, locale = 'en') {
  const date = new Date(rawDate);
  const mappedLocale = locales[locale];
  const formattedDate = format(date, 'dd-MMM-yyyy', {
    locale: mappedLocale || locales.en,
  }); 

  return formattedDate;
}

// Returns a transfer date in format DD-Month-YYYY UTC(e.g. 03-May-2023)
export function getFormattedTransferDate(rawDate) {
  const date = new Date(rawDate);
  
  const formattedDate = new Intl.DateTimeFormat('en-US', {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
    timeZone: 'GMT' // GMT matches UTC
  }).format(date);
  
  return formattedDate;
}

// For Auths - display description as 'Authorization'
export function getFormattedTransactionDescription(original) {
  const { auth_amount: authAmount } = original;

  if (authAmount !== undefined) {
    return 'Authorization';
  }

  return original.description;
}

// Returns human reable status for a transfer
export function getFormattedTransferType() {
  return 'Direct Send Transfer';
}

export function getFormattedStatus(original){
  switch (original) {
  case 'awaiting_settlement':
    return 'Requested';
  case 'processing':
    return 'Pending';
  case 'approved':
    return 'Successful';
  case 'declined':
    return 'Declined';
  case 'cancelled':
    return 'Canceled';
  case 'error':
    return 'Failed';
  default:
    return '';
  }
}
