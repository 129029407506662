import React from 'react';
// Import Shared Components
import { Search, Button } from 'shared-components';

import SEARCHBY from '../searchby';
import AMOUNTS from '../amountOptions';
import STATUSES from '../statusOptions';
// Import Component Styles
import { SearchContainer, FilterButtonGroup } from './styles';

export default ({
  updateSearchName,
  updateSearchStatus,
  updateSearchByState,
  updateSearchAmount,
  searchTransactions,
  searchBy,
  toggleSearchFilter,
  filterButton,
  resetSearch,
  params: { name, amount, status },
}) => {
  const searchByAmount = searchBy === 'amount';

  return (
    <SearchContainer>
      <Search
        searchOptions={SEARCHBY}
        updateSearchBy={updateSearchByState}
        searchBy={searchBy}
        value={name}
        onChange={updateSearchName}
        onClick={searchTransactions}
        options={searchByAmount ? AMOUNTS : STATUSES}
        updateItem={searchByAmount ? updateSearchAmount : updateSearchStatus}
        selected={searchByAmount ? amount : status}
        dropdown={searchBy === 'amount' || searchBy === 'status'}
      />
      {filterButton && (
        <FilterButtonGroup>
          <Button
            variant="text"
            type="primary"
            size="small"
            onClick={toggleSearchFilter}
          >
            Add Filters
          </Button>
          <p>/</p>
          <Button
            variant="text"
            type="primary"
            size="small"
            onClick={resetSearch}
          >
            Reset
          </Button>
        </FilterButtonGroup>
      )}
    </SearchContainer>
  );
};
