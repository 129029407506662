import React from 'react';
import { Switch } from 'react-router-dom';

import Auth from './Auth';
import Private from './Private';
import NotFound from './NotFound';
import RedeemPayout from './RedeemPayout';

import Route from '../layouts/components/Route';

export default props => (
  <Switch>
    <Route {...props} path="/payouts/redeem" component={RedeemPayout} reedemPayoutPage/>
    <Route {...props} path="/auth" component={Auth} />
    <Route {...props} component={Private} priv />
    <Route {...props} component={NotFound} />
  </Switch>
);
