import { withHandlers } from 'recompose';
// Import Utils
import { mutationErrorMessageParser } from '../../../../../../utils/errorLogger';

export default withHandlers({
  openCreatePaymentCardModal: ({
    updateCreatePaymentCardModal,
    updateAccountHolderInfo
  }) => id => {
    updateAccountHolderInfo(id);
    updateCreatePaymentCardModal(true);
  },
  closeCreatePaymentCardModal: ({
    updateCreatePaymentCardModal,
    updateAccountHolderInfo
  }) => () => {
    updateAccountHolderInfo('');
    updateCreatePaymentCardModal(false);
  },
  openCreateBankAccountModal: ({
    updateCreateBankAccountModal,
    updateAccountHolderInfo
  }) => id => {
    updateAccountHolderInfo(id);
    updateCreateBankAccountModal(true);
  },
  closeCreateBankAccountModal: ({
    updateCreateBankAccountModal,
    updateAccountHolderInfo
  }) => () => {
    updateAccountHolderInfo('');
    updateCreateBankAccountModal(false);
  },
  openEditBankAccountModal: ({
    updateEditBankAccountModal,
    updateAccountHolderInfo,
    updateSelectedFA
  }) => (accountHolderId, fa) => {
    updateAccountHolderInfo(accountHolderId);
    updateSelectedFA(fa);
    updateEditBankAccountModal(true);
  },
  closeEditBankAccountModal: ({
    updateEditBankAccountModal,
    updateAccountHolderInfo,
    updateSelectedFA
  }) => () => {
    updateSelectedFA('');
    updateAccountHolderInfo('');
    updateEditBankAccountModal(false);
  },
  openEditPaymentCardModal: ({
    updateEditPaymentCardModal,
    updateSelectedFA,
    updateAccountHolderInfo
  }) => (accountHolderId, fa) => {
    updateAccountHolderInfo(accountHolderId);
    updateSelectedFA(fa);
    updateEditPaymentCardModal(true);
  },
  closeEditPaymentCardModal: ({
    updateEditPaymentCardModal,
    updateAccountHolderInfo,
    updateSelectedFA
  }) => () => {
    updateSelectedFA('');
    updateAccountHolderInfo('');
    updateEditPaymentCardModal(false);
  },
  openConfirmDeleteModal: ({
    confirmDeleteModal,
    updateConfirmDeleteModal,
    updateRemoveAccountInfo
  }) => value => () => {
    updateRemoveAccountInfo(value);
    updateConfirmDeleteModal(!confirmDeleteModal);
  },
  closeConfirmDeleteModal: ({
    confirmDeleteModal,
    updateConfirmDeleteModal,
    updateRemoveAccountInfo
  }) => e => {
    e && e.preventDefault();
    updateConfirmDeleteModal(!confirmDeleteModal);
    updateRemoveAccountInfo({});
  },
  deleteAccountHolderFundingSource: ({
    removeFundingSource,
    updateSuccess,
    updateError,
    removeAccountInfo,
    updateRemoveAccountInfo,
    confirmDeleteModal,
    updateConfirmDeleteModal
  }) => () => {
    removeFundingSource({ id: removeAccountInfo.id })
      .then(() => {
        updateConfirmDeleteModal(!confirmDeleteModal);
        updateSuccess(
          'Account has been removed successfully for Account Holder'
        );
      })
      .then(updateRemoveAccountInfo({}))
      .catch(err => {
        const message = mutationErrorMessageParser(
          err,
          'Account for Account Holder could  not be removed.'
        );
        updateConfirmDeleteModal(!confirmDeleteModal);
        updateError(message);
        updateSuccess(null);
      });
  },
  disableDefaultAccount: ({
    removeDefaultFinancialAccount,
    updateSuccess,
    updateError,
    updateConfirmDeleteModal,
    openFirstExpandAndResetPagination,
  }) => accountHolderId => () => {
    removeDefaultFinancialAccount({ accountHolderId })
      .then(() => {
        updateSuccess('Default Account has been disabled');
        openFirstExpandAndResetPagination();
      })
      .then(updateConfirmDeleteModal(false))
      .catch(err => {
        const message = mutationErrorMessageParser(
          err,
          'Default Account could not be disabled for Account Holder.'
        );
        updateError(message);
        updateSuccess(null);
      });
  },
  changeDefaultAccount: ({
    updateDefaultFinancialAccount,
    openFirstExpandAndResetPagination,
    updateSuccess,
    updateError
  }) => (accountHolderId, financialAccountId) => () => {
    updateDefaultFinancialAccount({
      accountHolderId,
      financialAccountId
    })
      .then(() => {
        updateSuccess('Default Account has been updated');
        openFirstExpandAndResetPagination();
      })
      .catch(err => {
        const message = mutationErrorMessageParser(
          err,
          'Default Account could not be updated for Account Holder.'
        );
        updateError(message);
        updateSuccess(null);
      });
  }
});
