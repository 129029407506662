// Utility functions for Subscriptions

export const getActiveTransferSubscription = subscriptions => {
  const activeSub = subscriptions.find(
    sub => sub.plan.type === 'disbursements' && sub.status === 'active'
  );
  if (activeSub) {
    return activeSub;
  }
  return null;
};

export const getActiveSubscriptions = subscriptions =>
  subscriptions.filter(sub => sub.status === 'active');
