import React, { useState, useEffect } from 'react';
// Import Shared Components
import {
  Title,
  Alert,
  Table,
  Card,
  CardGroup,
  Search,
  Loader,
  Button,
  DatePicker,
  CSVButton,
  ButtonGroup,
} from 'shared-components';
// Import Shared Utils
import {
  getStartAndEndDates,
  getEndOfDay,
  getThirtyDaysAgo,
} from 'shared-components/src/utils/dates';
// Import Global Components
import ClientErrorComponent from '../../../components/ClientErrorComponent';
// Import Common Utils
import { getCardholderCSVData } from '../../../utils/csv';
import { formatDateParams } from '../../../utils/cardholders';
import { get } from '../../../utils/http';
// Import Hook
import { useTimeZone } from '../../../hooks/useTimeZone';
// Import filter options for search bar
import FILTER from './filter';
// Import CSV headers for cardholder list CSV generation
import { cardholderHeaders } from './csvHeaders';
// Import Component Styles
import { Header, DateAndButtonGroup } from './styles';
// Import Columns for tables
import { getCardholderColumns } from './components/cardholderColumns';
import KYCTable from './components/KYCTable';

//role context
import {RoleContext} from '../../../context/RoleContext';

export default ({ history ,bulkerSetting}) => {
  const initialPaginationState = {
    limit: 10,
    offset: 0,
    page: 0,
  };

  const initialSearchState = {
    primary_processor_reference: '',
    external_tag: '',
    account_id: '',
  };

  const initialDateState = {
    from: getThirtyDaysAgo(),
    to: getEndOfDay(),
  };

  // Set component states
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [kycEnabled, setKycEnabled] = useState(false);
  const [bulker, setBulker] = useState(bulkerSetting.bulkerEnabled);
  // Set data states
  const [pagination, setPagination] = useState(initialPaginationState);
  const [searchBy, setSearchBy] = useState(null);
  // Search params: PRN, External tag, accountID
  const [searchParams, setSearchParams] = useState(initialSearchState);
  // Filters cardholders based on date range
  const [dateRange, setDateRange] = useState(initialDateState);
  const [cardholders, setCardholders] = useState({ count: 0, data: [] });
  const [urlQuery, setUrlQuery] = useState(initialSearchState);
  const [timeZone] = useTimeZone();

  // Get role from context
  const userRole = React.useContext(RoleContext);

  // Fetch initial data and fetch whenever urlQuery state updates
  useEffect(() => {
    const fetchData = async () => {
      try {
        // format date query string by passing the timezone and the local time of that timezone
        const formattedDates = formatDateParams(dateRange, timeZone);
        const query = new URLSearchParams({
          ...formattedDates,
          ...pagination,
          ...urlQuery,
        }).toString();

        const cardholderListData = await get(
          `/api/v1/client_portal/card_issuing/cardholders?${query}`
        );
        const kycEnabledData = await get(
          '/api/v1/client_portal/card_issuing/programs/kyc_config'
        );
        const companyKycEnabled = kycEnabledData.data.some(
          kyc_config => kyc_config.kyc_enabled
        );
        if (companyKycEnabled) {
          setKycEnabled(true);
        }
        setCardholders(cardholderListData);
      } catch (e) {
        setError(true);
      }
      setLoading(false);
    };
    fetchData();
  }, [urlQuery, dateRange, pagination, timeZone]);

  // Display success message on page if required
  useEffect(() => {
    if (history.location.state && history.location.state.success) {
      setSuccess(history.location.state.success);
      // Remove success message from state

      const clearSuccess = setTimeout(() => {
        // Clear Success message after 5 seconds
        setSuccess(null);
      }, 5000);
      // clean up timer before useEffect and when component unmounts
      return () => {
        setSuccess(null);
        clearTimeout(clearSuccess);
      };
    }

    return () => setSuccess(null);
  }, [history.location.state]);

  // Needs this, to ensure, that state is set correctly. Going from card-issuing
  // page to card-issuing-bulker does not set state properly without this
  useEffect(()=> {
    setBulker(bulkerSetting.bulkerEnabled)
  }, [bulkerSetting.bulkerEnabled]);
  // Triggers a cardholder search based on params passed in by user
  const searchCardholders = () => {
    setUrlQuery(searchParams);
    // Reset initial pagination params
    setPagination(initialPaginationState);
  };

  // Handle change in table page size
  const handlePageSizeChange = (pageSize, pageIndex) => {
    setPagination({
      ...pagination,
      limit: pageSize,
      offset: pageIndex * pageSize,
    });
  };

  const handlePageChange = pageIndex => {
    setPagination({
      ...pagination,
      page: pageIndex,
      offset: pageIndex * pagination.limit,
    });
  };

  const handleUpdateSearchParamField = (field, value) => {
    setSearchParams({
      ...initialSearchState,
      [field]: value,
    });
  };

  if (loading) return <Loader />;
  if (error) return <ClientErrorComponent data={{}} />;

  return (
    <CardGroup>
      {success && <Alert type="success">{success}</Alert>}
      <Header>
        <Card>
          <Search
            searchOptions={FILTER}
            updateSearchBy={setSearchBy}
            searchBy={searchBy}
            value={searchParams[searchBy]}
            onChange={e =>
              handleUpdateSearchParamField(searchBy, e.target.value)
            }
            onClick={() => searchCardholders()}
          />
        </Card>
        <DateAndButtonGroup>
          <Card>
            <DatePicker
              startDate={dateRange.from}
              endDate={dateRange.to}
              onDatesChange={({ startDate, endDate }) => {
                const { validStartDate, validEndDate } = getStartAndEndDates(
                  startDate,
                  endDate
                );
                setDateRange({ from: validStartDate, to: validEndDate });
              }}
              isOutsideRange={() => false}
              hideKeyboardShortcutsPanel
              showDefaultInputIcon
            />
          </Card>
          <Card>
            <ButtonGroup>
              <CSVButton
                data={getCardholderCSVData(cardholders.data)}
                // Forces react-CSV component unmount and remount whenever cardholder updates
                // Due to a bug in react-CSV
                key={cardholders.data.map(ch => ch.id).join('')}
                headers={cardholderHeaders}
                filename={`cardholders-${new Date().toLocaleDateString()}`}
              >
                Export to CSV
              </CSVButton>
              <Button
                type="primary"
                disabled={userRole == 'user' }
                onClick={() => {
                  if (bulker){
                    history.push(
                      {
                        pathname:'/bulker/card-issuing/cardholders/create',
                        state: {
                          bulker: true
                        }
                      }
                     )
                  }
                  else
                  {
                    history.push('/card-issuing/cardholders/create')
                  }
                  }}
              >
                Create Cardholders
              </Button>
            </ButtonGroup>
          </Card>
        </DateAndButtonGroup>
      </Header>
      <Card>
        <Title>Cardholder List</Title>
        <Table
          columns={getCardholderColumns(timeZone)}
          data={cardholders.data}
          noDataText="Currently there are no Cardholders"
          /* eslint-disable no-unused-vars */
          getTrProps={(state, rowInfo, column) => {
            return {
              style: {
                cursor: 'pointer',
              },
              className: 'hoverable',
              onClick: (e, handleOriginal) => {
                history.push(
                  `/card-issuing/cardholders/${rowInfo.original.id}`
                );
              },
            };
          }}
          showPagination={cardholders.count > pagination.limit}
          pages={Math.ceil(cardholders.count / pagination.limit)}
          page={pagination.page}
          manual
          onPageSizeChange={handlePageSizeChange}
          onPageChange={handlePageChange}
          /* eslint-enable no-unused-vars */
          sortable={false}
          defaultSorted={[{ id: 'id', desc: true }]}
        />
      </Card>
      {kycEnabled && <KYCTable history={history} />}
    </CardGroup>
  );
};