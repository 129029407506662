import React from 'react';
import { useQuery } from '@apollo/react-hooks';
// Import Shared Components
import { Card, Table, Title, Link, Loader } from 'shared-components';
// Import Shared Utils
import { formatCurrencyCSV } from 'shared-components/src/utils/formatCurrency';
import { formatTime } from 'shared-components/src/utils/formatDateTime';
// Import Graphql Query
import GET_TRANSFERS from '../../../graphql/queries/transfersQuery';
// Import Global Components
import ClientErrorComponent from '../../../components/ClientErrorComponent';
import PaymentStatus from '../../../components/PaymentStatus';
// Import Hooks
import { useTimeZone } from '../../../hooks/useTimeZone';
// Import Component Styles
import { Container } from '../styles';

export default () => {
  // Query for getting last 10 Transfers
  const { loading, error, data } = useQuery(GET_TRANSFERS, {
    variables: { limit: 10 },
    fetchPolicy: 'cache-and-network',
  });
  const [timeZone] = useTimeZone();

  if (loading)
    return (
      <Card>
        <Loader />
      </Card>
    );
  if (error) return <ClientErrorComponent data={{ error }} />;

  const columns = [
    {
      Header: 'ID',
      accessor: 'id',
      show: false,
    },
    {
      Header: 'Date',
      accessor: 'insertedAt',
      // value string represents UTC time
      // with out the Z(ulu), JS Date will parse value as local. 
      Cell: ({ value }) => formatTime(`${value}Z`, 'dateTime', timeZone),
    },
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      Header: 'Payments',
      accessor: item => item,
      id: 'payments',
      Cell: ({ value }) => value.recipientsCount,
    },
    {
      Header: 'Total Amount',
      accessor: item => item,
      id: 'amount',
      Cell: ({ value }) => formatCurrencyCSV(value.amount / 100, value.currency),
    },
    {
      Header: 'Expiry',
      accessor: 'expirationDatetime',
      // See above for explanation of concatenating "Z" 
      Cell: ({ value }) => formatTime(`${value}Z`, 'longDate', timeZone),
    },
    {
      Header: 'Status',
      accessor: item => item,
      id: 'status',
      Cell: ({ value }) => <PaymentStatus status={value.latestStatus} />,
    },
  ];

  return (
    <Card>
      <Container>
        <div>
          <Title>Direct Send - Transfer History</Title>
          <Table data={data.viewer.transfersSearch.data} columns={columns} />
        </div>
        <Link type="primary" to="direct-send/transfers">
          See Full Transfer History
        </Link>
      </Container>
    </Card>
  );
};
