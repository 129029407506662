import { humanizeString } from 'shared-components/src/utils/string';
import { formatTime } from 'shared-components/src/utils/formatDateTime';

export const getProgramColumns = (timeZone) => [
  [
    {
      header: 'Id:',
      accessor: 'id',
    },   
    {
      header: 'Order Id:',
      accessor: 'order_id',
    },
    {
      header: 'External Tag:',
      accessor: 'external_tag',
    },  
  ],
  [   
    {
      header: 'Type:',
      accessor: 'type',
      cell: value => humanizeString(value),
    },  
    {
      header: 'Retry on Fail:',
      accessor: 'retry_on_fail',
      cell: value => formatTrueFalse(value),
    }, 
  ],
  [    
    {
      header: 'Status:',
      accessor: 'status',      
    },
    {
      header: 'Fail Count:',
      accessor: 'fail_count',
      cell: value => value || "0",
    },  
    {
      header: 'Created At:',
      accessor: 'created_at',
      cell: value => formatTime(value, 'longDate', timeZone),
    },         
    {
      header: 'Updated At:',
      accessor: 'updated_at',
      cell: value => formatTime(value, 'longDate', timeZone),
    },
  ],
];

const formatTrueFalse = (value) => {
  if(value === true){
    return "Yes";
  }
  else
  {
    return "No";
  }
};