import React from 'react';
// Import Local Components
import CompanyTransactionLimits from './components/CompanyTransactionLimits';
import CompanyVelocityLimits from './components/CompanyVelocityLimits';

export default ({
  subtab,
  transactionLimits,
  transactionColumns,
  velocityLimits,
  velocityColumns,
}) => (
  <>
    {subtab === 'transaction' ? (
      <CompanyTransactionLimits
        limits={transactionLimits}
        columns={transactionColumns}
      />
    ) : (
      <CompanyVelocityLimits
        limits={velocityLimits}
        columns={velocityColumns}
      />
    )}
  </>
);
