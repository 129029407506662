import styled from 'styled-components';

// Wraps filter and reset buttons
export const CustomButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 5px;

  & > button {
    padding-bottom: 0;
  }

  & > *:not(:last-child) {
    margin-right: 5px;
  }

  ${({ theme }) => theme.media.desktopUp`
    margin-top: 0;
    margin-left: 5px;

    & > button {
      padding-bottom: 5px;
    }
  `}
`;