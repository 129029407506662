import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

const MUTATION = gql`
  mutation cancelPayment($id: Int!) {
    cancelPayment(id: $id) {
      id
      latestStatus
    }
  }
`;

export default graphql(MUTATION, {
  props: ({ mutate }) => ({
    cancelPayment: id => mutate({ variables: { id } }),
  })
});
