import React from 'react';
// Import Component Styles
import { AuthWrapper, Auth, Card } from './styles';

export default ({ children }) => (
  <AuthWrapper>
    <Card>
      <Auth>{children}</Auth>
    </Card>
  </AuthWrapper>
);

export { Card } from './styles';
