import React from 'react';
// Import Shared Components
import {
  Alert,
  Modal,
  Button,
  ButtonGroup,
  ActionBar,
} from 'shared-components';
// Import Component Styles
import { UserEmail } from './styles';
// Import Utils
import { post } from '../../../../../utils/http';

export default ({ 
  userID,
  email,
  setSuccess,
  setError,
  closeModal
}) => {

  const handleResendInvite = async () => {
    try {
      await post(`/api/v1/client_portal/users/${userID}/resend_invite`, {});
      setSuccess(`A new invite has been sent to ${email}`);
      closeModal();
    } catch (e) {
      setSuccess(null);
      return setError('Failed to resend invite, try again.');
    }
    closeModal();
  };

  return (
    <Modal key="resend-invite-modal">
      <Alert type="info">
        Before resending your invite, please check that the user's email is
        correct.
      </Alert>
      <UserEmail>
        <strong>{email}</strong>
      </UserEmail>
      <ActionBar>
        <ButtonGroup reverse>
          <Button type="primary" onClick={() => handleResendInvite()}>
            Yes, Resend Email
          </Button>
          <Button type="danger" variant="text" onClick={closeModal}>
            Cancel
          </Button>
        </ButtonGroup>
      </ActionBar>
    </Modal>
  );
};
