import styled, { css } from 'styled-components';
import { NavLink as Link } from 'react-router-dom';

// TODO: separate out styles
export const SidebarNav = styled.nav`
  //  for mobile first
  position: absolute;
  display: inline-flex;
  flex-direction: column;
  z-index: 2;

  .navTrigger {
    position: absolute;
    z-index: 3;
    padding: 1.0714em 1.4285em;
    left: 0;
    color: ${({ theme }) => theme.brand.text};
    cursor: pointer;
    background: #fff;
    width: 100vw;
    height: 3.5928em;
  }

  .nav {
    display: flex;
    padding: 3.5928em 0 1.071em;
    transition: 0.7s;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 1px;
    left: -18.64em;
    overflow: hidden;
    white-space: nowrap;
    background: #fff;
    opacity: 1;
  }

  .nav:hover,
  .nav:focus,
  .navTrigger:hover + .nav,
  .navTrigger:focus + .nav {
    left: 0;
    width: 18.64em;
  }

  // Allow for screen readers to still focus through links
  .links-offscreen {
    clip: rect(1px 1px 1px 1px); /* IE 6/7 */
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap; /* added line */
    width: 1px;
    background-color: white;

    &:focus {
      position: relative;
      clip: auto;
      width: auto;
      height: auto;
    }
  }

  .links-open {
    background-color: ${({ theme }) => theme.colors.grey};
  }

  ${({ theme }) => theme.media.tabletPortraitUp`
    position: fixed;
    height: ${({ marginTop }) => `calc(100vh - ${marginTop}px)`};
    top: ${({ marginTop }) => `${marginTop}px`};
    left: 0;
    z-index: 2;
    flex: 1 1 0;

    .navTrigger {
      width: auto;
    }

    .nav {
      width: 4.1428em;
      left: 0;
    }

    // Allow for screen readers to still focus through links
    .links-open {
      clip: rect(1px 1px 1px 1px); /* IE 6/7 */
      clip: rect(1px, 1px, 1px, 1px);
      height: 1px;
      overflow: hidden;
      position: absolute;
      white-space: nowrap; /* added line */
      width: 1px;

      &:focus {
        position:relative;
        clip:auto;
        width:auto;
        height:auto;
      }
    }

    .nav:hover,
    .nav:focus,
    .navTrigger:hover + .nav,
    .navTrigger:focus + .nav {
      left: 0;
      width: 18.64em;

      // Allow for screen readers to still focus through links
      .links-open {
        position: static;
        clip:auto;
        width:auto;
        height:auto;

        &:focus {
          position: static;
          clip:auto;
          width:auto;
          height:auto;
        }
      }
    }
  `}

  ${({ theme }) => theme.media.desktopUp`
    .navTrigger {
      display: none;
    }

    .nav {
      position: static;
      margin-top: 0;
      min-width: 18.64em;
      flex: 1 1 0;
      padding-top: 1.071em;
    }

    .links-open {
      position: static;
      clip:auto;
      width:auto;
      height:auto;
    }
  `}
`;

// Wraps each navgiation section on the sidebar
export const SectionWrapper = styled.div`
  border-left: ${({ theme, open }) =>
    open ? `5px solid ${theme.brand.secondary}` : 'none'};
`;

// Section Title in the sidebar navigation that will contain pages nested beneath once clicked.
export const SectionTitle = styled.span`
  display: flex;
  font-size: 1rem;
  flex-direction: row;
  text-decoration: none;
  cursor: pointer;
  white-space: no-wrap;
  align-items: center;
  padding: 15px 20px;

  ${({ theme, open }) => (open ? theme.sidebar.open : theme.sidebar.default)}

  &:hover {
    ${({ theme }) => theme.sidebar.hover}
  }
`;

// Section Title in the sidebar navigation that will not contain pages nested beneath once clicked.
export const SectionTitleLink = styled(Link)`
  display: flex;
  font-size: 1rem;
  flex-direction: row;
  text-decoration: none;
  cursor: pointer;
  white-space: no-wrap;
  align-items: center;
  padding: 15px 20px;

  ${({ theme, open }) => (open ? theme.sidebar.open : theme.sidebar.default)}

  &:hover {
    ${({ theme }) => theme.sidebar.hover}
  }

  &:active,
  &:visited {
    ${({ theme }) => theme.sidebar.default}
  }
`;

// Styling for links
const linkStyles = css`
  font-size: 1rem;
  display: flex;
  text-decoration: none;
  align-items: center;
  white-space: no-wrap;
  color: ${({ theme }) => theme.brand.text};
  padding: 10px 20px 10px 53px;

  background-color: ${({ open }) => (open ? '#FFF' : 'inherit')};

  &:hover {
    background-color: #fff;
  }

  &:active,
  &:visited {
    text-decoration: none;
    color: ${({ theme }) => theme.brand.text};
  }
`;

// Styles for links that lead to an external link
export const ExternalSite = styled.a`
  ${linkStyles}
`;

// Styles for links that lead to a page within the portal
export const NavLink = styled(Link)`
  ${linkStyles}
`;

// Styles for Logout in navigation
export const Logout = styled.div`
  padding-top: 2.8571em;

  ${({ theme }) => theme.media.tabletPortraitUp`
    padding-top: 0;
  `}
`;
