import React from 'react';
import { withTheme } from 'styled-components';
import { Logo } from 'shared-components';
// Import Component Styles
import { Header, PageTitle, LogoWrapper } from './styles';

export default withTheme(({ theme, pageTitle }) => (
  <Header>
    <LogoWrapper className="col-4">
      <Logo src={theme.brand.logo} />
    </LogoWrapper>
    <PageTitle className="col-4">{pageTitle}</PageTitle>
  </Header>
));
