// Import Shared Utils

import { getCardholderID, getCardholderProcessorReference, getCardholderStatus, getKycStatus } from "shared-components/src/utils/cardholders"
import { formatCountryCode } from "shared-components/src/utils/formatCountry";
import { formatTime } from 'shared-components/src/utils/formatDateTime';

export const getKYCResultColumns = (timeZone)=> [
  {
    accessor: 'name',
    header: 'Name:',
  },
  {
    accessor: 'status',
    header: 'Status:',
    cell: value => getKycStatus(value),
  },  
  {
    accessor: 'kyc_transaction_id',
    header: 'KYC Transaction ID:',
  },  
  {
    accessor: 'sanction_screening_transaction_id',
    header: 'Sanction Screening Transaction ID:',
    cell: value => value || '--',
  },  
  {
    accessor: 'cardholder',
    header: 'Cardholder Status:',
    cell: value => getCardholderStatus(value),

  },  
  {
    accessor: 'cardholder',
    header: 'Cardholder ID:',
    cell: value => getCardholderID(value),
  },  
  {
    accessor: 'cardholder',
    header: 'Processor Reference:',
    cell: value => getCardholderProcessorReference(value),
  },  
  {
    accessor: 'created_at',
    header: 'Created On',
    cell: value => formatTime(value, 'dateTime', timeZone),
  },
  {    
    accessor: 'updated_at',
    header: 'Updated On',
    cell: value => formatTime(value, 'dateTime', timeZone),
  },
];

export const CLIENTINFORMATIONCOLUMNS = [
  {
    accessor: 'first_name',
    header: 'First Name:',
  },
  {
    accessor: 'last_name',
    header: 'Last Name:',
  },
  {
    accessor: 'middle_name',
    header: 'Middle Name:',
    cell: value => value || '--',

  },
  {
    accessor: 'date_of_birth',
    header: 'Date Of Birth:',
  },
  {
    accessor: 'phone',
    header: 'Phone:',
  },
  {
    accessor: 'address1',
    header: 'Address 1:',
  },
  {
    accessor: 'address2',
    header: 'Address 2:',
    cell: value => value || '--',
  },
  {
    accessor: 'city',
    header: 'City:',
  },
  {
    accessor: 'state',
    header: 'State/Province:',
  },
  {
    accessor: 'postal_code',
    header: 'ZIP/Postal Code:',
    cell: value => value.toUpperCase(),
  },
  {
    accessor: 'country',
    header: 'Country:',
    cell: value => formatCountryCode(value),

  }
];
