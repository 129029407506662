import React from 'react';
// Import Shared Components
import { Card } from 'shared-components';
// Import Local Components
import DownloadFile from './DownloadFile';
import ValueLoadDetails from './ValueLoadDetails';
// // Import Component Styles
import { ActionsContainer } from './styles';

export default ({ loadStep, ...props }) => (
  <Card>
    <ActionsContainer>
      {loadStep === 1 && <DownloadFile />}
      {loadStep === 2 && <ValueLoadDetails {...props} />}
    </ActionsContainer>
  </Card>
);
