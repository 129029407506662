// Import Shared Utils
import { formatTime } from 'shared-components/src/utils/formatDateTime';
import { zonedTimeToUtc } from 'shared-components/src/utils/timeZone';

// date params are in utc
export const formatDateParams = (dateRange, zone) => {
  const createdOnGte = dateRange.from
    ? formatTime(zonedTimeToUtc(dateRange.from, zone), 'urlDateTime', 'UTC')
    : '';
  const createdOnLte = dateRange.to
    ? formatTime(zonedTimeToUtc(dateRange.to, zone), 'urlDateTime', 'UTC')
    : '';
  return {
    created_on_gte: createdOnGte,
    created_on_lte: createdOnLte,
  };
};
