import { compose, withState, lifecycle } from 'recompose';
import SuccessCheck from './SuccessCheck';

export default compose(
  withState('animate', 'updateAnimate', false),
  lifecycle({
    componentDidMount() {
      setTimeout(
        () =>
          requestAnimationFrame(() => {
            this.props.updateAnimate(true);
          }),
        500
      );
    }
  })
)(SuccessCheck);
