import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

const MUTATION = gql`
  mutation createTransfer(
    $name: String!
    $currency: String!
    $payments: [PaymentInput]!
  ) {
    createTransfer(
      input: { name: $name, currency: $currency, payments: $payments }
    ) {
      id
      latestStatus
    }
  }
`;

export default graphql(MUTATION, {
  props: ({ mutate }) => ({
    createTransfer: ({ currency, name, fundingAccount, payments }) =>
      mutate({
        variables: { currency, name, fundingAccount, payments }
      })
  })
});
