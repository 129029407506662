import React, { useEffect } from 'react';
// Import Shared Components
import {
  Card,
  CardGroup,
  Title,
  Link,
  DatePicker,
  Alert,
  CSVButton,
} from 'shared-components';
import { formatCurrencyCSV } from 'shared-components/src/utils/formatCurrency';
import { getStartAndEndDates } from 'shared-components/src/utils/dates';
import { formatTime } from 'shared-components/src/utils/formatDateTime';
// Import Global Components
import Table from '../../../components/Table';
import PaymentStatus from '../../../components/PaymentStatus';
// Import Local Components
import SearchFilter from './components/SearchFilter';
import Search from './components/Search';
// Import Component Styles
import { Header, TableHeader } from './styles';
// Import Utils
import { getTransfersCsvData } from '../../../utils/csv';
// Import Hooks
import { useTimeZone } from '../../../hooks/useTimeZone';

export default ({
  data: {
    viewer: {
      transfersSearch: { data, count },
    },
  },
  searchData,
  params,
  updateParams,
  updateSearchName,
  updateSearchStatus,
  updateSearchByState,
  updateSearchAmount,
  updateSearchData,
  searchTransactions,
  searchBy,
  toggleSearchFilter,
  searchFilter,
  filterButton,
  resetSearch,
  error,
  success,
  handlePageSizeChange,
  handlePageChange,
  pagination,
}) => {
  const [timeZone] = useTimeZone();
  const { dateFrom, dateTo, focusedInput } = searchData;

  useEffect(()=> {
    updateSearchData(searchData => ({
      ...searchData,
      timeZone,
    }));
  }, [timeZone, updateSearchData]);

  // Take current transfer data and return data that can be used to create
  // a downloadable csv file
  const csvData = getTransfersCsvData(data, timeZone);

  // Define transaction header labels to be used when converting data of type
  // Object to CSV.
  const headers = [
    { label: 'Date', key: 'date' },
    { label: 'Transfer Name', key: 'name' },
    { label: 'Number of Payments', key: 'payments' },
    { label: 'Total Amount', key: 'amount' },
    { label: 'Expiry', key: 'expiry' },
    { label: 'Status', key: 'status' },
  ];

  // Define Table columns
  const columns = [
    {
      Header: 'ID',
      accessor: 'id',
      show: false,
    },
    {
      Header: 'Date',
      accessor: 'insertedAt',
      Cell: t => formatTime(`${t.value}Z`, 'dateTime', timeZone),
    },
    {
      Header: 'Name',
      accessor: 'name',
      Cell: t => (
        <Link to={`/direct-send/transfers/${t.original.id}`}>{t.value}</Link>
      ),
    },
    {
      Header: 'Payments',
      accessor: item => item,
      id: 'payments',
      Cell: ({ value }) => value.recipientsCount,
    },
    {
      Header: 'Total Amount',
      accessor: item => item,
      id: 'amount',
      Cell: ({ value }) =>
        formatCurrencyCSV(value.amount / 100, value.currency),
    },
    {
      Header: 'Expiry',
      accessor: 'expirationDatetime',
      Cell: ({ value }) => formatTime(`${value}Z`, 'date', timeZone),
    },
    {
      Header: 'Status',
      accessor: item => item,
      id: 'status',
      Cell: ({ value }) => <PaymentStatus status={value.latestStatus} />,
    },
  ];

  return (
    <>
      {error && <Alert type="danger">{error}</Alert>}
      {success && <Alert type="success">{success}</Alert>}
      <CardGroup>
        <Header>
          <Card>
            <Search
              updateSearchName={updateSearchName}
              updateSearchStatus={updateSearchStatus}
              updateSearchByState={updateSearchByState}
              updateSearchAmount={updateSearchAmount}
              searchTransactions={searchTransactions}
              searchBy={searchBy}
              toggleSearchFilter={toggleSearchFilter}
              filterButton={filterButton}
              resetSearch={resetSearch}
              params={params}
            />
          </Card>
          <Card>
            <DatePicker
              startDate={dateFrom}
              endDate={dateTo}
              onDatesChange={({ startDate, endDate }) => {
                const { validStartDate, validEndDate } = getStartAndEndDates(
                  startDate,
                  endDate
                );
                updateSearchData(searchData => ({
                  ...searchData,
                  dateFrom: validStartDate,
                  dateTo: validEndDate,
                }));
              }}
              focusedInput={focusedInput}
              onFocusChange={focusedInput =>
                updateSearchData(searchData => ({
                  ...searchData,
                  focusedInput,
                }))}
            />
          </Card>
        </Header>
        {searchFilter && (
          <Card>
            <SearchFilter
              type={searchBy}
              params={params}
              updateParams={updateParams}
            />
          </Card>
        )}
        <Card>
          <TableHeader>
            <Title>Transfer History</Title>
            <CSVButton
              data={csvData}
              headers={headers}
              filename={`transfer-history-${new Date().toLocaleDateString()}`}
            >
              Export to CSV
            </CSVButton>
          </TableHeader>
          <Table
            columns={columns}
            data={data}
            defaultSorted={[
              {
                id: 'created_at',
                asc: false,
              },
            ]}
            noDataText="No Transfers found"
            showPagination={count > pagination.limit && true}
            pages={Math.ceil(count / pagination.limit)}
            page={pagination.page}
            pageSize={pagination.limit}
            manual
            onPageSizeChange={(pageSize, pageIndex) =>
              handlePageSizeChange(pageSize, pageIndex)}
            onPageChange={pageIndex => handlePageChange(pageIndex)}
          />
        </Card>
      </CardGroup>
    </>
  );
};
