import React from 'react';
import { Card, Title, InputGroup } from 'shared-components';
import { useTimeZone } from '../../../hooks/useTimeZone';

const TIME_ZONES = [
  { key: 'UTC', val: 'UTC' },
  { key: 'America/Vancouver', val: 'Pacific Time (Vancouver, Los Angeles)' },
  { key: 'America/Denver', val: 'Mountain Time (Denver, Edmonton)' },
  { key: 'America/Winnipeg', val: 'Central Time (Winnipeg, Houston)' },
  { key: 'America/Toronto', val: 'Eastern Time (Toronto, New York City)' },
  { key: 'America/Halifax', val: 'Atlantic Time (Halifax)' },
  { key: 'America/St_Johns', val: 'Newfoundland Time (Labrador southeast)' },
  { key: 'Pacific/Honolulu', val: 'Hawaii' },
  { key: 'America/Anchorage', val: 'Alaska' },
  { key: 'America/Argentina/Buenos_Aires', val: 'Buenos_Aires' },
  { key: 'America/Nuuk', val: 'Greenland' },
  { key: 'Atlantic/Cape_Verde', val: 'Cape Verde' },
  { key: 'Atlantic/Azores', val: 'Azores' },
  { key: 'Atlantic/Reykjavik', val: 'Reykjavik' },
  { key: 'Europe/London', val: 'London' },
  { key: 'Africa/Casablanca', val: 'Casablanca' },
  { key: 'Europe/Berlin', val: 'Berlin' },
  { key: 'Africa/Cairo', val: 'Cairo' },
  { key: 'Europe/Athens', val: 'Athens' },
  { key: 'Europe/Moscow', val: 'Moscow' },
  { key: 'Asia/Tehran', val: 'Tehran' },
  { key: 'Asia/Dubai', val: 'Dubai' },
  { key: 'Asia/Kabul', val: 'Kabul' },
  { key: 'Asia/Karachi', val: 'Karachi' },
  { key: 'Asia/Kolkata', val: 'New Delhi' },
  { key: 'Asia/Kathmandu', val: 'Kathmandu' },
  { key: 'Asia/Thimphu', val: 'Thimphu' },
  { key: 'Asia/Rangoon', val: 'Rangoon' },
  { key: 'Asia/Ho_Chi_Minh', val: 'Ho Chi Minh' },
  { key: 'Asia/Shanghai', val: 'Shanghai' },
  { key: 'Asia/Tokyo', val: 'Tokyo' },
  { key: 'Australia/Sydney', val: 'Sydney' },
  { key: 'Pacific/Port_Moresby', val: 'Port Moresby' },
  { key: 'Pacific/Majuro', val: 'Majuro' },
  { key: 'Pacific/Auckland', val: 'Auckland' },
  { key: 'Pacific/Enderbury', val: 'Enderbury' },
  { key: 'Pacific/Pago_Pago', val: 'Pago Pago' },
  { key: 'Pacific/Kiritimati', val: 'Kiritimati' },
];

export default () => {
  const [timeZone, setTimeZone] = useTimeZone();
  return (
    <Card>
      <Title>Time Zone Settings</Title>
      <InputGroup
        dropdown
        label="Select Time Zone:"
        labelFor="timezone"
        selected={timeZone}
        options={TIME_ZONES}
        updateItem={value => setTimeZone(value)}
      />
    </Card>
  );
};
