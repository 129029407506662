import ApolloClient from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { createHttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from 'apollo-cache-inmemory';

import { get, del } from 'shared-components/src/utils/storage';
import { TOKEN_KEY } from 'shared-components/src/utils/auth';
import introspectionQueryResultData from './fragmentTypes.json';

const middlewareLink = new ApolloLink((operation, forward) => {
  const TOKEN = get(TOKEN_KEY);
  operation.setContext({
    headers: {
      Authorization: `Bearer ${TOKEN}`,
    },
  });
  return forward(operation);
});

const errorLink = onError(
  ({ operation, response, graphQLErrors, networkError, forward }) => {
    if (graphQLErrors && process.env.NODE_ENV === 'development') {
      if (graphQLErrors.constructor === Array) {
        graphQLErrors.map(({ message, locations, path }) =>
          console.error(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          )
        );
      } else if (graphQLErrors.detail) {
        console.error(`[GraphQL error]: Message: ${graphQLErrors.detail}`);
      } else {
        console.error(`[GraphQL error]: Message: ${graphQLErrors}`);
      }
    }

    if (
      networkError &&
      (networkError.statusCode === 401 || networkError.statusCode === 403)
    ) {
      // Request received unauthorized status from server, so log user out and reload page
      del(TOKEN_KEY);
      window.location.reload();
    }
    if (
      networkError &&
      networkError.statusCode >= 500 &&
      process.env.NODE_ENV === 'development'
    ) {
      console.error(`[Network error]: ${networkError}`);
    }
  }
);

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});

const httpLink = createHttpLink({
  uri: '/api/graphql',
  credentials: 'same-origin',
});

const cache = new InMemoryCache({
  // TODO: This is providing massive problems.
  // Make sure all objects in querys and mutation returning results return an id field
  dataIdFromObject: o => o.id + o.__typename,
  fragmentMatcher,
}).restore({});

export default new ApolloClient({
  link: ApolloLink.from([middlewareLink, errorLink, httpLink]),
  cache,
});
