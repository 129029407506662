import React from 'react';
import ReactDOM from 'react-dom';

// import "normalize.css";
// import "./base.css";
import App from './App';

const ROOT_NODE = document.getElementById('root');

const render = () => ReactDOM.render(<App />, ROOT_NODE);

if (module.hot) module.hot.accept('./App', render);
render();
