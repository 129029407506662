import React from 'react';
import { Alert } from 'shared-components';

export default ({ graphQLErrors }) => (
  <>
    {graphQLErrors.map(({ message, locations, path }, idx) => (
      <Alert key={idx} type="danger">
        {message}
      </Alert>
    ))}
  </>
);
