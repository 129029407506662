import React from 'react';
import { useIntl } from 'react-intl';
import {
  Title,
  Loader,
  Alert,
  InputGroup,
  PasswordRequirements,
} from 'shared-components';
// Import Local Components
import AuthForm from '../AuthForm';
// Import Layouts
import AuthLayout from '../../layouts/AuthLayout';
import InfoTooltip from '../../../../components/InfoTooltip';

export default ({
  error,
  formError,
  email,
  password,
  onUpdatePassword,
  passwordConfirm,
  onUpdatePasswordConfirm,
  onClick,
  btnText,
  loading,
  title,
  page,
}) => {
  const intl = useIntl();
  if (loading) {
    return (
      <AuthLayout>
        <Title>Loading...</Title>
        <Loader />
      </AuthLayout>
    );
  }

  if (error) {
    return (
      <AuthLayout>
        <Alert type="danger">{error}</Alert>
      </AuthLayout>
    );
  }

  return (
    <AuthForm
      title={title}
      submitButtonText={btnText}
      onSubmit={onClick}
      error={formError}>
      <InputGroup
        label="Email:"
        labelFor="email"
        dataId={`${page}-form-email-input`}
        value={email}
        readOnly
      />
      <InputGroup
        tooltip={
          <InfoTooltip
            position="top"
            content={<PasswordRequirements messages={intl.messages} />}
          />
        }
        label="Password:"
        labelFor="password"
        dataId={`${page}-form-password-input`}
        value={password}
        type="password"
        onChange={onUpdatePassword}
        minLength={8}
        pattern="(?=.*[\d])(?=.*[A-Z])(?=.*[!@#$*])[\w!@#$*]{8,}"
        required
      />
      <InputGroup
        label="Confirm Password:"
        labelFor="confirmPassword"
        dataId={`${page}-form-confirm-password-input`}
        value={passwordConfirm}
        type="password"
        onChange={onUpdatePasswordConfirm}
        minLength={8}
        pattern="(?=.*[\d])(?=.*[A-Z])(?=.*[!@#$*])[\w!@#$*]{8,}"
        required
      />
    </AuthForm>
  );
};
