import React, { useEffect, useState } from 'react';
// Import Common Utils
import { Loader, ClientErrorComponent } from 'shared-components';
import { get } from '../utils/http';

const CompanyContext = React.createContext();

const CompanyProvider = ({ children }) => {
  const [companyData, setCompanyData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const companyData = await get(
          '/api/v1/client_portal/card_issuing/company'
        );

        setCompanyData(companyData.data);
      } catch (e) {
        setError(true);
      }
      setLoading(false);
    };
    fetchData();
  }, []);

  const renderBody = () => {
    if (loading) return <Loader />;
    if (error) return <ClientErrorComponent />;

    return <>{children}</>;
  };

  return (
    <CompanyContext.Provider value={companyData}>
      {renderBody()}
    </CompanyContext.Provider>
  );
};

export { CompanyContext, CompanyProvider };
