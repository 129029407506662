import React from 'react';
// Import Shared Components
import { Button, ButtonGroup } from 'shared-components';
import { ReportHeader } from '../styles';

export default ({ toggleButton, month, report }) => (
  <ReportHeader>
    <ButtonGroup justifyContent="flex-start">
      <Button
        type="primary"
        onClick={() => {
          return toggleButton('current');
        }}
        variant={report === 'current' ? null : 'text'}
      >
        This Month
      </Button>
      <Button
        type="primary"
        onClick={() => {
          return toggleButton('last');
        }}
        variant={report === 'last' ? null : 'text'}
      >
        Last Month
      </Button>
    </ButtonGroup>
    <p>
      These are the push and pull transactions that have been made for the month
      of <b>{month}</b>
    </p>
  </ReportHeader>
);
