import React, { useState, useContext } from 'react';

import {
  Card,
  Title,
  ActionBar,
  Button,
  Alert,
  Loader
} from 'shared-components';

import CreateActivateLoad from './components/CreateActivateLoad';

import { ProgramContext } from '../../../context/ProgramConfigContext';

import {validateProxyNumber, validateExternalTag, validateLoadAmount} from './validation';

import { post } from '../../../utils/http';

import NotFound from '../../NotFound';


export default () => {
  const activateLoadEnabled = useContext(ProgramContext).programContext.allow_activate_load_card

  const initialActivateLoadInput = {
    proxy_number: "",
    external_tag: "",
    load_amount: ""
  }

  const [activateLoadInput, setActivateLoadInput] = useState(initialActivateLoadInput);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const updateInputs = (e, index, original, id) => {
    setActivateLoadInput({...activateLoadInput, [id]: e.target.value})
  }

  const submitActivateLoad = () => {
    setSuccess(false)
    const errorProxy= validateProxyNumber(activateLoadInput.proxy_number)
    const errorExternalTag = validateExternalTag(activateLoadInput.external_tag)
    const errorLoadAmount = validateLoadAmount(activateLoadInput.load_amount)

    setError(errorProxy + errorLoadAmount + errorExternalTag)

    if (errorProxy || errorExternalTag || errorLoadAmount) {
      return
    }

    const payload = {
      "load_amount": convertToCents(activateLoadInput.load_amount),
      "external_tag": activateLoadInput.external_tag
    }

    return activateLoadCard(activateLoadInput.proxy_number, payload)
  }

  const activateLoadCard = async (prn, input) => {
    setIsLoading(true);

    try {
      await post(`/api/v1/client_portal/card_issuing/cardholders/${prn}/activate_load`, input);

      setSuccess(true)
      setActivateLoadInput(initialActivateLoadInput)
    } catch (e) {
      setError(e.message);
    }
    setIsLoading(false);
  };

  const convertToCents = (value) => {
    const amount = parseFloat(value);
    return Math.round(amount * 100);
  };

  if (isLoading) return <Loader />;
  if (!activateLoadEnabled) return <NotFound />;

  return (
    <>
    <Card>
      {error && (
            <Alert type="danger">
              {error}
            </Alert>
          )}
                {success && (
            <Alert type="success">
              The Card was successfully activated and loaded.
            </Alert>
          )}
      <Title>Activate & Load Card</Title>
      <CreateActivateLoad
        data={[activateLoadInput]}
        updateCell={updateInputs}
      />
      <ActionBar>
          <Button
            type="primary"
            size="large"
            onClick={()=>submitActivateLoad()}
          >
            Activate & Load Card
          </Button>
      </ActionBar>
    </Card>
    </>
  )
}