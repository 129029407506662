import React from 'react';
import PropTypes from 'prop-types';
// Import Shared Components

// Import Local Components
import Table from './CollectionsTable';
// Import Component Styles
import {
  Form,
  FormGroup,
} from '../styles';



const CreateCollectionForm = ({ recipients, updateRecipient, deleteRecipient, columns, prefixes }) => {
  return (
    <Form id="collection">
      <FormGroup form="collection">
        <Table
          recipients={recipients}
          updateRecipient={updateRecipient}
          deleteRecipient={deleteRecipient}
          columns={columns}
          prefixes={prefixes}
        />
      </FormGroup>
    </Form>
  );
};

CreateCollectionForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  recipients: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  columns: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  prefixes: PropTypes.array.isRequired,
  updateRecipient: PropTypes.func.isRequired,
  deleteRecipient: PropTypes.func.isRequired,
};

export default CreateCollectionForm;