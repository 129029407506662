import React, { useContext } from 'react';
// Import Shared Components
import {
  Card,
  CardGroup,
  Title,
  Details,
  DetailsGroup,
  Loader,
  Alert,
  VirtualCard
} from 'shared-components';
// Import Global Components
import ClientErrorComponent from '../../../components/ClientErrorComponent';
// Import Local Components
import MasterFundingAccount from './components/MasterFundingAccount';
import Locations from './components/Locations';
import Reporting from './components/Reporting';
import { getProgramColumns } from './columns';
// Import Component Styles
import { ProgramDetails, Program } from './styles';
// Import Common Hooks
import useTellerApi from '../../../hooks/useTellerApi';
import { useTimeZone } from '../../../hooks/useTimeZone';
import { ServiceConfigContext } from '../../../context/ServiceConfigContext';

export default ({
  match: {
    params: { id: programId },
  },
  history: { location },
}) => {
  if (!programId)
    return (
      <Alert type="warn">
        No program is selected. Please select a program to view its program
        details.
      </Alert>
    );

  const [
    {
      data: { data: program },
      isLoading,
      isError,
    },
  ] = useTellerApi(`/api/v1/client_portal/card_issuing/programs/${programId}`, {
    count: 0,
    data: [],
    limit: 100,
    offset: 0,
  });

  const [timeZone] = useTimeZone();
  const serviceConfiguration = useContext(ServiceConfigContext);

  if (isLoading) return <Loader />;
  if (isError) return <ClientErrorComponent data={{}} />;

  return (
    <CardGroup>
      <Card>
        <Title>Program Details</Title>
        {program.program_type.includes('virtual') ? (
          <Program>
            <VirtualCard  number="XXXXXXXXXXXX1234" expiry={new Date("2023, 11, 03")} name="John Doe"/>
            <ProgramDetails>
              <DetailsGroup>
                {getProgramColumns(timeZone).map(c => (
                  <Details key={c[0].header} columns={c} data={program} />
                ))}
              </DetailsGroup>
            </ProgramDetails>
          </Program>
        ) : (
          <DetailsGroup>
            {getProgramColumns(timeZone).map(c => (
              <Details
                key={c[0].header}
                columns={c}
                data={program}
              />
            ))}
          </DetailsGroup>
        )}
      </Card>
      <MasterFundingAccount programID={programId} />
      { program.allow_location_management === true &&
      <Locations programID={programId} />
      }
      <Reporting program={program} createSuccess={(location.state && location.state.success) || null} />
    </CardGroup>
  );
};
