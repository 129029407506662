import React from 'react';
// Import Shared Components
import { Title, InputGroup, Loader } from 'shared-components';
// Import Local Components
import AuthForm from './AuthForm';
// Import Layouts
import AuthLayout from '../layouts/AuthLayout';

export default ({
  email,
  password,
  loading,
  onUpdateEmail,
  onUpdatePassword,
  onLoginSubmit,
  handleLoginKeyUp,
  formError,
  forgotPassword,
}) => {
  if (loading)
    return (
      <AuthLayout>
        <Title>Logging In...</Title>
        <Loader />
      </AuthLayout>
    );

  return (
    <AuthForm
      title="Login"
      error={formError}
      onSubmit={onLoginSubmit}
      onKeyUp={handleLoginKeyUp}
      secondaryButtonText="Forgot Password"
      onClick={forgotPassword}
      submitButtonText="Submit"
    >
      <InputGroup
        label="Email:"
        labelFor="email"
        dataId="login-form-email-input"
        value={email}
        onChange={onUpdateEmail}
        type="email"
      />
      <InputGroup
        label="Password:"
        labelFor="password"
        dataId="login-form-password-input"
        value={password}
        onChange={onUpdatePassword}
        type="password"
      />
    </AuthForm>
  );
};
