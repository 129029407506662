import gql from 'graphql-tag';

export const MUTATION = gql`
  mutation updateDefaultFinancialAccount(
    $accountHolderId: Int!
    $financialAccountId: String!
  ) {
    updateDefaultFinancialAccount(
      accountHolderId: $accountHolderId
      financialAccountId: $financialAccountId
    )
  }
`;
