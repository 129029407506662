import { withHandlers } from 'recompose';
// Import Utils
import { mutationErrorMessageParser } from '../../../../../../utils/errorLogger';

export default withHandlers({
  submit: ({
    createManyCustomers,
    data,
    updateError,
    updateUploadError,
    updateErroredAccountHolders,
    updateUploadedAccountHolders,
    history,
  }) => () =>
    createManyCustomers({ customers: data })
      .then(({ data }) => {
        if (data.createManyCustomers.length === 0) {
          history.push({
            pathname: '/direct-send/account-holders',
            state: {
              success: 'All Account Holders were created successfully.',
            },
          });
        } else {
          updateErroredAccountHolders([...data.createManyCustomers]);
          updateUploadError(true);
          updateUploadedAccountHolders([]);
        }
      })
      .catch(err => {
        const errorMessage = mutationErrorMessageParser(err.message);
        updateError(errorMessage);
      }),
  back: ({ history }) => () => history.goBack(),
});
