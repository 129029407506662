import React from 'react';
// Import Shared Components
import { Tag } from 'shared-components';

export default ({ value }) => {
  switch (value) {
  case true:
    return <Tag type="info">True</Tag>;
  case false:
    return <Tag type="danger">False</Tag>;
  default:
    return null;
  }
};
