import styled from 'styled-components';
// Import Components to Extend Styles
import Table from '../../../../../components/Table';

export const DefaultAccount = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  div:first-child {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  /* Tag Component */
  span {
    margin-left: 9px;
  }
`;

// Table displaying Account Holder Account information
export const AccountTable = styled(Table)`
  .rt-tbody {
    border-left: solid 1px rgba(0, 0, 0, 0.05);
    border-right: solid 1px rgba(0, 0, 0, 0.05);
    border-bottom: solid 1px rgba(0, 0, 0, 0.05);
  }
`;

export const Accounts = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 40px 30px;
`;

export const AccountsTableWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0 20px;
`;

export const NoAccountsTable = styled.p`
  margin-bottom: 11px;
`;

export const IconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  color: ${({ theme, color }) => color && theme.colors[color]};

  p {
    padding-left: 5px;
  }
`;
