import styled from 'styled-components';

export const BaseWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100vw;
  max-width: 100%;
  ${({ theme }) =>
    theme.brand.background.includes('https')
      ? `background: url(${theme.brand.background});
       background-size: cover;
    `
      : `background: ${theme.brand.background};`}
  overflow: auto;
`;

export const Base = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto 0;
  align-items: center;
  height: 100%;
  padding: 45px 0;

  a {
    margin-bottom: 15px;
  }

  ${({ theme }) => theme.media.tabletPortraitUp`
    padding: 45px;
  `}
`;
