import React, { useContext } from 'react';
// Import Shared Components
import {
    Card,
    Title,
    Details,
    Loader,
} from 'shared-components';
// Import Global Components
import ClientErrorComponent from '../../../../components/ClientErrorComponent';
// Import Local Components
import { escrowColumns, interacColumns } from '../columns';
// Import Common Hooks
import useTellerApi from '../../../../hooks/useTellerApi';
import { ServiceConfigContext } from '../../../../context/ServiceConfigContext';

export default ({escrowCurrency}) => {
    const [{ data: { data: escrow }, isLoading, isError }] = useTellerApi(`/api/v1/client_portal/direct-send/escrow/balance`, []);

    const serviceConfiguration = useContext(ServiceConfigContext);

    const revealVopayBalance = serviceConfiguration && serviceConfiguration["reveal_vopay_balance"]

    if (isLoading) return <Loader />;
    if (isError) return <ClientErrorComponent data={{}} />;

    escrowCurrency(escrow.currency);

    return (
        <Card>
            <Title>Escrow Balance</Title>
            <Details columns={escrowColumns} data={escrow} />
            {revealVopayBalance && escrow.allocations?.interac &&
                <div style={{ marginTop: '20px' }}>
                    <Details columns={interacColumns} data={escrow.allocations.interac} />
                </div>
            }
        </Card>
    );
};
