import { compose, withHandlers, lifecycle } from 'recompose';
import EditAccountHolderModal from './EditAccountHolderModal';

// States
import withCompanyNameState from '../../enhancers/states/withCompanyNameState.js';
import withEmailState from '../../enhancers/states/withEmailState.js';
import withErrorState from '../../enhancers/states/withErrorState.js';
import withFirstNameState from '../../enhancers/states/withFirstNameState.js';
import withLastNameState from '../../enhancers/states/withLastNameState.js';
import withPhoneState from '../../enhancers/states/withPhoneNumberState.js';
import withTypeState from '../../enhancers/states/withTypeState.js';

import withValidationErrorsState from '../../enhancers/states/withValidationErrorsState';
import withValidationHandlers from '../../enhancers/withValidationHandlers';

export default compose(
  withCompanyNameState,
  withEmailState,
  withErrorState,
  withFirstNameState,
  withLastNameState,
  withPhoneState,
  withTypeState,
  withValidationErrorsState,
  withValidationHandlers,
  lifecycle({
    componentDidMount() {
      const {
        accountHolderInfo,
        updateEmail,
        updateFirstName,
        updateLastName,
        updateCompanyName,
        updateType,
        updatePhone
      } = this.props;

      if (accountHolderInfo.type === 'individual') {
        const splitName = accountHolderInfo.contactDetails.fullName.split(' ');
        updateFirstName(splitName[0]);
        updateLastName(splitName[1]);
      } else {
        updateCompanyName(accountHolderInfo.contactDetails.fullName.trim());
        updateType('business');
      }

      updateEmail(accountHolderInfo.email);
      updatePhone(accountHolderInfo.contactDetails.phone);
    }
  }),
  withHandlers({
    submit: ({
      closeModal,
      email,
      firstName,
      lastName,
      companyName,
      type,
      phone,
      onFormSubmit,
      updateError,
      emailValidate,
      accountHolderInfo,
      firstNameValidate,
      lastNameValidate,
      phoneNumberValidate,
      validationError,
      updateSuccess
    }) => e => {
      e.preventDefault();

      const {id} = accountHolderInfo;

      // Check Validations and make sure fields are filled
      if (
        type === 'business' &&
        (validationError.email ||
          !email ||
          (validationError.companyName || !companyName) ||
          validationError.phone)
      ) {
        return updateError('One or more fields are incorrect please try again');
      } if (
        type === 'individual' &&
        (validationError.email ||
          !email ||
          (validationError.firstName || !firstName) ||
          (validationError.lastName || !lastName) ||
          validationError.phone)
      ) {
        return updateError('One or more fields are incorrect please try again');
      }

      if (type === 'business') {
        onFormSubmit({
          id: +id,
          type: type.toUpperCase(), // UpperCase is Required by GraphQL
          email: email.trim(),
          firstName: companyName.trim(),
          lastName: '   ',
          phone: (phone && phone.trim()) || null
        });
      } else {
        onFormSubmit({
          id: +id,
          type: type.toUpperCase(), // UpperCase is Required by GraphQL
          email: email.trim(),
          firstName: firstName.trim(),
          lastName: lastName.trim(),
          phone: (phone && phone.trim()) || null
        });
      }

      closeModal();
    },
    onUpdateType: ({ updateType, updateError }) => key => {
      updateType(key);
      updateError(false);
    },
    onUpdateEmail: ({ updateEmail, updateError }) => e => {
      updateEmail(e.target.value.trim());
      updateError(false);
    },
    onUpdateFirstName: ({ updateFirstName, updateError }) => e => {
      updateFirstName(e.target.value);
      updateError(false);
    },
    onUpdateLastName: ({ updateLastName, updateError }) => e => {
      updateLastName(e.target.value);
      updateError(false);
    },
    onUpdateCompanyName: ({ updateCompanyName, updateError }) => e => {
      updateCompanyName(e.target.value);
      updateError(false);
    },
    onUpdatePhone: ({ updatePhone, updateError }) => e => {
      updatePhone(e.target.value);
      updateError(false);
    }
  })
)(EditAccountHolderModal);
