import React, { useEffect, useState } from 'react';
// Import Common Utils
import { Loader, ClientErrorComponent } from 'shared-components';
import { DEFAULT_SERVICE_CONFIGURATION } from 'shared-components/src/utils/services';
import { get } from '../utils/http';

const ServiceConfigContext = React.createContext();

const ServiceConfigProvider = ({ children }) => {
  const [configs, setConfigs] = useState(DEFAULT_SERVICE_CONFIGURATION);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const configData = await get(
          '/api/v1/client_portal/service_configuration'
        );

        setConfigs(configData.data);
      } catch (e) {
        setError(true);
      }
      setLoading(false);
    };
    fetchData();
  }, []);

  const renderBody = () => {
    if (loading) return <Loader />;
    if (error) return <ClientErrorComponent />;

    return <>{children}</>;
  };

  return (
    <ServiceConfigContext.Provider value={configs}>
      {renderBody()}
    </ServiceConfigContext.Provider>
  );
};

export { ServiceConfigContext, ServiceConfigProvider };
