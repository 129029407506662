import React from 'react';
import { Icon } from 'shared-components';
// Import Component Styles
import { ExpanderIcon } from './styles';

function iconToRender(usedValue) {
  switch (usedValue) {
  case 1:
    return 'danger';
  case 2:
    return 'success';
  case 3:
    return 'warning';
  default:
    return 'chevron-down';
  }
}

export default ({ usedValue }) => (
  <ExpanderIcon usedValue={usedValue}>
    <Icon type={iconToRender(usedValue)} align="middle" />
  </ExpanderIcon>
);
