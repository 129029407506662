import {
  setStartOfDay,
  getEndOfDay,
  getStartOfDay,
  addDays,
  subtractDays,
} from 'shared-components/src/utils/dates';
import { validateEmail } from 'shared-components/src/validations/cardholderValidations';

// Options for Card Issuing reporting form
export const programReportTypeOptions = [
  { key: 'mcr_reloadable', val: 'MCR Reloadable' },
  { key: 'mcr_non_reloadable', val: 'MCR Non Reloadable' },
  { key: 'escrow_transactions', val: 'Escrow Transactions' },
  { key: 'transactions', val: 'Transactions' },
  { key: 'value_load', val: 'Value Load' },
];

// Options for Direct Send reporting form
export const ReportTypeOptions = [
  { key: 'transaction_summary', val: 'Transaction Summary' },
];

export const reportFrequency = [
  { key: '@daily', val: 'Daily' },
  { key: '@weekly', val: 'Weekly' },
  { key: '@monthly', val: 'Monthly' },
];

export const reportingMethods = [
  { key: 'email', val: 'Email' },
  { key: 'sftp', val: 'SFTP' },
  { key: 'email_and_sftp', val: 'Email and SFTP' },
  { key: 'sftp_push', val: 'SFTP Push' },
];

export const reportTimeRange = [
  { key: 'yesterday', val: 'Yesterday' },
  { key: 'previous_7_days', val: 'Previous Week (7 Days)' },
  { key: 'previous_31_days', val: 'Previous Month (31 Days)' },
  { key: 'previous_93_days', val: 'Previous Three Months (93 Days)' },
  // { key: 'custom', val: 'Custom' },
];

export const reportTimeRangeDates = {
  'yesterday': subtractDays(new Date(getStartOfDay()), 1),
  'previous_7_days': subtractDays(new Date(getStartOfDay()), 7),
  'previous_31_days': subtractDays(new Date(getStartOfDay()), 31),
  'previous_93_days': subtractDays(new Date(getStartOfDay()), 93)
};

export const getTimeRangeStartDate = (key, programStartDate) => {
  const startDate = reportTimeRangeDates[key];
  return startDate > programStartDate ? startDate : programStartDate;
};

// Date Functions for Card Issuing Reports

// Compares selected start date with program's start date
// Returns program's start date if selected start date is before
export const getStartDate = (programStartDate, date) => {
  const newProgramStartDate = setStartOfDay(new Date(programStartDate));
  return date < newProgramStartDate ? newProgramStartDate : date;
};

// Validate selected end date is no more than 93 days (three months) from the selected start date before submitting to generate report
export const validateEndDate = (startDate, endDate) => {
  const validEndDate = addDays(new Date(startDate), 93);
  return endDate < validEndDate;
};

// Get days that should be disabled in reporting calendar for card issuing
export const getDisabledDays = programStartDate => {
  const after = getEndOfDay();
  const threeMonthsAgo = subtractDays(new Date(getStartOfDay()), 93);
  const before =
    programStartDate > threeMonthsAgo ? programStartDate : threeMonthsAgo;

  return { before, after };
};

export const runValidationCheck = (setValidationErrors, formInfo) => {
  validateReportEndDate(setValidationErrors, formInfo);
  validateReportType(setValidationErrors, formInfo);
  validateReportEmail(setValidationErrors, formInfo);
  validateReportFrequency(setValidationErrors, formInfo);
  validateReportTimeRange(setValidationErrors, formInfo);
};

export const checkForValidationErrors = (formInfo) => {
  if(formInfo.email===null||formInfo.email===""){ 
    return formInfo.reportType === null || !validateEndDate(formInfo.startDate, formInfo.endDate)
  }else{
    return formInfo.reportType === null || !validateEmail(formInfo.email) || !validateEndDate(formInfo.startDate, formInfo.endDate)
  }
};

export const validateReportEndDate = (setValidationErrors, formInfo) =>
  setValidationErrors({
    key: 'endDate',
    value: !validateEndDate(formInfo.startDate, formInfo.endDate),
  });

export const validateReportType = (setValidationErrors, formInfo) =>
  setValidationErrors({
    key: 'reportType',
    value: formInfo.reportType === null,
  });

export const validateReportFrequency = (setValidationErrors, formInfo) =>
  setValidationErrors({
    key: 'frequency',
    value: formInfo.frequency === null,
  });

export const validateReportTimeRange = (setValidationErrors, formInfo) =>
  setValidationErrors({
    key: 'timeRange',
    value: formInfo.timeRange === null,
  });

export const validateReportingMethod = (setValidationErrors, formInfo) =>
  setValidationErrors({
    key: 'reportingMethod',
    value: formInfo.reportingMethod === null,
  });

export const validateReportEmail = (setValidationErrors, formInfo) =>{
  if(formInfo.email===null){
    setValidationErrors({ key: 'email', value: false });
  }else{
    setValidationErrors({ key: 'email', value: !validateEmail(formInfo.email) });
  }
}
