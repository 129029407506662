// Function for determining the correct CSV requirements for activate cards
export const getCardsCSVFieldDetails = () => {

  let baseDetails = [
    {
      field: 'Proxy',
      required: 'Yes',
      requirements: 'Account ID',
      notes:
        'Account ID.',
      example: '14563',
    },
    {
      field: 'Message',
      required: 'No',
      requirements:
        'Letters, numbers, any sign',
      notes: 'Internal message. Max 50 characters.',
      example: 'Expired, closed',
    },
    {
      field: 'External Tag',
      required: 'No',
      requirements:
        'Letters, numbers, any sign',
      notes: 'External Tag. Tag that can be used to filter orders. Max 255 characters.',
      example: 'Group A',
    },
  ];
  return baseDetails;
};
