import React from 'react';
// Import Shared Components
import { Card, Title, Table, UploadButton, ActionBar } from 'shared-components';
// Import data
import FIELDDATA from './fieldData';

export default ({ disableUpload, parseCsv }) => {
  const columns = [
    {
      Header: 'Field',
      accessor: 'field',
      maxWidth: 180
    },
    {
      Header: 'Required',
      accessor: 'required',
      maxWidth: 100
    },
    {
      Header: 'Requirements',
      accessor: 'requirements'
    },
    {
      Header: 'Notes',
      accessor: 'notes'
    },
    {
      Header: 'Example',
      accessor: 'example'
    }
  ];

  return (
    <Card>
      <Title>CSV Field Details</Title>
      <Table columns={columns} data={FIELDDATA} />
      <ActionBar>
        <UploadButton
          type="primary"
          onChange={parseCsv}
          disabled={disableUpload}
        >
          Upload CSV
        </UploadButton>
      </ActionBar>
    </Card>
  );
};
