import React from 'react';
import PropTypes from 'prop-types';
// Import Shared Components
import { Tag } from 'shared-components';
import { humanizeString } from 'shared-components/src/utils/string';

// Statuses to change once Teller is updated with correct status for bulk transfers
const CollectionStatus = ({ status }) => {
  const formattedStatus = humanizeString(status);
  switch (status) {
  case 'initiated':
    return <Tag type="processing">{formattedStatus}</Tag>;
  case 'email_sent':
    return <Tag type="info">{formattedStatus}</Tag>;
  case 'email_failed':
    return <Tag type="warning">{formattedStatus}</Tag>;
  case 'paid':
    return <Tag type="success">{formattedStatus}</Tag>;
  case 'settled':
    return <Tag type="success">{formattedStatus}</Tag>;
  case 'declined':
    return <Tag type="danger">{formattedStatus}</Tag>;
  case 'void':
    return <Tag type="danger">{formattedStatus}</Tag>;
  case 'returned':
    return <Tag type="danger">{formattedStatus}</Tag>;
  case 'cancelled':
    return <Tag type="danger">{formattedStatus}</Tag>;
  case 'expired':
    return <Tag type="danger">{formattedStatus}</Tag>;
  default:
    return null;
  }
};

CollectionStatus.propTypes = {
  status: PropTypes.string.isRequired,
};

export default CollectionStatus;