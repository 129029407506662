import styled from 'styled-components';

export default styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${({ theme }) => theme.media.tabletLandscapeUp`
    flex-direction: row;

    & > *:not(:first-child) {
      padding-left: 10px;
    }
  `}
`;
