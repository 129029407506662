import React, { createContext } from 'react';
import PropTypes from 'prop-types';
import { useStateWithLocalStorage } from '../hooks/useStateWithLocalStorage';

const TimeZoneContext = createContext();

const TimeZoneProvider = ({ children }) => {
  const [timeZone, setTimeZone] = useStateWithLocalStorage('time_zone', 'UTC');
  return (
    <TimeZoneContext.Provider value={[timeZone, setTimeZone]}>
      {children}
    </TimeZoneContext.Provider>
  );
};

TimeZoneProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
export { TimeZoneProvider, TimeZoneContext };
