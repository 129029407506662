import React from 'react';
import Shell from '../Shell';

import Route from './Route';

const PrivateRoute = ({ title, breadcrumbs, ...props }) => (
  <Shell title={title} breadcrumbs={breadcrumbs}>
    <Route {...props} priv authorize />
  </Shell>
);

export default PrivateRoute;
