import React from 'react';
// Import Shared Components
import { Title, Details } from 'shared-components';
import { formatCurrencySV } from 'shared-components/src/utils/formatCurrency';
// Import Component Styles
import { Amount, TitleWrapper } from './styles';

export default ({ transfer }) => {
  const columns = [
    {
      accessor: 'redeemedAmount',
      header: 'Redeemed:',
      cell: value => (
        <Amount color="green">{formatCurrencySV(value / 100, transfer.currency)}</Amount>
      ),
    },
    {
      accessor: 'canceledAmount',
      header: 'Canceled:',
      cell: value => (
        <Amount color="red">{formatCurrencySV(value / 100, transfer.currency)}</Amount>
      ),
    },
    {
      accessor: 'erroredAmount',
      header: 'Errored:',
      cell: value => (
        <Amount color="red">{formatCurrencySV(value / 100, transfer.currency)}</Amount>
      ),
    },
    {
      accessor: 'outstandingAmount',
      header: 'Outstanding:',
      cell: value => (
        <Amount color="orange">
          {formatCurrencySV(value / 100, transfer.currency)}
        </Amount>
      ),
    },
  ];

  return (
    <>
      <TitleWrapper>
        <Title>Total Sent Details</Title>
        <p>
          <b>
            {transfer.amount &&
              formatCurrencySV(transfer.amount / 100, transfer.currency)}
          </b>{' '}
          is the total amount that was pulled from your funding source to fund
          the payments in this transfer the day it was created.
        </p>
      </TitleWrapper>
      <Details data={transfer} columns={columns} />
    </>
  );
};
