export default [
  // Canadian Provinces
  { key: null, val: '-- Canadian Provinces --' },
  { key: 'AB', val: 'AB' },
  { key: 'BC', val: 'BC' },
  { key: 'MB', val: 'MB' },
  { key: 'NB', val: 'NB' },
  { key: 'NL', val: 'NL' },
  { key: 'NS', val: 'NS' },
  { key: 'NU', val: 'NU' },
  { key: 'NT', val: 'NT' },
  { key: 'ON', val: 'ON' },
  { key: 'PE', val: 'PE' },
  { key: 'QC', val: 'QC' },
  { key: 'SK', val: 'SK' },
  { key: 'YT', val: 'YT' },
  // United States
  { key: null, val: '-- US States --' },
  { key: 'AL', val: 'AL' },
  { key: 'AK', val: 'AK' },
  { key: 'AZ', val: 'AZ' },
  { key: 'AR', val: 'AR' },
  { key: 'CA', val: 'CA' },
  { key: 'CO', val: 'CO' },
  { key: 'CT', val: 'CT' },
  { key: 'DE', val: 'DE' },
  { key: 'FL', val: 'FL' },
  { key: 'GA', val: 'GA' },
  { key: 'HI', val: 'HI' },
  { key: 'ID', val: 'ID' },
  { key: 'IL', val: 'IL' },
  { key: 'IN', val: 'IN' },
  { key: 'IA', val: 'IA' },
  { key: 'KS', val: 'KS' },
  { key: 'KY', val: 'KY' },
  { key: 'LA', val: 'LA' },
  { key: 'ME', val: 'ME' },
  { key: 'MD', val: 'MD' },
  { key: 'MA', val: 'MA' },
  { key: 'MI', val: 'MI' },
  { key: 'MN', val: 'MN' },
  { key: 'MS', val: 'MS' },
  { key: 'MO', val: 'MO' },
  { key: 'MT', val: 'MT' },
  { key: 'NE', val: 'NE' },
  { key: 'NV', val: 'NV' },
  { key: 'NH', val: 'NH' },
  { key: 'NJ', val: 'NJ' },
  { key: 'NM', val: 'NM' },
  { key: 'NY', val: 'NY' },
  { key: 'NC', val: 'NC' },
  { key: 'ND', val: 'ND' },
  { key: 'OH', val: 'OH' },
  { key: 'OK', val: 'OK' },
  { key: 'OR', val: 'OR' },
  { key: 'PA', val: 'PA' },
  { key: 'RI', val: 'RI' },
  { key: 'SC', val: 'SC' },
  { key: 'SD', val: 'SD' },
  { key: 'TN', val: 'TN' },
  { key: 'TX', val: 'TX' },
  { key: 'UT', val: 'UT' },
  { key: 'VT', val: 'VT' },
  { key: 'VA', val: 'VA' },
  { key: 'WA', val: 'WA' },
  { key: 'WV', val: 'WV' },
  { key: 'WI', val: 'WI' },
  { key: 'WY', val: 'WY' },
];

export const PROVINCES = [
  { key: 'AB', val: 'AB' },
  { key: 'BC', val: 'BC' },
  { key: 'MB', val: 'MB' },
  { key: 'NB', val: 'NB' },
  { key: 'NL', val: 'NL' },
  { key: 'NS', val: 'NS' },
  { key: 'NU', val: 'NU' },
  { key: 'NT', val: 'NT' },
  { key: 'ON', val: 'ON' },
  { key: 'PE', val: 'PE' },
  { key: 'QC', val: 'QC' },
  { key: 'SK', val: 'SK' },
  { key: 'YT', val: 'YT' },
];
export const STATES = [
  { key: 'AL', val: 'AL' },
  { key: 'AK', val: 'AK' },
  { key: 'AZ', val: 'AZ' },
  { key: 'AR', val: 'AR' },
  { key: 'CA', val: 'CA' },
  { key: 'CO', val: 'CO' },
  { key: 'CT', val: 'CT' },
  { key: 'DE', val: 'DE' },
  { key: 'FL', val: 'FL' },
  { key: 'GA', val: 'GA' },
  { key: 'HI', val: 'HI' },
  { key: 'ID', val: 'ID' },
  { key: 'IL', val: 'IL' },
  { key: 'IN', val: 'IN' },
  { key: 'IA', val: 'IA' },
  { key: 'KS', val: 'KS' },
  { key: 'KY', val: 'KY' },
  { key: 'LA', val: 'LA' },
  { key: 'ME', val: 'ME' },
  { key: 'MD', val: 'MD' },
  { key: 'MA', val: 'MA' },
  { key: 'MI', val: 'MI' },
  { key: 'MN', val: 'MN' },
  { key: 'MS', val: 'MS' },
  { key: 'MO', val: 'MO' },
  { key: 'MT', val: 'MT' },
  { key: 'NE', val: 'NE' },
  { key: 'NV', val: 'NV' },
  { key: 'NH', val: 'NH' },
  { key: 'NJ', val: 'NJ' },
  { key: 'NM', val: 'NM' },
  { key: 'NY', val: 'NY' },
  { key: 'NC', val: 'NC' },
  { key: 'ND', val: 'ND' },
  { key: 'OH', val: 'OH' },
  { key: 'OK', val: 'OK' },
  { key: 'OR', val: 'OR' },
  { key: 'PA', val: 'PA' },
  { key: 'RI', val: 'RI' },
  { key: 'SC', val: 'SC' },
  { key: 'SD', val: 'SD' },
  { key: 'TN', val: 'TN' },
  { key: 'TX', val: 'TX' },
  { key: 'UT', val: 'UT' },
  { key: 'VT', val: 'VT' },
  { key: 'VA', val: 'VA' },
  { key: 'WA', val: 'WA' },
  { key: 'WV', val: 'WV' },
  { key: 'WI', val: 'WI' },
  { key: 'WY', val: 'WY' },
];
