import styled from 'styled-components';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  height: 100%;
`;

export const FormGroup = styled.fieldset`
  padding: 5px;
  margin-bottom: 5px;

  & > *:nth-child(n + 3) {
    border-bottom: ${({ theme }) => theme.borders.default};
  }
`;

export const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
  margin: 5px;
  padding 5px 10px 10px 10px;
  
  border-bottom: ${({ theme }) => theme.borders.default};

  ${({ theme }) => theme.media.desktopUp`
    flex-direction: row;
  `}
`;

export const InputContainer = styled.div`
  flex: 1;
  margin: 0 5px 0 5px;
`;

export const RedText = styled.p`
  text-align: center;
  color: ${({ theme }) => theme.colors.red};
  margin: 13px 0 5px 0;
`;

export const LargeText = styled.p`
  font-weight: bold;
  font-size: 16px;
`;

export const RedeemMessage = styled.div`
  width: 100%
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 15px;
  border-bottom: ${({ theme }) => theme.borders.default};
  padding-bottom: 15px;
`;
