export const DIRECT_SEND = 'direct_send';
export const CARD_ISSUING = 'card_issuing';
export const COLLECTIONS = 'collections';
export const BULKER = 'bulker';
export const VALUE_LOADS = 'value_loads';
export const CREATE_CARDHOLDERS = 'create_cardholders';
export const CLOSE_UNLOAD = 'close_unload';
export const MODIFY_CARDS = 'modify_cards';
export const ACTIVATE_CARDS = 'activate_cards';
export const INSTANT_ISSUANCE_CREATE_CARDS = 'instant_issuance_create_cards';
export const VISA_ANI_VALIDATION = 'visa_ani_validation';
export const API_KEY_ENABLED = 'api_key_enabled';

export const DEFAULT_SERVICE_CONFIGURATION = {
  [DIRECT_SEND]: false,
  [CARD_ISSUING]: false,
  [COLLECTIONS]: false,
  [BULKER]: false,
  [VALUE_LOADS]: false,
  [CREATE_CARDHOLDERS]: false,
  [CLOSE_UNLOAD]: false,
  [MODIFY_CARDS]: false,
  [ACTIVATE_CARDS]: false,
  [INSTANT_ISSUANCE_CREATE_CARDS]: false,
  [VISA_ANI_VALIDATION]: false,
  [API_KEY_ENABLED]: false,
};
