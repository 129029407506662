import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  min-height: 45px;
  whitespace: wrap;
  align-items: center;

  button {
    flex: 0;
    margin-top: 15px;
  }
  table {
    flex: 1;
    width: auto;
  }

  ${({ theme }) => theme.media.tabletPortraitUp`
    display: flex;
    flex-direction: row;
    align-items: center;

    button {
      margin-left: 15px;
      margin-top: 0;
    }
  `}
`;
