export const parseAmountToCents = string => {
  // This function is to be used particularly if the number has already been validated
  try {
    let num = 0;
    let frac = 0;
    const [decwhole, decpart] = string.split('.');
    if(decwhole) {
      num = parseInt(decwhole, 10);
    }
    if(decpart) {
      const frac1 = parseInt(decpart.substring(0, 1), 10) * 10;
      const frac2 = parseInt(decpart.substring(1, 2), 10);
      frac = Number.isNaN(frac2) ? frac1 : frac1 + frac2;
    }
    return num * 100 + frac;
  } catch(e) {
    return NaN;
  };
};

export const centsToDollars = amount => {
  return parseInt(amount, 10) / 100;
};

/**
 * Converts a numeric Dollar amount to Cents.
 * @param amount A number value representing an amount in dollars and cents.
 */
export const numberDollarsToCents = amount => {
  // Math.round will handle floating point inaccuracy.
  return Math.round(amount * 100);
};

export const formatCurrency = (amount) => {
  const opts = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  };
  return `${new Intl.NumberFormat({}, opts).format(amount)}`;
};