import styled from 'styled-components';

export const Header = styled.header`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1.0714em 1.42857em;
  height: 90px;
  z-index: 3;
  ${({ theme }) =>
    theme.brand.background.includes('https')
      ? `background: url(${theme.brand.headerBackground});
       background-size: cover;
    `
      : `background:
     ${theme.brand.headerBackground};`}
  ${({ theme }) => theme.media.tabletPortraitUp`
    justify-content: flex-start;

  `}
`;

export const PageTitle = styled.h2`
  color: #fff;
  margin: 0;
  font-weight: 800;
  text-align: center;
`;

export const LogoWrapper = styled.div`
  display: none;

  ${({ theme }) => theme.media.tabletPortraitUp`
    display: inline-flex;
  `}
`;
