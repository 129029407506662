import React from 'react';
import PropTypes from 'prop-types';
import { Title, Table, Card } from 'shared-components';

const ValueLoadTable = ({
  columns,
  valueLoads,
  count,
  pagination,
  searchParams,
  setPagination,
  setUrl,
}) => {
  const handlePageSizeChange = (pageSize, pageIndex) => {
    setPagination({
      ...pagination,
      limit: pageSize,
      offset: pageIndex * pageSize,
    });

    const params = new URLSearchParams({
      ...searchParams,
      ...pagination,
      limit: pageSize,
      offset: pageIndex * pageSize,
    }).toString();
    setUrl(`/api/v1/client_portal/card_issuing/value_loads?${params}`);
  };

  const handlePageChange = pageIndex => {
    setPagination({
      ...pagination,
      page: pageIndex,
      offset: pageIndex * pagination.limit,
    });
    const params = new URLSearchParams({
      ...searchParams,
      ...pagination,
      page: pageIndex,
      offset: pageIndex * pagination.limit,
    }).toString();
    setUrl(`/api/v1/client_portal/card_issuing/value_loads?${params}`);
  };
  return (
    <Card>
      <Title>Value Load List</Title>
      <Table
        columns={columns}
        data={valueLoads}
        noDataText="Currently there are no Value Loads"
        /* eslint-disable no-unused-vars */
        showPagination={count > pagination.limit && true}
        pages={Math.ceil(count / pagination.limit)}
        page={pagination.page}
        manual
        onPageSizeChange={handlePageSizeChange}
        onPageChange={handlePageChange}
        /* eslint-enable no-unused-vars */
        sortable={false}
        defaultSorted={[{ id: 'id', desc: true }]}
      />
    </Card>
  );
};

ValueLoadTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  valueLoads: PropTypes.arrayOf(PropTypes.object).isRequired,
  count: PropTypes.number.isRequired,
  pagination: PropTypes.shape({
    limit: PropTypes.number,
    offset: PropTypes.number,
    page: PropTypes.number,
  }).isRequired,
  searchParams: PropTypes.shape({
    program_id: PropTypes.string,
    external_tag: PropTypes.string,
    processor_reference: PropTypes.string,
    account_id: PropTypes.string,
    cardholder_id: PropTypes.string,
  }).isRequired,
  setPagination: PropTypes.func.isRequired,
  setUrl: PropTypes.func.isRequired,
};

export default ValueLoadTable;
