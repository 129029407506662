import { branch, withProps, renderComponent } from 'recompose';

import Loader from '../components/Loader';
import ClientErrorComponent from '../components/ClientErrorComponent';

export const renderWhileLoading = branch(
  ({ data }) => data && data.loading,
  renderComponent(Loader)
);

export const renderWhileLoadingTwoQueries = branch(
  ({ loadingOne, loadingTwo }) => loadingOne || loadingTwo,
  renderComponent(Loader)
);

export const renderWhileLoadingThreeQueries = branch(
  props => props.loadingOne || props.loadingTwo || props.loadingThree,
  renderComponent(Loader)
);

export const renderForError = branch(
  props => props.data && props.data.error,
  renderComponent(ClientErrorComponent)
);

export const renderForErrorDashboard = branch(
  props =>
    (props.data && props.data.error) ||
    (!props.dateRangeData && !props.noDateRangeData),
  renderComponent(ClientErrorComponent)
);

export const renderForErrorCreateTransfer = branch(
  props =>
    (props.data && props.data.error) ||
    (!props.subscriptionData && !props.limitsData),
  renderComponent(ClientErrorComponent)
);

export const renderForErrorTransferDetails = branch(
  props =>
    (props.data && props.data.error) ||
    (!props.searchParamsData && !props.noSearchParamsData),
  renderComponent(ClientErrorComponent)
);

export const setRefetchProp = withProps(({ data }) => ({
  refetch: data && data.refetch,
}));
