import React, { useState, useEffect } from 'react';
// Import Shared Components
import { Loader } from 'shared-components';
// Import Shared utils
import { parseURLSearchParams } from 'shared-components/src/utils/string';
// Import Local Components
import TransactionNotFound from './components/TransactionNotFound';
// Import Local Layouts
import Base from './layouts/Base';
import RedemptionForm from './layouts/RedemptionForm';
import RedemptionResult from './layouts/RedemptionResult';
// Import Common Utils
import { redeemPayment } from '../../utils/redeem';
// Import Styles
import { Card } from './styles';

export default () => {
  // Component States
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  // Data States
  const [transaction, setTransaction] = useState(null);
  const [redemptionResult, setRedemptionResult] = useState(null);
  const [redemptionMethod, setRedemptionMethod] = useState('PAYMENT_CARD');
  const [saveAsDefault, setSaveAsDefault] = useState(false);

  useEffect(() => {
    const validateToken = async () => {
      const { rt, df } = parseURLSearchParams(window.location.search);

      // If a redemption token exists, validate the token
      if (rt) {
        try {
          const res = await fetch(
            `/api/v1/transfers/validate_payment?rt=${rt}`
          );
          const body = await res.json();
          // If an error is received, either display the error message or a default message
          if (body.error) {
            if (body.error.message) throw new Error(body.error.message);
            throw new Error('There was an issue fetching data.');
          }

          // Able to contact Berkeley Payments and we can now inspect response and see if the transaction details where returned
          if (!body.data) {
            setError(
              'Transaction does not exist or has been redeemed already. Please contact your Payment Sender or Berkeley Payments if you have any further questions.'
            );
          }

          // Retrieve data and update state of transaction
          const { data } = body;
          setTransaction(data);

          // Data is available and default account is present and true.
          if (data && (df && df !== 'false')) {
            handleRedeemPayout({ payment_id: data.id });
          } else {
            setLoading(false);
          }
        } catch (e) {
          setError(e.message);
          setLoading(false);
        }
      } else {
        setError(
          'Unable to validate email link, please contact Payment Sender.'
        );
        setLoading(false);
      }
    };

    validateToken();
  }, []);

  // Redeem payment if default account is available
  const handleRedeemPayout = async payload => {
    setLoading(true);

    try {
      const data = await redeemPayment(payload);
      if (data.code != null && data.code === 'invalid_transaction_details'){
        setRedemptionResult({ status: 'invalid_transaction_details', message: data.message });
      }
      else {
        switch (data.transaction_status) {
        case 'processing':
          setRedemptionResult({ status: 'processing', ...data });
          break;
        case 'awaiting_settlement':
        case 'approved':
          setRedemptionResult({ status: 'approved', ...data });
          break;
        case 'declined':
          setRedemptionResult({ status: 'declined', ...data });
          break;
          // Failed
          // "network_connection_failure"
          // "payment_network_unavailable"
          // "payment_network_timeout"
          // "payment_network_error"
          // "internal_error"
          // "transaction_payload_invalid"
        default:
          setRedemptionResult({
            status: 'error',
            message: 'Failed to redeem funds',
          });
        }
      }
    } catch (e) {

      if (e.code === 'invalid_transaction_details'){
        setRedemptionResult({ status: 'invalid_transaction_details', message: e.message });
      } else{
        setRedemptionResult({ status: 'error', message: e.message });
      }
    }

    setLoading(false);
  };

  const handleSubmit = params => {
    // Contruct basic redemption payload
    const basicParams = {
      payment_id: transaction.id,
      token: params.token,
      set_default: saveAsDefault,
    };

    // If the params contain security details, we need to add them to the push transaction call
    const body = params.hasOwnProperty('security_question')
      ? {
        security_question: params.security_question,
        security_question_answer: params.security_question_answer,
        ...basicParams,
      }
      : basicParams;

    handleRedeemPayout(body);
  };

  // Determine which component to render
  const renderBody = () => {
    if (loading)
      return (
        <Card>
          <Loader />
        </Card>
      );

    if (error)
      return (
        <Card>
          <TransactionNotFound error={error} />
        </Card>
      );

    if (redemptionResult)
      return (
        <Card>
          <RedemptionResult
            result={redemptionResult}
            transaction={transaction}
          />
        </Card>
      );

    return (
      <RedemptionForm
        setRedemptionMethod={setRedemptionMethod}
        redemptionMethod={redemptionMethod}
        transaction={transaction}
        handleSubmit={handleSubmit}
        saveAsDefault={saveAsDefault}
        setSaveAsDefault={setSaveAsDefault}
      />
    );
  };

  return <Base>{renderBody()}</Base>;
};
