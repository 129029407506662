import { formatTime } from 'shared-components/src/utils/formatDateTime';

export default (timeZone) => ([
  {
    header: 'Account ID:',
    accessor: 'id',
  },
  {
    header: 'Program ID:',
    accessor: 'program_id',
  },
  {
    header: 'Program Name:',
    accessor: 'program_name',
  },
  {
    accessor: 'processor_reference',
    header: 'Processor Reference:',
  },
  {
    accessor: 'status_message',
    header: 'Status:',
  },
  {
    accessor: 'created_at',
    header: 'Created At:',
    cell: value => formatTime(value, 'dateTime', timeZone),
  },
  {
    accessor: 'balance',
    header: 'Balance:',
  },
]);
