import gql from 'graphql-tag';

export const Viewer = {
  fragments: {
    viewerInfo: gql`
      fragment ViewerInfo on Viewer {
        id
        role
      }
    `,
    tokenizationKey: gql`
      fragment TokenizationKey on Viewer {
        company {
          id
          tokenizationKey
        }
      }
    `
  }
};
