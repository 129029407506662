import { TOKEN_KEY } from 'shared-components/src/utils/auth';
import { get as getToken, del } from 'shared-components/src/utils/storage';

export const get = async url => {
  const TOKEN = getToken(TOKEN_KEY) || null;

  const res = await fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${TOKEN}`,
    },
  });
  if (!res.ok) {
    // Check if this is an Authorization Error
    if (res.status === 401 || res.status === 403) {
      // Request received unauthorized status from server, so log user out and reload page
      del(TOKEN_KEY);
      window.location.reload();
      return;
    }
    const body = await res.json();
    if (body?.error?.message) {
      throw new Error(body.error.message);
    }
    throw new Error('Failed to fetch data');
  }
  return res.json();
};

export const post = async (url, body) => {
  const TOKEN = getToken(TOKEN_KEY) || null;

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${TOKEN}`,
    },
    body: JSON.stringify(body),
  })
    .then(res => {
      if (!res.ok) {
        // Check if this is an Auth Error
        if (res.status === 401 || res.status === 403) {
          del(TOKEN_KEY);
          window.location.reload();
          return;
        }
      }
      return res.json();
    })
    .then(body => {
      if (body.error) {
        if (body.error.message) throw new Error(body.error.message);
        throw new Error('Failed to send request.');
      }
      return body;
    });

  return response;
};

export const postFormData = async (url, body) => {
  const TOKEN = getToken(TOKEN_KEY) || null;

  const headers = {
    Accept: 'application/json',
    Authorization: `Bearer ${TOKEN}`,
  };

  const response = await fetch(url, {
    method: 'POST',
    headers: headers,
    body: body,
  })
    .then(res => {
      if (!res.ok) {
        // Check if this is an Auth Error
        if (res.status === 401 || res.status === 403) {
          del(TOKEN_KEY);
          window.location.reload();
          return;
        }
      }
      return res.json();
    })
    .then(body => {
      if (body.error) {
        if (body.error.message) throw new Error(body.error.message);
        throw new Error('Failed to send request.');
      }
      return body;
    });

  return response;
};


export const put = async (url, body) => {
  const TOKEN = getToken(TOKEN_KEY) || null;

  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${TOKEN}`,
    },
    body: JSON.stringify(body),
  });
  if (!response.ok) {
    // Check if this is an Authorization Error
    if (response.status === 401 || response.status === 403) {
      // Request received unauthorized status from server, so log user out and reload page
      del(TOKEN_KEY);
      window.location.reload();
      return;
    }
    const body = await response.json();
    if (body?.error?.message) {
      throw new Error(body.error.message);
    }
    throw new Error('Failed to send request');
  }
  return response.json();
};

export const deleteHTTP = async (url, body) => {
  const TOKEN = getToken(TOKEN_KEY) || null;

  const response = await fetch(url, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${TOKEN}`,
    },
    body: JSON.stringify(body),
  });
  if (!response.ok) {
    // Check if this is an Authorization Error
    if (response.status === 401 || response.status === 403) {
      // Request received unauthorized status from server, so log user out and reload page
      del(TOKEN_KEY);
      window.location.reload();
      return;
    }
    const body = await response.json();
    if (body?.error?.message) {
      throw new Error(body.error.message);
    }
    throw new Error('Failed to send request');
  }
  return response.json();
};
