import React, { useContext } from 'react';
// Import Shared Components
import { Modal } from 'shared-components';
// Import Global Components
import CardDetailsFormV2 from '../../../../../../../components/CardDetailsForm/CardDetailsFormV2';
// Import Common Utils
import { mutationErrorMessageParser } from '../../../../../../../utils/errorLogger';
import { CompanyContext } from '../../../../../../../context/CompanyContext';
import { companyCountries } from 'shared-components/src/utils/company';

export default ({
  closeModal,
  tokenizationKey,
  updateFinancialAccount,
  selectedFinancialAccount,
  accountHolderId,
  updateError,
  updateSuccess,
  ...props
}) => {
  const { country } = useContext(CompanyContext);
  const tokenCreateCallback = ({ token }) => {
    updateFinancialAccount({
      accountHolderId,
      token,
      fsId: selectedFinancialAccount.id,
    })
      .then(() => updateSuccess('Payment card has been updated successfully'))
      .then(closeModal())
      .catch(err => {
        const message = mutationErrorMessageParser(
          err,
          'Payment Card could not be updated. \n Please update your card details and try again.'
        );
        updateError(message);
        updateSuccess(null);
        closeModal();
      });
  };

  return (
    <Modal key="editAccountModal">
      <CardDetailsFormV2
        title="Update Card Info"
        primaryBtnTitle="Update"
        tokenCreateCallback={tokenCreateCallback}
        tokenizationKey={tokenizationKey}
        secondaryButtonText="Close"
        secondaryBtnFunc={closeModal}
        selectedFinancialAccount={selectedFinancialAccount}
        isCanadian={country === companyCountries.CANADA}
        {...props}
      />
    </Modal>
  );
};
