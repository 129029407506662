import {
  isNameValid as validateName,
  isCardholderNameValid as validateCardholderName,
} from './accountHolderValidations';

const isAllNumbers = /^\d+$/;

export const isNameValid = validateName;
export const isCardholderNameValid = validateCardholderName;

export const validateCardNumber = cardNumber => {
  const ccNum = cardNumber.replace(/[\s-]+/g, '');
  if (ccNum.length > 19 || ccNum.length < 13) return false;
  return isAllNumbers.test(ccNum);
};

export const validateCVV = cvv => {
  if (cvv.length < 3) return false;
  return isAllNumbers.test(cvv);
};

export const validateExpiryMonth = val => {
  if (val.length !== 2) return false;
  if (val > 12) return false;
  return isAllNumbers.test(val);
};

export const validateShortExpiryYear = val => {
  const currentYr = new Date()
    .getFullYear()
    .toString()
    .slice(2);
  if (val.length !== 2) return false;
  return isAllNumbers.test(val) && val >= currentYr;
};

export const validateLongExpiryYear = val => {
  const currentYr = new Date().getFullYear();
  if (val.length !== 4) return false;
  return isAllNumbers.test(val) && val >= currentYr;
};

// validate expiry for Cardholder verify card form.
// Expiry will be sent in the form of MMYY
export const validateExpiry = expiry => {
  if (expiry.length !== 6) return false;

  const expiryMatches = expiry.match(/^(\d{2})(\d{4})$/);
  const expiryMonth = expiryMatches[1];
  const expiryYear = expiryMatches[2];

  return validateExpiryMonth(expiryMonth) && validateLongExpiryYear(expiryYear);
};

export const validateAddress = address => {
  const checkAddress = /[^a-zA-Z0-9 ,.'-]/g;
  if (address.length > 35 || address.length < 1) return false;
  if (checkAddress.test(address)) return false;
  return true;
};

export const validateCity = val => {
  const checkCity = /^[a-zA-Z.'\- ]+$/g;
  if (val.length > 25 || val.length < 1) return false;
  return checkCity.test(val);
};

export const validatePostalCode = val => {
  const checkPostalCode = /[^a-zA-Z0-9 .'-]/g;
  const postalCode = val.replace(/[\s-]+/g, '');
  if (postalCode.length > 9 || postalCode.length < 5) return false;
  if (checkPostalCode.test(postalCode)) return false;
  return true;
};

export const validateProvince = province => {
  return province !== '';
};

// Object to combine all card info validations
export const cardValidations = {
  cvv: validateCVV,
  expiryMonth: validateExpiryMonth,
  expiryYear: validateLongExpiryYear,
  name: validateName,
  number: validateCardNumber,
};

// Object to combine all billing info validations
export const billingValidations = {
  address1: validateAddress,
  address2: () => true,
  city: validateCity,
  postalCode: validatePostalCode,
  province: validateProvince,
};

export const verifyCardValidations = {
  cardNumber: validateCardNumber,
  cvv: validateCVV,
  expiry: validateExpiry,
};

// Checks whether the supplied credit card number is valid from a format perspective
// Card Number comes in as a long string of digits
// https://en.wikipedia.org/wiki/Luhn_algorithm
export const luhnCheck = cardNumber => {
  const value = cardNumber
    .split('')
    .reverse()
    .reduce((acc, curr, idx) => {
      let num = Number(curr);
      if (idx % 2 !== 0) {
        num *= 2;
        if (num >= 10) num -= 9;
      }
      return (acc += num); // eslint-disable-line no-return-assign, no-param-reassign
    }, 0);

  return value % 10 === 0;
};

export const validateLastFour = val => {
  if (val.length !== 4) return false;
  if (val > 9999) return false;
  return isAllNumbers.test(val);
};

export const validateModifyCardStatus = val => {
  const validValues = ['mark_card_active', 'mark_card_lost', 'mark_card_stolen', 'initiate_card_lost', 'initiate_card_stolen', 'suspend', 'unsuspend'];
  return validValues.includes(val);
};