import React from 'react';
import { Helmet } from 'react-helmet';
// Import Global Layouts
import BaseLayout from '../../../../layouts/Base';
import { Base } from './styles';

export default ({ children }) => (
  <>
    <Helmet key="helmet">
      <title>Redeem Payout | Berkeley Payment</title>
    </Helmet>
    <BaseLayout key="redeem-body">
      <Base>{children}</Base>
    </BaseLayout>
  </>
);
