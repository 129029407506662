import { compose } from 'recompose';
import {
  renderWhileLoading,
  setRefetchProp,
  renderForError
} from '../../../state/apolloRecompose';

import TabState from './enhancers/withTabState';
import SubtabState from './enhancers/withSubtabState';
import LimitsQuery from './enhancers/withLimitsQuery';
import withHandlers from './enhancers/withHandlers';
import Limits from './Limits';

export default compose(
  TabState,
  SubtabState,
  LimitsQuery,
  withHandlers,
  renderWhileLoading,
  setRefetchProp,
  renderForError
)(Limits);
