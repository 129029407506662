import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
// Import Shared Components
import { Input } from 'shared-components';
// Import Shared Utils
import { createTokenV1 } from 'shared-components/src/utils/tokenization';
// Import Global Components
import Form from '../../../../../../components/Form';
import Legend from '../../../../../../components/Legend';
import InfoTooltip from '../../../../../../components/InfoTooltip';
// Import Global Layouts
import InputRow from '../../../../../../layouts/InputRow';
import InputGroup from '../../../../../../layouts/InputGroup';
import Fieldset from '../../../../../../layouts/Fieldset';
// Import Local Components
import NotificationMethod from './components/NotificationMethod';

const EtransferCustomerForm = ({
  tokenCreateCallback,
  tokenizationKey,
  etransferConfig,
  secondaryButtonFunc,
}) => {
  const nameInput = useRef(null);
  const emailInput = useRef(null);
  const phoneInput = useRef(null);
  const securityQuestionInput = useRef(null);
  const securityAnswerInput = useRef(null);

  const initialNotificationMethod =
    etransferConfig === 'both' ? 'email' : etransferConfig;

  // Data states
  const [notificationMethod, setNotificationMethod] = useState(
    initialNotificationMethod
  );
  const [tokenError, setTokenError] = useState(null);
  // Component States
  const [loading, setLoading] = useState(false);

  const handleSubmit = async e => {
    e.preventDefault();
    setLoading(true);
    setTokenError(null);

    const basicParams = { name: nameInput.current.value };

    const body =
      notificationMethod === 'email'
        ? { etransfer: { email: emailInput.current.value, ...basicParams } }
        : {
          etransfer: {
            phone_number: phoneInput.current.value,
            ...basicParams,
          },
        };

    const securityParams = {
      security_question: securityQuestionInput.current.value,
      security_question_answer: securityAnswerInput.current.value,
    };

    try {
      const tokenDetails = await createTokenV1(body, tokenizationKey);

      tokenCreateCallback({ ...securityParams, ...tokenDetails });
    } catch (e) {
      setTokenError(e.message);
      setLoading(false);
    }
  };

  const renderInput = () => {
    return notificationMethod === 'email' ? (
      <InputGroup label="Email:" labelFor="email" required>
        <Input
          ref={emailInput}
          required
          dataTestid="email"
          name="email"
          autocomplete="email"
          type="email"
        />
      </InputGroup>
    ) : (
      <InputGroup label="Phone Number:" labelFor="name" required>
        <Input
          ref={phoneInput}
          required
          dataTestId="phone"
          name="phone"
          autocomplete="phone"
          type="tel"
          pattern="[0-9]{10}"
          title="Enter a valid phone number. Digits only, no dashes or spaces allowed"
        />
      </InputGroup>
    );
  };
  return (
    <Form
      dataId="etransfer-customer-details-form"
      submit={handleSubmit}
      submitButtonText="Redeem"
      secondaryButtonFunc={secondaryButtonFunc}
      secondaryButtonText="Back"
      error={tokenError}
      title="Redeem by e-mail / Remboursement par e-mail"
      loading={loading}
    >
      <Fieldset>
        <Legend>Contact Details / Détails de Contact</Legend>
        <InputGroup label="Name / Nom:" labelFor="name" required>
          <Input
            ref={nameInput}
            required
            dataTestid="etransfer-name"
            name="etransfer-name"
            min="1"
            max="60"
            autocomplete="etransfer-name"
          />
        </InputGroup>
        <InputRow style={{ marginBottom: '5px' }}>
          <NotificationMethod
            label="Email / E-mail"
            labelFor="email"
            method="email"
            selectedMethod={notificationMethod}
            setNotificationMethod={setNotificationMethod}
          />
          <NotificationMethod
            label="SMS"
            labelFor="sms"
            method="sms"
            selectedMethod={notificationMethod}
            setNotificationMethod={setNotificationMethod}
          />
        </InputRow>
        {renderInput()}
      </Fieldset>
      <Fieldset>
        <Legend>Security Question / Question de Sécurité</Legend>
        <p style={{ fontSize: '.9em' }}>
          <i>
            Note that if you have direct deposit enabled, the money will
            automatically be deposited into your account. With that, we still
            require these fields at this time. 
            - Notez que si vous avez activé le dépôt direct, l'argent sera 
            automatiquement déposé dans votre compte. Ces champs sont 
            toujours obligatoires.
          </i>
        </p>
        <InputGroup
          label="Security Question / Question de Sécurité:"
          labelFor="security-question"
          required
          tooltip={
            <InfoTooltip content="Provide a short, simple question that is not easily looked up or guessed." />
          }
        >
          <Input
            ref={securityQuestionInput}
            required
            dataTestid="security-question"
            name="security-question"
            max="50"
            autocomplete="security-question"
          />
        </InputGroup>
        <InputGroup
          label="Security Answer / Réponse de Sécurité:"
          labelFor="security-answer"
          required
          tooltip={
            <InfoTooltip content="Must contain between 3 to 25 characters with no spaces." />
          }
        >
          <Input
            ref={securityAnswerInput}
            required
            dataTestid="security-answer"
            name="security-answer"
            pattern="[0-9A-Za-z]{3,25}"
            title="Must be alphanumeric and between 3 to 25 characters"
            autocomplete="security-answer"
          />
        </InputGroup>
      </Fieldset>
    </Form>
  );
};

EtransferCustomerForm.propTypes = {
  etransferConfig: PropTypes.string.isRequired,
  tokenCreateCallback: PropTypes.func.isRequired,
  tokenizationKey: PropTypes.string.isRequired,
  secondaryButtonFunc: PropTypes.func.isRequired,
};

export default EtransferCustomerForm;
