import gql from 'graphql-tag';

export default gql`
  query(
    $name: String
    $latestStatus: [String]
    $dateFrom: String
    $dateTo: String
    $amountFrom: String
    $amountTo: String
    $expirationDatetime: String
    $limit: Int
    $offset: Int
  ) {
    viewer {
      id
      company {
        id
      }
      transfersSearch(
        name: $name
        latestStatus: $latestStatus
        date_from: $dateFrom
        date_to: $dateTo
        amount_from: $amountFrom
        amount_to: $amountTo
        expirationDatetime: $expirationDatetime
        limit: $limit
        offset: $offset
      ) {
        count
        data {
          id
          name
          latestStatus
          insertedAt
          expirationDatetime
          amount
          recipientsCount
          currency
        }
      }
    }
  }
`;
