// Programs

// isVirtualProgram
// Returns a Boolean to describe if a program is virtual or not
export const isVirtualProgram = ({ program_type }) =>
  program_type.includes('virtual');

// Get country origin based on processor_name
// To do: Country can be more accurately determined by currency code, in the case which a single program supports multiple country
export const getProgramCountry = ({ processor_name }) => {
  const processorCountry = {
    galileo: 'USA',
    carta: 'CAN',
  };
  return processorCountry[processor_name];
};

export const getShippingMethods = ({ processor_name }) => {
  const processorShippingMethods = {
    galileo: '1 - USPS Domestic First Class Mail, 2 - UPS Domestic Ground, 3 - USPS Priority International, 4 - BULK Domestic Ground',
    carta: '3	- USPS Mail (5-7 business days), 4 - USPS International (7-21 business days), 8 -	UPS Worldwide Saver, 9 - UPS Standard (day definite by date scheduled delivery by end of day)',
  };

  return processorShippingMethods[processor_name];
}