import styled from 'styled-components';

export const TitleContainer = styled.div`
  margin-bottom: 10px;
`;

export const CardDetail = styled.div`
  display: flex;
  flex-direction: row;

  &:not(:first-child) {
    margin-top: 15px;
  }

  span {
    margin-left: 15px;
    font-weight: bold;
  }
`;
