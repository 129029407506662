import styled from 'styled-components';

export const AggreeBoxesContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 2em 0;
  label {
      margin-left: 0.5em;
  }
`;

export const FormRow = styled.div`
  display: flex;
  flex-direction: column;

  & > *:not(:last-child) {
    margin-bottom: 15px;
  }

  ${({ theme }) => theme.media.tabletPortraitUp`
    flex-direction: row;

    & > *:not(:last-child) {
      margin-bottom: 0;
      margin-right: 15px;
    }
  `}
`;