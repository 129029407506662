import React from 'react';
// Import Shared Components
import { capitalize } from 'shared-components/src/utils/string';
// Import Local Layouts
import ConfirmModalLayout from '../layouts/ConfirmModalLayout';

export default ({ status, ...props }) => {
  const markCardLostOrStolen =
    status.includes('lost') || status.includes('stolen');

  const confirmModalText = {
    markCardLostOrStolen: {
      alert: `Careful, this action will initiate a card ${status} process. You cannot undo this action.`,
      confirmText: `<p>Are you sure you want to mark this card as <b>${status}</b></p>`,
      buttonText: `Yes, Mark as ${capitalize(status)}`,
    },
    suspendOrUnsuspendCard: {
      confirmText: `<p>Are you sure you want to <b>${status}</b> this card?</p>`,
      buttonText: `Yes, ${capitalize(status)} Card`,
    },
  };

  return (
    <>
      {markCardLostOrStolen ? (
        <ConfirmModalLayout
          {...props}
          alert
          text={confirmModalText.markCardLostOrStolen}
        />
      ) : (
        <ConfirmModalLayout
          {...props}
          text={confirmModalText.suspendOrUnsuspendCard}
        />
      )}
    </>
  );
};
