import React from 'react';
// Import Component Styles
import { Footer, Copyright } from './styles';

export default () => {
  const currentYear = new Date().getFullYear();

  return (
    <Footer>
      <Copyright>&copy;{currentYear} Berkeley Payment Solutions Inc.</Copyright>
    </Footer>
  );
};
