import React from 'react';
// Import Shared Components
import { CSVButton } from 'shared-components';
// Import Component Styles
import { DownloadFile } from './styles';

export default () => {
  const timeNow = new Date().getTime();
  // List of fields needed to create a value load.
  // These will be the "headers" in the csv
  const csvHeaders = [['*Account ID', 'External Tag', '*Amount', 'Message']];

  return (
    <DownloadFile>
      <div>
        <p>
          To begin, please download the spreadsheet form and fill it out with
          the Value Load details.
        </p>
        <p>
          When complete, upload the file and your Value Unoad entries will show
          up below.
        </p>
      </div>
      <CSVButton
        type="primary"
        onClick={() => {}}
        data={csvHeaders}
        filename={`valueunload-form-${timeNow}`}
      >
        Download
      </CSVButton>
    </DownloadFile>
  );
};
