import React from 'react';
// Import Shared Components
import {
  ActionBar,
  Button,
  ButtonGroup,
  Alert,
  Modal,
} from 'shared-components';
// Import Component Styles
import { ConfirmBody } from './styles';

export default ({ onClick, closeModal, removeValue }) => (
  <Modal key="delete-modal">
    <Alert type="warn">Careful, this action cannot be undone.</Alert>
    <ConfirmBody>
      <p>Are you sure you want to delete</p>
      <p>
        <b>{removeValue}</b>?
      </p>
    </ConfirmBody>
    <ActionBar>
      <ButtonGroup reverse justifyContent="space-between">
        <Button type="danger" size="large" onClick={onClick}>
          Yes, Delete
        </Button>
        <Button onClick={closeModal} size="large">
          Cancel
        </Button>
      </ButtonGroup>
    </ActionBar>
  </Modal>
);
