import React from 'react';
// Import Shared Components
import { InputGroup } from 'shared-components';
// Import Global Components
import ModalForm from '../../../../../components/ModalForm';
// Import Validations
import {
  validateEmail,
  validateFirstName,
  validateLastName,
} from '../../validations';
// Import Utils
import { ROLE_MAP } from '../../../../../utils/roles';

export default ({
  closeModal,
  error,
  userInfo,
  setUserInfo,
  validationErrors,
  setValidationErrors,
  submit,
  title,
  page,
}) => (
  <ModalForm
    title={title}
    submit={e => {
      e.preventDefault();
      return submit();
    }}
    data-id={`${page}-form`}
    error={error}
    closeModal={closeModal}
  >
    <InputGroup
      required
      label="Email:"
      labelFor="email"
      dataId={`${page}-email-input`}
      value={userInfo.email}
      onChange={e =>
        setUserInfo({ key: 'email', value: e.target.value.trim() })}
      onBlur={() => validateEmail(setValidationErrors, userInfo)}
      error={validationErrors.email}
      type="email"
    />
    <InputGroup
      required
      label="First Name:"
      labelFor="firstName"
      dataId={`${page}-first-name-input`}
      value={userInfo.firstName}
      onChange={e =>
        setUserInfo({ key: 'firstName', value: e.target.value.trim() })}
      onBlur={() => validateFirstName(setValidationErrors, userInfo)}
      error={validationErrors.firstName}
    />
    <InputGroup
      required
      label="Last Name:"
      labelFor="lastName"
      dataId={`${page}-last-name-input`}
      value={userInfo.lastName}
      onChange={e =>
        setUserInfo({ key: 'lastName', value: e.target.value.trim() })}
      onBlur={() => validateLastName(setValidationErrors, userInfo)}
      error={validationErrors.lastName}
    />
    <InputGroup
      required
      dropdown
      label="Role:"
      labelFor="role"
      dataId={`${page}-role-dropdown`}
      selected={userInfo.role}
      options={ROLE_MAP}
      updateItem={value => setUserInfo({ key: 'role', value })}
    />
  </ModalForm>
);
