import React from 'react';
// Import Shared Components
import { Tag } from 'shared-components';
import { humanizeString } from 'shared-components/src/utils/string';

export default ({ status }) => {
  const formattedStatus = humanizeString(status);
  switch (status) {
  case 'active':
    return <Tag type="success">{formattedStatus}</Tag>;
  case 'onboarding':
    return <Tag type="processing">{formattedStatus}</Tag>;
  default:
    return <Tag type="processing">{formattedStatus}</Tag>;
  }
};
