export default [
  {
    Header: 'Proxy',
    accessor: 'id',
    id: 'id',
  },
  {
    Header: 'Message',
    accessor: 'message',
    id: 'message',
  },
  {
    Header: 'External Tag',
    accessor: 'external_tag',
    id: 'external_tag',
  },
];

