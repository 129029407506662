import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
// Import Shared Components
import { Input } from 'shared-components';
// Import Shared Utils
import { createTokenV1 } from 'shared-components/src/utils/tokenization';
// Import Global Components
import Form from '../../../../../../components/Form';
import Legend from '../../../../../../components/Legend';
// Import Global Layouts
import InputRow from '../../../../../../layouts/InputRow';
import InputGroup from '../../../../../../layouts/InputGroup';
import Fieldset from '../../../../../../layouts/Fieldset';

const BankDetailsForm = ({
  tokenCreateCallback,
  tokenizationKey,
  secondaryButtonFunc,
}) => {
  const accountNumberInput = useRef(null);
  const accountHolderNameInput = useRef(null);
  const transitNumberInput = useRef(null);
  const institutionNumberInput = useRef(null);
  const currencyInput = useRef(null);
  const countryInput = useRef(null);

  const [tokenError, setTokenError] = useState(null);
  // Component States
  const [loading, setLoading] = useState(false);

  const handleSubmit = async e => {
    e.preventDefault();
    setLoading(true);
    setTokenError(null);

    const body = {
      bank_account: {
        account_number: accountNumberInput.current.value,
        account_holder_name: accountHolderNameInput.current.value,
        transit_number: transitNumberInput.current.value,
        institution_number: institutionNumberInput.current.value,
        currency: currencyInput.current.value,
        country: countryInput.current.value,
      },
    };

    try {
      const tokenDetails = await createTokenV1(body, tokenizationKey);

      tokenCreateCallback(tokenDetails);
    } catch (e) {
      setTokenError(e.message);
      setLoading(false);
    }
  };

  return (
    <Form
      dataId="bank-details-form"
      submit={handleSubmit}
      submitButtonText="Redeem"
      secondaryButtonFunc={secondaryButtonFunc}
      secondaryButtonText="Back"
      error={tokenError}
      title="Redeem with Bank Transfer"
      loading={loading}
    >
      <Fieldset>
        <Legend>Banking Details</Legend>
        <InputGroup
          label="Account Holder Name:"
          labelFor="bank-account-name"
          required
        >
          <Input
            ref={accountHolderNameInput}
            required
            dataTestid="bank-account-name"
            name="bank-account-name"
            min="3"
            max="70"
            autocomplete="bank-account-name"
          />
        </InputGroup>
        <InputGroup
          label="Account Number:"
          labelFor="bank-account-number"
          required
        >
          <Input
            ref={accountNumberInput}
            required
            dataTestid="bank-account-number"
            name="bank-account-number"
            min="7"
            max="12"
            autocomplete="bank-account-number"
          />
        </InputGroup>
        <InputGroup label="Transit Number:" labelFor="transit-number" required>
          <Input
            ref={transitNumberInput}
            required
            dataTestid="transit-number"
            name="transit-number"
            min="5"
            max="5"
            autocomplete="transit-number"
          />
        </InputGroup>
        <InputGroup
          label="Institution Number:"
          labelFor="institution-number"
          required
        >
          <Input
            ref={institutionNumberInput}
            required
            dataTestid="institution-number"
            name="institution-number"
            min="3"
            max="3"
            autocomplete="institution-number"
          />
        </InputGroup>
        <InputRow style={{ marginBottom: '5px' }}>
          <InputGroup label="Currency:" labelFor="currency" required>
            <Input
              as="select"
              ref={currencyInput}
              required
              dataTestid="currency"
              name="currency"
              autocomplete="currency"
            >
              {[
                <option key="currency-option-1" disabled selected value="">
                  {' '}
                  -- select an option --{' '}
                </option>,
                <option key="currency-option-2" value="CAD">
                  CAD
                </option>,
              ]}
            </Input>
          </InputGroup>
          <InputGroup label="Country:" labelFor="country" required>
            <Input
              as="select"
              ref={countryInput}
              required
              dataTestid="country"
              name="country"
              autocomplete="country"
            >
              {[
                <option key="country-option-1" disabled selected value="">
                  {' '}
                  -- select an option --{' '}
                </option>,
                <option key="country-option-1" value="CAN">
                  CAN
                </option>,
              ]}
            </Input>
          </InputGroup>
        </InputRow>
      </Fieldset>
    </Form>
  );
};

BankDetailsForm.propTypes = {
  tokenCreateCallback: PropTypes.func.isRequired,
  tokenizationKey: PropTypes.string.isRequired,
  secondaryButtonFunc: PropTypes.func.isRequired,
};

export default BankDetailsForm;
