// Tokenization Function
export const postTellerApi = async (url, body, key) => {
  const options = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${key}`,
    },
    body: JSON.stringify(body),
  };

  try {
    const res = await fetch(url, options);
    const body = await res.json();

    if (body.error) {
      if (body.error.message) throw new Error(body.error.message);
      throw new Error('Failed to send request.');
    }

    return body.data;
  } catch (e) {
    throw new Error(e.message);
  }
};

// eslint-disable-next-line
const paymentAccountValidation = async (body, key) => {
  if (body.cvv)
    return postTellerApi('/api/v2/payment_account_validation', body, key);
};

export default async (cardDetails, apiKey) => {
  const url = '/api/v1/tokens';
  const options = {
    method: 'POST',
    body: JSON.stringify(cardDetails),
    headers: {
      authorization: `Bearer ${apiKey}`,
      'content-type': 'application/json',
    },
  };

  try {
    const res = await fetch(url, options);
    const body = await res.json();
    if (body.error) throw new Error(body.error.message);
    return body.data;
  } catch (e) {
    throw new Error(e.message);
  }
};

export const createTokenV2 = async (params, key) => {
  try {
    const data = await postTellerApi('/api/v2/tokens', params, key);

    const { token } = data;
    const { cvv } = params.card;
    try {
      await Promise.all([
        // If card tokenizes and cvv details are present in params we should run a payment account validation call
        paymentAccountValidation({ token, cvv }, key),
        postTellerApi('/api/v2/funds_transfer_inquiry', { token }, key),
        postTellerApi('/api/v2/watchlist_inquiry', { token }, key),
      ]);
    } catch (e) {
      throw new Error(e.message);
    }

    return data;
  } catch (e) {
    throw new Error(e.message);
  }
};

export const createTokenV1 = async (params, key) => {
  try {
    const data = await postTellerApi('/api/v1/tokens', params, key);

    return data;
  } catch (e) {
    throw new Error(e.message);
  }
};
