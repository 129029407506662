import gql from 'graphql-tag';

export default gql`
  query {
    viewer {
      id
      company {
        id
        name
        email
        latestStatus
        inserted_at
      }
    }
  }
`;
