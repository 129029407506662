import styled from 'styled-components';

export const HeaderSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  white-space: nowrap;

  &:not(:last-child) {
    margin-bottom: 15px;
  }

  & > :not(label) {
    max-width: 60%;
    margin: 0;
    margin-top: 5px;
  }

  ${({ theme }) => theme.media.tabletLandscapeUp`
    ${({ spaceOut }) => spaceOut && 'width: calc(30%)'};
    align-items: ${({ alignItems }) => alignItems || 'flex-start'};
    justify-content: space-between;

    &:not(:last-child) {
      margin-bottom: 0;
    }

    & > :not(label) {
      float: none;
      margin-right: 0;
      max-width: 100%;
    }
  `}
`;
