import { withHandlers } from 'recompose';
// Import Utils
import {
  isNameValid,
  isEmailValid,
  isPhoneNumberValid,
} from '../../../../utils/validations';
import { mutationErrorMessageParser } from '../../../../utils/errorLogger';
import { parse } from '../../../../utils/csv';

export default withHandlers({
  handlePageSizeChange: ({ updatePagination, updateError, updateSuccess }) => (
    pageSize,
    pageIndex
  ) => {
    updateSuccess(null);
    updateError(null);
    updatePagination({ limit: pageSize, offset: pageIndex * pageSize });
  },
  handlePageChange: ({
    updatePage,
    updatePagination,
    pagination,
    updateSuccess,
    updateError,
  }) => pageIndex => {
    updateSuccess(null);
    updateError(null);
    updatePage(pageIndex);
    updatePagination({ ...pagination, offset: pageIndex * pagination.limit });
  },
  toggleExpand: ({ updateExpand }) => (newExpanded, index) => {
    newExpanded[index[0]] === false
      ? updateExpand({})
      : updateExpand({ [index]: true });
  },
  openFirstExpandAndResetPagination: ({
    updatePage,
    updatePagination,
    updateExpand,
  }) => () => {
    updateExpand({ '0': true });
    updatePage(0);
    updatePagination({ limit: 10, offset: 0});
  },
  toggleCreateAccountHolderModal: ({
    createAccountHolderModal,
    updateCreateAccountHolderModal,
  }) => e => {
    e && e.preventDefault();
    updateCreateAccountHolderModal(!createAccountHolderModal);
  },
  closeEditAccountHolderModal: ({
    editAccountHolderModal,
    updateEditAccountHolderModal,
  }) => () => {
    updateEditAccountHolderModal(!editAccountHolderModal);
  },
  openEditAccountHolderModal: ({
    editAccountHolderModal,
    updateEditAccountHolderModal,
    updateEditAccountHolderState,
  }) => value => {
    updateEditAccountHolderState(value);
    updateEditAccountHolderModal(!editAccountHolderModal);
  },
  toggleUploadAccountHoldersModal: ({
    uploadAccountHoldersModal,
    updateUploadAccountHoldersModal,
  }) => () => {
    updateUploadAccountHoldersModal(!uploadAccountHoldersModal);
  },
  onCreateAccountHolder: ({
    createAccountHolder,
    updateError,
    updateSuccess,
  }) => ({ email, firstName, lastName, type, phone }) => {
    createAccountHolder({
      firstName,
      lastName,
      type,
      email,
      phone,
    })
      .then(() => updateSuccess('Account Holder was created successfully'))
      .catch(err => {
        const message = mutationErrorMessageParser(
          err,
          'Account Holder could not be created.'
        );
        updateError(message);
        updateSuccess(null);
      });
  },
  onEditAccountHolder: ({
    updateAccountHolder,
    updateError,
    updateSuccess,
  }) => ({ id, type, email, firstName, lastName, phone }) => {
    updateAccountHolder({
      id,
      type,
      firstName,
      lastName,
      email,
      phone,
    })
      .then(() => updateSuccess('Account Holder was updated successfully'))
      .catch(err => {
        const message = mutationErrorMessageParser(
          err,
          'Account Holder could not be updated.'
        );
        updateError(message);
        updateSuccess(null);
      });
  },
  openConfirmDeleteModal: ({
    confirmDeleteModal,
    updateConfirmDeleteModal,
    updateRemoveAccountHolderInfo,
  }) => value => () => {
    updateRemoveAccountHolderInfo(value);
    updateConfirmDeleteModal(!confirmDeleteModal);
  },
  closeConfirmDeleteModal: ({
    confirmDeleteModal,
    updateConfirmDeleteModal,
    updateRemoveAccountHolderInfo,
  }) => e => {
    e && e.preventDefault();
    updateConfirmDeleteModal(!confirmDeleteModal);
    updateRemoveAccountHolderInfo({});
  },
  onRemoveAccountHolder: ({
    removeAccountHolder,
    updateError,
    updateSuccess,
    updateConfirmDeleteModal,
    removeAccountHolderInfo,
    updateRemoveAccountHolderInfo,
  }) => () => {
    removeAccountHolder({ id: parseInt(removeAccountHolderInfo.id, 10) })
      .then(() => updateSuccess('Account Holder was removed successfully'))
      .then(updateConfirmDeleteModal(false))
      .then(updateRemoveAccountHolderInfo({}))
      .catch(err => {
        const message = mutationErrorMessageParser(
          err,
          'Account Holder was not removed.'
        );
        updateError(message);
        updateSuccess(null);
      });
  },
  uploadCsv: ({
    history,
    updateError,
    uploadAccountHolderState,
    updateUploadAccountHolderState,
    updateSuccess,
  }) => async e => {
    // Get file and clear file input
    const file = e.target.files[0];
    e.target.value = null;

    // Clear any general error
    updateError(false);

    try {
      const rows = await parse(file);
      const data = parseAccountHolderRows(rows.data.slice(1));

      updateSuccess('CSV has been validated successfully. Please review your submission and click Confirm to begin processing.');

      history.push({
        pathname: '/direct-send/account-holders/upload',
        state: { data },
      });
    } catch (err) {
      updateError(
        `Failed to validate file! \n ${err.message} \n Please correct file and try again`
      );
      updateSuccess(null);
    }
  },
});

// Function to parse row of Account Holder CSV
// returns null if row doesn't contain correct data or invalid data fields
// these rows will be filtered out later
function parseAccountHolderRows(rows) {
  return rows.map((row, idx) => {
    if (row.length !== 5) {
      throw new Error(`Column missing data. Row: ${++idx}.`);
    }

    // Validate that account holder type is there and has correct value
    const type = row[0];
    if (!(type && ['individual', 'business'].includes(type))) {
      throw new Error(
        `Received unrecognized account holder type: ${type ||
          '[blank]'}, Row: ${++idx}, column: 1. Recognized types are "business" or "individual".`
      );
    }

    // Validate that all account holders have a valid first name
    const firstName = row[1];
    if (!(firstName && isNameValid(firstName))) {
      throw new Error(
        `Missing or invalid first name: ${firstName ||
          '[blank]'}. Row: ${++idx}, column: 2.`
      );
    }

    // Validate that all account holders have a valid last name
    // This is an optional field
    const lastName = row[2];
    if (row[0] === 'individual' && !isNameValid(lastName)) {
      throw new Error(
        `Missing or invalid last name: ${lastName ||
          '[blank]'}. Row: ${++idx}, column: 3.`
      );
    }

    // Validate that all account holders have a valid email provided
    const email = row[3];
    if (!(email && isEmailValid(email))) {
      throw new Error(
        `Missing or invalid email: ${email ||
          '[blank]'}. Row: ${++idx}, column: 4.`
      );
    }

    const phone = row[4].replace(/-/gi, '');
    if (!(phone && isPhoneNumberValid(phone))) {
      throw new Error(
        `Missing or invalid phone number: ${phone ||
          '[blank]'}. Row: ${++idx}, column: 5.`
      );
    }

    // Now we can map depending on the different types
    if (type === 'individual') {
      return {
        type,
        firstName: firstName.trim(),
        lastName: (row[2] && row[2].trim()) || '   ',
        email: email.trim(),
        phone: (row[4] && row[4].trim()) || null,
      };
    }
    return {
      type,
      firstName: firstName.trim(),
      lastName: '   ',
      email: email.trim(),
      phone: (row[4] && row[4].trim()) || null,
    };
  });
}
