import React, {useState} from 'react';
import {Copy} from 'react-feather';
import {Tooltip} from 'react-tippy';
import {StyledCopyButton} from './styles';

export default ({
  value,
  noCopyValue = '',
  ...props
}) => {
  const [clicked, setClicked] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  return (
    <StyledCopyButton>
      <Tooltip
        html={<>{isCopied ? 'Copied!' : 'Copy'}</>}
        theme="light"
        style={{
          height: '16px',
          color: '#5D6F84',
          cursor: 'pointer',
        }}
        arrow="true"
      >
        <Copy
          onMouseDown={() => setClicked(true)}
          onMouseUp={() => setClicked(false)}
          onMouseLeave={() => setIsCopied(false)}
          className={clicked ? 'button-clicked' : 'button'}
          onClick={() => {
            if (value === noCopyValue) {
              return;
            }
            navigator.clipboard.writeText(value);
            setIsCopied(true);
          }}
          {...props}
        />
      </Tooltip>
    </StyledCopyButton>
  );
};