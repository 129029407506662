import React, { useContext } from 'react';
// Import Shared Components
import { Modal } from 'shared-components';
// Import Global Components
import CardDetailsFormV2 from '../../../../../../../components/CardDetailsForm/CardDetailsFormV2';
// Import Common Utils
import { mutationErrorMessageParser } from '../../../../../../../utils/errorLogger';
import { CompanyContext } from '../../../../../../../context/CompanyContext';
import { companyCountries } from 'shared-components/src/utils/company';

export default ({
  closeModal,
  tokenizationKey,
  createAccountHolderFundingSource,
  accountHolderId,
  updateError,
  updateSuccess,
  ...props
}) => {
  const { country } = useContext(CompanyContext);

  const tokenCreateCallback = tokenDetails => {
    return createAccountHolderFundingSource({
      token: tokenDetails.token,
      id: accountHolderId,
    })
      .then(() => updateSuccess('Payment card has been added successfully.'))
      .then(closeModal())
      .catch(err => {
        const message = mutationErrorMessageParser(
          err,
          'Payment Card could not be added. \n Please update your card details and try again.'
        );
        updateError(message);
        updateSuccess(null);
        closeModal();
      });
  };
  return (
    <Modal key="createAccountModal">
      <CardDetailsFormV2
        title="Add New Card"
        primaryBtnTitle="Save"
        tokenCreateCallback={tokenCreateCallback}
        tokenizationKey={tokenizationKey}
        secondaryButtonText="Close"
        secondaryBtnFunc={closeModal}
        isCanadian={country === companyCountries.CANADA}
        {...props}
      />
    </Modal>
  );
};
