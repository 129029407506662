import React, { useEffect, useState } from 'react';
// Import Shared Components
import {
  Title,
  Card,
  CardGroup,
  Details,
  DetailsGroup,
  Loader,
} from 'shared-components';
// Import Util
import { get } from '../../../utils/http';
// Import Hook
import { useTimeZone } from '../../../hooks/useTimeZone';
// Import Global Components
import ClientErrorComponent from '../../../components/ClientErrorComponent';
// Import Local Misc
import {
  getKYCResultColumns,
  CLIENTINFORMATIONCOLUMNS,
} from './components/kycResultColumns';



export default ({
  match: {
    params: { id: kycResultsID },
  },
}) => {

  const [kycResults, setKycResults] = useState(null);
  const [isError, setError] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [timeZone] = useTimeZone();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      try {
        const kycResultsData = await get(
          `/api/v1/client_portal/card_issuing/kyc_results/${kycResultsID}`
        );
        
        setKycResults(kycResultsData);
        setLoading(false);

      } catch (e) {
        setError(true);
        setLoading(false);
      }
    };

    fetchData();
  }, [kycResultsID]);

  if (isLoading) return <Loader />;
  if (isError) return <ClientErrorComponent data={{}} />;
  return(  
    <>
      <CardGroup>
        <Card>
          <Title>Result</Title>
          <DetailsGroup>
            <Details
              key="kyc-result-profile"
              columns={getKYCResultColumns(timeZone)}
              data={kycResults.result}
            />
          </DetailsGroup>
        </Card>
        <Card>
          <Title>Client Information</Title>
          <DetailsGroup>
            <Details
              key="kyc-client-info"
              columns={CLIENTINFORMATIONCOLUMNS}
              data={kycResults.client_information}
            />
          </DetailsGroup>
        </Card>
      </CardGroup>
    </>
  );


};
