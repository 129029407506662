import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Title, Table, Card } from 'shared-components';
// Import Hook
import { useTimeZone } from '../../../../hooks/useTimeZone';
import useTablePagination from '../../../../hooks/useTablePagination';
// Import Utils
import { get } from '../../../../utils/http';
import { getKYCResultsColumns } from './cardholderColumns';

const KYCTable = ({ history }) => {
  const {
    pagination,
    handlePageChange,
    handlePageSizeChange,
  } = useTablePagination();
  const [timeZone] = useTimeZone();
  const [kycResults, setKycResults] = useState({ count: 0, data: [] });

  useEffect(() => {
    const fetchKYC = async () => {
      const query = new URLSearchParams({
        ...pagination,
      }).toString();
      const kycResultsData = await get(
        `/api/v1/client_portal/card_issuing/kyc_results?${query}`
      );
      setKycResults(kycResultsData);
    };
    fetchKYC();
  }, [pagination]);
  return (
    <Card>
      <Title>KYC Results</Title>

      <Table
        columns={getKYCResultsColumns(timeZone)}
        data={kycResults.data}
        noDataText="Currently there are no KYC results"
        /* eslint-disable no-unused-vars */
        getTrProps={(state, rowInfo, column) => {
          return {
            style: {
              cursor: 'pointer',
            },
            className: 'hoverable',
            onClick: (e, handleOriginal) => {
              history.push(`/card-issuing/kyc_results/${rowInfo.original.id}`);
            },
          };
        }}
        /* eslint-enable no-unused-vars */
        showPagination={kycResults.count > pagination.limit}
        pages={Math.ceil(kycResults.count / pagination.limit)}
        page={pagination.page}
        manual
        onPageSizeChange={handlePageSizeChange}
        onPageChange={handlePageChange}
        sortable={false}
        defaultSorted={[{ id: 'id', desc: true }]}
      />
    </Card>
  );
};

KYCTable.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
};

export default KYCTable;
