import React from 'react';
// Import Shared Components
import { Tag } from 'shared-components';
import { humanizeString } from 'shared-components/src/utils/string';

// Statuses to change once Teller is updated with correct status for bulk transfers
export default ({ status }) => {
  const formattedStatus = humanizeString(status);
  switch (status) {
  case 'successful':
    return <Tag type="success">{formattedStatus}</Tag>;
  case 'processing_funding_transaction':
    return <Tag type="processing">{formattedStatus}</Tag>;
  case 'funding_transaction_failed':
    return <Tag type="danger">{formattedStatus}</Tag>;
  case 'in_progress':
    return <Tag type="processing">{formattedStatus}</Tag>;
  case 'completed_with_errors':
    return <Tag type="warning">{formattedStatus}</Tag>;
  case 'failed':
    return <Tag type="danger">{formattedStatus}</Tag>;
  case 'cancelling':
    return <Tag type="warning">{formattedStatus}</Tag>;
  case 'canceled':
    return <Tag type="danger">{formattedStatus}</Tag>;
  default:
    return null;
  }
};
