import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
// Import Shared Components
import { ErrorBoundary, CookieConsent } from 'shared-components';
// Import Contexts
import { RoleProvider } from '../../context/RoleContext';
import { ServiceConfigProvider } from '../../context/ServiceConfigContext';
import { ProgramConfigurationProvider } from '../../context/ProgramConfigContext';
// Import Local components
import Header from '../components/Header';
import Sidebar from '../Sidebar';
// Import Component Styles
import { PrivateBody, PrivateMain, PrivateBase } from './styles';

// Import Error Logger
import { errorLogger } from '../../utils/errorLogger';
import { CompanyProvider } from '../../context/CompanyContext';

export default ({ children, history, pageTitle }) => {
  const intl = useIntl();
  const [pathname, updatePathname] = useState(window.location.pathname);

  const location = window.location.pathname;

  useEffect(() => {
    if (pathname !== location) return updatePathname(location);
  }, [location, pathname]);

  return (
    <PrivateBase>
      <ServiceConfigProvider>
          <ProgramConfigurationProvider>
            <RoleProvider>
              <CompanyProvider>
                <Header key="private-header" pageTitle={pageTitle} />
                <PrivateBody>
                  <Sidebar
                    pathname={pathname}
                    updatePathname={updatePathname}
                    history={history}
                  />
                  <PrivateMain>
                    <ErrorBoundary errorLogger={errorLogger}>
                      {children}
                    </ErrorBoundary>
                  </PrivateMain>
                </PrivateBody>
                <CookieConsent messages={intl.messages}/>
              </CompanyProvider>
            </RoleProvider>
          </ProgramConfigurationProvider>
      </ServiceConfigProvider>
    </PrivateBase>
  );
};
