export const getKeyData = (apiKeyArray, type) => {
  return apiKeyArray.filter(
    key => key.type === type && key.latestStatus === 'active'
  );
};

export const tokenKeyInfo =
  'This key can be used in your public code such as client side applications (web pages and mobile phones) and can only be used with the tokenization endpoint in order to create a token for a payment card or bank account.';

export const secretKeyInfo =
  'This key should not be placed in publicly accessible areas as it contains many privileges and allows access to all endpoints and services.';
