import React, { useState } from 'react';
// Import Shared Components
import {
  Card,
  CardGroup,
  Title,
  Link,
  Alert,
  CSVButton,
  Loader,
  ClientErrorComponent,
} from 'shared-components';
import { formatCurrencyCSV } from 'shared-components/src/utils/formatCurrency';
import { formatTime } from 'shared-components/src/utils/formatDateTime';
// Import Hooks
import useTellerApi from '../../../hooks/useTellerApi';
import { useTimeZone } from '../../../hooks/useTimeZone';
// Import Global Components
import Table from '../../../components/Table';
import CollectionStatus from './components/CollectionStatus';
// Import Local Components
// import SearchFilter from './components/SearchFilter';
import Search from './components/Search';
// Import Component Styles
import { Header, TableHeader } from './styles';
// Import Utils
import { getCollectionsCsvData } from '../../../utils/csv';

const Collections = () => {
  const [pagination, setPagination] = useState({
    limit: 10,
    offset: 0,
    page: 0,
  });

  const [
    {
      data: { count, data: collections },
      isLoading,
      isError,
    },
    setUrl,
  ] = useTellerApi('/api/v1/client_portal/collections', {
    count: 0,
    data: [],
    limit: pagination.limit,
    offset: pagination.offset,
  });

  const [error] = useState(false);
  const [searchBy, setSearchBy] = useState('');

  const [searchParams, setSearchParams] = useState({
    external_tag: '',
    status: '',
    first_name: '',
    last_name: '',
  });
  const [timeZone] = useTimeZone();

  const handlePageSizeChange = (pageSize, pageIndex) => {
    setPagination({
      ...pagination,
      limit: pageSize,
      offset: pageIndex * pageSize,
    });

    const params = new URLSearchParams({
      ...searchParams,
      ...pagination,
      limit: pageSize,
      offset: pageIndex * pageSize,
    }).toString();
    setUrl(`/api/v1/client_portal/collections?${params}`);
  };

  const handlePageChange = pageIndex => {
    setPagination({
      ...pagination,
      page: pageIndex,
      offset: pageIndex * pagination.limit,
    });
    const params = new URLSearchParams({
      ...searchParams,
      ...pagination,
      page: pageIndex,
      offset: pageIndex * pagination.limit,
    }).toString();
    setUrl(`/api/v1/client_portal/collections?${params}`);
  };

  const updateSearchParamField = (field, value) => {
    setSearchParams({
      ...searchParams,
      status: '',
      amount: '',
      name: '',
      external_tag: '',
      [field]: value,
    });
  };

  const searchCollections = () => {
    const params = new URLSearchParams({
      ...searchParams,
      ...pagination,
    }).toString();

    setUrl(`/api/v1/client_portal/collections?${params}`);
  };

  // Define transaction header labels to be used when converting data of type
  // Object to CSV.
  const headers = [
    { label: 'Date', key: 'date' },
    { label: 'Name', key: 'name' },
    { label: 'Amount Requested', key: 'amount' },
    { label: 'Amount Collected', key: 'transaction_amount'},
    { label: 'External Tag', key: 'external_tag' },
    { label: 'Expiry', key: 'expiry' },
    { label: 'Status', key: 'status' },
  ];

  // Define Table columns
  const columns = [
    {
      Header: 'ID',
      accessor: 'id',
      show: false,
    },
    {
      Header: 'Date',
      accessor: 'created_at',
      Cell: t => formatTime(t.value, 'dateTime', timeZone),
    },
    {
      Header: 'Name',
      accessor: 'name',
      Cell: t => (
        <Link
          to={`/collections/collections/${t.original.id}`}
        >{`${t.original.first_name} ${t.original.last_name}`}
        </Link>
      ),
    },
    {
      Header: 'External Tag',
      accessor: 'external_tag',
      Cell: ({ value }) => value,
    },
    {
      Header: 'Amount Requested',
      accessor: 'amount',
      Cell: t => formatCurrencyCSV(t.value / 100, t.original.currency),
    },
    {
      Header: 'Amount Collected',
      accessor: 'transaction_amount',
      Cell: t => formatCurrencyCSV(t.value / 100, t.original.currency),
    },
    {
      Header: 'Expiry',
      accessor: 'expiry',
      Cell: ({ value }) => formatTime(value, 'date', timeZone),
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: t => <CollectionStatus status={t.value} />,
    },
  ];

  if (isLoading) {
    return (
      <Card>
        <Loader />
      </Card>
    );
  }

  if (isError) {
    return (
      <Card>
        <ClientErrorComponent />
      </Card>
    );
  }

  return (
    <>
      {error && <Alert type="danger">{error}</Alert>}
      <CardGroup>
        <Header>
          <Card>
            <Search
              setSearchBy={setSearchBy}
              searchBy={searchBy}
              searchParams={searchParams}
              updateSearchParamField={updateSearchParamField}
              searchHook={searchCollections}
            />
          </Card>
        </Header>
        <Card>
          <TableHeader>
            <Title>Collections History</Title>
            <CSVButton
              data={getCollectionsCsvData(collections, timeZone)}
              headers={headers}
              filename={`collection-history-${new Date().toLocaleDateString()}`}
            >
              Export to CSV
            </CSVButton>
          </TableHeader>
          <Table
            columns={columns}
            data={collections}
            defaultSorted={[
              {
                id: 'created_at',
                asc: false,
              },
            ]}
            noDataText="No Collections found"
            showPagination={count > pagination.limit && true}
            pages={Math.ceil(count / pagination.limit)}
            page={pagination.page}
            manual
            onPageSizeChange={(pageSize, pageIndex) =>
              handlePageSizeChange(pageSize, pageIndex)}
            onPageChange={pageIndex => handlePageChange(pageIndex)}
          />
        </Card>
      </CardGroup>
    </>
  );
};

export default Collections;
