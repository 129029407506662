export default [
  { key: 'initiated', val: 'Initiated' },
  { key: 'email_sent', val: 'Email Sent' },
  { key: 'email_failed', val: 'Email Failed' },
  { key: 'paid', val: 'Paid' },
  { key: 'settled', val: 'Settled' },
  { key: 'declined', val: 'Declined' },
  { key: 'void', val: 'Void' },
  { key: 'returned', val: 'Returned' },
  { key: 'cancelled', val: 'Cancelled' },
  { key: 'expired', val: 'Expired' },
];
