import React from 'react';
import { removeSnakeCase } from 'shared-components/src/utils/string';

export default [
  {
    Header: 'Field',
    accessor: 'position',
    Cell: ({ value }) =>
      value.length > 1
        ? removeSnakeCase(value.join(': '))
        : removeSnakeCase(value[0]),
  },
  {
    Header: 'Message',
    accessor: 'message',
  },
  {
    Header: 'Invalid Elements',
    accessor: 'invalid_elements',
    Cell: props => (props.value ? props.value.join(' ') : 'n/a'),
  },
  {
    Header: 'Errored Value Passed',
    accessor: 'value',
    Cell: ({ value }) => (value === '' ? <i>empty</i> : value),
  },
];
