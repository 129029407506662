/**
 * This file is solely here to allow for Hot Module Reloading.
 * Don't consolidate it into index.js
 */

import React, { useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import { ApolloProvider } from 'react-apollo';
import { ApolloProvider as ApolloHooks } from '@apollo/react-hooks';
// Import Shared Components
import { ThemeProvider, ClientErrorComponent } from 'shared-components';
import { initializeGlobalErrorHandler } from 'shared-components/src/utils/errors';
import { PRODUCTION } from 'shared-components/src/constants/nodeEnv';
import useWhiteLabeling from 'shared-components/src/hooks/useWhiteLabeling';

import client from './state/apolloClient';
import TRANSLATIONS from './i18n/translations';

// Import Root Component
import Root from './routes/Root';

// Error Logging
import { errorLogger } from './utils/errorLogger';

// Time Zone Context
import { TimeZoneProvider } from './context/TimeZoneContext';

// Attach Error Logger to Window to Catch Global Errors
if (process.env.NODE_ENV === PRODUCTION) {
  initializeGlobalErrorHandler(errorLogger);
}

export default () => {
  const [locale, setLocale] = useState('en');

  // Retrieve white labeling for app
  const { loading, theme, localization, error } = useWhiteLabeling(
    'client-portal'
  );

  if (loading) return <div />;

  if (error) return <ClientErrorComponent />;

  return (
    <IntlProvider
      locale={locale}
      key={locale}
      defaultLocale="en"
      messages={TRANSLATIONS[locale]}
    >
      <ApolloProvider client={client}>
        <ApolloHooks client={client}>
          <BrowserRouter>
            <ThemeProvider theme={theme}>
              <TimeZoneProvider>
                <Root
                  setLocale={setLocale}
                  locale={locale}
                  localization={localization}
                />
              </TimeZoneProvider>
            </ThemeProvider>
          </BrowserRouter>
        </ApolloHooks>
      </ApolloProvider>
    </IntlProvider>
  );
};
