import styled from 'styled-components';

export const DashboardGroup = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  & > * {
    width: 100%;
  }

  & > *:not(:first-child) {
    margin-top: 15px;
  }

  ${({ theme }) => theme.media.desktopUp`
    flex-direction: row;
    align-content: stretch;

    & > *:not(:first-child) {
      margin-top: 0;
      margin-left: 15px;
    }
  `}
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
`;
