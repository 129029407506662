import React from 'react';
// Import Shared Components
import { Alert, Title } from 'shared-components';

const TransactionNotFound = ({ error }) => (
  <div>
    <Title>Payment Error</Title>
    <Alert type="danger">{error}</Alert>
  </div>
);

export default TransactionNotFound;
