import React from 'react';
// Import Shared Components
import { Search, Button } from 'shared-components';

import SEARCHBY from '../searchby';
import STATUSES from '../statusOptions';
// Import Component Styles
import { SearchContainer, FilterButtonGroup } from './styles';

export default ({
  updateSearchInput,
  updateSearchStatus,
  updateSearchByState,
  searchTransactions,
  searchBy,
  resetSearch,
  showResetButton,
  params: { name, status, email },
}) => (
  <SearchContainer>
    <Search
      searchOptions={SEARCHBY}
      updateSearchBy={updateSearchByState}
      value={searchBy === 'name' ? name : email}
      searchBy={searchBy}
      onChange={updateSearchInput}
      onClick={searchTransactions}
      options={STATUSES}
      updateItem={updateSearchStatus}
      selected={searchBy === 'status' && status}
      dropdown={searchBy === 'status'}
    />
    {showResetButton && (
      <FilterButtonGroup>
        <Button
          variant="text"
          type="primary"
          size="small"
          onClick={resetSearch}
        >
          Reset
        </Button>
      </FilterButtonGroup>
    )}
  </SearchContainer>
);
