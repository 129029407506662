import React from 'react';
// Import Shared Components
import { Search } from 'shared-components';

import SEARCHBY from '../searchby';
import STATUSES from '../statusOptions';
// Import Component Styles
import { SearchContainer } from './styles';

export default ({
  setSearchBy,
  searchBy,
  searchParams,
  updateSearchParamField,
  searchHook,
}) => {
  const options = {
    name: [],
    externalTag: [],
    status: STATUSES,
  };

  return (
    <SearchContainer>
      <Search
        searchOptions={SEARCHBY}
        updateSearchBy={setSearchBy}
        searchBy={searchBy}
        value={searchParams[searchBy]}
        onChange={e => updateSearchParamField(searchBy, e.target.value)}
        onClick={searchHook}
        options={options[searchBy]}
        updateItem={value => updateSearchParamField(searchBy, value)}
        selected={searchParams[searchBy]}
        dropdown={searchBy === 'status'}
      />
    </SearchContainer>
  );
};
