import React, { useState } from 'react';
// Import Shared Components
import { Modal } from 'shared-components';
// Import Local Components
import ModalForm from '../ModalForm';
// Import Utils
import { post } from '../../../../../utils/http';

export default ({
  closeModal,
  setError,
  setSuccess,
  userInfo,
  setUserInfo,
  validationErrors,
  setValidationErrors,
  refetchUsers,
}) => {
  const [formError, setFormError] = useState(null);

  const handleCreateUser = async () => {
    if (
      Object.keys(validationErrors).find(
        item =>
          validationErrors[item] === true || validationErrors[item] === null
      )
    ) {
      return setFormError(
        'Please correct invalid or blank fields and resubmit'
      );
    }

    try {
      await post('/api/v1/client_portal/users', {
        user: {
          email: userInfo.email,
          first_name: userInfo.firstName,
          last_name: userInfo.lastName,
          role: userInfo.role,
        }
      });
      setFormError(null);
      setSuccess('Team Member has been created successfully');
      refetchUsers();
      closeModal();
    } catch (e) {
      setSuccess(null);
      closeModal();
      return setError('Failed to Create User');
    }
  };

  return (
    <Modal key="create-user-modal">
      <ModalForm
        closeModal={closeModal}
        error={formError}
        userInfo={userInfo}
        setUserInfo={setUserInfo}
        validationErrors={validationErrors}
        setValidationErrors={setValidationErrors}
        submit={handleCreateUser}
        title="Add New Team Member"
        page="create-user"
      />
    </Modal>
  );
};
