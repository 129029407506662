import React from 'react';
import PropTypes from 'prop-types';
// Import Shared Components
import { Card, Title, Table, UploadButton, ActionBar } from 'shared-components';
// Import Shared Constants
import { program } from 'shared-components/src/constants/propTypes';
// Import data
import { getCardsCSVFieldDetails } from './fieldData';

const CSVFieldDetails = ({
  program,
  parseCsv,
}) => {
  const columns = [
    {
      Header: 'Field',
      accessor: 'field',
      maxWidth: 180,
    },
    {
      Header: 'Required',
      accessor: 'required',
      maxWidth: 100,
    },
    {
      Header: 'Requirements',
      accessor: 'requirements',
      style: { whiteSpace: 'unset' },
    },
    {
      Header: 'Notes',
      accessor: 'notes',
      style: { whiteSpace: 'unset' },
    },
    {
      Header: 'Example',
      accessor: 'example',
    },
  ];

  const data = getCardsCSVFieldDetails(program);

  return (
    <>
      <Card>
        <Title>CSV Field Details</Title>
        <Table columns={columns} data={data} />
        <ActionBar>
          <UploadButton
            type="primary"
            onChange={parseCsv}
          >
            Upload CSV
          </UploadButton>
        </ActionBar>
      </Card>
    </>
  );
};

CSVFieldDetails.propTypes = {
  program: program.isRequired,
  parseCsv: PropTypes.func.isRequired,
};

export default CSVFieldDetails;
