import React from 'react';
import PropTypes from 'prop-types';
// Import Shared Components
import {
  Title,
  Alert,
  Button,
  SubmitButton,
  ActionBar,
  ButtonGroup,
  Loader,
} from 'shared-components';
// Import Component Styles
import { FormContainer, StyledForm } from './styles';

const Form = ({
  secondaryButtonFunc,
  secondaryButtonText,
  error,
  submitButtonText,
  children,
  submit,
  title,
  dataId,
  submitButtonSize,
  loading,
}) => (
  <FormContainer>
    <Title>{title}</Title>
    {loading ? (
      <Loader />
    ) : (
      <StyledForm data-id={dataId} onSubmit={submit}>
        {error && <Alert type="danger">{error}</Alert>}
        {children}
        <ActionBar>
          <ButtonGroup reverse justifyContent="space-between">
            <SubmitButton
              size={submitButtonSize}
              value={submitButtonText}
              buttonType="primary"
            />
            {secondaryButtonFunc && (
              <Button onClick={secondaryButtonFunc} size="large">
                {secondaryButtonText}
              </Button>
            )}
          </ButtonGroup>
        </ActionBar>
      </StyledForm>
    )}
  </FormContainer>
);

Form.propTypes = {
  secondaryButtonFunc: PropTypes.func,
  secondaryButtonText: PropTypes.string,
  error: PropTypes.string,
  submitButtonText: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  submit: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  dataId: PropTypes.string.isRequired,
  submitButtonSize: PropTypes.string,
  loading: PropTypes.bool,
};

Form.defaultProps = {
  submitButtonSize: 'large',
  submitButtonText: 'Submit',
  secondaryButtonText: null,
  secondaryButtonFunc: null,
  loading: null,
  error: null,
};

export default Form;
