import React from 'react';
import { FormattedMessage } from 'react-intl';
// Import Shared Components
import { Label as StyledLabel } from 'shared-components';
import PropTypes from 'prop-types';

const Label = ({ labelFor, label, page, ...props }) => (
  <StyledLabel htmlFor={labelFor} {...props}>
    <FormattedMessage
      id={page ? `${page}-${labelFor}-label` : `${labelFor}-label`}
      description={`Label for ${label}`}
      defaultMessage={`${label}`}
    />
  </StyledLabel>
);

Label.propTypes = {
  page: PropTypes.string,
  labelFor: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

Label.defaultProps = {
  page: null,
};

export default Label;
