import React from 'react';
// Import Global Components
import InfoTooltip from '../../../../components/InfoTooltip';

export const FrequencyTooltip = () => (
  <InfoTooltip
    position="top"
    content={
      <>
        <p>
          <b>Daily:</b> Every Day at 6PM UTC
        </p>
        <p>
          <b>Weekly:</b> Every Sunday at 6PM UTC
        </p>
        <p>
          <b>Monthly:</b> Every First of the Month at 6PM UTC
        </p>
      </>
    }
  />
);

export const TimeRangeTooltip = () => (
  <InfoTooltip
    position="top"
    content={
      <>
        <p>
          You may select a time range up to <b>93 days</b> in the past from
          today's date.
        </p>
        <p>
          You may not be able to select a time in the past if it is before the
          companies direct send <b>start date</b>
        </p>
      </>
    }
  />
);

export const ReportingMethodTooltip = () => (
  <InfoTooltip
    position="top"
    content={
      <>
        <p>
          <b>Email:</b> Receive reports via email
        </p>
        <p>
          <b>SFTP:</b> Receive reports via SFTP
        </p>
        <p>
          <b>Email and SFTP:</b> Receive reports via both Email and SFTP
        </p>
      </>
    }
  />
);

export const EmailTooltip = () => (
  <InfoTooltip
    position="top"
    content={
      <>
        <p>
          You <b>must</b> provide an email address to receive reports via email
        </p>

      </>
    }
  />
);