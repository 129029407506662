import React from 'react';
// Import Shared Components
import { Button, Tag } from 'shared-components';
// Import Shared Utils
import { formatAccountString } from 'shared-components/src/utils/string';
// Import Component Styles
import { DefaultAccount } from '../styles';

export default ({ value, disableDefaultAccount, changeDefaultAccount }) =>
  value.defaultAcct ? (
    <DefaultAccount>
      <div>
        {formatAccountString(value)}
        <Tag type="success">Default</Tag>
      </div>
      <Button
        size="small"
        type="primary"
        variant="text"
        onClick={disableDefaultAccount(value.accountHolderId)}
      >
        Disable Default
      </Button>
    </DefaultAccount>
  ) : (
    <DefaultAccount>
      <div>{formatAccountString(value)}</div>
      <Button
        size="small"
        type="primary"
        variant="text"
        onClick={changeDefaultAccount(
          value.accountHolderId,
          value.id
        )}
      >
        Make Default
      </Button>
    </DefaultAccount>
  );
