import gql from 'graphql-tag';

export default gql`
  query {
    viewer {
      id
      company {
        id
        apiKeys {
          id
          token
          latestStatus
          insertedAt
          type
        }
      }
    }
  }
`;
