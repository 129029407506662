import styled from 'styled-components';

export const TableHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const FiltersGroup = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  /* Card wrapping search component */
  & > *:first-child {
    flex: 2 0 0;
  }

  /* Card wrapping create button */
  & > *:last-child {
    display: flex;
    justify-content: flex-end;
    align-items: stretch;
    flex: 1;
    margin-top: 15px;
  }

  ${({ theme }) => theme.media.desktopUp`
    flex-direction: row;

    & > *:last-child {
      margin-top: 0;
      margin-left: 15px;
    }
  
  `}
`;

export const ReportHeader = styled.div`
  display: flex;
  flex-direction: column;

  p {
    margin-top: 15px;
  }

  ${({ theme }) => theme.media.tabletPortraitUp`
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    p {
      width: 100%;
      margin-top: 0;
      text-align: right;
    }
  `}
`;
