import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
// Import Shared Components
import {
  Card,
  Title,
  Table,
  Button,
  ButtonGroup,
  ActionBar,
} from 'shared-components';
// Import Shared Constants
import {
  csvCardInputs,
} from 'shared-components/src/constants/propTypes';
// Import Constants
import COLUMNS from './columns';

const ValidUploadedCards = withRouter(
  ({
    cards,
    onClick,
    handleCardsSubmit,
  }) => {
    return (
      <Card>
        <Title>Cards</Title>
        <Table columns={COLUMNS} data={cards} />
        <ActionBar>
          <ButtonGroup justifyContent="space-between">
            <Button type="secondary" onClick={onClick}>
              Back
            </Button>
            <Button type="primary" onClick={e => { handleCardsSubmit(e) } }>
              Modify Cards
            </Button>
          </ButtonGroup>
        </ActionBar>
      </Card>
    );
  }
);

ValidUploadedCards.propTypes = {
  history: PropTypes.object.isRequired, //eslint-disable-line
  cards: csvCardInputs.isRequired,
  setCards: PropTypes.func.isRequired,
  setSuccess: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ValidUploadedCards;
