import { useState, useEffect } from 'react';
import { get } from '../utils/http';

export default (initalUrl, initalData = null) => {
  const [data, setData] = useState(initalData);
  const [url, setUrl] = useState(initalUrl);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const [forceRefetch, setForceRetch] = useState({});

  // setUrl causes a new API call to be made at that url
  useEffect(() => {
    const fetchData = async () => {
      setIsError(false);
      setIsLoading(true);

      try {
        const res = await get(url);
        setData(res);
      } catch (err) {
        setIsError(true);
      }

      setIsLoading(false);
    };

    // Only fetch data is the URL exists
    if (url) {
      fetchData();
    }
  }, [url, forceRefetch]);

  return [{ data, isLoading, isError }, setUrl, setForceRetch, setData];
};
