import { withHandlers } from 'recompose';
// Import Utils
import { getEndOfDay, getThirtyDaysAgo } from 'shared-components/src/utils/dates';

export default withHandlers({
  toggleSearchFilter: ({ updateSearchFilter, searchFilter }) => () => {
    updateSearchFilter(!searchFilter);
  },
  resetSearch: ({
    updateParams,
    updateSearchData,
    searchData,
    updateFilterButton,
    updateSearchBy,
    updateSearchFilter,
  }) => () => {
    updateParams({
      name: '',
      status: '',
      amountFrom: '',
      amountTo: '',
      amount: '',
    });
    updateSearchData({
      name: '',
      status: '',
      dateFrom: getThirtyDaysAgo(),
      dateTo: getEndOfDay(),
      amountFrom: '',
      amountTo: '',
      amount: '',
      focusedInput: null,
    });
    updateSearchFilter(false);
    updateFilterButton(false);
    updateSearchBy('');
  },
  updateSearchStatus: ({ updateParams }) => key => {
    updateParams({
      status: key,
      amountFrom: '',
      amountTo: '',
      amount: '',
    });
  },
  updateSearchByState: ({ updateSearchBy }) => key => {
    updateSearchBy(key);
  },
  updateSearchAmount: ({ updateParams, params }) => key => {
    const value = key.split(/[/]/);
    updateParams({
      status: '',
      amountFrom: value[0],
      amountTo: value[1],
      amount: key,
    });
  },
  searchTransactions: ({
    searchData,
    searchBy,
    updateSearchFilter,
    params,
    updateSearchData,
    updateFilterButton,
  }) => () => {
    updateSearchData({
      ...searchData,
      status: (params.status.length !== 0 && params.status) || null,
      amountFrom: (params.amountFrom.length !== 0 && params.amountFrom) || null,
      amountTo: (params.amountTo.length !== 0 && params.amountTo) || null,
    });
    if (searchBy) return updateFilterButton(true);
  },
});
