import React from 'react';
// Import Shared Components
import { humanizeString } from 'shared-components/src/utils/string';
import { Tag } from 'shared-components';

// Statuses to change once Teller is updated with correct status for bulk transfers
export default ({ status }) => {
  const formattedStatus = humanizeString(status);
  switch (status) {
  case 'awaiting_settlement':
    return <Tag type="processing">{formattedStatus}</Tag>;
  case 'network_failure':
  case 'internal_error':
    return <Tag type="danger">{formattedStatus}</Tag>;
  case 'approved':
    return <Tag type="success">{formattedStatus}</Tag>;
  case 'payment_network_unavailable':
  case 'payment_network_timeout':
  case 'payment_network_error':
  case 'transaction_payload_invalid':
  case 'declined':
    return <Tag type="warning">{formattedStatus}</Tag>;
  case 'processing':
    return <Tag type="processing">{formattedStatus}</Tag>;
  default:
    return null;
  }
};
