import React from 'react';
import PropTypes from 'prop-types';
// Import Shared Components
import {
  Title,
  Alert,
  Button,
  SubmitButton,
  ActionBar,
  ButtonGroup,
  Loader,
} from 'shared-components';
// Import Component Styles
import { ModalForm as StyledModalForm, Form } from './styles';

const ModalForm = ({
  closeModal,
  error,
  submitButtonText,
  children,
  submit,
  title,
  dataId,
  submitButtonSize,
  loading,
  secondaryButtonText,
  key,
  scrollable,
}) => (
  <StyledModalForm scrollable={scrollable}>
    <Title>{title}</Title>
    {loading ? (
      <Loader />
    ) : (
      <Form data-id={dataId} onSubmit={submit}>
        {error && <Alert type="danger">{error}</Alert>}
        {children}
        <ActionBar>
          <ButtonGroup reverse justifyContent="space-between">
            <SubmitButton
              size={submitButtonSize}
              value={submitButtonText || 'Submit'}
              buttonType="primary"
            />
            {closeModal && (
              <Button size={submitButtonSize} onClick={closeModal}>
                {secondaryButtonText || 'Cancel'}
              </Button>
            )}
          </ButtonGroup>
        </ActionBar>
      </Form>
    )}
  </StyledModalForm>
);

ModalForm.propTypes = {
  closeModal: PropTypes.func,
  error: PropTypes.string,
  submitButtonText: PropTypes.string,
  children: PropTypes.node.isRequired,
  submit: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  dataId: PropTypes.string.isRequired,
  submitButtonSize: PropTypes.string,
  loading: PropTypes.bool,
  secondaryButtonText: PropTypes.string,
  scrollable: PropTypes.bool
};

ModalForm.defaultProps = {
  submitButtonSize: 'large',
  loading: null,
  error: null,
  closeModal: null,
  secondaryButtonText: 'Cancel',
  submitButtonText: 'Submit',
  scrollable: false,
};

export { ModalForm as StyledModalForm } from './styles';

export default ModalForm;
