export const convertCountryCode = (countryCode) => {
  switch (countryCode) {
    case "124":
      return "CA"
    case "840":
      return "US"
    default:
  }
}

export const convertCountryAbbrev = (countryAbbrev) => {
  switch (countryAbbrev) {
    case "CA":
      return "124"
    case "US":
      return "840"
    default:
  }
}