import React, { useState } from 'react';
import PropTypes from 'prop-types';
// Import Shared Components
import { Alert, Title, Card, CardGroup, Button, Loader, ClientErrorComponent, } from 'shared-components';
import { formatTime } from 'shared-components/src/utils/formatDateTime';
// import Hooks
import useTellerApi from '../../../hooks/useTellerApi';   
import { useTimeZone } from '../../../hooks/useTimeZone';
// import utils
import { post } from '../../../utils/http';

// Import Global Components
import Table from '../../../components/Table';
import { IconButtonGroup } from '../../../components/IconButton';
// Import Local Components
import Header from './components/Header';
// Import Component Styles
import { CollectionDetails } from './styles';

//role context
import {RoleContext} from '../../../context/RoleContext';

const Collection = ({ match: { params: { id } } }) => {
  const [{ data: collection, isLoading, isError }, setUrl] = useTellerApi(`/api/v1/client_portal/collections/${id}`, null);

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [timeZone] = useTimeZone();

  // Get role from context
  const userRole = React.useContext(RoleContext);  

  const resendEmail = async() => {
    try {
      await post(`/api/v1/client_portal/collections/${id}/resend`, {});

      setSuccess('Successfuly sent email');
      setError(null);
      setUrl(`/api/v1/client_portal/collections/${id}`);
    } catch (err) {
      setError('Failed to resend Collection notification: '.concat(err));
      setSuccess(null);
    }
  };

  const cancelCollection = async() => {
    try {
      await post(`/api/v1/client_portal/collections/${id}/cancel`, {});
      
      setSuccess('Successfuly cancelled collection');
      setError(null);
      setTimeout(() => setUrl(`/api/v1/client_portal/collections/${id}`), 1500);
    } catch (err) {
      setError('Failed to cancel Collection: '.concat(err));
      setSuccess(null);
    }
  };

  const voidCollection = async() => {
    try {
      await post(`/api/v1/client_portal/collections/${id}/void`, {});
      
      setSuccess('Successfuly voided collection');
      setError(null);
      setUrl(`/api/v1/client_portal/collections/${id}`);
    } catch (err) {
      setError('Failed to void Collection: '.concat(err));
      setSuccess(null);
    }
  };

  const returnCollection = async() => {
    try {
      await post(`/api/v1/client_portal/collections/${id}/return`, {});
      
      setSuccess('Successfuly return collection');
      setError(null);
      setUrl(`/api/v1/client_portal/collections/${id}`);
    } catch (err) {
      setError('Failed to return Collection: '.concat(err));
      setSuccess(null);
    }
  };

  const columns = [
    {
      Header: 'ID',
      accessor: 'id',
      show: false,
    },
    {
      Header: 'Account Holder Email',
      accessor: 'email',
      Cell: t => <div>{t.value}</div>,
      maxWidth: 250,
    },
    {
      Header: 'External Tag',
      accessor: 'external_tag',
      maxWidth: 200,
      Cell: (t) =>
        t.value ? (
          <div style={{ textAlign: 'left', paddingRight: '10px' }}>{t.value}</div>
        ) : (
          '--'
        ),
    },
    {
      Header: 'Transaction Time',
      accessor: 'txn_time',
      Cell: t => 
        t.value ? (
          <div>{formatTime(t.value, 'dateTime', timeZone)}</div>
        ) : ('--'),
      maxWidth: 250,
    },
    {
      Header: 'Actions',
      accessor: item => item,
      id: 'action',
      sortable: false,
      Cell: (t) => ['declined', 'void', 'returned', 'cancelled', 'expired'].includes(t.value.status) ? (
        <div />
      ) : (
        <IconButtonGroup>
          {['paid', 'settled'].includes(t.value.status)?
           null
           :
          <>
            <Button
              type="primary"
              disabled={userRole == 'user' }
              variant="text"
              onClick={() => resendEmail()}
            >
              Resend
            </Button>
            <Button
              type="danger"
              disabled={userRole == 'user' }
              variant="text"
              onClick={cancelCollection}
            >
              Cancel
            </Button>
          </>
        }
          <Button
            type="warning"
            disabled={userRole == 'user' }
            variant="text"
            onClick={voidCollection}
          >
            Void
          </Button>
          <Button
            type="warning"
            disabled={userRole == 'user' }
            variant="text"
            onClick={returnCollection}
          >
            Return
          </Button>
        </IconButtonGroup>
      ),
    },
  ];

  if (isLoading) {
    return (
      <Card>
        <Loader />
      </Card>
    );
  }

  if (isError) {
    return (
      <Card>
        <ClientErrorComponent />
      </Card>
    );
  }

  return (
    <CollectionDetails>
      <CardGroup>
        {error && <Alert type="danger">{error}</Alert>}
        {success && <Alert type="success">{success}</Alert>}
        <Card>
          <Header data={collection.data} />
        </Card>
      </CardGroup>
      <CardGroup>
        <Card>
          <Title>Summary</Title>
          <Table
            key="single-collection-table"
            columns={columns}
            data={[collection.data]}
            noDataText="No Collection found"
          />
        </Card>
      </CardGroup>
    </CollectionDetails>
  );
};

Collection.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.any.isRequired,
    }).isRequired,
  }).isRequired,
};

export default Collection;