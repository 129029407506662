// Country Formatting

export const countryCodes = {
  '840': 'USA',
  usd: 'USA',
  usa: 'USA',
  USD: 'USA',
  USA: 'USA',
  '124': 'CAN',
  cad: 'CAN',
  can: 'CAN',
  CAD: 'CAN',
  CAN: 'CAN',
  US: 'USA',
  CA: 'CAN',
  us: 'USA',
  ca: 'CAN',
};

export const formatCountryCode = countryCode => countryCodes[countryCode];

export const getCountryCode = country => {
  switch (country) {
  case 'CAN':
    return '124';
  case 'USA':
    return '840';
  default:
    return country;
  }
};
