import React from 'react';
// Import Global Components
import Table from '../../../../../../components/Table';
// Import Local Components
import Section from '../../Section';

export default ({ columns, limits }) => (
  <>
    <Section>
      <p>
        This limit applies on a <strong>per Transaction</strong> basis.
      </p>
    </Section>
    <Section>
      <p>
        These limits restrict the amount of any given Transaction for a specific <strong>Payment Network</strong>, <strong>Product</strong> and <strong>Transaction Type</strong> for this Company
      </p>
    </Section>
    <Table data={limits} columns={columns} sortable={false} />
  </>
);
