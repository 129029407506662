import React, { useState } from 'react';
// Import Shared Components
import { Alert, Title, Card, CardGroup, Loader, ClientErrorComponent, } from 'shared-components';
// import Hooks
import useTellerApi from '../../../hooks/useTellerApi';  

// import utils
import { put } from '../../../utils/http';

// Import Local Components
import EmailConfigurationForm from './components/EmailConfigurationForm';

// Import Component Styles
import { EmailConfiguration } from './styles';

const Config = () => {
  const [{ data, isLoading, isError }, setUrl] = useTellerApi('/api/v1/client_portal/collection_config', null);

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const updateConfiguration = async(emailExpiryWindow, emailBlurb) => {
    try {
      await put('/api/v1/client_portal/collection_config', { 
        email_expiry_window: emailExpiryWindow, 
        email_blurb: emailBlurb,
      });

      setSuccess('Successfuly updated configuration');
      setError(null);
      setUrl('/api/v1/client_portal/collection_config');
    } catch (err) {
      setError('Failed to update configuration');
      setSuccess(null);
    }
  };

  if (isLoading) {
    return (
      <Card>
        <Loader />
      </Card>
    );
  }

  if (isError) {
    return (
      <Card>
        <ClientErrorComponent />
      </Card>
    );
  }

  return (
    <EmailConfiguration>
      <CardGroup>
        {error && <Alert type="danger">{error}</Alert>}
        {success && <Alert type="success">{success}</Alert>}
        <Card>
          <Title>Email Configuration</Title>
          <EmailConfigurationForm 
            handleFormSubmission={updateConfiguration}
            expiryWindow={data.data.email_expiry_window}
            blurb={data.data.email_blurb}
          />
        </Card>
      </CardGroup>
    </EmailConfiguration>
  );
};

export default Config;