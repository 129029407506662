export const getAmountPercentage = (currentStanding, amount) => (currentStanding / amount) * 100;

export const getAmountValue = (currentStanding, amount) => {
  // calculate the amount available in a limit
  // calculate the percent value of the amount available
  const value = getAmountPercentage(currentStanding, amount);

  if (value <= 75) return 2;
  if (value >= 90) return 1;
  if (value < 90 && value > 75) return 3;
};
