import React, { useState } from 'react';
// import Shared Components
import {
  Card,
  Title,
  ActionBar,
  Alert,
  Button,
  IconButton,
  ButtonGroup,
  Loader,
} from 'shared-components';
import { formatTime } from 'shared-components/src/utils/formatDateTime';
import {
  useUpdateStateObject,
  updateManyValuesInStateObject,
} from 'shared-components/src/hooks/useUpdateStateObject';
// Import Hooks
import useTellerApi from '../../../hooks/useTellerApi';
import { useTimeZone } from '../../../hooks/useTimeZone';
// Import Global Components
import ClientErrorComponent from '../../../components/ClientErrorComponent';
import Table from '../../../components/Table';
import Role from '../../../components/RoleTag';
import ConfirmedTag from '../../../components/ConfirmedTag';
import ConfirmDelete from '../../../components/ConfirmDelete';
// Import Local Components
import CreateUserModal from './components/CreateUserModal';
import EditUserModal from './components/EditUserModal';
import ResendInviteModal from './components/ResendInviteModal';
// Import Component Styles
import { ConfirmedTagGroup } from './styles';
// Import Utils
import { getCurrentUsers } from '../../../utils/users';
import { deleteHTTP } from '../../../utils/http';

export default () => {
  // Set States
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const [addUserModal, setAddUserModal] = useState(false);
  const [editUserModal, setEditUserModal] = useState(false);
  const [resendInviteModal, setResendInviteModal] = useState(false);
  const [resendInviteState, setResendInviteState] = useState({});
  const [removeUserInfo, setRemoveUserInfo] = useState({});
  const [timeZone] = useTimeZone();

  const initialUserInfoState = {
    id: '',
    email: '',
    firstName: '',
    lastName: '',
    role: '',
  };
  const initialValidationErrorState = {
    email: null,
    firstName: null,
    lastName: null,
  };

  const [userInfo, setUserInfo] = useUpdateStateObject(initialUserInfoState);
  const [validationErrors, setValidationErrors] = useUpdateStateObject(
    initialValidationErrorState
  );

  // Query for searching users
  const [
    {
      data: { data: users },
      isLoading,
      isError,
    },
    setUrl,
    setForceRetch,
  ] = useTellerApi('/api/v1/client_portal/users', {
    count: 0,
    data: [],
    limit: 10,
    offset: 0,
  });

  // Render states if data is loading or has errored
  if (isLoading) return <Loader />;
  if (isError) return <ClientErrorComponent />;

  // Get all active users for a company
  const currentUsers = getCurrentUsers(users);

  // Set Handlers
  const resetUserInfo = () => {
    const resetValues = [
      { key: 'id', value: '' },
      { key: 'firstName', value: '' },
      { key: 'lastName', value: '' },
      { key: 'email', value: '' },
      { key: 'role', value: '' },
    ];
    updateManyValuesInStateObject(resetValues, setUserInfo);
  };
  const resetValidationErrors = () => {
    const resetValues = [
      { key: 'firstName', value: null },
      { key: 'lastName', value: null },
      { key: 'email', value: null },
    ];
    updateManyValuesInStateObject(resetValues, setValidationErrors);
  };

  const resetAlerts = () => {
    setSuccess(null);
    setError(null);
  };

  const handleOpenResendInviteModal = value => {
    resetAlerts();
    setResendInviteState(value);
    setResendInviteModal(!resendInviteModal);
  };

  const handleCloseResendInviteModal = () => {
    setResendInviteState({});
    setResendInviteModal(!resendInviteModal);
  };

  const handleOpenCreateUserModal = () => {
    resetAlerts();
    setAddUserModal(!addUserModal);
  };

  const handleCloseCreateUserModal = () => {
    resetUserInfo();
    resetValidationErrors();
    setAddUserModal(!addUserModal);
  };

  const handleOpenEditUserModal = ({
    id,
    first_name,
    last_name,
    role,
    email,
  }) => {
    const newValues = [
      { key: 'id', value: id },
      { key: 'firstName', value: first_name },
      { key: 'lastName', value: last_name },
      { key: 'email', value: email },
      { key: 'role', value: role },
    ];
    resetAlerts();
    updateManyValuesInStateObject(newValues, setUserInfo);
    setEditUserModal(!editUserModal);
  };

  const handleCloseEditUserModal = () => {
    resetUserInfo();
    resetValidationErrors();
    setEditUserModal(!editUserModal);
  };

  const handleOpenConfirmDeleteModal = value => {
    resetAlerts();
    setRemoveUserInfo(value);
    setConfirmDeleteModal(!confirmDeleteModal);
  };

  const handleCloseConfirmDeleteModal = () => {
    setRemoveUserInfo({});
    setConfirmDeleteModal(!confirmDeleteModal);
  };

  const handleRemoveUser = async () => {
    try {
      const userID = removeUserInfo.id;
      await deleteHTTP(`/api/v1/client_portal/users/${userID}`, {});
      setSuccess('Team Member has been removed successfully');
      setConfirmDeleteModal(false);
      setRemoveUserInfo({});
      // Refetch Updated User's List
      setUrl('/api/v1/client_portal/users');
      refetchUsers();
    } catch (e) {
      setSuccess(null);
      setConfirmDeleteModal(false);
      return setError('Failed to Delete User');
    }
  };

  const refetchUsers = () => {
    // Create new Search Params Query
    const params = new URLSearchParams({}).toString();
    setUrl(`/api/v1/client_portal/users?${params}`);
    setForceRetch({});
  };

  const columns = [
    {
      Header: 'ID',
      accessor: item => parseInt(item.id, 10),
      id: 'id',
      maxWidth: 60,
      show: false,
    },
    {
      Header: 'First Name',
      accessor: 'first_name',
      maxWidth: 150,
    },
    {
      Header: 'Last Name',
      accessor: 'last_name',
      maxWidth: 150,
    },
    {
      Header: 'Email',
      accessor: 'email',
    },
    {
      Header: 'Role',
      accessor: 'role',
      maxWidth: 100,
      Cell: ({ value }) => <Role role={value} />,
    },
    {
      Header: 'Confirmed',
      id: 'confirmed',
      accessor: item => item,
      maxWidth: 180,
      Cell: ({ value }) =>
        value.confirmed === true ? (
          <ConfirmedTag value={value.confirmed} />
        ) : (
          <ConfirmedTagGroup>
            <ConfirmedTag value={value.confirmed} />
            <Button
              type="primary"
              variant="text"
              onClick={() => handleOpenResendInviteModal(value)}
            >
              Resend
            </Button>
          </ConfirmedTagGroup>
        ),
    },
    {
      Header: 'Date Added',
      accessor: 'inserted_at',
      maxWidth: 180,
      Cell: ({ value }) => (value !== '' ? formatTime(`${value}Z`, 'longDate', timeZone) : ''),
    },
    {
      Header: 'Actions',
      maxWidth: 90,
      style: { margin: 'auto 0' },
      accessor: item => item,
      id: 'edit',
      Cell: ({ value }) =>
        value.role !== 'owner' && (
          <ButtonGroup>
            <IconButton
              iconType="edit"
              variant="text"
              type="primary"
              iconSize={16}
              onClick={() => handleOpenEditUserModal(value)}
            />
            <IconButton
              iconType="delete"
              variant="text"
              type="danger"
              iconSize={16}
              onClick={() => handleOpenConfirmDeleteModal(value)}
            />
          </ButtonGroup>
        ),
      sortable: false,
      filterable: false,
    },
  ];

  return (
    <>
      {error && <Alert type="danger">{error}</Alert>}
      {success && <Alert type="success">{success}</Alert>}
      <Card>
        <Title>Team Members</Title>
        {currentUsers.length === 0 ? (
          <Alert type="info">
            There are no team members associated with this account.
          </Alert>
        ) : (
          <Table columns={columns} data={currentUsers} s />
        )}
        <ActionBar>
          <Button type="primary" onClick={() => handleOpenCreateUserModal()}>
            Add New Team Member
          </Button>
        </ActionBar>
      </Card>

      {addUserModal && (
        <CreateUserModal
          closeModal={() => handleCloseCreateUserModal()}
          userInfo={userInfo}
          setError={setError}
          setSuccess={setSuccess}
          setUserInfo={setUserInfo}
          validationErrors={validationErrors}
          setValidationErrors={setValidationErrors}
          refetchUsers={refetchUsers}
        />
      )}
      {editUserModal && (
        <EditUserModal
          closeModal={handleCloseEditUserModal}
          userInfo={userInfo}
          setError={setError}
          setSuccess={setSuccess}
          setUserInfo={setUserInfo}
          validationErrors={validationErrors}
          setValidationErrors={setValidationErrors}
          refetchUsers={refetchUsers}
        />
      )}
      {confirmDeleteModal && (
        <ConfirmDelete
          onClick={() => handleRemoveUser()}
          closeModal={() => handleCloseConfirmDeleteModal()}
          removeValue={`${removeUserInfo.first_name} ${removeUserInfo.last_name}`}
        />
      )}
      {resendInviteModal && (
        <ResendInviteModal
          userID={resendInviteState.id}
          email={resendInviteState.email}
          closeModal={() => handleCloseResendInviteModal()}
          setError={setError}
          setSuccess={setSuccess}
        />
      )}
    </>
  );
};
