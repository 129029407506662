import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox,
  Button,
  InputGroup,
  ActionBar,
  ButtonGroup,
  Alert,
} from 'shared-components';
import {
  validateName,
  validateEmail,
  validatePhone,
} from 'shared-components/src/validations/cardholderValidations';
import { AggreeBoxesContainer, FormRow } from './styles';

const AgreeBoxes = ({
  setKycAttestationModal,
  setAgreeCollect,
  setAgreeValidateStore,
  setAgreeProvide,
  setFirstName,
  setLastName,
  setEmail,
  setPhone,
  handleCardholderSubmit,
  agreeCollect,
  agreeValidateStore,
  agreeProvide,
  firstName,
  lastName,
  email,
  phone,
}) => {
  const [error, setError] = useState();
  const validateAttestation = () => {
    if (
      !agreeCollect ||
      !agreeValidateStore ||
      !agreeProvide ||
      !validateName(firstName) ||
      !validateName(lastName) ||
      !validateEmail(email) ||
      !validatePhone(phone)
    ) {
      setError(
        'Please check all the boxes and fill in the fields in the correct format'
      );
      return false;
    }
    return true;
  };
  return (
    <>
      {error && <Alert type="danger">{error}</Alert>}
      <p>
        In compliance with KYC Regulations, as of April 1st, 2022 we are
        required to gather all necessary information to confirm client identity.
        Please agree to the following statements by filling out the following
        categories and clicking Accept. You may use the back button to return to
        the previous page.
      </p>
      <AggreeBoxesContainer>
        <Checkbox
          id="agreeCollect"
          label="I agree to have collected the required KYC information including Name, Date of Birth, Address, and Occupation."
          onChange={e => {
            setAgreeCollect(e.target.checked);
          }}
          required
        />
        <Checkbox
          id="agreeValidateStore"
          label="I agree to have validated and stored all the required KYC data and verification documents collected (Government ID and proof of address)."
          onChange={e => setAgreeValidateStore(e.target.checked)}
          required
        />
        <Checkbox
          id="agreeProvide"
          label="I agree to provide any necessary KYC information to Berkeley within 2-5 business days of request."
          onChange={e => setAgreeProvide(e.target.checked)}
          required
        />
      </AggreeBoxesContainer>
      <FormRow>
        <InputGroup
          label="First Name"
          maxLength={50}
          onChange={e => setFirstName(e.target.value)}
          value={firstName}
          required
        />
        <InputGroup
          label="Last Name"
          maxLength={50}
          onChange={e => setLastName(e.target.value)}
          value={lastName}
          required
        />
      </FormRow>
      <FormRow>
        <InputGroup
          label="Email"
          style={{ maxWidth: '30em' }}
          maxLength={50}
          onChange={e => setEmail(e.target.value)}
          value={email}
          required
        />
      </FormRow>
      <FormRow>
        <InputGroup
          label="Phone"
          style={{ maxWidth: '15em' }}
          maxLength={16}
          onChange={e => setPhone(e.target.value)}
          value={phone}
          required
        />
      </FormRow>
      <ActionBar>
        <ButtonGroup>
          <Button
            type="secondary"
            onClick={() => setKycAttestationModal(false)}
          >
            Close
          </Button>
          <Button
            type="primary"
            onClick={e => {
              if (validateAttestation()) {
                handleCardholderSubmit(e);
              }
            }}
          >
            Create Cardholders
          </Button>
        </ButtonGroup>
      </ActionBar>
    </>
  );
};

AgreeBoxes.propTypes = {
  setKycAttestationModal: PropTypes.func.isRequired,
  setAgreeCollect: PropTypes.func.isRequired,
  setAgreeValidateStore: PropTypes.func.isRequired,
  setAgreeProvide: PropTypes.func.isRequired,
  setFirstName: PropTypes.func.isRequired,
  setLastName: PropTypes.func.isRequired,
  setEmail: PropTypes.func.isRequired,
  setPhone: PropTypes.func.isRequired,
  handleCardholderSubmit: PropTypes.func.isRequired,
  agreeCollect: PropTypes.bool.isRequired,
  agreeValidateStore: PropTypes.bool.isRequired,
  agreeProvide: PropTypes.bool.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
};

export default AgreeBoxes;
