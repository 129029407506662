import React from 'react';
import PropTypes from 'prop-types';
// Import Global Components
import Header from '../../../../components/Header';
// Import Local Components
import CardDetails from '../components/CardDetails';
import ValidUploadedCards from '../components/ValidUploadedCards';
// Import Local Layouts
import CreateHeaderCard from './CreateHeaderCard';

const ReviewCSV = ({
  setCreateInstantIssuanceCards,
  setErroredCardsInputs,
  setValidationError,
  validationError,
  ...props
}) => {
  return (
    <>
      <Header>
        <CreateHeaderCard>
          <CardDetails cards={props.cards} />
        </CreateHeaderCard>
      </Header>
      <ValidUploadedCards
        onClick={() => {
          props.setSuccess(null);
          props.setError(null);
          setCreateInstantIssuanceCards(2);
          props.setCards([]);
        }}
        {...props}
      />
    </>
  );
};

ReviewCSV.propTypes = {
  setCreateInstantIssuanceCards: PropTypes.func.isRequired,
  setErroredCardsInputs: PropTypes.func.isRequired,
  setValidationError: PropTypes.func.isRequired,
  validationError: PropTypes.bool.isRequired,
};

export default ReviewCSV;
