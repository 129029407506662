import styled from 'styled-components';
import InfoTooltip from '../InfoTooltip';

export const FormBody = styled.div`
  display: grid;
  grid-template-columns: calc(50% - 10px) calc(50% - 10px);
  grid-gap: 20px;
  width: 100%;
  min-width: 610px;
  max-width: 700px;

  @media only screen and (max-width: 700px) {
    grid-template-columns: 100%;
    grid-gap: 40px;
    min-width: 300px;
  }
`;

export const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  max-width: 700px;

  & > *:not(:last-child) {
    margin-bottom: 30px;
  }
`;

export const FormSection = styled.fieldset`
  & > *:not(:last-child) {
    margin-bottom: 15px;
  }
`;

export const FormSectionTitle = styled.legend`
  border-bottom: ${({ theme }) => theme.borders.default}
    margin-bottom: 1.4285em;
  width: 100%;
  margin-bottom: 15px;
`;

// Contains the dropdown and report name input
export const Row = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;

  & > *:not(:last-child) {
    margin-bottom: 15px;
  }

  ${({ theme }) => theme.media.tabletPortraitUp`
    flex-direction: row;

    & > * {
      width: 50%;
    }

    & > *:not(:last-child) {
      margin-bottom: 0;
      margin-right: 15px;
    }
  `}
`;

export const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-top: 10px;

  & > *:first-child {
    margin-right: 10px;
  }
`;

export const LabelContainer = styled.div`
  display: flex;
`;

export const StyledInfoTooltip = styled(InfoTooltip)`
  margin-left: 8px;
`;
