import { useReducer } from 'react';

// Can be used to update the state of a single value within an object.
export const useUpdateStateObject = initialState => {
  // Function that will be used to update input fields
  const objectReducer = (state, action) => {
    return { ...state, [action.key]: action.value };
  };

  // Returns the updated Object and a function to update that Object
  // will return [updatedObject, updateFunction]
  return useReducer(objectReducer, initialState);
};

// Function to update many key value pairs within a state object created by useUpdateStateObject hook.
// First argument is an array of objects containing the key and value you wish to update.
// Example: [
//   { key: "Key1", value: newValue1},
//   { key: "Key2", value: newValue2},
//   { key: "Key3", value: newValue3},
// ]
// Second argument is the update function created when using the useUpdateStateObject hook
export function updateManyValuesInStateObject(array, updateFunction) {
  return array.map(object => updateFunction(object));
}
