import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import { AccountHolder } from '../../../../graphql/fragments/accountHolderFragments';

const QUERY = gql`
  query($id: String, $status: String, $name: String, $email: String, $limit: Int, $offset: Int) {
    viewer {
      id
      transfer(id: $id) {
        paymentsSearch(status: $status, name: $name, email: $email, limit: $limit, offset: $offset) {
          count
          data {
            id
            latestStatus
            notes
            amount
            currency
            customer {
              ... on CustomerIndividual {
                id
                email
                ...ContactDetailsIndividual
              }
              ... on CustomerCompany {
                id
                email
                ...ContactDetailsCompany
              }
              ... on CustomerSelf {
                id
                email
                ...ContactDetailsSelf
              }
            }
          }
        }
      }
    }
  }
  ${AccountHolder.fragments.contactDetailsIndividual}
  ${AccountHolder.fragments.contactDetailsCompany}
  ${AccountHolder.fragments.contactDetailsSelf}
`;

export default graphql(QUERY, {
  props: props => ({
    id: 2,
    loadingTwo: props.data.loading,
    searchParamsData: props.data.viewer,
    data: { error: props.data.error },
    ...props,
  }),
  options: ({
    match: {
      params: { id },
    },
    searchData,
    pagination,
  }) => ({
    variables: {
      id,
      status: searchData.status || null,
      name: searchData.name || null,
      email: searchData.email || null,
      limit: pagination.limit,
      offset: pagination.offset
    },
    fetchPolicy: 'cache-and-network',
    // NOTE: Commenting out for now until we can find a way to poll
    // for transaction statuses only in the active browser tab
    // pollInterval: 1000 * 30,
  }),
});
