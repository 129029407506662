// Headers for current account holder data
export const accountHolderHeaders = [
  { label: 'Name', key: 'name' },
  { label: 'Type', key: 'type' },
  { label: 'Email', key: 'email' },
  { label: 'Phone Number', key: 'phone' },
];

// Headers for creating account holders
export const createAccountHolderHeaders = [
  '*Type (individual or business)',
  '*First Name',
  '*Last Name',
  '*Email',
  'Phone Number',
];
