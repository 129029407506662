import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import { QUERY } from '../../../../../graphql/queries/accountHoldersQuery';

const MUTATION = gql`
  mutation createCustomer(
    $firstName: String
    $lastName: String
    $type: AccountHolderType!
    $email: String!
    $phone: String
  ) {
    createCustomer(
      input: {
        firstName: $firstName
        lastName: $lastName
        type: $type
        email: $email
        phone: $phone
      }
    ) {
      ... on CustomerIndividual {
        id
      }
      ... on CustomerCompany {
        id
      }
      ... on CustomerSelf {
        id
      }
    }
  }
`;

export default graphql(MUTATION, {
  props: ({ mutate }) => ({
    createAccountHolder: ({ firstName, lastName, type, email, phone }) =>
      mutate({
        variables: {
          firstName,
          lastName,
          type,
          email,
          phone
        }
      })
  }),
  options: {
    refetchQueries: [{ query: QUERY }],
    fetchPolicy: 'no-cache'
  }
});
