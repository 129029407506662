import {
  getSevenDaysAgo,
  addDays,
  setEndOfDay,
} from 'shared-components/src/utils/dates';
import { utcToZonedTime } from 'shared-components/src/utils/timeZone';

// a function to get a new instance of an array with dates to manipulate
const weekTrxn = () => [
  {
    x: setEndOfDay(getSevenDaysAgo()),
    y: 0,
  },
  {
    x: setEndOfDay(addDays(getSevenDaysAgo(), 1)),
    y: 0,
  },
  {
    x: setEndOfDay(addDays(getSevenDaysAgo(), 2)),
    y: 0,
  },
  {
    x: setEndOfDay(addDays(getSevenDaysAgo(), 3)),
    y: 0,
  },
  {
    x: setEndOfDay(addDays(getSevenDaysAgo(), 4)),
    y: 0,
  },
  {
    x: setEndOfDay(addDays(getSevenDaysAgo(), 5)),
    y: 0,
  },
  {
    x: setEndOfDay(addDays(getSevenDaysAgo(), 6)),
    y: 0,
  },
];

function isSameDay(d1, d2) {
  return (
    d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate()
  );
}

function sortTransactions(data, type, dateRange, timeZone) {
  const chartData = dateRange === 'week' && weekTrxn();
  data
    // filter transaction by type
    .filter(
      trxn =>
        trxn.type === type && trxn.latestStatus !== 'payment_network_error'
    )
    // retrieve transaction inserted by date transformed into a Date object
    .map(trxn => utcToZonedTime(new Date(`${trxn.insertedAt}Z`), timeZone))
    // sort each date and insert into chart data
    .map(date =>
      // eslint-disable-next-line
      chartData.map(data => {
        if (isSameDay(date, data.x)) return (data.y += 1);
      })
    );
  return chartData;
}

export const getWeeksTransactionData = (data, timeZone) => {
  // Sort transaction data by type of transaction
  const push = sortTransactions(data, 'push', 'week', timeZone);
  const pull = sortTransactions(data, 'pull', 'week', timeZone);

  return [push, pull];
};
