import React from 'react';
// Import Shared Components
import { Tag } from 'shared-components';
import { humanizeString } from 'shared-components/src/utils/string';

// Statuses to change once Teller is updated with correct status for bulk transfers
export default ({ status }) => {
  const formattedStatus = humanizeString(status);
  switch (status) {
  case 'pending':
  case 'processing':
    return <Tag type="processing">{formattedStatus}</Tag>;
  case 'error':
    return <Tag type="danger">{formattedStatus}</Tag>;
  case 'queued':
    return <Tag type="queued">{formattedStatus}</Tag>;
  case 'awaiting_approval':
    return <Tag type="warning">{formattedStatus}</Tag>;
  case 'notification_sent':
    return <Tag type="info">{formattedStatus}</Tag>;
  case 'notification_failed':
    return <Tag type="danger">{formattedStatus}</Tag>;
  case 'redeeming':
    return <Tag type="queued">{formattedStatus}</Tag>;
  case 'redeemed':
    return <Tag type="success">{formattedStatus}</Tag>;
  case 'cancelling':
    return <Tag type="queued">{formattedStatus}</Tag>;
  case 'canceled':
    return <Tag type="danger">{formattedStatus}</Tag>;
  default:
    return null;
  }
};
