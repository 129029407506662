// Function for determining the correct CSV requirements for activate cards
export const getCardsCSVFieldDetails = () => {

  let baseDetails = [
    {
      field: 'Account Id',
      required: 'Yes',
      requirements: 'Account ID',
      notes:
        'Account ID.',
      example: '14563',
    },
    {
      field: 'Last Four Digits',
      required: 'Yes',
      requirements:
        'Last four digits of PAN',
      notes: 'Last four digits of PAN',
      example: '0991',
    },
    {
      field: 'Status',
      required: 'Yes',
      requirements:
        'New Status',
      notes: 'Valid statuses: mark_card_active, mark_card_lost, mark_card_stolen, initiate_card_lost, initiate_card_stolen, suspend, unsuspend',
      example: 'mark_card_lost',
    },
    
  ];
  return baseDetails;
};
