import React from 'react';
// Import Shared Components
import {
  Alert,
  Button,
  ButtonGroup,
  ActionBar,
  IconButton,
} from 'shared-components';
// Import Shared Utils
import { capitalize } from 'shared-components/src/utils/string';
import { getExpiryDate } from 'shared-components/src/utils/dates';
// Import Global Components
import ConfirmDelete from '../../../../../components/ConfirmDelete';
// Import Local Components
import Icon from './components/FundsEnabledIcon';
import DefaultAccount from './components/DefaultAccount';
import AddNewCardModal from './components/AddNewCardModal';
import UpdateCardModal from './components/UpdateCardModal';
// Import Component Styles
import {
  AccountTable,
  Accounts,
  AccountsTableWrapper,
  NoAccountsTable,
} from './styles';
// Import Common Utils
import {
  getActiveAccounts,
  addAccountHolderInfo,
} from '../../../../../utils/accounts';

export default ({
  data: { accounts, id, defaultFinancialAccountId },
  tokenizationKey,
  createPaymentCardModal,
  openCreatePaymentCardModal,
  closeCreatePaymentCardModal,
  editPaymentCardModal,
  openEditPaymentCardModal,
  closeEditPaymentCardModal,
  createAccountHolderFundingSource,
  updateFinancialAccount,
  deleteAccountHolderFundingSource,
  accountHolderInfo,
  changeDefaultAccount,
  disableDefaultAccount,
  confirmDeleteModal,
  openConfirmDeleteModal,
  closeConfirmDeleteModal,
  removeAccountInfo,
  selectedFA,
  error,
  updateError,
  success,
  updateSuccess,
}) => {
  const accountData = addAccountHolderInfo(accounts, id);
  const { activePaymentCards } = getActiveAccounts(
    accountData,
    defaultFinancialAccountId
  );
  const columns = [
    {
      Header: 'ID',
      accessor: 'id',
      show: false,
    },
    {
      Header: 'Account on File',
      accessor: item => item,
      id: 'account',
      Cell: ({ value }) => (
        <DefaultAccount
          value={value}
          disableDefaultAccount={disableDefaultAccount}
          changeDefaultAccount={changeDefaultAccount}
        />
      ),
    },
    {
      Header: 'Expiry Date',
      maxWidth: 125,
      accessor: item => item,
      id: 'expiry',
      Cell: ({ value }) => {
        if (value.type === 'payment_card' && value.expiryMonth && value.expiryYear) {
          const expiryDate = getExpiryDate(value.expiryMonth, value.expiryYear);
          if (expiryDate.class === 'expired') {
            return <p style={{ color: 'red' }}>{expiryDate.expiry} EXPIRED</p>;
          }
          return <p>{expiryDate.expiry}</p>;
        }
        return <p>--</p>;
      },
    },
    {
      Header: 'Fast Funds',
      maxWidth: 125,
      accessor: item => item,
      id: 'fastFundsEnabled',
      Cell: ({ value }) => {
        if (value.type === 'bank_account') {
          return <Icon value={false} />;
        } else if (value.type === 'payment_card' && (value.fastFundsEnabled === null || value.fastFundsEnabled === undefined)) {
          return <p>--</p>;
        } else {
          return <Icon value={value.fastFundsEnabled} />;
        }
      },
    },
    {
      Header: 'Push Funds',
      maxWidth: 125,
      accessor: item => item,
      id: 'pushFundsEnabled',
      Cell: ({ value }) => {
        if (value.type === 'bank_account') {
          return <Icon value />;
        } else if (value.type === 'payment_card' && (value.pushFundsEnabled === null || value.pushFundsEnabled === undefined)) {
          return <p>--</p>;
        } else {
          return <Icon value={value.pushFundsEnabled} />;
        }
      },
    },
    {
      Header: 'Actions',
      maxWidth: 90,
      style: { margin: 'auto 0' },
      accessor: item => item,
      id: 'edit',
      Cell: ({ value }) => (
        <ButtonGroup justifyContent="center">
          <IconButton
            iconType="edit"
            iconSize={16}
            variant="text"
            type="primary"
            onClick={() =>
              openEditPaymentCardModal(value.accountHolderId, value)}
          />
          <IconButton
            iconType="delete"
            iconSize={16}
            variant="text"
            type="danger"
            onClick={openConfirmDeleteModal(value)}
          />
        </ButtonGroup>
      ),
      sortable: false,
      filterable: false,
    },
  ];

  return (
    <>
      <Accounts>
        {error && <Alert type="danger">{error}</Alert>}
        {success && <Alert type="success">{success}</Alert>}
        <AccountsTableWrapper>
          {activePaymentCards.length > 0 ? (
            <AccountTable
              data={activePaymentCards}
              columns={columns}
              getTrProps={(state, rowInfo) => {
                if (rowInfo && rowInfo.original) {
                  return {
                    style: {
                      border: rowInfo.original.default && '2px solid #fcf8e3',
                    },
                  };
                }
                return {};
              }}
            />
          ) : (
            <NoAccountsTable> No Accounts Available </NoAccountsTable>
          )}
        </AccountsTableWrapper>
        <ActionBar>
          <ButtonGroup>
            <Button
              type="primary"
              onClick={() => openCreatePaymentCardModal(id)}
            >
              Add Payment Card
            </Button>
          </ButtonGroup>
        </ActionBar>
      </Accounts>
      {createPaymentCardModal && (
        <AddNewCardModal
          accountHolderId={accountHolderInfo}
          tokenizationKey={tokenizationKey}
          createAccountHolderFundingSource={createAccountHolderFundingSource}
          closeModal={closeCreatePaymentCardModal}
          updateError={updateError}
          updateSuccess={updateSuccess}
        />
      )}
      {editPaymentCardModal && (
        <UpdateCardModal
          accountHolderId={accountHolderInfo}
          tokenizationKey={tokenizationKey}
          updateFinancialAccount={updateFinancialAccount}
          selectedFinancialAccount={selectedFA}
          closeModal={closeEditPaymentCardModal}
          updateError={updateError}
          updateSuccess={updateSuccess}
        />
      )}
      {confirmDeleteModal && (
        <ConfirmDelete
          onClick={deleteAccountHolderFundingSource}
          closeModal={closeConfirmDeleteModal}
          removeValue={`${
            removeAccountInfo.brand
              ? `${capitalize(removeAccountInfo.brand)}`
              : 'Bank Account'
          } ending in ${removeAccountInfo.lastFourDigits}`}
        />
      )}
    </>
  );
};
