import React from 'react';
import { Alert, Button } from 'shared-components';

export default () => (
  <>
    <Alert type="danger">
      Error connecting to Berkeley Payments. Wait for a second and then click
      the retry button below
    </Alert>
    <Button type="primary" onClick={() => window.location.reload()}>
      Retry
    </Button>
  </>
);
