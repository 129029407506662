import React from 'react';
// Import Global Components
import { Loader, Title } from 'shared-components';
// Import Component Styles
import { ConfirmTransferModal } from './styles';

export default () => (
  <ConfirmTransferModal>
    <Title>Processing Transfer...</Title>
    <Loader />
  </ConfirmTransferModal>
);
