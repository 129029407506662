import { capitalize, humanizeString } from 'shared-components/src/utils/string';
import { formatTime } from 'shared-components/src/utils/formatDateTime';
import {
  formatCurrencyCode,
  formatCurrencyV,
} from 'shared-components/src/utils/formatCurrency';

export const getProgramColumns = (timeZone) => [
  [
    {
      header: 'Program Id:',
      accessor: 'id',
    },
    {
      header: 'Program Name:',
      accessor: 'name',
    },
    {
      header: 'Created On:',
      accessor: 'created_at',
      cell: value => formatTime(value, 'longDate', timeZone),
    },
  ],
  [
    {
      header: 'Program Status:',
      accessor: 'status',
      cell: value => capitalize(value),
    },
    {
      header: 'Program Type:',
      accessor: 'program_type',
      cell: value => humanizeString(value),
    },
    {
      header: 'Processor Reference:',
      accessor: 'processor_reference',
    },
  ],
];

export const escrowColumns = [
  {
    header: 'Account Number:',
    accessor: 'account_number',
  },
  {
    header: 'Balance:',
    accessor: 'balance',
    cell: val => formatCurrencyV(val),
  },
  {
    header: 'Currency:',
    accessor: 'currency',
    cell: val => formatCurrencyCode(val),
  },
];
