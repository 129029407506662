import React from 'react';
import { withTheme } from 'styled-components';

import { LegendGroup, LegendItem, Circle, LegendLabel } from './styles';

export default withTheme(({ theme }) => (
  <>
    <LegendGroup>
      <LegendItem>
        <Circle color={`${theme.brand.primary}`} />
        <LegendLabel>Pushes</LegendLabel>
      </LegendItem>
      <LegendItem>
        <Circle color={`${theme.brand.secondary}`} />
        <LegendLabel>Pulls</LegendLabel>
      </LegendItem>
    </LegendGroup>
  </>
));
