import React from 'react';
import {
  capitalize,
  removeSnakeCase,
} from 'shared-components/src/utils/string';
import { formatTime } from 'shared-components/src/utils/formatDateTime';
import { Button } from 'shared-components';
import { IconButtonGroup } from '../../../../components/IconButton';

export default (timeZone, resendVirtualEmail, a) => [
  {
    accessor: 'number',
    Header: 'Number',
  },
  {
    accessor: 'last_four_digits',
    Header: 'Last Four Digits',
  },
  {
    accessor: 'expiry_date',
    Header: 'Expiry Date',
    Cell: ({ value }) => formatTime(value, 'expiryDate', timeZone),
  },
  {
    accessor: 'status',
    Header: 'Status',
    Cell: ({ value }) => removeSnakeCase(capitalize(value)),
  },
  {
    accessor: 'order_status',
    Header: 'Order Status',
  },
  {
    accessor: 'order_tracking_number',
    Header: 'Tracking Number',
  },
  {
    accessor: card => card,
    id: 'action',
    Header: 'Action',
    Cell: (card) => (
      ['not_active'].includes(card.value.status) 
      && ['virtual_reloadable', 'virtual_nonreloadable'].includes(
      a.program_type
      )) ? (
      <IconButtonGroup>
        <>
          <Button
            type="primary"
            variant="text"
            onClick={() => resendVirtualEmail(a)}
          >
            Resend email
          </Button>
        </>
      </IconButtonGroup>
    ) : (
      <div />
    ), 
  },
];
