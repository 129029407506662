import React from 'react';
// Import Shared Components
import { Card, Title, Table, Button, ActionBar } from 'shared-components';
// Import Utils
import { formatCurrencyV } from 'shared-components/src/utils/formatCurrency';
import { centsToDollars } from 'shared-components/src/utils/currency';

export default ({
  valueLoads,
  setValueLoads,
  setLoadStep,
  setSuccess,
  setError,
  setBulkerStep,
  handleValueLoadSubmit,
}) => {

  const columns = [
    {
      Header: 'Account Id',
      accessor: 'account_id'
    },
    {
      Header: 'External Tag',
      accessor: 'external_tag'
    },
    {
      Header: 'Message',
      accessor: 'message'
    },
    {
      Header: 'Amount',
      accessor: 'amount',
      Cell: ({ value }) => formatCurrencyV(centsToDollars(value))
    },
  ];

  return (
    <Card>
      <Title>Value Loads</Title>
      <p>
        <i>
          Please take the time to review the uploaded value load information
          below for correctness before completing.
        </i>
      </p>
      <Table columns={columns} data={valueLoads} />
      <ActionBar justifyContent="space-between">
        <Button
          type="secondary"
          onClick={() => {
            setValueLoads([]);
            setLoadStep(1);
            setSuccess(null);
            setBulkerStep(2);
            setError(null);
          }}
        >
          Back
        </Button>
        <Button type="primary" onClick={() => handleValueLoadSubmit()}>
          Create Value Loads
        </Button>
      </ActionBar>
    </Card>
  );
};
