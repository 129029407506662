import {
  isVirtualProgram,
  getProgramCountry,
  getShippingMethods,
} from '../../../../../utils/programs';

// Function for determining the correct CSV requirements
export const getCSVFieldDetails = selectedProgram => {
  const type = isVirtualProgram(selectedProgram) ? 'virtual' : 'physical';
  const country = getProgramCountry(selectedProgram);
  const shippingMethods = getShippingMethods(selectedProgram);
  const requiresSinVerification = selectedProgram.requires_sin_verification;

  // Default is Canadian & virtual
  let baseDetails = [
    {
      field: 'External Tag',
      required: 'No',
      requirements: 'Maximum 255 characters: letters, numbers, underscores, periods and spaces.',
      notes:
        'Free form text field that will be stored with value load record to allow for storing additional information.',
      example: 'Order 2253',
    },
    {
      field: 'First Name',
      required: 'Yes',
      requirements:
        "1-40 characters; letters, dashes, periods, spaces and apostrophes.",
      notes: "Cardholder's first name",
      example: 'Ed',
    },
    {
      field: 'Last Name',
      required: 'Yes',
      requirements:
        "1-40 characters; letters, dashes, periods, spaces and apostrophes.",
      notes: "Cardholder's last name",
      example: 'Harley',
    },
    {
      field: 'Email Address',
      required: 'Yes',
      requirements: 'Valid email address',
      notes: "Cardholder's email",
      example: 'user@domain.com',
    },
    {
      field: 'Phone Number',
      required: 'Yes',
      requirements: '10 numbers. Valid phone number',
      notes: "Cardholder's primary phone number",
      example: '8013650394',
    },
    {
      field: 'Date of Birth',
      required: 'Yes',
      requirements: 'Valid Date, DD-MM-YYYY',
      notes: "Cardholder's date of birth",
      example: '01-01-1980',
    },
    {
      field: 'Address Line 1',
      required: 'Yes',
      requirements: 'Maximum length of 40 characters. Cannot be a P.O. Box',
      notes: "Cardholder's address line 1",
      example: '33 Main Street',
    },
    {
      field: 'Address Line 2',
      required: 'No',
      requirements: 'Maximum length of 30 characters.',
      notes: 'Maximum length of 30 characters.',
      example: 'Apt 4B',
    },
    {
      field: 'City',
      required: 'Yes',
      requirements: 'Letters and spaces, maximum length of 20 characters',
      notes: "Cardholder's city",
      example: 'Toronto',
    },
    {
      field: 'State/Province',
      required: 'Yes',
      requirements: 'Valid 2 character state abbreviation.',
      notes: "Cardholder's state (US) or province (CA)",
      example: 'ON',
    },
    {
      field: 'Postal Code',
      required: 'Yes',
      requirements: '12345 or 12345-1234 (US), M5V-2M7 or M5V2M7 (CA).',
      notes: "Cardholder's postal or zip code.",
      example: 'M5V-2M7 or M5V2M7',
    },
    {
      field: 'Country',
      required: 'Yes',
      requirements: 'Valid 2 character country abbreviation.',
      notes: "Cardholder's country.",
      example: 'CA',
    },
    {
      field: 'Load Amount',
      required: 'No',
      requirements: 'Monetary amount greater than 0',
      notes:
        'Currency amounts passed as a whole or fractional amounts. Initial load amount on card must be within product load limits or designated amount for Instant Issue card.',
      example: '100 or 100.50',
    },
    {
      field: 'Emboss Line 2',
      required: 'No',
      requirements: "Alphanumeric 0-23 characters in length, '-' allowed.",
      notes: 'Second line on card emboss text. ONLY available for mag cards. If used on emv cards, the order will fail at production.',
      example: 'Line beneath either Cardholder Name or Company Name on a card.',
    },
    {
      field: 'SIN/SSN',
      required: 'No',
      requirements: 'Valid SIN / SSN for Canada / USA respectively.',
      notes:
        'For KYC Evaluation. This is a required field for all U.S. programs that require a KYC verification check. It is an optional field for programs issued in Canada.',
      example: '987654321',
    },
  ];
  // Only physical cards requires shipping method
  if (type === 'physical') {
    const shippingMethod = {
      field: 'Shipping Method',
      required: 'Yes',
      requirements: 'Valid 1 character Shipping Method type.',
      notes: shippingMethods,
      example: '1',
    };
    // Add shipping method at position 12 so fields in csv same order as requirements
    baseDetails = [
      ...baseDetails.slice(0, 12),
      shippingMethod,
      ...baseDetails.slice(12),
    ];
    const shippingAddress = [
      {
        field: 'Shipping First Name',
        required: 'Yes (Conditional)',
        requirements:
          "1-40 characters; letters, spaces, numbers, and some punctuation: , . ? @ & ! # ' ~ * - ; +",
        notes: 'Used for the purpose of "Attention to" First Name',
        example: 'Gary',
      },
      {
        field: 'Shipping Last Name',
        required: 'Yes (Conditional)',
        requirements:
          "1-40 characters; letters, spaces, numbers, and some punctuation: , . ? @ & ! # ' ~ * - ; +",
        notes: 'Used for the purpose of "Attention to" Last Name',
        example: 'Raymond',
      },
      {
        field: 'Shipping Address Line 1',
        required: 'Yes (Conditional)',
        requirements: 'Maximum length of 40 characters. Cannot be a P.O. Box',
        notes: 'Shipping address line 1',
        example: '33 Main Street',
      },
      {
        field: 'Shipping Address Line 2',
        required: 'No',
        requirements: 'Maximum length of 30 characters.',
        notes: 'Maximum length of 30 characters.',
        example: 'Apt 4B',
      },
      {
        field: 'Shipping Address City',
        required: 'Yes (Conditional)',
        requirements: 'Letters and spaces, maximum length of 20 characters',
        notes: 'Shipping address city',
        example: 'Toronto',
      },
      {
        field: 'Shipping Address State/Province',
        required: 'Yes (Conditional)',
        requirements: 'Valid 2 character state abbreviation.',
        notes: 'Shipping address state (US) or province (CA)',
        example: 'ON',
      },
      {
        field: 'Shipping Address Postal Code',
        required: 'Yes (Conditional)',
        requirements: '12345 or 12345-1234 (US), M5V-2M7 or M5V2M7 (CA).',
        notes: 'Shipping address postal or zip code.',
        example: 'M5V-2M7 or M5V2M7',
      },
      {
        field: 'Shipping Address Country',
        required: 'Yes (Conditional)',
        requirements: 'Valid 2 character country abbreviation.',
        notes: 'Shipping address country.',
        example: 'CA',
      },
    ];
     // Insert shipping method to index 12 so that field order in the CSV requirement table matches with the to be filled CSV sheet column order
    baseDetails = [...baseDetails, ...shippingAddress];
    // Check if program is "galileo" and append "Location Id" at the end for bulk shipping
    if (selectedProgram.processor_name === 'galileo') {
      const locationIdField = {
        field: 'Location Id',
        required: 'No',
        requirements: "Numeric Location Id for Bulk Shipping",
        notes: 'Must be a valid location id.',
        example: '189',
      };
      baseDetails.push(locationIdField);
    }
  }
  if (country === 'USA') {
    const birthdayIndex = baseDetails.findIndex(
      e => e.field === 'Date of Birth'
    );
    // birthday is REQUIRED field only if you are issuing cards in Canada
    baseDetails[birthdayIndex].required = 'No';

    const phoneIndex = baseDetails.findIndex(e => e.field === 'Phone Number');
    // Phone is REQUIRED field only if you are issuing cards in Canada (carta)
    baseDetails[phoneIndex].required = 'No';
  }
  if (requiresSinVerification) {
    const sinIndex = baseDetails.findIndex(e => e.field === 'SIN/SSN');
    // SIN is REQUIRED field if specified under program
    baseDetails[sinIndex].required = 'Yes';
  }
  return baseDetails;
};
