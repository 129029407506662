import styled from 'styled-components';

export const Program = styled.div`
  display: flex;
  flex-direction: column-reverse;
  width: 100%;

  ${({ theme }) => theme.media.tabletLandscapeUp`
    flex-direction: row;
  `}
`;

export const ProgramDetails = styled.div`
  width: 100%;

  ${({ theme }) => theme.media.tabletLandscapeUp`
      margin-bottom: 0;
      margin-left: 25px;
  `};
`;
