// Import Shared Components utils
import { formatCurrencyV } from 'shared-components/src/utils/formatCurrency';
import { formatTime } from 'shared-components/src/utils/formatDateTime';

// Describes value loads table under cardholder details
// Does not include information such as cardholder_id & account_id which already exists on cardholder details
const getValueLoadColumns = timeZone => [
  {
    Header: 'ID',
    accessor: 'id',
    sortType: 'basic',
  },
  {
    Header: 'External Tag',
    accessor: 'external_tag',
    Cell: ({ value }) => (value && value.length > 0 ? value : '--'),
  },
  {
    Header: 'Value Load Processor Reference Number (PRN)',
    accessor: 'processor_reference',
  },
  {
    Header: 'Load Type',
    accessor: 'load_type',
    show: false,
  },
  {
    Header: 'Amount',
    accessor: 'amount',
    Cell: ({ value, row }) => {
      return row.load_type === 'unload'
        ? formatCurrencyV(-value / 100)
        : formatCurrencyV(value / 100);
    },
  },
  {
    Header: 'Created On',
    accessor: 'created_at',
    Cell: ({ value }) => formatTime(value, 'longDate', timeZone),
  },
];

export default getValueLoadColumns;
