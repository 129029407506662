import React from 'react';
import PropTypes from 'prop-types';
// Import Shared Components
import { Details, DetailsGroup, Title } from 'shared-components';
import { formatLocalDateTime } from 'shared-components/src/utils/formatDateTime';
import { formatCurrencySVC } from 'shared-components/src/utils/formatCurrency';
// Import Component Styles
import { PayoutTotal, StyledRedeemDetails } from './styles';

const RedeemDetails = ({
  amount,
  sender,
  date,
  notes,
  currency,
  saveAsDefault,
  updateSaveAsDefault,
  support_company_name,
  support_email,
  support_phone_number
}) => {
  const columns = [
    {
      header: 'From / D\'après:',
      accessor: 'sender',
    },
    {
      header: 'Date Sent / Date D\'envoi :',
      accessor: 'date',
      cell: value => formatLocalDateTime(value),
    },
    {
      header: 'Notes / Remarques:',
      accessor: 'notes',
      cell: value => (value === '' ? '--' : value),
    },
  ];

  return (
    <StyledRedeemDetails>
      <div>
        <Title>You've received a payment! / Vous avez reçu un paiement!</Title>
        <PayoutTotal>{formatCurrencySVC(amount / 100, currency)}</PayoutTotal>
      </div>
      <DetailsGroup>
        <Details
          key="payout-details"
          columns={columns}
          data={{ sender, date, notes }}
        />
      </DetailsGroup>
    </StyledRedeemDetails>
  );
};

RedeemDetails.propTypes = {
  amount: PropTypes.string.isRequired,
  sender: PropTypes.string.isRequired,
  date: PropTypes.instanceOf(Date).isRequired,
  notes: PropTypes.string,
  currency: PropTypes.number.isRequired,
  saveAsDefault: PropTypes.bool.isRequired,
  updateSaveAsDefault: PropTypes.func.isRequired,
};

RedeemDetails.defaultProps = {
  notes: null,
};

export default RedeemDetails;
