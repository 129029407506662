import React, { useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
// Import Shared Components
import { SubmitButton } from 'shared-components';
import {
  isEmailBlurbValid,
} from 'shared-components/src/validations/collectionValidations';
// Import Global Components
import Label from '../../../../components/Label';
// Import Component Styles
import {
  Area,
  Input,
  Form,
  FormGroup,
  InputGroup,
  InputContainer,
} from '../styles';

export default injectIntl(
  ({ intl, handleFormSubmission, expiryWindow, blurb }) => {
    const [valid, setValid] = useState(false);
    const [error, setError] = useState({
      emailExpiryWindowError: false, 
      emailBlurbError: false
    });
    const [emailExpiryWindow, setEmailExpiryWindow] = useState(expiryWindow);
    const [emailBlurb, setEmailBlurb] = useState(blurb);

    useEffect(() => {
      setError({
        emailExpiryWindowError: false, 
        emailBlurbError: false
      });
      const emailExpiryWindowValid = emailExpiryWindow > 0;
      const emailBlurbValid = isEmailBlurbValid(emailBlurb);

      if (emailExpiryWindowValid && emailBlurbValid) {
        setValid(true);
      } else {
        setValid(false);
        setError({
          emailExpiryWindowError: emailExpiryWindow === 0 ? false : !emailExpiryWindowValid, 
          emailBlurbError: emailBlurb === '' ? false : !emailBlurbValid,
        });
      }
    }, [emailExpiryWindow, emailBlurb]);

    const handleOnSubmit = (e) => {
      e.preventDefault();

      handleFormSubmission(emailExpiryWindow, emailBlurb);
    };

    const disabled = !(valid);

    return (
      <Form id="configuration" onSubmit={disabled ? () => {} : handleOnSubmit}>
        <FormGroup form="configuration">
          <InputGroup>
            <InputContainer>
              <Label
                label='Collection Expiry Window:'
                labelFor="expiry-window"
                page="configuration"
              />
              <Input
                onChange={e => setEmailExpiryWindow(e.target.value)}
                type='Number'
                value={emailExpiryWindow}
                error={error.emailExpiryWindowError}
                required
              />
            </InputContainer>
            <InputContainer>
              <Label
                label='Collection Email Message:'
                labelFor="email-blurb"
                page="configuration"
              />
              <Area 
                onChange={e => setEmailBlurb(e.target.value)}
                value={emailBlurb}
                error={error.emailBlurbError}
                required
              />
            </InputContainer>
          </InputGroup>
        </FormGroup>
        <SubmitButton
          buttonType="primary"
          size="large"
          value={intl.formatMessage({
            id: 'configuration-submit-button',
            defaultMessage: 'Update',
          })}
          disabled={disabled}
        />
      </Form>
    );
  }
);
