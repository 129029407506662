import { compose } from 'recompose';
import {
  renderWhileLoadingTwoQueries,
  setRefetchProp,
  renderForErrorTransferDetails,
} from '../../../state/apolloRecompose';
import TransferDetails from './TransferDetails';

import withSearchDataState from './enhancers/states/withSearchDataState';
import withParamsState from './enhancers/states/withParamsState';
import withSearchByState from './enhancers/states/withSearchByState';
import withTransferErrorState from './enhancers/states/withTransferErrorState';
import withHandlers from './enhancers/withHandlers';
import withSuccessState from './enhancers/states/withSuccessState';
import withSubtabState from './enhancers/states/withSubtabState';
import withErrorState from './enhancers/states/withErrorState';
import withShowResetButtonState from './enhancers/states/withShowResetButtonState';

// Enhancers
import withTransferNoSearchQuery from './enhancers/withTransferNoSearchQuery';
import withTransferWithSearchQuery from './enhancers/withTransferWithSearchQuery';
import withResendMutation from './enhancers/withResendMutation';
import withCancelPaymentMutation from './enhancers/withCancelPaymentMutation';
import withPaginationState from "./enhancers/states/withPaginationState";

export default compose(
  withSearchDataState,
  withSuccessState,
  withSubtabState,
  withErrorState,
  withSearchByState,
  withParamsState,
  withPaginationState,
  withTransferErrorState,
  withShowResetButtonState,
  withTransferNoSearchQuery,
  withTransferWithSearchQuery,
  withResendMutation,
  withCancelPaymentMutation,
  withHandlers,
  renderWhileLoadingTwoQueries,
  setRefetchProp,
  renderForErrorTransferDetails
)(TransferDetails);
