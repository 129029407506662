import React from 'react';
import { withTheme } from 'styled-components';
// Import Global Components
import { Icon } from 'shared-components';
// Import Component Styles
import { IconWrapper } from './styles';

export default withTheme(({ theme, type }) => (
  <IconWrapper>
    <Icon key={type} type={type} align="middle" color={theme.brand.tertiary} />
  </IconWrapper>
));
