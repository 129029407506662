import { humanizeString } from 'shared-components/src/utils/string';
import { formatTime } from 'shared-components/src/utils/formatDateTime';

export const getProgramColumns = (timeZone) => [
  [
    {
      header: 'Id:',
      accessor: 'id',
    },   
    {
      header: 'Program Id:',
      accessor: 'program_id',
    },
    {
      header: 'Order Items:',
      accessor: 'count',      
    },   
  ],
  [   
    {
      header: 'Description:',
      accessor: 'description',
      cell: value => humanizeString(value),
    },
    {
      header: 'Status:',
      accessor: 'status',      
    },
    {
      header: 'Type:',
      accessor: 'type',
      cell: value => humanizeString(value),
    },  
  ],
  [
    {
      header: 'External Tag:',
      accessor: 'external_tag',
    },  
    {
      header: 'Created At:',
      accessor: 'created_at',
      cell: value => formatTime(value, 'longDate', timeZone),
    },         
    {
      header: 'Updated At:',
      accessor: 'updated_at',
      cell: value => formatTime(value, 'longDate', timeZone),
    },
  ],
];