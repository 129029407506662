import React from 'react';
// Import Shared Components
import { Tag } from 'shared-components';
import { capitalize } from 'shared-components/src/utils/string';

export default ({ role }) => {
  const formattedRole = capitalize(role);
  switch (role) {
  case 'owner':
    return <Tag type="primary">{formattedRole}</Tag>;
  case 'admin':
    return <Tag type="secondary">{formattedRole}</Tag>;
  case 'user':
    return <Tag type="success">{formattedRole}</Tag>;
  case 'developer':
    return <Tag type="info">{formattedRole}</Tag>;
  default:
    return null;
  }
};
