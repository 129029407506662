// Import Shared Utils
import { formatCountryCode } from 'shared-components/src/utils/formatCountry';
import { formatCurrencyV } from 'shared-components/src/utils/formatCurrency';

export default [
  {
    Header: 'Errors',
    expander: true,
    width: 64,
  },
  {
    Header: 'External Tag',
    accessor: row => row.cardholder.external_tag,
    id: 'external_tag',
    Cell: ({ value }) => (value.length > 0 ? value : '--'),
  },
  {
    Header: 'First Name',
    accessor: row => row.cardholder.first_name,
    id: 'first_name',
  },
  {
    Header: 'Last Name',
    accessor: row => row.cardholder.last_name,
    id: 'last_name',
  },
  {
    Header: 'Email',
    accessor: row => row.cardholder.email,
    id: 'email',
  },
  {
    Header: 'Date of Birth',
    accessor: row => row.cardholder.date_of_birth,
    id: 'date_of_birth',
  },
  {
    Header: 'Address Line 1',
    accessor: row => row.cardholder.address1,
    id: 'address1',
  },
  {
    Header: 'Postal Code',
    minWidth: 60,
    accessor: row => row.cardholder.postal_code,
    id: 'postal_code',
  },
  {
    Header: 'Country',
    accessor: row => row.cardholder.country,
    id: 'country',
    minWidth: 45,
    Cell: ({ value }) => formatCountryCode(value),
  },
  {
    Header: 'Value Load',
    accessor: row => row.cardholder.load_amount,
    id: 'load_amount',
    Cell: ({ value }) => formatCurrencyV(value / 100),
  },
  {
    Header: 'SIN/SSN',
    accessor: row => row.cardholder.sin,
    id: 'sin',
  },
];
