import styled from 'styled-components';

export const NoAccountHolders = styled.p`
  padding-bottom: 10px;
`;

export const Account = styled.p`
  color: ${({ accounts, theme }) =>
    accounts ? theme.colors.green : theme.colors.default};

  &:hover {
    color: ${({ accounts, theme }) =>
    accounts ? theme.colors.green : theme.colors.default};
  }
`;

export const TableHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
