// Import Shared Utils
import { formatCountryCode } from 'shared-components/src/utils/formatCountry';

export const CARDHOLDERCOLUMNS = [
  {
    accessor: 'id',
    header: 'Cardholder ID:',
  },
  {
    accessor: 'username',
    header: 'Username:',
    cell: value => value || "--",
  },
  {
    header: 'External Tag:',
    accessor: 'external_tag',
  },
  {
    header: 'Name:',
    cell: value => `${value.first_name} ${value.middle_name} ${value.last_name}`,
  },
  {
    accessor: 'email',
    header: 'Email:',
  },
  {
    accessor: 'phone',
    header: 'Phone:',
  },
  {
    accessor: 'date_of_birth',
    header: 'Date of Birth:',
  }
];

export const ADDRESSCOLUMNS = [
  {
    accessor: 'address1',
    header: 'Address Line 1:',
  },
  {
    accessor: 'address2',
    header: 'Address Line 2:',
  },
  {
    accessor: 'city',
    header: 'City:',
  },
  {
    accessor: 'state',
    header: 'State/Province:',
  },
  {
    accessor: 'postal_code',
    header: 'Zip/Postal Code:',
    cell: value => value.toUpperCase(),
  },
  {
    accessor: 'country',
    header: 'Country:',
    cell: value => formatCountryCode(value),
  }
];
