import React from 'react';

const Contact = () => (
  <address>
    If you have any questions, please visit us <a href="https://berkeleypayments.atlassian.net/wiki/x/2pIF">here</a> or contact us
    directly by submitting a ticket <a href="https://desk.zoho.com/portal/berkeleypayment/home">here</a>.
  </address>
);

export default Contact;
