import React from 'react';
import { withTheme } from 'styled-components';
import { formatTime } from 'shared-components/src/utils/formatDateTime';
import {
  VictoryChart,
  VictoryGroup,
  VictoryBar,
  VictoryAxis,
  VictoryTooltip,
} from 'victory';
// Import Hook
import { useTimeZone } from '../../../../hooks/useTimeZone';
// Import Component Styles
import { ChartWrapper } from './styles';

// export default withTheme(({ theme, data }) => {
export default withTheme(({ theme, dataSet }) => {
  const [timeZone] = useTimeZone();
  const grey = `${theme.brand.text}`;
  const colorScale = [`${theme.brand.primary}`, `${theme.brand.secondary}`];
  const label = ['Pushes', 'Pulls'];

  const tickStyles = {
    axis: {
      fill: grey,
      strokeWidth: 1,
    },
    ticks: {
      fill: grey,
      size: 3,
      stroke: grey,
    },
    tickLabels: {
      fontFamily: '\'Muli\',Helvetica Neue,Helvetica,Arial,sans-serif',
      fontSize: 10,
      letterSpacing: 'normal',
      padding: 10,
      fill: grey,
    },
  };

  return (
    <ChartWrapper>
      <VictoryChart domainPadding={15} padding={50}>
        <VictoryAxis
          // x-axis #1
          scale="time"
          style={tickStyles}
          // Victory rounds the time of x ticks to local midnight, which makes time zone conversions tricky
          // pass in the actual x ticks as workaround
          tickValues={dataSet[0].map(t => t.x)}
          tickFormat={t => `${formatTime(t, 'chartTick', timeZone)}`}
          
        />
        <VictoryAxis
          // x-axis #2
          scale="time"
          style={{
            axis: {
              fill: 'transparent',
              strokeWidth: 1,
            },
            ticks: {
              fill: 'transparent',
              stroke: 'transparent',
            },
            tickLabels: {
              fill: 'transparent',
            },
          }}
        />
        <VictoryAxis
          // y-axis
          dependentAxis
          label="Number of Pushes/Pulls Sent"
          scale="time"
          style={{
            grid: { stroke: '#e1e1e1' },
            axisLabel: { fontSize: 10, fill: grey, padding: 35 },
            ...tickStyles,
          }}
          tickFormat={t => `${Math.trunc(t)}`}
        />
        <VictoryGroup
          offset={15}
          animate={{
            duration: 800,
            onLoad: { duration: 500 },
          }}
          colorScale={colorScale}
        >
          {dataSet.map((data, idx) => {
            return (
              <VictoryBar
                id={`victory-bar-chart-${idx + 1}`}
                data={data}
                cornerRadius={2}
                barWidth={10}
                labels={({ datum }) => `${label[idx]}: ${datum.y}`}
                labelComponent={(
                  <VictoryTooltip
                    cornerRadius={1}
                    pointerLength={0}
                    flyoutStyle={{ stroke: colorScale[idx], fill: '#fff' }}
                    style={{ fill: grey, fontSize: 10 }}
                  />
                )}
              />
            );
          })}
        </VictoryGroup>
      </VictoryChart>
    </ChartWrapper>
  );
});
