import { compose, withState } from 'recompose';
import Auth from './Auth';

import withHandlers from './enhancers/withHandlers';

export default compose(
  withState('email', 'updateEmail', ''),
  withState('password', 'updatePassword', ''),
  withState('forgotPassword', 'updateForgotPassword', ''),
  withState('emailSent', 'updateEmailSent', false),
  withState('passwordConfirm', 'updatePasswordConfirm', ''),
  withState('formError', 'updateFormError', null),
  withState('user', 'updateUser', {}),
  withState('loading', 'updateLoading', false),
  withState('error', 'updateError', null),
  withState('alert', 'updateAlert', null),
  withHandlers
)(Auth);
