import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

const QUERY = gql`
  query {
    viewer {
      id
      company {
        id
        transactionLimits {
          id
          amount
          product
          currency
          networkType
          transactionType
          currency
        }
        velocityLimits {
          id
          amount
          product
          currency
          networkType
          transactionType
          trailingTimeWindow
          type
          currency
          currentStanding {
            sum
            count
          }
        }
        disbursementLimit
      }
      globalVelocityLimits {
        id
        product
        amount
        currency
        networkType
        transactionType
        trailingTimeWindow
        type
        currency
      }
      globalTransactionLimits {
        id
        product
        amount
        currency
        networkType
        transactionType
        currency
      }
    }
  }
`;

export default graphql(QUERY, {
  props: props => ({
    id: 2,
    loadingTwo: props.data.loading,
    limitsData: props.data.viewer,
    data: { error: props.data.error },
    ...props
  }),
  options: props => ({
    fetchPolicy: 'network-only'
  })
});
