import styled from 'styled-components';

export const Circle = styled.span`
  width: 12px;
  height: 12px;
  background-color: ${({ color }) => color};
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  margin: auto 0;
`;

export const LegendLabel = styled.p`
  padding-left: 0.64935em;
  color: ${({ theme }) => theme.brand.text};
`;

export const LegendItem = styled.span`
  display: flex;
  flex-direction: row;
`;

export const LegendGroup = styled.div`
  display: flex;
  flex-direction: row;

  & > *:not(:first-child) {
    margin-left: 20px;
  }
`;

export const LegendContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  & > *:last-child {
    margin-top: 5px;
  }
`;

export const ChartHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;

  & > *:last-child {
    margin-left: 15px;
  }
`;

export const ChartContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;

export const ChartWrapper = styled.div`
  margin: 0 auto;
  max-width: 750px;
`;
