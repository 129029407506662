import gql from 'graphql-tag';

import { FinancialAccount } from './financialAccountFragments';

export const AccountHolder = {
  fragments: {
    contactDetailsIndividual: gql`
      fragment ContactDetailsIndividual on CustomerIndividual {
        contactDetails {
          id
          fullName
          phone
        }
      }
    `,
    contactDetailsCompany: gql`
      fragment ContactDetailsCompany on CustomerCompany {
        contactDetails {
          id
          fullName
          phone
        }
      }
    `,
    contactDetailsSelf: gql`
      fragment ContactDetailsSelf on CustomerSelf {
        contactDetails {
          id
          fullName
          phone
        }
      }
    `,
    accountsIndividual: gql`
      fragment AccountsIndividual on CustomerIndividual {
        accounts {
          ... on PaymentCard {
            ...PaymentCardInfo
          }
          ... on BankAccount {
            ...BankAccountInfo
          }
          ... on EtransferCustomer {
            ...EtransferCustomerInfo
          }
        }
      }
      ${FinancialAccount.fragments.paymentCard}
      ${FinancialAccount.fragments.bankAccount}
      ${FinancialAccount.fragments.etransferCustomer}
    `,
    accountsCompany: gql`
      fragment AccountsCompany on CustomerCompany {
        accounts {
          ... on PaymentCard {
            ...PaymentCardInfo
          }
          ... on BankAccount {
            ...BankAccountInfo
          }
          ... on EtransferCustomer {
            ...EtransferCustomerInfo
          }
        }
      }
      ${FinancialAccount.fragments.paymentCard}
      ${FinancialAccount.fragments.bankAccount}
      ${FinancialAccount.fragments.etransferCustomer}
    `,
  },
};
