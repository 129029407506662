// List of fields needed to create an order.
// These will be the "headers" in the csv
export const physicalCardHeader = [
  [
    'ExternalTag',
    '*First Name',
    '*Last Name',
    '*Email Address',
    'Phone Number',
    'Date of Birth',
    '*Address Line 1',
    'Address Line 2',
    '*City',
    '*State/Province',
    '*Postal Code',
    '*Country',
    '*ShippingMethod',
    'Load Amount',
    'Emboss Line 2',
    'SIN/SSN',
    'Shipping Address First Name',
    'Shipping Address Last Name',
    'Shipping Address Line 1',
    'Shipping Address Line 2',
    'Shipping Address City',
    'Shipping Address State',
    'Shipping Address Postal Code',
    'Shipping Address Country',
  ]
];

export const galileoPhysicalCardHeader = [
  [
    ...physicalCardHeader[0],
    'Location Id'
  ]
];

export const virtualCardHeader = [
  [
    'ExternalTag',
    '*First Name',
    '*Last Name',
    '*Email Address',
    'Phone Number',
    'Date of Birth',
    '*Address Line 1',
    'Address Line 2',
    '*City',
    '*State/Province',
    '*Postal Code',
    '*Country',
    'Load Amount',
    'Emboss Line 2',
    'SIN/SSN'
  ]
];
