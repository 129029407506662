// Common translations (currently only password requirement) from shared-components
import sharedTranslations from 'shared-components/src/i18n/translations';
// Translations for pages regarding User Authorization
import { loginEn, loginFr } from './routes/auth/login';
import {
  forgotPasswordEn,
  forgotPasswordFr
} from './routes/auth/forgotPassword';
import { resetPasswordEn, resetPasswordFr } from './routes/auth/resetPassword';
import { registerEn, registerFr } from './routes/auth/register';
// Translations for pages regarding Dashboard
import { dashboardEn, dashboardFr } from './routes/dashboard';
// Translations for pages regarding Card Issuing
import {
  orderDetailsEn,
  orderDetailsFr
} from './routes/cardIssuing/orderDetails';
import { createOrderEn, createOrderFr } from './routes/cardIssuing/createOrder';
import { cardHoldersEn, cardHoldersFr } from './routes/cardIssuing/cardHolders';
import { escrowEn, escrowFr } from './routes/cardIssuing/escrow';
import {
  programDetailsEn,
  programDetailsFr
} from './routes/cardIssuing/programDetails';
import { ciReportingEn, ciReportingFr } from './routes/cardIssuing/reporting';
// Translations for pages regarding Real Time Transfers
import {
  createTransferEn,
  createTransferFr
} from './routes/realTimeTransfers/createTransfer';
import {
  transferHistoryEn,
  transferHistoryFr
} from './routes/realTimeTransfers/transferHistory';
import {
  transferDetailsEn,
  transferDetailsFr
} from './routes/realTimeTransfers/transferDetails';
import {
  accountHoldersEn,
  accountHoldersFr
} from './routes/realTimeTransfers/accountHolders';
import {
  accountHolderDetailsEn,
  accountHolderDetailsFr
} from './routes/realTimeTransfers/accountHolderDetails';
import { limitsEn, limitsFr } from './routes/realTimeTransfers/limits';
import {
  rttReportingEn,
  rttReportingFr
} from './routes/realTimeTransfers/reporting';
// Translations for pages regarding Settings
import { companyEn, companyFr } from './routes/settings/company';
import { subscriptionEn, subscriptionFr } from './routes/settings/subscription';
import { usersEn, usersFr } from './routes/settings/users';
// Translations for page regarding Api
import { apiEn, apiFr } from './routes/api';
// Translations for page regarding Help
import { helpEn, helpFr } from './routes/help';
// Translations for page regarding Not Found
import { notFoundEn, notFoundFr } from './routes/notFound';
// Translations for page regarding Redeeming Payouts
import { redeemEn, redeemFr } from './routes/redeem';
// Translations for pages regarding Collections
import {
  createCollectionsEn,
  createCollectionsFr
} from './routes/collections/createCollection';
// Translations for cookie
import { cookieEn, cookieFr } from './routes/cookie';

export default {
  en: {
    ...sharedTranslations.en,
    ...loginEn,
    ...forgotPasswordEn,
    ...resetPasswordEn,
    ...registerEn,
    ...dashboardEn,
    ...orderDetailsEn,
    ...createOrderEn,
    ...cardHoldersEn,
    ...escrowEn,
    ...programDetailsEn,
    ...ciReportingEn,
    ...createTransferEn,
    ...transferHistoryEn,
    ...transferDetailsEn,
    ...accountHoldersEn,
    ...accountHolderDetailsEn,
    ...limitsEn,
    ...rttReportingEn,
    ...companyEn,
    ...subscriptionEn,
    ...usersEn,
    ...apiEn,
    ...helpEn,
    ...notFoundEn,
    ...redeemEn,
    ...createCollectionsEn,
    ...cookieEn
  },
  fr: {
    ...sharedTranslations.fr,
    ...loginFr,
    ...forgotPasswordFr,
    ...resetPasswordFr,
    ...registerFr,
    ...dashboardFr,
    ...orderDetailsFr,
    ...createOrderFr,
    ...cardHoldersFr,
    ...escrowFr,
    ...programDetailsFr,
    ...ciReportingFr,
    ...createTransferFr,
    ...transferHistoryFr,
    ...transferDetailsFr,
    ...accountHoldersFr,
    ...accountHolderDetailsFr,
    ...limitsFr,
    ...rttReportingFr,
    ...companyFr,
    ...subscriptionFr,
    ...usersFr,
    ...apiFr,
    ...helpFr,
    ...notFoundFr,
    ...redeemFr,
    ...createCollectionsFr,
    ...cookieFr
  }
};
