export default [
  {
    Header: 'ID',
    accessor: 'row_id',
    id: 'row_id',
  },
  {
    Header: 'Qty',
    accessor: 'qty',
    id: 'qty',
  },
  {
    Header: 'First name',
    accessor: 'first_name',
    id: 'first_name',
  },
  {
    Header: 'Last name',
    accessor: 'last_name',
    id: 'last_name',
  },
  {
    Header: 'Fourth line embossing',
    accessor: 'fourth_line_embossing',
    id: 'fourth_line_embossing',
  },
  {
    Header: 'Address Line 1',
    accessor: 'address_line_1',
    id: 'address_line_1',
  },
  {
    Header: 'Address Line 2',
    accessor: 'address_line_2',
    id: 'address_line_2',
  },
  {
    Header: 'City',
    accessor: 'city',
    id: 'city',
  },
  {
    Header: 'Province/ State',
    accessor: 'province_state',
    id: 'province_state',
  },
  {
    Header: 'Country',
    accessor: 'country',
    id: 'country',
  },
  {
    Header: 'Post/ Zip code',
    accessor: 'post_zip_code',
    id: 'post_zip_code',
  },
  {
    Header: 'Shipping To',
    accessor: 'shipping_to',
    id: 'shipping_to',
  },
];

