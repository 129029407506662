import React from 'react';
// Import Global Components
import RegisterForm from '../RegisterForm';

export default ({ user, updateFormError, registerUser, ...props }) => (
  <RegisterForm
    email={user.email}
    title="Register"
    onClick={registerUser}
    btnText="Submit"
    {...props}
  />
);
