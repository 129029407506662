import { setToken, getToken } from 'shared-components/src/utils/auth';

export const refresh = async () => {
  const { token } = getToken();

  return fetch('/api/refresh', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ token }),
  })
    .then(res => {
      if (!res.ok)
        throw new Error(
          'Unable to refresh session. Please try again or wait for login screen'
        );
      return res.json();
    })
    .then(({ exp, token }) => {
      setToken(token, exp);
    });
};

export const login = async (email, password) => {
  return fetch('/api/login', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email, password }),
  })
    .then(res => {
      if (!res.ok) throw new Error('Failed to Login User');
      return res.json();
    })
    .then(({ exp, token }) => {
      setToken(token, exp);
      window.location.pathname = '/';
    });
};

export const forgotPassword = async (email, emailSent, updateEmailSent) => {
  return fetch('/api/v1/forgot_password', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email }),
  })
    .then(res => {
      if (!res.ok) throw new Error('Failed to Request Password Reset');
      return res.json();
    })
    .then(updateEmailSent(true));
};

export const register = async payload => {
  return fetch('/api/v1/register_user', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  }).then(res => {
    if (!res.ok) throw new Error('Failed to Register User');
    return res.json();
  });
};

export const resetPassword = async payload => {
  return fetch('/api/v1/reset_password', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  }).then(res => {
    if (!res.ok) throw new Error('Failed to Reset User Password');
    return res.json();
  });
};
