import styled from 'styled-components';

// Wraps cards containing search filters for transfer history
export const Header = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  /* Card wrapping search component */
  & > *:first-child {
    flex: 2 0 0;
  }

  /* Card wrapping create button */
  & > *:last-child {
    display: flex;
    justify-content: flex-end;
    align-items: stretch;
    flex: 1;
    margin-top: 15px;

    & > div {
      flex: 1;
    }
  }

  ${({ theme }) => theme.media.tabletLandscapeUp`
    flex-direction: row;

    & > *:last-child {
      margin-top: 0;
      margin-left: 15px;
    }

  `}
`;

// Header to Table that contains Title and CSV button
export const TableHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
