import React from 'react';
import PropTypes from 'prop-types';
import Label from '../../components/Label';
// Import Component Styles
import { StyledInputGroup, StyledLabelGroup } from './styles';

const InputGroup = ({
  label,
  labelFor,
  dataId,
  required,
  tooltip,
  page,
  children,
}) => {
  return (
    <StyledInputGroup data-id={dataId}>
      <StyledLabelGroup>
        <Label
          labelFor={labelFor}
          label={label}
          page={page}
          required={required}
        />
        {tooltip}
      </StyledLabelGroup>
      {children}
    </StyledInputGroup>
  );
};

InputGroup.propTypes = {
  label: PropTypes.node.isRequired,
  labelFor: PropTypes.string.isRequired,
  dataId: PropTypes.string,
  required: PropTypes.bool,
  tooltip: PropTypes.element,
  page: PropTypes.string,
  children: PropTypes.node.isRequired,
};

InputGroup.defaultProps = {
  dataId: null,
  required: null,
  tooltip: null,
  page: null,
};

export default InputGroup;
