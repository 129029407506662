export default {
  disabled: '#95989A',
  danger: '#db2828',
  error: '#FF9494',
  blue: '#2185d0',
  green: '#5cb85c',
  red: '#E23636',
  lightBlue: '#5bc0de',
  orange: '#f2711c',
  yellow: '#fbbd08',
  lilac: '#bb8fce',
  grey: '#F0F0F2',
  border: '#ccc',
  label: '#00000099',
  default: '#7d8084',
  black: '#343a40',
  tableBg: '#fff',
  tableStripedBg: '#F5F6FA',
  errorTableBg: '#F2DEDE',
  errorTableColor: '#A94442',
  errorTableBorder: '#EBCCD1',
};
