import styled from 'styled-components';

export const InvalidTotal = styled.p`
  color: ${({ theme }) => theme.colors.redError};
`;

export const ValidTotal = styled.p`
  margin: 0;
  font-size: 18.72px;
  color: ${({ theme }) => theme.colors.green};
`;
