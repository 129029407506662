import styled from 'styled-components';
import {DetailsGroup } from 'shared-components';

export const StyledRedeemResult = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-width: 300px;

  ${({theme}) => theme.media.tabletLandscapeUp`
    min-width: 610px;
    max-width: 700px;
  `}
`;

export const DetailsWrapper = styled(DetailsGroup)`
  width: 100%;

  ${({theme}) => theme.media.tabletPortraitUp`
    display: flex;
    flex: 1;
    justify-content: space-between

    & > * {
      width: 45%;
      margin-bottom: 15px;
    }
  `}
`;

export const RedeemMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;
  border-bottom: ${({ theme }) => theme.borders.default};
  padding-bottom: 15px;

  ${({approved}) => approved && `
    position: relative;

    & > *:first-child {
      position: absolute;
      top: -52px;
    }

    & > *:not(:first-child) {
      margin-top: 30px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    `
}
`;
