import React from 'react';
import PropTypes from 'prop-types';
// Import Shared Components
import { Button, Details } from 'shared-components';
// Import Shared Constants
import { program } from 'shared-components/src/constants/propTypes';
// Import Utils
import {
  capitalize,
  removeSnakeCase,
} from 'shared-components/src/utils/string';

const SelectedProgramDetails = ({ program, changeProgram }) => {
  const columns = [
    {
      accessor: 'name',
      header: 'Name:',
    },
    {
      accessor: 'program_type',
      header: 'Type:',
      cell: value => capitalize(removeSnakeCase(value)),
    },
  ];
  return (
    <>
      <Details columns={columns} data={program} />
      <Button type="primary" onClick={() => changeProgram(true)}>
        Change Program
      </Button>
    </>
  );
};

SelectedProgramDetails.propTypes = {
  program: program.isRequired,
  changeProgram: PropTypes.func.isRequired,
};

export default SelectedProgramDetails;
