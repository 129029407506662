import styled from 'styled-components';

export const ProgressWrapper = styled.div`
  max-width: 640px;
  height: 2em;
  background-color: ${({ theme }) => theme.colors.border};
  border-radius: 0.1em;
`;

export const ProgressBar = styled.div`
  width: ${({ usedValue }) => `${usedValue}%`}};
  height: 100%;
  background-color: ${({ usedValue, theme }) => {
    if (usedValue <= 75) return theme.colors.green;
    if (usedValue >= 90) return theme.colors.red;
    if (usedValue < 90 && usedValue > 75) return theme.colors.orange;
  }};
  border-radius: 0.1em;
`;
