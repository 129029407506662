import React from 'react';
import ReactTable from 'react-table';
// Import Component Styles
import { Table } from './styles';

export default ({
  columns = [],
  data = [],
  minRows = 1,
  defaultSorted = [{ id: 'id', asc: true }],
  defaultPageSize = 10,
  showPagination,
  ...props
}) => {
  const pagination = !!(showPagination || data.length > 10);
  return (
    <Table>
      <ReactTable
        data={data}
        columns={columns}
        minRows={minRows}
        className="table"
        defaultPageSize={defaultPageSize}
        defaultSorted={defaultSorted}
        showPagination={pagination}
        showPageJump={false}
        {...props}
      />
    </Table>
  );
};
