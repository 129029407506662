import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import { AccountHolder } from '../../../../graphql/fragments/accountHolderFragments';

const QUERY = gql`
  query($id: String) {
    viewer {
      id
      transfer(id: $id) {
        id
        name
        latestStatus
        expirationDatetime
        insertedAt
        amount
        recipientsCount
        currency
        redeemedAmount
        erroredAmount
        canceledAmount
        outstandingAmount
      }
    }
  }
  ${AccountHolder.fragments.contactDetailsIndividual}
  ${AccountHolder.fragments.contactDetailsCompany}
  ${AccountHolder.fragments.contactDetailsSelf}
`;

export default graphql(QUERY, {
  props: props => ({
    id: 1,
    loadingOne: props.data.loading,
    noSearchParamsData: props.data.viewer,
    data: { error: props.data.error },
    ...props,
  }),
  options: ({
    match: {
      params: { id },
    },
  }) => ({
    variables: {
      id,
    },
    fetchPolicy: 'cache-and-network',
    // NOTE: Commenting out for now until we can find a way to poll
    // for transaction statuses only in the active browser tab
    // pollInterval: 1000 * 30,
  }),
});
