import gql from 'graphql-tag';

export default gql`
  query($dateTo: String, $dateFrom: String, $limit: Int, $offset: Int, $external: String) {
    viewer {
      id
      transactions(
        dateTo: $dateTo
        dateFrom: $dateFrom
        orderBy: "inserted_at"
        limit: $limit
        offset: $offset
        externalTag: $external  
      ) {
        count
        data {
          id
          amount
          currency
          insertedAt
          latestStatus
          networkType
          product
          purpose
          type
          externalTag
          company {
            id
            name
          }
          financialAccount {
            ... on PaymentCard {
              id
              accountHolder {
                ... on CustomerCompany {
                  id
                  email
                  contactDetails {
                    id
                    fullName
                  }
                }
                ... on CustomerIndividual {
                  id
                  email
                  contactDetails {
                    id
                    fullName
                  }
                }
                ... on CustomerSelf {
                  id
                  email
                  contactDetails {
                    id
                    fullName
                  }
                }
              }
            }
            ... on BankAccount {
              id
              accountHolder {
                ... on CustomerCompany {
                  id
                  email
                  contactDetails {
                    id
                    fullName
                  }
                }
                ... on CustomerIndividual {
                  id
                  email
                  contactDetails {
                    id
                    fullName
                  }
                }
                ... on CustomerSelf {
                  id
                  email
                  contactDetails {
                    id
                    fullName
                  }
                }
              }
            }
            ... on EtransferCustomer {
              id
              accountHolder {
                ... on CustomerCompany {
                  id
                  email
                  contactDetails {
                    id
                    fullName
                  }
                }
                ... on CustomerIndividual {
                  id
                  email
                  contactDetails {
                    id
                    fullName
                  }
                }
                ... on CustomerSelf {
                  id
                  email
                  contactDetails {
                    id
                    fullName
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
