import React from 'react';
// Import Component Styles
import { formatCurrencyV } from 'shared-components/src/utils/formatCurrency';
import { ValueLoadDetails, ValueLoadDetail } from './styles';
// Import Utils

export default ({ valueLoadDetails }) => (
  <ValueLoadDetails>
    <ValueLoadDetail>
      <p>Total Value Loads</p>
      <span>{valueLoadDetails.loads}</span>
    </ValueLoadDetail>
    <ValueLoadDetail>
      <p>Total Load Amount</p>
      <span>
        {formatCurrencyV(valueLoadDetails.amount)}
      </span>
    </ValueLoadDetail>
  </ValueLoadDetails>
);
