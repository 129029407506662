import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

const MUTATION = gql`
  mutation createManyCustomers($customers: [CustomerInput]!) {
    createManyCustomers(input: $customers) {
      id
      email
      error
      firstName
      lastName
      phone
      type
    }
  }
`;

export default graphql(MUTATION, {
  props: ({ mutate }) => ({
    createManyCustomers: ({ customers }) =>
      mutate({
        variables: {
          customers: customers.map(customer => ({
            ...customer,
            type: customer.type.toUpperCase()
          }))
        }
      })
  })
});
