//  Error logging for Client Portal Application
import SumoLogger from 'sumo-logger';
import {
  generateErrorLogger,
  consoleErrorTransport,
  sumoLogicErrorTransport,
} from 'shared-components/src/utils/errors';
import { PRODUCTION } from 'shared-components/src/constants/nodeEnv';


// Construct Application Error Logger
// Create Error Logger and export to rest of application
let logger;

// During Build Time we will conditionally use different errorLogTransports
// depending on environment
if (process.env.NODE_ENV === PRODUCTION) {
  const sumoLogger = new SumoLogger({
    endpoint: process.env.REACT_APP_SUMO_LOGIC_ENDPOINT,
  });
  logger = generateErrorLogger('ClientPortal', sumoLogicErrorTransport(sumoLogger));
} else {
  logger = generateErrorLogger('ClientPortal', consoleErrorTransport);
}

const errorLogger = logger;

export { errorLogger };


// GraphQL based error messages

// Mutation Error Message Creator
// Constructs an error Message from the result of a failed mutation
export const mutationErrorMessageParser = (
  err,
  defaultErrorMessage = 'An Error has occured please try again.'
) => {
  // Check for GraphQL first that came back from the server
  // Next check for network errors
  // Then check for general errors
  // Finally use the default error message
  if (err.graphQLErrors && err.graphQLErrors.length > 0) {
    return err.graphQLErrors.map( ({ message }) => `${message} ${defaultErrorMessage}`);
  } if (err.networkError && err.networkError.message) {
    // A network error has occured so we should tell the user to try again
    if (process.env.NODE_ENV === 'development')
      console.log('Network Error', err.networkError.message);
    return 'Unable to contact Pungle Platform, please try again.';
  } if (err.message) {
    return err.message;
  } 
  return defaultErrorMessage;
  
};
