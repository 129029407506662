import React, {useState} from 'react';
import {
  Title,
  Input,
  Button,
  Details,
  Loader
} from 'shared-components';
import {get} from '../../../../utils/http';
import CopyButton from "../../../../components/CopyButton";

export default () => {
  const [prn, setPrn] = useState('');
  const [balance, setBalance] = useState(0.0);
  const [loading, setLoading] = useState(false);

  const checkBalance = async () => {
    try {
      setLoading(true);
      const {data: {balance}} = await get(`/api/v1/client_portal/card_issuing/cardholders/${prn}/balance`);
      setBalance(parseFloat(balance));
      setLoading(false);
    } catch (e) {
      setBalance(0.0);
      setLoading(false);
    }
  };

  const updatePrnValue = () => e => {
    setPrn(e.target.value);
  };

  const handleKeyDown = () => e => {
    if (e.key === 'Enter') {
      return checkBalance();
    }
  };

  const columns = [
    {
      accessor: 'input',
      header: 'Enter proxy',
      cell: () => {
        return (
          <>
            <Input
              className="cardPRN"
              onChange={updatePrnValue()}
              onKeyDown={handleKeyDown()}
              name="card-prn"
              title="Enter Proxy"
            />
            <Button
              style={{marginTop: 10}}
              type='primary'
              size='small'
              onClick={() => checkBalance()}
            > Search Proxy
            </Button>
          </>
        );
      }
    },
    {
      accessor: 'copy-prn',
      cell: () => {
        return <CopyButton value={prn} />;
      }
    },
    {
      accessor: 'balance',
      header: 'Current Card Balance:',
      cell: () => {
        if (loading) return <Loader />;
        return balance.toFixed(2);
      }
    },
    {
      accessor: 'copy-balance',
      cell: () => {
        return (
          <CopyButton
            value={balance.toFixed(2)}
            noCopyValue='0.00'
          />
        );
      }
    },
  ];

  return (
    <>
      <Title>Find Current Card Balance</Title>
      <Details columns={columns} data={{}} />
    </>
  );
};