import { format } from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from './timeZone';

const formatPattern = {
  time: 'hh:mm a',
  date: 'MMM dd, yyyy',
  expiryDate: 'MM/yyyy',
  shortDate: 'MM/dd/yyyy',
  longDate: 'MMM dd, yyyy',
  dateTime: 'MMM dd, yyyy - hh:mm a',
  billingCycleRange: 'MMM-dd',
  chartTick: 'MMM dd',
  urlDateTime: 'yyyy-MM-dd HH:mm:ss',
};

export const toLocalTime = time => new Date(time);

export function toUTCTime(time) {
  const date = toLocalTime(time);
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  );
}

// Pitfall warning: the format constructor always evaluate the date object and return a time string in local time
// To work around this and display a UTC time string from a date object, we create a new date object that is behind/ahead
// by the UTF offset amount (caution: the actual value of the new date object represents a different point in time
// when compared to the old date object). format(newDateObject) would still convert the new date object to local time, but its string representation
// would be equivalent to the UTC string value of the old time object
export const formatUTCDateTime = time => {
  // toLocalTime just in case a time string is passed in
  const date = toLocalTime(time);
  const utc = toUTCTime(date);
  return format(utc, 'yyyy-MM-dd HH:mm:ss');
};

export const formatUTCDateTimeWithTimezone = time => {
  const date = new Date(time);
  const utc = toUTCTime(date);
  return `${format(utc, 'yyyy-MM-dd HH:mm:ss')} UTC`;
};

export const formatLocalTime = time => format(toLocalTime(time), 'hh:mm a');

export const formatLocalDate = time =>
  format(toLocalTime(time), 'MMM dd, yyyy');

export const formatExpiryDate = time => format(toLocalTime(time), 'MM/yyyy');

export const formatShortLocalDate = time =>
  format(toLocalTime(time), 'MM/dd/yyyy');

export const formatLongLocalDate = time =>
  format(toLocalTime(time), 'MMM dd, yyyy');

export const formatLocalDateTime = time =>
  format(toLocalTime(time), 'MMM dd, yyyy - hh:mm a');

export const formatLocalBillingCycleRange = time =>
  format(toLocalTime(time), 'MMM-dd');

export const formatChartTick = date => format(toLocalTime(date), 'MMM dd');

// We don't need to set local time here
export const formatChartTicks = (dateRange, time) =>
  dateRange === 'day' ? format(time, 'h a') : format(time, 'MM/dd/yy');

export const addToLocalDate = time =>
  format(toLocalTime(time), 'dddd, MMM Do, yy');

export const formatDateForTransactionQuery = date =>
  format(toLocalTime(date), 'dd-MM-yyyy');


/*  A date object is returned, where its local time value is equal to the desired timezone time of the time param
    Ex: pass in time param has local value of 6am Jan 5th 2021. Local time zone is GMT: -5. The time param has UTC value of 11am Jan 5th 2021. 
    'utc' is passed to zone. 
    the return date object would have a local value of 11am Jan 5th 2021. Note how the returned date object does not have a utc value of 11am Jan 5th.  
*/

export const convertTime = (time, zone) => {
  if (zone === 'utc') {
    return toUTCTime(time);
  }
  return toLocalTime(time);
};

/*
  Params:
    time: the point in time you want to show
    strPattern: the format pattern of the returned string
    zone: The time zone which the point in time you want to show in
*/
export const formatTime = (time, strPattern, zone) => {
  const date = new Date(time);
  const convertedTime = utcToZonedTime(date, zone);
  return format(convertedTime, formatPattern[strPattern]);
};

// Ex: pass in 6 am Jan 4th 2021 in local time, and UTC for zone
//     returns a date object that represents 6 am Jan 4th 2021 in UTC time
export const getDateObjectFromTime = (time, zone) => {
  if (zone === 'utc') {
    return new Date(
      Date.UTC(
        time.getFullYear(),
        time.getMonth(),
        time.getDate(),
        time.getHours(),
        time.getMinutes(),
        time.getSeconds(),
        time.getMilliseconds()
      )
    );
  }
  return new Date(time);
};

export const convertTimeToISO = (time, zone) => {
  const convertedTime = zonedTimeToUtc(time, zone);
  return convertedTime.toISOString();
};
