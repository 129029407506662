import React from 'react';
import PropTypes from 'prop-types';
// Import Global Components
import Label from '../../../../../../../components/Label';

const NotificationMethod = ({
  method,
  selectedMethod,
  labelFor,
  label,
  setNotificationMethod,
}) => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <input
      type="radio"
      onChange={() => setNotificationMethod(method)}
      checked={selectedMethod === method}
      style={{ marginRight: '5px' }}
    />
    <Label label={label} labelFor={labelFor} />
  </div>
);

NotificationMethod.propTypes = {
  method: PropTypes.string.isRequired,
  selectedMethod: PropTypes.string.isRequired,
  labelFor: PropTypes.string.isRequired,
  setNotificationMethod: PropTypes.func.isRequired,
};

export default NotificationMethod;
