import { formatTime } from 'shared-components/src/utils/formatDateTime';
import { getKycStatus } from 'shared-components/src/utils/cardholders';

export const getCardholderColumns = (timeZone) => [
  {
    Header: 'Cardholder ID',
    accessor: 'id',
  },
  {
    Header: 'Username',
    accessor: 'username',
    Cell: ({ value }) => (value || '--'),

  },
  {
    Header: 'Processor Reference',
    accessor: 'primary_processor_reference',
  },
  {
    Header: 'External Tag',
    accessor: 'external_tag',
    Cell: ({ value }) => (value && value.length > 0 ? value : '--'),
  },
  {
    Header: 'Created On',
    accessor: 'created_at',
    Cell: ({ value }) => formatTime(value, 'dateTime', timeZone),
  },
];

export const getKYCResultsColumns = (timeZone) => [
  {
    Header: 'ID',
    accessor: 'id',
  },
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'KYC Transaction ID',
    accessor: 'kyc_transaction_id',
  },
  {
    Header: 'Status',
    accessor: 'status',
    Cell: ({ value }) => getKycStatus(value),
  },
  {
    Header: 'Created On',
    accessor: 'created_at',
    Cell: ({ value }) => formatTime(value, 'dateTime', timeZone),
  },
  {
    Header: 'Updated On',
    accessor: 'updated_at',
    Cell: ({ value }) => formatTime(value, 'dateTime', timeZone),
  },
];