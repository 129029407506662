import React, { useState, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
// Import Shared Components
import { Input, UncontrolledInputGroup, Checkbox } from 'shared-components';
// Import Shared Utils
import {
  createTokenV2,
  createTokenV1,
} from 'shared-components/src/utils/tokenization';
import PROVINCES from 'shared-components/src/utils/provinces';
import COUNTRIES from 'shared-components/src/utils/countries';
// Import Components
import Label from '../Label';
import InfoTooltip from '../InfoTooltip';
import ModalForm from '../ModalForm';
// Import Component Styles
import {
  CheckboxContainer,
  Row,
  FormGroup,
  FormSection,
  FormSectionTitle,
  LabelContainer,
  StyledInfoTooltip,
} from './styles';

import { ServiceConfigContext } from '../../context/ServiceConfigContext';
import { VISA_ANI_VALIDATION } from 'shared-components/src/utils/services';

// New tokenization
const CardDetailsForm = ({
  primaryBtnTitle,
  tokenCreateCallback,
  tokenizationKey,
  secondaryButtonText,
  secondaryBtnFunc,
  selectedFinancialAccount,
  title,
  isCanadian,
  submitButtonSize,
  setDefaultAccount,
  saveDefaultAccount,
  showNamePartFields,
}) => {
  const cardholderNameInput = useRef(null);
  const firstNameInput = useRef(null);
  const middleNameInput = useRef(null);
  const lastNameInput = useRef(null);
  const cardNumberInput = useRef(null);
  const expiryMonthInput = useRef(null);
  const expiryYearInput = useRef(null);
  const cvvInput = useRef(null);
  const addressLine1Input = useRef(null);
  const addressLine2Input = useRef(null);
  const addressPostalCodeInput = useRef(null);
  const addressCityInput = useRef(null);
  const addressStateInput = useRef(null);
  const addressCountryInput = useRef(null);

  /* Auto populate the full name field by concatenating the individual name parts if it hasn't been manually edited yet
  or if the name isn't already auto populated */
  const [autoUpdateFullName, setAutoUpdateFullName] = useState(
    !Boolean(selectedFinancialAccount.name)
  );

  const updateFullName = () => {
    if (autoUpdateFullName) {
      cardholderNameInput.current.value = [
        firstNameInput.current.value,
        middleNameInput.current.value,
        lastNameInput.current.value,
      ]
        .filter(Boolean)
        .join(' ');
    }
  };

  const handleCardholderNameInputChange = () => {
    if (autoUpdateFullName) {
      setAutoUpdateFullName(false);
    }
  };

  const serviceConfiguration = useContext(ServiceConfigContext);

  const [tokenError, setTokenError] = useState(null);
  const [loading, setLoading] = useState(false);

  // If showNamePartFields is passed in as true, we will always show the name part fields regardless of whether VISA_ANI_VALIDATION is enabled
  // E.g. for the redeem payout form, we always show them
  const showNameParts =
    (serviceConfiguration && serviceConfiguration[VISA_ANI_VALIDATION]) ||
    showNamePartFields;

  const handleSubmit = async e => {
    e.preventDefault();
    setLoading(true);
    setTokenError(null);

    const body = {
      card: {
        name: cardholderNameInput.current.value,
        number: cardNumberInput.current.value,
        cvv: cvvInput.current.value,
        expiry_month: expiryMonthInput.current.value,
        expiry_year: expiryYearInput.current.value,
        address_city: addressCityInput.current.value,
        address_country: addressCountryInput.current.value,
        address_line1: addressLine1Input.current.value,
        address_line2: addressLine2Input.current.value,
        address_state: addressStateInput.current.value,
        address_postal_code: addressPostalCodeInput.current.value,
      },
    };

    if (showNameParts) {
      body.card.first_name = firstNameInput.current.value;
      body.card.middle_name = middleNameInput.current.value;
      body.card.last_name = lastNameInput.current.value;
    }

    try {
      const tokenDetails = isCanadian
        ? await createTokenV2(body, tokenizationKey)
        : await createTokenV1(body, tokenizationKey);
      tokenCreateCallback(tokenDetails);
    } catch (e) {
      setTokenError(
        "An error occured with the entered payment card, please try again or try another card. / Une erreur s'est produite avec la carte de paiement saisie, veuillez réessayer ou essayer une autre carte."
      );
      setLoading(false);
    }
  };

  const page = 'card-details';
  return (
    <ModalForm
      dataId="card-details-form"
      submit={handleSubmit}
      submitButtonText={primaryBtnTitle}
      secondaryButtonText={secondaryButtonText}
      closeModal={secondaryBtnFunc}
      error={tokenError}
      title={title}
      submitButtonSize={submitButtonSize}
      loading={loading}
      scrollable={
        !loading &&
        serviceConfiguration &&
        serviceConfiguration[VISA_ANI_VALIDATION]
      }
    >
      <FormGroup>
        <FormSection>
          <FormSectionTitle>
            Card Details / Détails de la Carte
          </FormSectionTitle>
          {showNameParts && (
            <>
              <UncontrolledInputGroup>
                <LabelContainer>
                  <Label
                    label="First Name / Prénom"
                    labelFor="given-name"
                    page={page}
                  />
                  <StyledInfoTooltip
                    content={<p>First legal name on account</p>}
                  />
                </LabelContainer>
                <Input
                  ref={firstNameInput}
                  dataTestid="given-name"
                  name="given-name"
                  min="1"
                  max="35"
                  autocomplete="given-name"
                  onChange={updateFullName}
                />
              </UncontrolledInputGroup>
              <UncontrolledInputGroup>
                <LabelContainer>
                  <Label
                    label="Middle Name / Deuxième prénom:"
                    labelFor="additional-name"
                    page={page}
                  />
                  <StyledInfoTooltip
                    content={<p>Middle legal name on account</p>}
                  />
                </LabelContainer>
                <Input
                  ref={middleNameInput}
                  dataTestid="additional-name"
                  name="additional-name"
                  min="1"
                  max="35"
                  autocomplete="additional-name"
                  onChange={updateFullName}
                />
              </UncontrolledInputGroup>
              <UncontrolledInputGroup>
                <LabelContainer>
                  <Label
                    required
                    label="Last Name / Nom de famille:"
                    labelFor="family-name"
                    page={page}
                  />
                  <StyledInfoTooltip
                    content={<p>Last legal name on account</p>}
                  />
                </LabelContainer>
                <Input
                  ref={lastNameInput}
                  required
                  dataTestid="family-name"
                  name="family-name"
                  min="1"
                  max="35"
                  autocomplete="family-name"
                  onChange={updateFullName}
                />
              </UncontrolledInputGroup>
            </>
          )}
          <UncontrolledInputGroup>
            <Label
              label="Full Name on Card / Nom complet sur la carte:"
              labelFor="cc-name"
              page={page}
              required
            />
            <Input
              defaultValue={selectedFinancialAccount.name || ''}
              ref={cardholderNameInput}
              required
              dataTestid="cc-name"
              name="cc-name"
              min="1"
              max="30"
              autocomplete="cc-name"
              onChange={handleCardholderNameInputChange}
            />
          </UncontrolledInputGroup>
          <UncontrolledInputGroup>
            <Label
              label="Card Number / Numéro de Carte:"
              labelFor="cc-number"
              page={page}
              required
            />
            <Input
              ref={cardNumberInput}
              required
              dataTestid="cc-number"
              name="cc-number"
              min="13"
              max="19"
              autocomplete="cc-number"
              title="Please enter a valid payment card number"
              pattern="[0-9]{13,19}"
            />
          </UncontrolledInputGroup>
          <Row>
            <UncontrolledInputGroup>
              <Label
                label="Expiry Month / Mois d'Expiration:"
                labelFor="cc-exp-month"
                page={page}
                required
              />
              <Input
                defaultValue={selectedFinancialAccount.expiryMonth || ''}
                ref={expiryMonthInput}
                dataTestid="cc-exp-month"
                name="cc-exp-month"
                placeholder="MM"
                min="2"
                max="2"
                autocomplete="cc-exp-month"
                pattern="[0-9]{2}"
                title="Please enter expiry month as MM"
                required
              />
            </UncontrolledInputGroup>
            <UncontrolledInputGroup>
              <Label
                label="Expiry Year / Année d'Expiration:"
                labelFor="cc-exp-year"
                page={page}
                required
              />
              <Input
                defaultValue={selectedFinancialAccount.expiryYear || ''}
                ref={expiryYearInput}
                placeholder="YYYY"
                dataTestid="cc-exp-year"
                name="cc-exp-year"
                min="4"
                max="4"
                autocomplete="cc-exp-year"
                pattern="[0-9]{4}"
                title="Please enter expiry year as YYYY"
                required
              />
            </UncontrolledInputGroup>
            <UncontrolledInputGroup>
              <Label label="CVV:" labelFor="cc-csc" page={page} required />
              <Input
                ref={cvvInput}
                dataTestid="cc-csc"
                name="cc-csc"
                min="3"
                max="4"
                autocomplete="cc-csc"
                type="password"
                pattern="[0-9]{3,4}"
                title="Please enter 3 or 4 Digit CVV"
                required
              />
            </UncontrolledInputGroup>
          </Row>
        </FormSection>
        <FormSection>
          <FormSectionTitle>
            Billing Details / Détails de la facturation
          </FormSectionTitle>
          <UncontrolledInputGroup>
            <Label
              label="Address / Adresse 1:"
              labelFor="address-line1"
              page={page}
              required
            />
            <Input
              defaultValue={selectedFinancialAccount.addressLine1 || ''}
              ref={addressLine1Input}
              dataTestid="address-line1"
              name="address-line1"
              min="1"
              max="35"
              autocomplete="address-line1"
              required
            />
          </UncontrolledInputGroup>
          <UncontrolledInputGroup>
            <Label
              label="Address / Adresse 2:"
              labelFor="address-line2"
              page={page}
            />
            <Input
              defaultValue={selectedFinancialAccount.addressLine2 || ''}
              ref={addressLine2Input}
              dataTestid="address-line2"
              name="address-line2"
              min="1"
              max="35"
              autocomplete="address-line2"
            />
          </UncontrolledInputGroup>
          <Row>
            <UncontrolledInputGroup>
              <Label
                label="City / Ville:"
                labelFor="address-level2"
                page={page}
                required
              />
              <Input
                defaultValue={selectedFinancialAccount.addressCity || ''}
                ref={addressCityInput}
                dataTestid="address-level2"
                name="address-level2"
                min="1"
                max="25"
                autocomplete="address-level2"
                required
              />
            </UncontrolledInputGroup>
            <UncontrolledInputGroup>
              <Label
                label="Postal Code / Code Postal:"
                labelFor="postal-code"
                page={page}
                required
              />
              <Input
                defaultValue={selectedFinancialAccount.addressPostalCode || ''}
                ref={addressPostalCodeInput}
                dataTestid="postal-code"
                name="postal-code"
                min="5"
                max="9"
                autocomplete="postal-code"
                required
              />
            </UncontrolledInputGroup>
          </Row>
          <Row>
            <UncontrolledInputGroup>
              <Label
                label="Province/State/État:"
                labelFor="address-level1"
                page={page}
                required
              />
              <Input
                as="select"
                ref={addressStateInput}
                dataTestid="address-level1"
                name="address-level1"
                required
                defaultValue=""
              >
                {[
                  <option key="province-default" disabled value="">
                    -- select an option --
                  </option>,
                  ...PROVINCES.map(p => (
                    <option key={`province-${p.val}`} value={p.key}>
                      {p.val}
                    </option>
                  )),
                ]}
              </Input>
            </UncontrolledInputGroup>
            <UncontrolledInputGroup>
              <Label
                label="Country / Pays:"
                labelFor="country"
                page={page}
                required
              />
              <Input
                as="select"
                ref={addressCountryInput}
                dataTestid="country"
                name="country"
                required
                defaultValue=""
              >
                {[
                  <option key="countries-default" disabled value="">
                    {' '}
                    -- select an option --{' '}
                  </option>,
                  ...COUNTRIES.map(p => (
                    <option key={`countries-${p.val}`} value={p.key}>
                      {p.val}
                    </option>
                  )),
                ]}
              </Input>
            </UncontrolledInputGroup>
          </Row>
          {setDefaultAccount && (
            <CheckboxContainer>
              <Checkbox
                label="Remember my payment card"
                value={saveDefaultAccount}
                onChange={() => setDefaultAccount(true)}
              />
              <InfoTooltip
                title="Saving Account Details"
                content={
                  <p style={{ fontSize: '.9rem', width: '150px' }}>
                    This will allow future payments to be made directly to this
                    account.
                  </p>
                }
                placement="top"
              />
            </CheckboxContainer>
          )}
        </FormSection>
      </FormGroup>
    </ModalForm>
  );
};

CardDetailsForm.propTypes = {
  primaryBtnTitle: PropTypes.string.isRequired,
  tokenCreateCallback: PropTypes.func.isRequired,
  tokenizationKey: PropTypes.string.isRequired,
  secondaryButtonText: PropTypes.string.isRequired,
  secondaryBtnFunc: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  isCanadian: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  selectedFinancialAccount: PropTypes.object,
  submitButtonSize: PropTypes.string,
  showNamePartFields: PropTypes.bool,
};

CardDetailsForm.defaultProps = {
  selectedFinancialAccount: {},
  submitButtonSize: 'large',
  secondaryButtonText: null,
  showNamePartFields: false,
};

export default CardDetailsForm;
