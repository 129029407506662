import React from 'react';
// Import Shared Components
import {
  Link,
  Card,
  CardGroup,
  Title,
  Details,
  DatePicker,
} from 'shared-components';
import {
  formatCurrencyV,
  formatCurrencyCSV,
} from 'shared-components/src/utils/formatCurrency';
import { getStartAndEndDates } from 'shared-components/src/utils/dates';
import { formatTime } from 'shared-components/src/utils/formatDateTime';
// Import Global Components
import Table from '../../../components/Table';
import SinglePaymentStatus from '../../../components/SinglePaymentStatus';
// Import Local Components
import SearchFilter from './components/SearchFilter';
import Search from './components/Search';
// Import Component Styles
import { Header, Icon, AccountHolderDetails } from './styles';
// Import Utils
import { getTotalTransactionAmount } from '../../../utils/accountHolders';
// Import Hooks
import { useTimeZone } from '../../../hooks/useTimeZone';

export default ({
  data,
  params: { amount, status },
  params,
  updateParams,
  searchData,
  updateSearchData,
  searchBy,
  updateSearchStatus,
  updateSearchByState,
  updateSearchAmount,
  searchTransactions,
  toggleSearchFilter,
  searchFilter,
  filterButton,
  resetSearch,
}) => {
  const [timeZone] = useTimeZone();
  const { dateFrom, dateTo, focusedInput } = searchData;
  const { customer } = data.viewer;
  const transactions = customer.paymentSearch.data;

  // Get total amount sent to Account Holder
  const totalAmount = getTotalTransactionAmount(transactions);

  const columns = [
    {
      Header: 'ID',
      accessor: 'id',
      show: false,
    },
    {
      Header: 'Date',
      accessor: 'insertedAt',
      Cell: t => formatTime(`${t.value}Z`, 'dateTime', timeZone),
    },
    {
      Header: 'Amount',
      accessor: item => item,
      id: 'amount',
      Cell: t => (
        <div style={{ textAlign: 'left', paddingRight: '10px' }}>
          {formatCurrencyCSV(t.value.amount / 100, t.value.currency)}
        </div>
      ),
    },
    {
      Header: 'Notes',
      accessor: 'notes',
      Cell: ({ value }) =>
        value.length > 0 ? (
          <div style={{ textAlign: 'left', paddingRight: '10px' }}>{value}</div>
        ) : (
          '--'
        ),
    },
    {
      Header: 'Status',
      accessor: 'latestStatus',
      Cell: ({ value }) => <SinglePaymentStatus status={value} />,
    },
    {
      minWidth: 32,
      maxWidth: 32,
      accessor: 'transferId',
      Cell: ({ value }) => (
        <Link to={`/direct-send/transfers/${value}`}>
          <Icon type="external" size={16} />
        </Link>
      ),
    },
  ];

  return (
    <AccountHolderDetails>
      <Card>
        <Title>{customer.contactDetails.fullName}</Title>
        <Details
          columns={[
            { accessor: 'email', header: 'Email:' },
            { accessor: 'transactions', header: 'Transactions:' },
            {
              accessor: 'amount',
              header: 'Amount Sent:',
              cell: value => formatCurrencyV(value),
            },
          ]}
          data={{
            email: data.viewer.customer.email,
            transactions: transactions.length,
            amount: totalAmount,
          }}
        />
      </Card>
      <CardGroup>
        <Header>
          <Card>
            <Search
              updateSearchStatus={updateSearchStatus}
              updateSearchByState={updateSearchByState}
              updateSearchAmount={updateSearchAmount}
              searchTransactions={searchTransactions}
              searchBy={searchBy}
              toggleSearchFilter={toggleSearchFilter}
              filterButton={filterButton}
              resetSearch={resetSearch}
              params={params}
            />
          </Card>
          <Card>
            <DatePicker
              startDate={dateFrom}
              endDate={dateTo}
              onDatesChange={({ startDate, endDate }) => {
                const { validStartDate, validEndDate } = getStartAndEndDates(
                  startDate,
                  endDate
                );
                updateSearchData(searchData => ({
                  ...searchData,
                  dateFrom: validStartDate,
                  dateTo: validEndDate,
                }));
              }}
              focusedInput={focusedInput}
              onFocusChange={focusedInput =>
                updateSearchData(searchData => ({
                  ...searchData,
                  focusedInput,
                }))}
              isOutsideRange={() => false}
              hideKeyboardShortcutsPanel
              showDefaultInputIcon
            />
          </Card>
        </Header>
        {searchFilter && (
          <Card>
            <SearchFilter
              type={searchBy}
              params={params}
              updateParams={updateParams}
            />
          </Card>
        )}
        <Card>
          <Table
            key="account-holder-transaction-table"
            columns={columns}
            data={transactions}
            defaultSorted={[
              {
                id: 'created_at',
                asc: false,
              },
            ]}
            noDataText="No Transactions found"
          />
        </Card>
      </CardGroup>
    </AccountHolderDetails>
  );
};
