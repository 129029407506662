export const getActiveAccounts = (accounts, defaultID) => {
  // Get all active accounts and for the default account add a key to that account for default account and place it in the front of all active accounts. This way it renders as the first card.

  const active = accounts.filter(account => account.latestStatus === 'active');
  const defaultAccount = active
    .filter(account => account.id === defaultID)
    .map(account => ({ ...account, defaultAcct: true }))[0];

  const allActiveAccounts = active.filter(account => account.id !== defaultID);
  defaultAccount && allActiveAccounts.unshift(defaultAccount);

  const activePaymentCards = allActiveAccounts.filter(
    account => account.type === 'payment_card'
  );
  const activeBankAccounts = allActiveAccounts.filter(
    account => account.type === 'bank_account'
  );

  return {
    defaultAccount,
    allActiveAccounts,
    activePaymentCards,
    activeBankAccounts,
  };
};

export const addAccountHolderInfo = (accounts, id) => {
  return accounts.map(account => ({
    ...account,
    accountHolderId: id,
  }));
};
