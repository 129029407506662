import styled from 'styled-components';

export const Details = styled.div`
  padding-bottom: 10px;
  text-align: center;
`;

export const TransferAmount = styled.h1`
  color: ${({ theme }) => theme.colors.green};
  font-size: 32px;
  margin-bottom: 5px;
`;

export const ConfirmTransferModal = styled.div`
  min-width: 250px;
`;
