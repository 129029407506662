import { compose, withState, lifecycle } from 'recompose';
import {
  renderWhileLoading,
  setRefetchProp,
  renderForError
} from '../../../state/apolloRecompose';

import AccountHolders from './AccountHolders';

// Enhancers
import withAccountHoldersQuery from './enhancers/withAccountHoldersQuery';
import withHandlers from './enhancers/withHandlers';
import withCreateAccountHolderMutation from './enhancers/mutations/withCreateAccountHolderMutation';
import withUpdateAccountHolderMutation from './enhancers/mutations/withUpdateAccountHolderMutation';
import withRemoveAccountHolderMutation from './enhancers/mutations/withRemoveAccountHolderMutation';
import withExpanderState from './enhancers/states/withExpanderState';
import withSuccessState from './enhancers/states/withSuccessState';
import withConfirmDeleteModal from './enhancers/states/withConfirmDeleteModal';
import withRemoveAccountHolderState from './enhancers/states/withRemoveAccountHolderState';

export default compose(
  withState(
    'createAccountHolderModal',
    'updateCreateAccountHolderModal',
    false
  ),
  withState('editAccountHolderModal', 'updateEditAccountHolderModal', false),
  withState('editAccountHolderState', 'updateEditAccountHolderState', null),
  withState('uploadAccountHolderState', 'updateUploadAccountHolderState', null),
  withState('error', 'updateError', null),
  withState('pagination', 'updatePagination', { limit: 10, offset: 0 }),
  withState('page', 'updatePage', 0),
  withExpanderState,
  withSuccessState,
  withConfirmDeleteModal,
  withRemoveAccountHolderState,
  lifecycle({
    componentDidMount() {
      const { updateSuccess } = this.props;

      if (this.props.location && this.props.location.state) {
        this.props.location.state.success &&
          updateSuccess(this.props.location.state.success);
      }
    }
  }),
  withAccountHoldersQuery,
  withCreateAccountHolderMutation,
  withUpdateAccountHolderMutation,
  withRemoveAccountHolderMutation,
  withHandlers,
  renderWhileLoading,
  setRefetchProp,
  renderForError
)(AccountHolders);
