import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
// Import Shared Components
import { Title, Dropdown, ActionBar, Button, Modal } from 'shared-components';
// Import Global Components
import { StyledModalForm } from '../../../../components/ModalForm';
// Import Component Styles
import { TitleContainer } from './styles';

const SelectProgramModal = withRouter(
  ({ updateItem, options, changeProgram, onClick }) => {
    const renderTitle = () => {
      return changeProgram ? (
        <Title>Change Program</Title>
      ) : (
        <>
          <TitleContainer>
            <Title>Select a Program</Title>
            <p>
              When creating an order for close/unloads you must select a program, all cards entered for close/unload must be under this program.
            </p>
            <p>
              Please select the program that the cards you wish to close and unload are under.
            </p>
            <br />
            <p>
              Only Programs that are currently active will appear in this list.
            </p>
          </TitleContainer>
        </>
      );
    };

    return (
      <Modal key="selectProgramModal">
        <StyledModalForm>
          {renderTitle()}
          <Dropdown updateItem={val => updateItem(val)} options={options} />
          <ActionBar>
            <Button type="secondary" onClick={onClick}>
              Cancel
            </Button>
          </ActionBar>
        </StyledModalForm>
      </Modal>
    );
  }
);

SelectProgramModal.propTypes = {
  updateItem: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({ key: PropTypes.number, val: PropTypes.string })
  ).isRequired,
  onClick: PropTypes.func.isRequired,
  changeProgram: PropTypes.bool,
};

SelectProgramModal.defaultProps = {
  changeProgram: false,
};

export default SelectProgramModal;
