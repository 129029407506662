import React from 'react';
// Import Shared Components
import { Alert, Title, Card, CardGroup, Button } from 'shared-components';
import { formatCurrencyCSV } from 'shared-components/src/utils/formatCurrency';
// Import Global Components
import Table from '../../../components/Table';
import { IconButtonGroup } from '../../../components/IconButton';
import SinglePaymentStatus from '../../../components/SinglePaymentStatus';
// Import Local Components
import Search from './components/Search';
import Header from './components/Header';
import TotalAmountDetails from './components/TotalAmountDetails';
// Import Component Styles
import { TransferDetails } from './styles';

export default ({
  noSearchParamsData: { transfer },
  searchParamsData: {
    transfer: {
      paymentsSearch: {
        count,
        data
      }
    },
  },
  updateSearchInput,
  handlePageSizeChange,
  handlePageChange,
  updateSearchStatus,
  updateSearchByState,
  searchTransactions,
  searchBy,
  showResetButton,
  resetSearch,
  params,
  resendEmail,
  cancelTransaction,
  success,
  error,
  pagination,
  ...props
}) => {
  const columns = [
    {
      Header: 'ID',
      accessor: 'id',
      show: false,
    },
    {
      Header: 'Account Holder Email',
      accessor: 'customer',
      Cell: ({ value }) => <div>{value.email}</div>,
      maxWidth: 200,
    },
    {
      Header: 'Account Holder Name',
      accessor: item => item.customer.contactDetails.fullName,
      id: 'name',
      Cell: ({ value }) => <div>{value}</div>,
      maxWidth: 150,
    },
    {
      Header: 'Amount',
      accessor: item => {return {amount: item.amount, currency: item.currency}},
      id: 'amount',
      maxWidth: 150,
      Cell: ({value}) => (
        <div style={{ textAlign: 'left', paddingRight: '10px' }}>
          {formatCurrencyCSV(value.amount / 100, value.currency)}
        </div>
      ),
    },
    {
      Header: 'Notes',
      accessor: 'notes',
      maxWidth: 200,
      Cell: ({ value }) =>
        value ? (
          <div style={{ textAlign: 'left', paddingRight: '10px' }}>{value}</div>
        ) : (
          '--'
        ),
    },
    {
      Header: 'Status',
      accessor: 'latestStatus',
      maxWidth: 200,
      Cell: ({ value }) => (
        <div style={{ textAlign: 'left', paddingRight: '10px' }}>
          <SinglePaymentStatus status={value} />
        </div>
      ),
    },
    {
      Header: 'Actions',
      accessor: item => item,
      id: 'action',
      sortable: false,
      Cell: ({ value }) =>
        value.latestStatus === 'notification_sent' ? (
          <IconButtonGroup>
            <Button
              type="primary"
              variant="text"
              onClick={() => resendEmail(parseInt(value.id, 10))}
            >
              Resend
            </Button>
            <Button
              type="danger"
              variant="text"
              onClick={() => cancelTransaction(parseInt(value.id, 10))}
            >
              Cancel
            </Button>
          </IconButtonGroup>
        ) : (
          <div />
        ),
    },
  ];

  return (
    <TransferDetails>
      <CardGroup>
        {error && <Alert type="danger">{error}</Alert>}
        {success && <Alert type="success">{success}</Alert>}
        <Card>
          <Header {...transfer} />
        </Card>
        <Card>
          <TotalAmountDetails transfer={transfer} />
        </Card>
      </CardGroup>
      <CardGroup>
        <Card>
          <Search
            updateSearchInput={updateSearchInput}
            updateSearchStatus={updateSearchStatus}
            updateSearchByState={updateSearchByState}
            searchTransactions={searchTransactions}
            searchBy={searchBy}
            resetSearch={resetSearch}
            params={params}
            showResetButton={showResetButton}
          />
        </Card>
        <Card>
          <Title>Payments</Title>
          <Table
            key="single-transfer-table"
            columns={columns}
            data={data}
            noDataText="No Transactions found"
            showPagination={count > pagination.limit && true}
            pages={Math.ceil(count / pagination.limit)}
            pageSize={pagination.limit}
            page={pagination.page}
            manual
            onPageSizeChange={(pageSize, pageIndex) =>
                handlePageSizeChange(pageSize, pageIndex)}
            onPageChange={pageIndex => handlePageChange(pageIndex)}
          />
        </Card>
      </CardGroup>
    </TransferDetails>
  );
};
