import React, { useEffect, useState } from 'react';
// Import Common Utilss
import { Loader, ClientErrorComponent } from 'shared-components';
import { get } from '../utils/http';

const RoleContext = React.createContext();

const RoleProvider = ({ children }) => {
  const [userRole, setUserRole] = useState('user');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userData = await get('/api/v1/client_portal/current_user');

        setUserRole(userData.data.role);
      } catch (e) {
        setError(true);
      }
      setLoading(false);
    };
    fetchData();
  }, []);

  const renderBody = () => {
    if (loading) return <Loader />;
    if (error) return <ClientErrorComponent />;

    return <>{children}</>;
  };

  return (
    <RoleContext.Provider value={userRole}>{renderBody()}</RoleContext.Provider>
  );
};

export { RoleContext, RoleProvider };
