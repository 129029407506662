import React from 'react';
import PropTypes from 'prop-types';
// Import Shared Components
import { CSVButton } from 'shared-components';
// Import Shared Utils
import { currentDate } from 'shared-components/src/utils/dates';
// Import Utils
import { virtualCardHeader, physicalCardHeader, galileoPhysicalCardHeader } from './csvHeaders';

const DownloadCSV = ({ program: { program_type, processor_name } }) => {

  const data = program_type.includes('virtual')
    ? virtualCardHeader
    : processor_name.includes('galileo')
    ? galileoPhysicalCardHeader
    : physicalCardHeader;


  const timeNow = currentDate();

  return (
    <>
      <div>
        <p>
          To begin, please download the spreadsheet form and fill it out with
          the Cardholder details.
        </p>
        <p>
          When complete, upload the file and your Cardholder entries will show
          up below.
        </p>
      </div>
      <CSVButton
        type="primary"
        onClick={() => {}}
        data={data}
        key={program_type}
        filename={`${program_type.split('_')[0]}-cardholders-form-${timeNow}`}
      >
        Download
      </CSVButton>
    </>
  );
};

DownloadCSV.propTypes = {
  program_type: PropTypes.string.isRequired,
};

export default DownloadCSV;
