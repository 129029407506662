// Error Utils

// GenerateErrorLogger
// Create an Error Logger for an application
// Requires an errorTransport to send the error report to somewhere
export const generateErrorLogger = (appName, errorTransport) => {

  return (error, errorInfo = {}) => {

    // Construct Error Data Object to send to exteral service
    const errorData = {
      bpsAppName: appName,
      // bspErrorLogKey is a value that we can use in an external logging service to confirm that we are the one's
      // sending the log
      bpsErrorLogKey: '1234',
      name: error.name,
      message: error.message,
      url: document.location.href,
      // the stack key exists in most common browsers, but may contain different information for each browser
      stack: error.stack,
      // React specific error information
      errorInfo,
      // Microsoft specific Error object keys
      description: error.description,
      number: error.number,
      // Firefox specific Error object keys
      fileName: error.fileName,
      lineNumber: error.lineNumber,
      columnNumber: error.columnNumber,
      // Browser information
      javaEnabled: navigator.javaEnabled(),
      language: navigator.language,
      // The platform property returns the browser platform (operating system):
      platform: navigator.platform,
      // The userAgent property returns the user-agent header sent by the browser to the server:
      userAgent: navigator.userAgent,
      // The appVersion property returns version information about the browser:
      appVersion: navigator.appVersion,
      // The product property returns the product name of the browser engine:
      product: navigator.product,
      // The appCodeName property returns the application code name of the browser:
      appCodeName: navigator.appCodeName,
      // The appName property returns the application name of the browser:
      appName: navigator.appName,
      // The cookieEnabled property returns true if cookies are enabled, otherwise false:
      cookieEnabled: navigator.cookieEnabled,
    };

    // Send Error To Transport
    errorTransport(errorData);

    
  };
};

export const consoleErrorTransport = (errorData) => console.error('Error Logger', errorData); // eslint-disable-line no-console

// Log error to external Sumo Logic Collector
export const sumoLogicErrorTransport = sumoLogger => errorData =>
  sumoLogger.log(JSON.stringify(errorData));

// initializeGlobalErrorHandler
// This function can be called a single time when the application starts
// it will passively sit and catch all errors that occur while the application is running
// and log them through the supplied error logger
export const initializeGlobalErrorHandler = (logger) => {
  window.onerror = (message, source, lineno, colno, error) => {
    logger(error);
    
  };
  
};
