import React from 'react';
import { formatTime } from 'shared-components/src/utils/formatDateTime';
import { formatCurrencyCSV } from 'shared-components/src/utils/formatCurrency';
import { capitalize } from 'shared-components/src/utils/string';
// Import Global Components
import TransactionStatus from '../../../../components/TransactionStatus';

export default (timeZone) => [
  {
    Header: 'ID',
    accessor: 'id',
    show: false,
  },
  {
    Header: 'Date',
    accessor: 'insertedAt',
    Cell: ({ value }) => formatTime(`${value}Z`, 'dateTime', timeZone),
  },
  {
    Header: 'Company Name',
    accessor: 'company',
    Cell: ({ value: { name } }) => name,
  },
  {
    Header: 'Account Holder Name',
    accessor: row => row.financialAccount.accountHolder.contactDetails.fullName,
    id: 'accountHolderName',
  },
  {
    Header: 'Email',
    accessor: row => row.financialAccount.accountHolder.email,
    id: 'email',
  },
  {
    id: 'amount',
    Header: 'Amount',
    accessor: row => formatCurrencyCSV(row.amount / 100, row.currency),
  },
  {
    Header: 'Type',
    accessor: 'type',
    Cell: ({ value }) => capitalize(value),
  },
  {
    Header: 'Status',
    accessor: 'latestStatus',
    Cell: ({ value }) => <TransactionStatus status={value} />,
  },
  {
    Header: 'External Tag',
    accessor: 'externalTag',
    Cell: ({ value }) => value,
  },
];
