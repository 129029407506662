export const getCardholderColumns = () => [
  {
    Header: 'First Name',
    accessor: 'first_name',
    Cell: ({ value }) => capitalizeFirstLetter(value),
  },  {
    Header: 'Last Name',
    accessor: 'last_name',
    Cell: ({ value }) => capitalizeFirstLetter(value),
  },
  {
    Header: 'Card Number',
    accessor: 'number',
  },
  {
    Header: 'Processor Reference',
    accessor: 'processor_reference',
  },
  {
    Header: 'Status',
    accessor: 'status',
    Cell: ({ value }) => capitalizeFirstLetter(value),

  },
  {
    Header: 'Card Program Type',
    accessor: 'card_program_type',
    Cell: ({ value }) => mapProgramTypes(value),

  },
  {
    Header: 'Date of Birth',
    accessor: 'date_of_birth',
  },
];

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const mapProgramTypes = (value) => {
  const map = {
    virtual_reloadable: "Virtual Reloadable",
    virtual_nonreloadable: "Virtual Nonreloadable",
    physical_nonreloadable: "Physical Nonreloadable",
    physical_reloadable: "Physical Reloadable",
  };

  if (map[value]) {
    return map[value];
  }
  return value;
}