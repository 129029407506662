import styled from 'styled-components';

export const Account = styled.div`
  & > *:not(:first-child) {
    padding-top: 30px;
    margin-top: 30px;
    border-top: ${({ theme }) => theme.borders.default};
  }
`;

export const FreezeInfo = styled.span`
  color: ${({ status, theme }) => status !== 'Unfrozen' && theme.colors.red};
`;

export const ConfirmBody = styled.div`
  padding: 10px 0 0;
  text-align: center;
`;
