import React from 'react';
import {
  Title,
  Alert,
  Button,
  ButtonGroup,
  ActionBar,
} from 'shared-components';
// Import Utils
import { formatCurrencySV } from 'shared-components/src/utils/formatCurrency';
// Import Component Styles
import { Details, TransferAmount, ConfirmTransferModal } from './styles';

export default ({
  source,
  total,
  currency,
  recipients,
  sendTransfer,
  closeModal,
  error,
}) => {
  const renderRecipients =
    recipients.length > 1 ? (
      <>
        <h5>Sending to</h5>
        <p>{recipients.length} Recipients</p>
      </>
    ) : (
      recipients.map(sentTo => (
        <>
          <h5>Sending to</h5>
          <p>{sentTo}</p>
        </>
      ))
    );

  return (
    <ConfirmTransferModal>
      <Details>
        <Title>Confirm Disbursement</Title>
        <TransferAmount>{formatCurrencySV(total, currency)}</TransferAmount>
        {renderRecipients}
      </Details>
      {error && (
        <Alert type="danger">
          Error: {error}. Please go back to correct errors and retry
        </Alert>
      )}
      <ActionBar>
        <ButtonGroup reverse>
          <Button onClick={(e) => sendTransfer(e, currency)} size="large" type="success">
            Confirm
          </Button>
          <Button onClick={closeModal} variant="text" type="danger">
            Cancel
          </Button>
        </ButtonGroup>
      </ActionBar>
    </ConfirmTransferModal>
  );
};
