import styled from 'styled-components';

export const FormGroup = styled.form`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;

  & > *:not(:last-child) {
    margin-bottom: 30px;
  }

`;

export const FormSection = styled.fieldset`
  & > *:not(:last-child) {
    margin-bottom: 15px;
  }
`;

export const FormSectionTitle = styled.legend`
  border-bottom: ${({ theme }) => theme.borders.default}
    margin-bottom: 1.4285em;
  width: 100%;
  margin-bottom: 15px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;

  & > *:not(:last-child) {
    margin-bottom: 15px;
  }

  ${({ theme }) => theme.media.tabletPortraitUp`
    flex-direction: row;

    & > * {
      width: 50%;
    }

    & > *:not(:last-child) {
      margin-bottom: 0;
      margin-right: 15px;
    }
  `}
`;
