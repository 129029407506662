import gql from 'graphql-tag';

export const MUTATION = gql`
  mutation updateFinancialAccount(
    $accountHolderId: Int!
    $token: String!
    $fsId: String!
  ) {
    updateFinancialAccount(
      accountHolderId: $accountHolderId
      token: $token
      fsId: $fsId
    )
  }
`;
