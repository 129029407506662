import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  /* Card wrapping search component */
  & > *:first-child {
    flex: 2 0 0;
  }

  /* Card wrapping create button */
  & > *:last-child {
    display: flex;
    justify-content: flex-end;
    align-items: stretch;
    flex: 1;
    margin-top: 15px;
  }

  ${({ theme }) => theme.media.desktopUp`
    flex-direction: row;

    & > *:last-child {
      margin-top: 0;
      margin-left: 15px;
    }
  
  `}
`;
