import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

const MUTATION = gql`
  mutation resendPaymentRedemptionEmail($paymentId: Int!) {
    resendPaymentRedemptionEmail(paymentId: $paymentId)
  }
`;

export default graphql(MUTATION, {
  props: ({ mutate }) => ({
    resendPaymentRedemptionEmail: paymentId =>
      mutate({ variables: { paymentId } }),
  })
});
