import styled from 'styled-components';

export const Amount = styled.span`
  color: ${({ theme, color }) => theme.colors[color] || theme.brand.text};
`;
export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;

  h3 {
    white-space: nowrap;
  }

  p {
    margin-left: 15px;
  }
`;
