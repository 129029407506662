import styled from 'styled-components';

export const Footer = styled.footer`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.brand.footerText};
  padding: 10px;
  background-color: transparent;
`;

export const Copyright = styled.p`
  font-size: 1rem;
`;
