import React from 'react';
// Import Shared components
import {
  ActionBar,
  Button,
  ButtonGroup,
  Alert,
  Modal,
} from 'shared-components';
// Import Component Styles
import { ConfirmBody } from '../components/styles';

export default ({ alert, text, onClick, closeModal }) => (
  <Modal key="confirm-change-modal">
    {alert && <Alert type="warn">{text.alert}</Alert>}
    <ConfirmBody>
      <div
        dangerouslySetInnerHTML={{
          __html: text.confirmText,
        }}
      />
    </ConfirmBody>
    <ActionBar>
      <ButtonGroup reverse>
        <Button type="danger" size="large" onClick={onClick}>
          {text.buttonText}
        </Button>
        <Button type="secondary" variant="text" onClick={closeModal}>
          Cancel
        </Button>
      </ButtonGroup>
    </ActionBar>
  </Modal>
);
