import React from 'react';
// Import Shared Components
import { formatCurrencySV } from 'shared-components/src/utils/formatCurrency';
// Import Component Styles
import { InvalidTotal, ValidTotal } from './styles';

export default ({ total, currency }) => {
  if (isNaN(total)) {
    // Return error if user has an invalid amount in the transaction rows
    return <InvalidTotal>Invalid Entry</InvalidTotal>;
  }
  return <ValidTotal>{formatCurrencySV(total, currency)}</ValidTotal>;
};
