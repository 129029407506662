export const getTotalAmount = recipients => {
  let total = 0;
  Object.keys(recipients).forEach(id => {
    if (
      !recipients[id].error.amountError &&
      !isNaN(parseFloat(recipients[id].data.amount))
    ) {
      total += parseFloat(recipients[id].data.amount);
    }
  });
  return total;
};
