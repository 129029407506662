import React from 'react';
// Import Shared Components
import { Card } from 'shared-components';
// Import Component Styles
import { Container } from './styles';

export default ({ children }) => (
  <Card>
    <Container>{children}</Container>
  </Card>
);
