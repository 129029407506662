export const redeemPayment = async params => {
  try {
    const res = await fetch('/api/v1/transfers/redeem_payment', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    });

    const body = await res.json();


    if (body.error) {
      if (body.error.code === 'invalid_transaction_details'){
        return body.error;
      }
      if (body.error.message) throw new Error(body.error.message);

      throw new Error('There was an issue while redeeming the payment.');
    }

    return body.data;
  } catch (e) {
    throw new Error(e.message);
  }
};
