import React from 'react';
// Import Shared Components
import {
    Title,
    Card,
    Button, 
    ButtonGroup,
    CardGroup,
  } from 'shared-components';
// Import Shared Components
import { Header } from './styles';

export default ({ history }) => {
 
  return (
    <>
        <Header>
        <CardGroup>
          <Card>
          <Title>Bulk Cards Activation</Title>
            <ButtonGroup>
              <Button
                type="primary"
                onClick={() => {
           
                  history.push(
                    {
                      pathname:'/bulker/card-issuing/bulk-activate/activate-cards'
                    }
                    )
                    
    
                  }}
              >
                Activate Cards
              </Button>
            </ButtonGroup>
          </Card>
          </CardGroup>
      </Header>
    </>
  );
};