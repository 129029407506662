import styled from 'styled-components';
// Import Components to Extend Styles
import { Icon as UnstyledIcon } from 'shared-components';

// Wraps cards containing search filters for transfer history
export const Header = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  /* Card wrapping search component */
  & > *:first-child {
    flex: 2 0 0;
  }

  /* Card wrapping create button */
  & > *:last-child {
    display: flex;
    justify-content: flex-end;
    align-items: stretch;
    flex: 1;
    margin-top: 15px;

    & > div {
      flex: 1;
    }
  }

  ${({ theme }) => theme.media.tabletLandscapeUp`
    flex-direction: row;

    & > *:last-child {
      margin-top: 0;
      margin-left: 15px;
    }

  `}
`;

export const Icon = styled(UnstyledIcon)`
  -moz-transform: rotateY(0deg);
  -webkit-transform: rotateY(0deg);
  -o-transform: rotateY(0deg);
  -ms-transform: rotateY(0deg);
  transform: rotateY(0deg);
`;

export const AccountHolderDetails = styled.div`
  & > *:last-child {
    margin-top: 30px;
  }
`;
