import { withHandlers } from 'recompose';
// Import Utils
import {
  isEmailValid,
  isNameValid,
  isCompanyNameValid,
  isPhoneNumberValid
} from '../../../../utils/validations';

export default withHandlers({
  validateEmail: ({ validationError, updateValidationError, email }) => () => {
    updateValidationError({
      ...validationError,
      email: !isEmailValid(email)
    });
  },
  validateCompanyName: ({
    validationError,
    updateValidationError,
    companyName
  }) => () => {
    updateValidationError({
      ...validationError,
      companyName: !isCompanyNameValid(companyName)
    });
  },
  validateFirstName: ({
    validationError,
    updateValidationError,
    firstName
  }) => () => {
    updateValidationError({
      ...validationError,
      firstName: !isNameValid(firstName)
    });
  },
  validateLastName: ({
    validationError,
    updateValidationError,
    lastName
  }) => () => {
    updateValidationError({
      ...validationError,
      lastName: !isNameValid(lastName)
    });
  },
  validatePhoneNumber: ({
    validationError,
    updateValidationError,
    phone
  }) => () => {
    updateValidationError({
      ...validationError,
      phone: !isPhoneNumberValid(phone)
    });
  },
  clearValidation: ({ updateValidationError }) => () => {
    updateValidationError({
      email: null,
      firstName: null,
      lastName: null,
      phone: null
    });
  }
});
