import styled from 'styled-components';
import { Card as UnstyledCard } from 'shared-components';

// Card that contains redemption information
export const Card = styled(UnstyledCard)`
  display: flex;
  flex: 1;
  padding: 60px 50px 25px;
  margin: 0 15px 15px;

  & > * {
    width: 100%;
  }
`;
