import React from 'react';
// Import Local Components
import RedeemDetails from '../components/RedeemDetails';
import RedemptionMethod from '../components/RedemptionMethod';
// import { StyledRedemptionForm } from './styles';
import { Card } from '../styles';

const RedemptionForm = ({
  transaction: {
    amount,
    sender,
    inserted_at,
    notes,
    currency,
    tokenization_key,
    networks,
    etransfer_config,
    support_company_name,
    support_email,
    support_phone_number,
  },
  handleSubmit,
  saveAsDefault,
  setSaveAsDefault,
}) => (
  <div style={{ width: '100%' }}>
    <Card>
      <RedeemDetails
        amount={amount}
        sender={sender}
        date={inserted_at}
        notes={notes}
        currency={currency}
        saveAsDefault={saveAsDefault}
        updateSaveAsDefault={setSaveAsDefault}
        support_company_name={support_company_name}
        support_email={support_email}
        support_phone_number={support_phone_number}
      />
    </Card>
    <Card>
      <RedemptionMethod
        networks={networks}
        currency={currency}
        etransferConfig={etransfer_config}
        handleSubmit={handleSubmit}
        tokenizationKey={tokenization_key}
        saveAsDefault={saveAsDefault}
        setSaveAsDefault={setSaveAsDefault}
      />
    </Card>
  </div>
);

export default RedemptionForm;
