// List of fields needed to activate a card.
// These will be the "headers" in the csv
export const cardHeader = [
  [
    'ID',
    'Qty',
    'First name',
    'Last name',
    'Fourth Line Embossing',
    'Address Line 1',
    'Address Line 2',
    'City',
    'Province/ State',
    'Country',
    'Post/ Zip code',
    'Shipping To',
  ]
];
