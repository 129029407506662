import styled from 'styled-components';

export const ButtonWrapper = styled.div`
  @keyframes bounce {
    0% {
      transform: translateY(0);
    }
    5%,
    10% {
      transform: translateY(-1px);
    }
    7%,
    12% {
      transform: translateY(1px);
    }
    15% {
      transform: translateY(0);
    }
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: ${({ theme }) => theme.borders.default};
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  word-wrap: break-word;
  white-space: pre-wrap
  word-break: break-word;
  min-width: 280px;
  width: 100%;

  ${({ main }) =>
    main
      ? `
    min-height: 250px;
    box-shadow: 0px 1.5px 3px #00000029;
    img {
      height: 40px;
    }

    img:not(last-child) {
      margin-right: 15px;
    }

    div {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 30px 0;
    }
    `
      : `
    min-height: 150px;

    img {
      margin-bottom: 15px;
      height: 58px;
    }
  `}

  ${({ theme, prominent }) =>
    prominent &&
    `
      border: 1px solid ${theme.brand.primary};
      animation-duration: 5s;
      animation-iteration-count: infinite;
      transform-origin: bottom;
      animation-name: bounce;
      animation-timing-function: linear;
      animation-delay: 0s;
    `}

  &:hover {
    background-color: #e1e1e1;
    color: ${({ theme }) => theme.colors.black};
  }

  button {
    padding: 0;
    margin: 0;
  }

  ${({ theme }) => theme.media.tabletPortraitUp`
    ${({ main }) =>
    main &&
      `
        width: 500px;
        img {
          height: 50px;
        }
      `}
  `}
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;

  & > * {
    margin-bottom: 15px;
  }

  ${({ theme }) => theme.media.tabletPortraitUp`
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin: 15px 0;

    & > * {
      margin-bottom: 15px;
    }
  `}
`;
