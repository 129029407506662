import React, { useEffect, useState } from "react";

import {    
    Title,        
    Card,
    CardGroup,
    Loader,
    Table,
    Details,
    DetailsGroup,   
} from 'shared-components';

import { formatTime } from 'shared-components/src/utils/formatDateTime';
// Import Global Components
import ClientErrorComponent from '../../../components/ClientErrorComponent';
// Import Common Hooks
import { useTimeZone } from '../../../hooks/useTimeZone';
import { getProgramColumns } from './components/detailsColumns';
import { get } from '../../../utils/http';

export default ({ history,
    match: {
        params: { id: orderItemId },
      },
    }) => {
      const [isError, setError] = useState(null);
      const [isLoading, setLoading] = useState(true);
      const [orderItem, setOrderItem] = useState({});
      const [timeZone] = useTimeZone();
      useEffect(() => {
        const fetchData = async () => {
          try {
  
            const order = await get(`/api/v1/client_portal/card_issuing/bulk/orders/order_items/${orderItemId}`);
            setOrderItem(order)         
          }
          catch (e){
            setError(true);
          }
          setLoading(false);
        };
        fetchData();
      }, [orderItemId]);
  
      const columns = [
        {
            Header: 'Status',
            accessor: 'status',
        },
      {
        Header: 'Date',
         accessor: 'created_at',
         Cell: ({ value }) => formatTime(value, 'dateTime', timeZone),
      },
      {
        Header: 'Response Code',
        accessor: 'code',
      },
      {
          Header: 'Response Message',
          accessor: 'me',
      },       
    ];
    
      if (isLoading) return <Loader />;
      if (isError) return <ClientErrorComponent data={{}} />;
  return (
    <>
      <CardGroup>     
          <Card>
            <Title>Order Item Details</Title>
            <DetailsGroup>
                {getProgramColumns(timeZone).map(c => (
                <Details
                    key={c[0].header}
                    columns={c}
                    data={orderItem.data}
                />
                ))}
            </DetailsGroup>
            </Card>

            <Card>                                   
            <Title>Order Item History</Title>
            <Table
            columns={columns}
            data={orderItem.data.history}                 
            />
            </Card>
      </CardGroup>
    </>
  );};