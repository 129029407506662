import { compose } from 'recompose';
import {
  renderWhileLoading,
  setRefetchProp,
  renderForError,
} from '../../../state/apolloRecompose';

// Components
import Company from './Company';

// Enhancers
import withCompanyInfo from './enhancers/withCompanyInfo';

export default compose(
  withCompanyInfo,
  renderWhileLoading,
  setRefetchProp,
  renderForError
)(Company);
