import styled from 'styled-components';
import {Input as UnstyledInput } from 'shared-components';

export const TableRow = styled.tr`
  border-bottom: ${({ theme }) => theme.borders.default} !important ;
`;

export const TableElement = styled.td`
  padding: 5px;
`;

export const Prefix = styled.span`
  padding: 7px 8px;
  background: ${({ theme }) => theme.colors.tableStripedBg};
  text-align: center;
  border: ${({ error, theme }) =>
    error ? theme.borders.error : theme.borders.default};
  border-radius: 3px 0 0 3px;
  width: 10%;
  height: 100%;
`;

export const Input = styled(UnstyledInput)`
  ${({ prefix }) => prefix && 'border-radius: 0 4px 4px 0; height: 50%; width: 85%;'};

  &:focus {
    background: #fff;
  }
`;