import styled from 'styled-components';

export const Status = styled.div`
  width: 50%;
  margin: 0 auto;
  padding: 40px 30px;
`;

export const SubText = styled.span`
  color: ${({ theme }) => theme.brand.text};
  font-size: 1rem;
`;

export const SubTitle = styled.h6`
  padding-top: 5px;
`;
