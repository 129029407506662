import React from 'react';
import { withTheme } from 'styled-components';
import { Route as DefaultRoute } from 'react-router-dom';
import { Helmet } from 'react-helmet';

export default withTheme(({ theme, title = '', ...props }) => (
  <>
    <Helmet key="helmet">
      <link rel="shortcut icon" href={theme.brand.favicon} />
      <title>{title}</title>
    </Helmet>
    <DefaultRoute {...props} key={`route-${title.split(' ').join('')}`} />
  </>
));
