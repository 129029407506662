import styled from 'styled-components';

// Styles the form
export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex: 1;
  width: 100%;

  ${({ theme }) => theme.media.tabletPortraitUp`
    input[type="submit"] {
      width: 100%;
    }
  `}
`;

// Contains all inputs in a form
export const InputContainer = styled.fieldset`
  box-sizing: border-box;
  margin-bottom: 20px;

  & > *:not(:last-child) {
    margin-bottom: 15px;
  }
`;
