import React from 'react';
import {withTheme} from 'styled-components'
import {Logo} from 'shared-components'
// Import Local Components
// import Logo from '../../components/Logo';
import Footer from '../components/Footer';
// Import Component Styles
import { BaseWrapper, Base } from './styles';

export default withTheme(({ theme, children, ...props }) => (
  <BaseWrapper key="base-body">
    <Base>
      <Logo src={theme.brand.logo} />
      {children}
      <Footer />
    </Base>
  </BaseWrapper>
));
