import { css } from 'styled-components';

// Here we define an object with our desired sizes, then use reduce to loop though them and spit out an object with our assembled media queries
const sizes = {
  phoneOnly: 599,
  tabletPortraitUp: 600,
  tabletLandscapeUp: 900,
  desktopUp: 1200,
  bigDesktopUp: 1800,
};

export default Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => {
    return label === 'phoneOnly'
      ? css`
          @media only screen and (max-width: ${sizes[label]}px) {
            ${css(...args)}
          }
        `
      : css`
          @media only screen and (min-width: ${sizes[label]}px) {
            ${css(...args)}
          }
        `;
  };

  return acc;
}, {});
