import styled from 'styled-components';

export default styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  button:last-child {
    margin-top: 15px;
  }
`;
