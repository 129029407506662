import { withHandlers } from 'recompose';
import {
  isEmailValid,
  isNameValid,
  isDisbursementNameValid,
  isAmountValid,
} from 'shared-components/src/validations/transferValidations';
import { mutationErrorMessageParser } from '../../../../utils/errorLogger';
import { parse } from '../../../../utils/csv';

export default withHandlers({
  validateTransferName: ({ validations, updateValidations, name }) => () => {
    updateValidations({
      ...validations,
      name: !isDisbursementNameValid(name),
    });
  },
  onNameChange: ({ updateName, updateError, updateWarning }) => e => {
    updateName(e.target.value);
    updateError(null);
    updateWarning(null);
  },
  onCurrencyChange: ({ updateCurrency, updateError, updateWarning }) => key => {
    updateCurrency(key);
    updateError(null);
    updateWarning(null);
  },
  toggleModal: ({
    updateConfirmModal,
    confirmModal,
    name,
    transactions,
    updateError,
    updateWarning,
    validations,
    limitsData: {
      company: {
        disbursementLimit
      }
    },
  }) => e => {
    e.preventDefault();

    if (!name || validations.name)
      return updateError('No transfer name created');

    if (
      validations.transactions.filter(item => item.amount === true).length > 0
    ) {
      return updateError(
        'One or more payments is over company limits and will be unredeemable'
      );
    }

    if (!validations.transactions || validations.transactions.length === 0) {
      return updateError('Please add a Payment entry');
    }

    if (transactions.length > disbursementLimit) {
      return updateError(
        `Max number of Recipients per Transfer is ${disbursementLimit}. Please split payments into multiple transfers if more payments are required.`
      );
    }

    if (
      transactions.length > 0 &&
      passCheck(transactions).length !== transactions.length
    ) {
      return updateError(
        'Payment entries contains errors or are incomplete, please correct and resubmit'
      );
    }

    if (checkTransactionRowErrors(validations.transactions)) {
      return updateError(
        'Payment entries contains errors or are incomplete, please correct and resubmit'
      );
    }

    updateConfirmModal(!confirmModal);
    updateError(null);
    updateWarning(null);
  },
  addData: ({
    updateTransactions,
    transactions,
    updateError,
    updateWarning,
    validations,
    updateValidations,
    limitsData: {
      company: {
        disbursementLimit
      }
    },
  }) => e => {
    e.preventDefault();
    // NOTE: Use id's not length since rows may have been deleted so we could potentially have collisions
    const lastTrans = transactions.slice(-1).map(t => t.id);
    const newId = Number(lastTrans) + 1;

    if (transactions.length <= disbursementLimit) {
      // Add new transaction to the list
      updateTransactions([
        ...transactions,
        {
          id: newId,
          email: '',
          firstName: '',
          lastName: '',
          amount: '',
          notes: '',
        },
      ]);

      // Add new row to validations
      updateValidations({
        ...validations,
        transactions: [
          ...validations.transactions,
          {
            id: newId,
            email: null,
            firstName: null,
            lastName: null,
            amount: null,
            notes: null,
          },
        ],
      });

      // Clear any general error
      updateError(null);
      updateWarning(null);
    } else {
      updateError(
        `Max number of Recipients per Transfer is ${disbursementLimit}. Please split payments into multiple transfers if more payments are required.`
      );
    }
  },
  deleteData: ({
    updateTransactions,
    transactions,
    updateError,
    updateWarning,
    validations,
    updateValidations,
  }) => current_index => {
    const updatedTransactions = transactions
      .filter((e, i) => current_index !== i)
      .map((e, i) => Object.assign(e, { id: i + 1 }));
    const updatedValidations = validations.transactions
      .filter((e, i) => current_index !== i)
      .map((e, i) => Object.assign(e, { id: i + 1 }));

    updateTransactions(updatedTransactions);
    updateValidations({
      ...validations,
      transactions: updatedValidations,
    });
    updateError(null);
    updateWarning(null);
  },
  parseCsv: ({
    updateTransactions,
    accountHolderType,
    validations,
    updateValidations,
    updateError,
    updateWarning,
    updateSuccess,
    limitsData: {
      company: {
        disbursementLimit
      }
    },
  }) => async e => {
    // Get file and clear file input
    const file = e.target.files[0];
    e.target.value = null;

    // Clear any general error
    updateError(null);
    updateWarning(null);

    try {
      const rows = await parse(file);
      if (rows.data.length > (disbursementLimit + 1)) {
        alert(
          `File contains more row then allowable limit of ${disbursementLimit}. Please reduce file size or split into multiple payments`
        );
        return;
      }
      const data = parseDisbursmentRows(rows.data.slice(1), accountHolderType, disbursementLimit);
      const transactionValidations = data.map(trx => ({
        id: trx.id,
        email: null,
        firstName: null,
        lastName: null,
        amount: null,
        notes: null,
      }));
      updateValidations({
        ...validations,
        transactions: transactionValidations,
      });

      // Add new rows to transactions
      updateTransactions(data);
      updateSuccess('CSV has been validated successfully. Please review your submission and click Send Transfer to begin processing.');
    } catch (err) {
      const message = mutationErrorMessageParser(err, 'Error uploading CSV');
      updateError(message);
    }
  },
  updateCell: ({
    updateTransactions,
    transactions,
    error,
    warning,
    updateError,
    updateWarning,
    validations,
    updateValidations,
    limitsData: {
      company: { transactionLimits },
      globalTransactionLimits,
    },
  }) => (e, index, original, id) => {
    const data = { ...original };
    data[id] = e.target.value;

    // Set eft push limit based on company limit or global limit
    const eftPushLimit = setEftPushLimit(
      transactionLimits,
      globalTransactionLimits
    );

    // Set visa push limit based on company limit or global limit
    const visaPushLimit = setVisaPushLimit(
      transactionLimits,
      globalTransactionLimits
    );

    const newTransactions = [
      ...transactions.slice(0, index),
      { ...transactions[index], ...data },
      ...transactions.slice(index + 1),
    ];
    // Update row in transactions list
    updateTransactions(newTransactions);

    const { amount } = newTransactions[index];

    if (
      id === 'amount' &&
      (!isAmountValid(amount) ||
        (amount * 100 > eftPushLimit && amount * 100 > visaPushLimit))
    ) {
      // check if amount is not valid of is amount is over limits
      updateValidations({
        ...validations,
        transactions: [
          ...validations.transactions.slice(0, index),
          // Update the amount validation using "NAN" to trigger validations warning
          updateTransactionValidationRow(
            validations.transactions[index],
            'amount',
            'NaN'
          ),
          ...validations.transactions.slice(index + 1),
        ],
      });
    } else {
      updateValidations({
        ...validations,
        transactions: [
          ...validations.transactions.slice(0, index),
          updateTransactionValidationRow(
            validations.transactions[index],
            id,
            data[id]
          ),
          ...validations.transactions.slice(index + 1),
        ],
      });
    }

    // Run validation on updated row
    const transactionOverLimits = newTransactions.find(
      item =>
        item.amount * 100 > eftPushLimit || item.amount * 100 > visaPushLimit
    );
    if (!error && !warning && transactionOverLimits) {
      updateWarning(
        'One or more payments is over a limit and will restrict redemption options'
      );
    } else if (warning && !transactionOverLimits) {
      updateWarning(null);
    } else if (
      error ===
        'One or more payments is over company limits and will be unredeemable' &&
      warning
    ) {
      updateWarning(null);
    }
    updateError(null);
  },
  prefilAccountHolderFromRouterState: ({
    updateTransactions,
  }) => accountHolder => {
    const fullName = accountHolder.contactDetails.fullName.split(' ');
    updateTransactions([
      {
        id: 0,
        firstName: fullName[0] || '',
        lastName: fullName[1] || '',
        type: accountHolder.type,
        email: accountHolder.email,
        amount: '',
        notes: '',
      },
    ]);
  },
  sendTransfer: ({
    createTransfer,
    transactions,
    history,
    name,
    updateError,
    confirmModal,
    updateConfirmModal,
    updateLoadingModal,
    accountHolderType,
    currency,
    updateSuccess,
  }) => (e, currency) => {
    e.preventDefault();

    const tr = formatTransactions(transactions, accountHolderType);

    // Remove Confirm Modal from Screen
    updateConfirmModal(!confirmModal);

    // Render Loading Modal to Screen
    updateLoadingModal(true);

    createTransfer({
      name,
      currency,
      payments: tr,
    })
      .then(({ data: { createTransfer: { id } } }) => {
        updateSuccess('Transfer was created successfully');
        // Transfer has completed so we can push user to transfer details screen
        history.push(`/direct-send/transfers/${id}`);
      })
      .catch(err => {
        const errorMessage = mutationErrorMessageParser(
          err,
          'Error Creating transfer, please try again'
        );
        // Remove loading modal and render error
        updateLoadingModal(false);
        updateError(errorMessage);
      });
  },
});

function checkTransactionRowErrors(transactionRow) {
  return transactionRow.find(item =>
    Object.keys(item).find(e => item[e] === true)
  );
}

function passCheck(transactions) {
  const passTrs = transactions
    .filter(t => !!t.amount)
    .filter(t => !!t.email)
    .filter(t => !!t.firstName);
  return passTrs.length === transactions.length ? passTrs : [];
}

function formatTransactions(transactions, type) {
  return (
    transactions
      .filter(t => t.amount > 0)
      .filter(t => !!t.email)
      .map(t => ({
        email: t.email.trim(),
        firstName: t.firstName.trim(),
        lastName: t.lastName.trim(),
        type,
        amount: convertAmount(t.amount),
        notes: t.notes.trim(),
      }))
      // FIXME: Currently we set a dummy value for the last name for a company
      // This should be removed once we mark last name optional in teller
      .map(t => (type === 'business' ? { ...t, lastName: '   ' } : t))
  );
}

function convertAmount(amount) {
  // multiply by 100 since we are assuming CAD as currency
  return Math.round(parseFloat(amount, 10) * 100);
}

// Parse the Disbursement CSV rows
// Throw errors if value fails validations
function parseDisbursmentRows(rows, accountHolderType, disbursementLimit) {
  if (rows.length > disbursementLimit) {
    throw new Error(`File contains too many rows. Max row count is ${disbursementLimit}.`);
  }

  return rows.map((row, id) => {
    if (accountHolderType === 'individual') {
      return parseUser(row, id);
    }
    if (accountHolderType === 'business') {
      return parseBusiness(row, id);
    }
    throw new Error('No Account Holder type');
  });
}

function parseUser(row, id) {
  if (row.length === 5) {
    // Validate that all account holders have a valid email provided
    const email = row[0];
    if (!(email && isEmailValid(email))) {
      throw new Error(`Missing or invalid email: ${email}, row: ${++id}.`);
    }

    // Validate that all account holders have a valid first name
    const firstName = row[1];
    if (!(firstName && isNameValid(firstName))) {
      throw new Error(
        `Missing or invalid first name: ${firstName}, row: ${++id}.`
      );
    }

    // Validate that all account holders have a valid last name
    // This is an optional field
    const lastName = row[2];
    if (lastName && !isNameValid(lastName)) {
      throw new Error(`Invalid last name: ${lastName}, row: ${++id}.`);
    }

    const amount = row[3];
    if (!(amount && isAmountValid(amount))) {
      throw new Error(`Missing or invalid amount: ${amount}, row: ${++id}.`);
    }

    return {
      id,
      firstName,
      lastName: row[2] || '   ',
      email,
      amount: parseFloat(amount, 10),
      notes: row[4] || '',
    };
  }
  throw new Error(`Row missing data: row ${++id}.`);
}

function parseBusiness(row, id) {
  if (row.length === 4) {
    // Validate that all businesses have a valid name
    const businessName = row[0];
    if (!(businessName && isNameValid(businessName))) {
      throw new Error(
        `Missing or invalid first name: ${businessName}, row: ${++id}.`
      );
    }

    // Validate that all businesses have a valid email provided
    const email = row[1];
    if (!(email && isEmailValid(email))) {
      throw new Error(`Missing or invalid email: ${email}, row: ${++id}.`);
    }

    const amount = row[2];
    if (!(amount && isAmountValid(amount))) {
      throw new Error(`Missing or invalid amount: ${amount}, row: ${++id}.`);
    }

    return {
      id,
      firstName: businessName,
      lastName: '   ',
      email,
      amount: parseFloat(amount, 10),
      notes: row[3] || '',
    };
  }
  throw new Error(`Row missing data: row ${++id}.`);
}

function updateTransactionValidationRow(transactionValidationRow, key, value) {
  switch (key) {
  case 'email':
    return { ...transactionValidationRow, email: !isEmailValid(value) };
  case 'amount':
    return {
      ...transactionValidationRow,
      amount: !isAmountValid(value),
    };
  case 'firstName':
    return {
      ...transactionValidationRow,
      firstName: !isNameValid(value),
    };
  case 'lastName':
    return {
      ...transactionValidationRow,
      lastName: !isNameValid(value),
    };
  case 'notes':
  default:
    return transactionValidationRow;
  }
}

// Set eft push limit based on company limit or global limit
function setEftPushLimit(txnLimits, globalTxnLimits) {
  const companyEftPushLimit = txnLimits.find(
    item =>
      item.networkType === 'eft' &&
      item.product === 'disbursements' &&
      item.transactionType === 'push'
  );
  const globalEftPushLimit = globalTxnLimits.find(
    item =>
      item.networkType === 'eft' &&
      item.product === 'disbursements' &&
      item.transactionType === 'push'
  );
  let eftPushLimit = Infinity;

  if (companyEftPushLimit) {
    eftPushLimit = companyEftPushLimit.amount;
  } else if (globalEftPushLimit) {
    eftPushLimit = globalEftPushLimit.amount;
  }

  return eftPushLimit;
}

// Set visa push limit based on company limit or global limit
function setVisaPushLimit(txnLimits, globalTxnLimits) {
  const companyVisaPushLimit = txnLimits.find(
    item =>
      item.networkType === 'visa' &&
      item.product === 'disbursements' &&
      item.transactionType === 'push'
  );
  const globalVisaPushLimit = globalTxnLimits.find(
    item =>
      item.networkType === 'visa' &&
      item.product === 'disbursements' &&
      item.transactionType === 'push'
  );
  let visaPushLimit = Infinity;

  if (companyVisaPushLimit) {
    visaPushLimit = companyVisaPushLimit.amount;
  } else if (globalVisaPushLimit) {
    visaPushLimit = globalVisaPushLimit.amount;
  }

  return visaPushLimit;
}
