import React, { useState } from 'react';
import { Modal } from 'shared-components';
import { useUpdateStateObject } from 'shared-components/src/hooks/useUpdateStateObject';
import ModalForm from '../ModalForm';
import ModalFormDelete from '../ModalFormDelete';
import { post, put, deleteHTTP } from '../../../../../utils/http';
// Import Shared Hook
import {
  checkForValidationErrors,
} from '../../utils';

export default ({
  type,
  closeModal,
  setError,
  setSuccess,
  locationInfo,
  setLocationInfo,
  refetchLocations,
  programID,
}) => {
  const initialValidationErrors = {
    name: false,
    phone: false,
    address1: false,
    address2: false,
    postalCode: false,
    city: false,
    countryCode: false,
    state: false,
    processorID: false,
  };

  const [validationErrors, setValidationErrors] = useUpdateStateObject(
    initialValidationErrors
  );

  const [formError, setFormError] = useState(null);

  const locationManagement = async (successMessage, failureMessage) => {
    const {
      name,
      phone,
      address1,
      address2,
      city,
      state,
      postalCode,
      countryCode,
      providerSpecifiedID,
      id,
    } = locationInfo;

    // we need to pass in two hardcored values for status and storeType, this may shift to a user input in the future
    try {
      let response;
      if (type === 'create') {
        response = await post(
          `/api/v1/client_portal/card_issuing/programs/${programID}/locations`,
          {
            name,
            phone,
            address1,
            address2,
            city,
            state,
            postalCode,
            countryCode,
            status: 'Active',
            storeType: 'Store',
          }
        );
      } else if (type === 'edit') {
        const url =
          `/api/v1/client_portal/card_issuing/programs/${programID}/locations/${id}`;
        response = await put(url, {
          name,
          phone,
          address1,
          address2,
          city,
          postalCode,
          state,
          countryCode,
          providerSpecifiedID,
          status: 'Active',
          storeType: 'Store',
        });
      } else if (type === 'deactivate') {
        const url =
          `/api/v1/client_portal/card_issuing/programs/${programID}/locations/${ 
            locationInfo.id}`;
        response = await deleteHTTP(url);
      }

      // Check response status if its not success we return the error
      if (response.status === 'Success') {
        setError(null);
        setSuccess(successMessage);
        refetchLocations();
        closeModal();
      } else {
        setSuccess(null);
        setError(`Error: ${  response.status}`); // Modify as per your response structure
        refetchLocations();
        closeModal();
      }
    } catch (e) {
      setSuccess(null);
      closeModal();
      return setError(failureMessage);
    }
  };

  const handleLocation = (successMessage, failureMessage) => {
    setFormError(false);

    if (checkForValidationErrors(locationInfo, type)) {
      return setFormError(
        'Please correct invalid or blank fields and resubmit'
      );
    }

    return locationManagement(successMessage, failureMessage);

  };

  const modalProps = {
    type,
    error: formError,
    locationInfo,
    closeModal,
    setLocationInfo,
    validationErrors,
    setValidationErrors,
  };

  return (
    <Modal key="create-location-modal">
      {type === 'create' && (
        <ModalForm
          {...modalProps}
          submit={() =>
            handleLocation(
              'New Location has been created successfully',
              'Failed to Create Location'
            )}
          title="Add a New Location"
          page="create-location"
        />
      )}
      {type === 'edit' && (
        <ModalForm
          {...modalProps}
          submit={() =>
            handleLocation(
              'Location has been updated successfully',
              'Failed to Update Location'
            )}
          title="Edit a Location"
          page="edit-location"
        />
      )}
      {type === 'deactivate' && (
        <ModalFormDelete
          {...modalProps}
          submit={() =>
            handleLocation(
              'Location has been deactivated successfully',
              'Failed to Deactivate Location'
            )}
          title="Deactivate a Location"
          page="deactivate-location"
        />
      )}
    </Modal>
  );
};
