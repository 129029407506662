import gql from 'graphql-tag';

export const FinancialAccount = {
  fragments: {
    paymentCard: gql`
      fragment PaymentCardInfo on PaymentCard {
        id
        vanityIdentifier
        name
        type
        fundingType
        pushFundsEnabled
        fastFundsEnabled
        brand
        lastFourDigits
        expiryMonth
        expiryYear
        addressCity
        addressCountry
        addressLine1
        addressLine2
        addressState
        addressPostalCode
        latestStatus
      }
    `,
    bankAccount: gql`
      fragment BankAccountInfo on BankAccount {
        id
        vanityIdentifier
        type
        accountHolderName
        routingNumber
        lastFourDigits
        latestStatus
        transitNumber
        institutionNumber
        country
        currency
      }
    `,
    etransferCustomer: gql`
      fragment EtransferCustomerInfo on EtransferCustomer {
        id
        vanityIdentifier
        customerName
        email
        phoneNumber
        type
      }
    `,
  },
};
