import { compose, withState } from 'recompose';

import Accounts from './Accounts';
import withHandlers from './enhancers/withHandlers';
import withCreateFinancialAccountMutation from './enhancers/mutations/withCreateFinancialAccountMutation';
import withUpdateFinancialAccountMutation from './enhancers/mutations/withUpdateFinancialAccountMutation';
import withRemoveFinancialAccountMutation from './enhancers/mutations/withRemoveFinancialAccountMutation';
import withUpdateDefaultFinancialAccount from './enhancers/mutations/withUpdateDefaultFinancialAccount';
import withRemoveDefaultFinancialAccount from './enhancers/mutations/withRemoveDefaultFinancialAccount';
import withSuccessState from './enhancers/states/withSuccessState';
import withConfirmDeleteModal from './enhancers/states/withConfirmDeleteModal';
import withRemoveAccountState from './enhancers/states/withRemoveAccountState';

export default compose(
  withState('createPaymentCardModal', 'updateCreatePaymentCardModal', false),
  withState('editPaymentCardModal', 'updateEditPaymentCardModal', false),
  withState('createBankAccountModal', 'updateCreateBankAccountModal', false),
  withState('editBankAccountModal', 'updateEditBankAccountModal', false),
  withState('accountHolderInfo', 'updateAccountHolderInfo', ''),
  withState('selectedFA', 'updateSelectedFA', ''),
  withState('error', 'updateError', null),
  withSuccessState,
  withConfirmDeleteModal,
  withRemoveAccountState,
  withCreateFinancialAccountMutation,
  withUpdateFinancialAccountMutation,
  withRemoveFinancialAccountMutation,
  withUpdateDefaultFinancialAccount,
  withRemoveDefaultFinancialAccount,
  withHandlers
)(Accounts);
