import React from 'react';
// Import Shared Constants
import { csvCardInputs } from 'shared-components/src/constants/propTypes';
import { CardDetail } from './styles';
// Import Utils
import { getCardDetails } from '../../../../utils/csv';

const CardDetails = ({ cards }) => {
  const cardDetails = getCardDetails(cards);

  return (
    <>
      <CardDetail>
        <p>Total Cards</p>
        <span>{cardDetails.cards}</span>
      </CardDetail>
    </>
  );
};

CardDetails.propTypes = {
  cards: csvCardInputs.isRequired,
};

export default CardDetails;
