import React from 'react';
// Import Local Components
import { Alert, Title } from 'shared-components';
import RedeemResult from '../components/RedeemResult';
import RedeemError from '../components/RedeemError';

export default ({ result, transaction }) => {
  if (result.status === 'invalid_transaction_details')
    return  (   
      <div>
        <Title>Error Redeeming Funds / Erreur de remboursement des fonds</Title>
        <Alert type="danger">
          Failed to Redeem Funds. Please check your card details, refresh the page and try again.
          Échec du remboursement des fonds. Veuillez vérifier les détails de votre carte, actualiser la page et réessayer
        </Alert>
      </div>
    );
  if (result.status === 'error')
    return <RedeemError error={result.message} transaction={transaction} />;

  return <RedeemResult result={result} transaction={transaction} />;
};
