export default [
  { key: 'pending', val: 'Pending' },
  { key: 'processing', val: 'In Progress' },
  { key: 'error', val: 'Error' },
  { key: 'queued', val: 'Queued' },
  { key: 'awaiting_approval', val: 'Awaiting Approval' },
  { key: 'notification_sent', val: 'Notification Sent' },
  { key: 'notification_failed', val: 'Notification Failed' },
  { key: 'redeeming', val: 'Redeeming' },
  { key: 'redeemed', val: 'Redeemed' },
  { key: 'cancelling', val: 'Cancelling' },
  { key: 'canceled', val: 'Canceled' },
];
