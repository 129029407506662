import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  /* Card wrapping search component */
  & > *:first-child {
    flex: 2 0 0;
  }

  /* Card wrapping create button */
  & > *:last-child {
    display: flex;
    justify-content: flex-end;
    align-items: stretch;
    flex: 1;
    margin-top: 15px;
  }

  ${({ theme }) => theme.media.bigDesktopUp`
    flex-direction: row;

    & > *:last-child {
      margin-top: 0;
      margin-left: 15px;
    }
  `}
`;
// Date selection and action buttons are grouped together in a flex box within the header flex box
export const DateAndButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  & > *:last-child {
    margin-top: 15px;
  }
  ${({ theme }) => theme.media.tabletLandscapeUp`
    flex-direction: row;
    min-width: 50em;
    & > *:first-child {
      flex: 2;
    }

    & > *:last-child {
      flex: 1;
      margin-left: 15px;
      margin-top: 0px;
    }
  
  `}
`;
