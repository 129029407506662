import { withState } from 'recompose';

export default withState('transactions', 'updateTransactions', [
  {
    id: 0,
    email: '',
    firstName: '',
    lastName: '',
    amount: '',
    notes: '',
  },
]);
