export const currentDateTime = () => new Date();

export const currentDate = () => new Date().toISOString().split('T')[0];

// Checks
export function isDate(date) {
  return (
    date instanceof Date ||
    (typeof date === 'object' &&
      Object.prototype.toString.call(date) === '[object Date]')
  );
}

// Setters
/**
 * @param {Date} date - the date to be changed
 * @returns {Date} the new date with the days added
 *
 * @example
 * // Set end of day:
 * var result = setStartOfDay(new Date(2014, 8, 1))
 * //=> Thu Sep 1 2014 00:00:00
 */

export function setStartOfDay(date) {
  return isDate(date)
    ? new Date(date.setHours(0, 0, 0, 0))
    : new Date(new Date(date).setHours(0, 0, 0, 0));
}
export function setEndOfDay(date) {
  return isDate(date)
    ? new Date(date.setHours(23, 59, 59, 999))
    : new Date(new Date(date).setHours(23, 59, 59, 999));
}
export function setStartOfMonth(date) {
  return isDate(date)
    ? new Date(date.getFullYear(), date.getMonth(), 1)
    : new Date(new Date(date).getFullYear(), new Date(date).getMonth(), 1);
}
export function setEndOfMonth(date) {
  return isDate(date)
    ? setEndOfDay(new Date(date.getFullYear(), date.getMonth() + 1, 0))
    : setEndOfDay(
      new Date(new Date(date).getFullYear(), new Date(date).getMonth() + 1, 0)
    );
}

// Manipulate
export function subtractMonth(date, months) {
  return isDate(date)
    ? new Date(date.getFullYear(), date.getMonth() - months, date.getDate())
    : new Date(
      new Date(date).getFullYear(),
      new Date(date).getMonth() - months,
      new Date(date).getDate()
    );
}
export function addMonth(date, months) {
  return isDate(date)
    ? new Date(date.getFullYear(), date.getMonth() + months, date.getDate())
    : new Date(
      new Date(date).getFullYear(),
      new Date(date).getMonth() - months,
      new Date(date).getDate()
    );
}
export function addDays(date, days) {
  return isDate(date)
    ? new Date(date.setDate(date.getDate() + days))
    : new Date(new Date(date).setDate(new Date(date).getDate() + days));
}
export function subtractDays(date, days) {
  return isDate(date)
    ? new Date(date.setDate(date.getDate() - days))
    : new Date(new Date(date).setDate(new Date(date).getDate() - days));
}

// NOTE: when manipulating Javascript Date Objects, you need to copy the date into a new variable before manipulating. Otherwise, the original named variable with the date object will also be manipulated.

// Getters
export const getMonthLongFormat = date =>
  new Intl.DateTimeFormat('en-US', { month: 'long' }).format(date);

export function getStartOfMonth() {
  return new Date(
    currentDateTime().getFullYear(),
    currentDateTime().getMonth(),
    1
  );
}
export function getEndOfMonth() {
  return setEndOfDay(
    new Date(
      currentDateTime().getFullYear(),
      currentDateTime().getMonth() + 1,
      0
    )
  );
}
export function getStartOfDay() {
  return new Date(currentDateTime().setHours(0, 0, 0, 0));
}
export function getEndOfDay(zone = 'local') {
  if (zone === 'utc') {
    const d = new Date();
    return new Date(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate(), 23, 59, 59, 999);
  }
  return new Date(currentDateTime().setHours(23, 59, 59, 999));
}
export function getSevenDaysAgo() {
  return setStartOfDay(
    new Date(currentDateTime().setDate(currentDateTime().getDate() - 6))
  );
}
export function getThirtyDaysAgo(zone = 'local') {
  if (zone === 'utc') {
    const d = new Date();
    return new Date(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate() - 30, 0, 0, 0);
  }
  return setStartOfDay(
    new Date(currentDateTime().setDate(currentDateTime().getDate() - 29))
  );
}
export function getThirtyDaysFromNow() {
  return setStartOfDay(
    new Date(currentDateTime().setDate(currentDateTime().getDate() + 29))
  );
}
export function getCurrentUnixTime() {
  return Math.floor(currentDateTime().getTime() / 1000);
}
export function getUnixTime(date) {
  return new Date(date).getTime() / 1000;
}

// Check start and end dates for date picker and make sure they're valid
export const getStartAndEndDates = (startDate, endDate) => {
  const getStartDate = (startDate, endDate) =>
    startDate === (null || undefined)
      ? setStartOfDay(endDate)
      : setStartOfDay(startDate);

  const getEndDate = (startDate, endDate) =>
    endDate === (null || undefined)
      ? setEndOfDay(startDate)
      : setEndOfDay(endDate);

  return {
    validStartDate: getStartDate(startDate, endDate),
    validEndDate: getEndDate(startDate, endDate),
  };
};

// Checks expiry dates on a payment card and returns a class with the text "expired" if it is.
export const getExpiryDate = (expiryMonth, expiryYear) => {
  const currentMonth = currentDateTime().getMonth() + 1;
  const currentYear = currentDateTime().getFullYear();
  const expiryMonthNum = parseInt(expiryMonth, 10);
  const expiryYearNum = parseInt(expiryYear, 10);

  if (
    expiryYearNum < currentYear ||
    (expiryYearNum === currentYear && expiryMonthNum < currentMonth)
  ) {
    return { expiry: `${expiryMonth}/${expiryYear}`, class: 'expired' };
  }
  return { expiry: `${expiryMonth}/${expiryYear}` };
};

export const times = {
  currentTime: getEndOfDay(),
  startOfDay: getStartOfDay(),
  beginningOfWeek: getSevenDaysAgo(),
  beginningOfMonth: getThirtyDaysAgo(),
};
