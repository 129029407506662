import React, {createContext, useState, useEffect} from 'react';
import {get} from '../utils/http';

const ProgramContext = createContext({
    allow_activate_load_card: true,
    card_assign_enabled: true,
    allow_value_unload_by_prn: true
});

function ProgramConfigurationProvider(props) {
    const [programContext, setProgramContext] = useState({
        allow_activate_load_card: true,
        card_assign_enabled: true,
        allow_value_unload_by_prn: true
    });

    async function fetchData() {
        const {data: programs} = await get(
            '/api/v1/client_portal/card_issuing/programs'
        );

        setProgramContext({
            allow_activate_load_card: programs.some(program => program.allow_activate_load_card === true),
            card_assign_enabled: programs.some(program => program.card_assign_enabled === true),
            allow_value_unload_by_prn: programs.some(program => program.allow_value_unload_by_prn === true)
        });
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <ProgramContext.Provider value={{programContext}}>
            {props.children}
        </ProgramContext.Provider>
    );
}

export {
    ProgramContext,
    ProgramConfigurationProvider
};