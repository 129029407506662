import styled from 'styled-components';

export const PayoutTotal = styled.p`
  font-size: 32px;
  color: ${({ theme }) => theme.colors.green};
  margin-bottom: 25px;
  line-height: 0.75;
`;

export const StyledRedeemDetails = styled.div`
  width: 100%;
  margin-bottom: 10px;
`;
