import styled from 'styled-components';

// Styles the form
export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex: 1;
  width: 100%;

  & > [data-id="reporting-form-error-alert"] {
    margin-top: 15px;
  }

  & > div:not(:last-child) {
    margin-bottom: 15px;
  }

  ${({ theme }) => theme.media.desktopUp`
    & > div:first-child {
      width: 50%;
      padding-right: 8px;
    }
  `}
`;

// Contains all inputs in the Form
export const InputsContainer = styled.fieldset`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
`;

// Wraps two inputs in an InputContainer
export const Row = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;

  & > * {
    margin-bottom: 15px;
  }

  ${({ theme }) => theme.media.tabletLandscapeUp`
    flex-direction: row;
    &:not(:last-child) {
      margin-bottom: 15px
    }

    & > * {
      margin-bottom: 0;
      width: 50%;
    }

    & > *:not(:last-child) {
      margin-right: 15px;
    }
  `}
  
  ${({ theme }) => theme.media.desktopUp`
    margin-bottom: 0;
    margin-right: 15px;
  `}
`;