import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
// Import Shared Components
import {
  Card,
  Title,
  Table,
  Button,
  ButtonGroup,
  ActionBar,
} from 'shared-components';
// Import Shared Constants
import {
  csvCardholderInputs,
} from 'shared-components/src/constants/propTypes';
// Import Constants
import COLUMNS from './columns';

const ValidUploadedCardholders = withRouter(
  ({
    cardholders,
    onClick,
    handleCardholderSubmit,
  }) => {
 

    return (
      <Card>
        <Title>Cardholders</Title>
        <Table columns={COLUMNS} data={cardholders} />
        <ActionBar>
          <ButtonGroup justifyContent="space-between">
            <Button type="secondary" onClick={onClick}>
              Back
            </Button>
            <Button type="primary" onClick={e => handleCardholderSubmit(e)}>
              Create Cardholders
            </Button>
          </ButtonGroup>
        </ActionBar>
      </Card>
    );
  }
);

ValidUploadedCardholders.propTypes = {
  history: PropTypes.object.isRequired, //eslint-disable-line
  cardholders: csvCardholderInputs.isRequired,
  setCardholders: PropTypes.func.isRequired,
  setSuccess: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ValidUploadedCardholders;
