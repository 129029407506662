import { withHandlers, compose } from 'recompose';

import { withApollo } from 'react-apollo';
import auth from 'shared-components/src/utils/auth';
import Sidebar from './Sidebar';

export default compose(
  withHandlers({
    logout: ({ history }) => () =>
      auth.logout().then(() => history.push('/auth/login')),
  }),
  withApollo
)(Sidebar);
