import styled from 'styled-components';

// Styles the fieldset
export default styled.fieldset`
  margin-bottom: 30px;

  & > *:not(:last-child):not(legend) {
    margin-bottom: 15px;
  }
`;
