import gql from 'graphql-tag';

export default gql`
  mutation createApiKey($type: String!) {
    createApiKey(type: $type) {
      id
      token
      type
      latestStatus
      insertedAt
    }
  }
`;
