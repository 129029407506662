import React, { useState, useEffect } from 'react';
import { InputGroup } from 'shared-components';
import ModalForm from '../../../../../components/ModalForm';
import { usaStates, canadaProvinces } from '../../../../../utils/locations';
import {
  runValidationCheck,
} from '../../utils';

const LocationForm = ({
  closeModal,
  error,
  locationInfo,
  setLocationInfo,
  validationErrors,
  submit,
  title,
  page,
  type,
  setValidationErrors,
}) => {
  const [stateOptions, setStateOptions] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);

  useEffect(() => {
    if (type === 'create') {
      setSelectedCountry(locationInfo.countryCode);
      setSelectedState(locationInfo.state);
    }
  }, [type, locationInfo.countryCode, locationInfo.state]);

  useEffect(() => {
    switch (locationInfo.countryCode) {
      case '840':
        setStateOptions(usaStates);
        break;
      case '124':
        setStateOptions(canadaProvinces);
        break;
      default:
        setStateOptions([]);
    }
  }, [locationInfo.countryCode]);
  
  const handleChange = (key, value) => {
    let newLocationInfo = { ...locationInfo, [key]: value };
  
    if (key === 'countryCode') {
      setSelectedCountry(value);
      // Reset state when changing country
      setSelectedState(null);
      newLocationInfo.state = null;
    } else if (key === 'state') {
      setSelectedState(value);
    }
    setLocationInfo(newLocationInfo);
  };

  const handleSubmit = e => {
    e.preventDefault();
    runValidationCheck(setValidationErrors, locationInfo);
    return submit();
  };

  const countryOptions = [
    { key: '124', val: 'Canada' },
    { key: '840', val: 'USA' },
  ];

  return (
    <ModalForm
      title={title}
      submit={handleSubmit}
      data-id={`${page}-form`}
      error={error}
      closeModal={closeModal}
    >
      <InputGroup
        required={type === 'create'}
        label="Name:"
        labelFor="name"
        dataId={`${page}-name-input`}
        value={locationInfo.name}
        onChange={e => handleChange('name', e.target.value)}
        error={validationErrors.name}
        type="text"
        maxLength={50}
        pattern="^[-a-zA-Z0-9 _'#.]{1,85}$"
        title="Max 85 characters: letters, numbers, spaces, _ (underscore), ' (single quote), - (hyphen), . (period)."
      />
      <InputGroup
        label="Phone:"
        labelFor="phone"
        type="tel"
        pattern="^\d{10}$"
        dataId={`${page}-phone-input`}
        value={locationInfo.phone}
        onChange={e => handleChange('phone', e.target.value)}
        error={validationErrors.phone}
        minLength="10"
        maxLength="10"
        title="Please enter a 10-digit phone number without hyphens."
      />
      <InputGroup
        required={type === 'create'}
        label="Address 1:"
        labelFor="address1"
        dataId={`${page}-address-one-input`}
        value={locationInfo.address1}
        onChange={e => handleChange('address1', e.target.value)}
        error={validationErrors.address1}
        type="text"
        maxLength={40}
        minLength={4}
        pattern="^[a-zA-Z0-9\s]{4,40}$"
        title="4–40 alphanumeric characters. E.g., '33 Maple Street'"
      />
      <InputGroup
        label="Address 2:"
        labelFor="address2"
        dataId={`${page}-address-two-input`}
        value={locationInfo.address2}
        onChange={e => handleChange('address2', e.target.value)}
        error={validationErrors.address2}
        type="text"
        maxLength={30}
        pattern="^[-a-zA-Z0-9\s#]{0,30}$"
        title="Max 30 alphanumeric characters. E.g., '#4B'"
      />
      <InputGroup
        required={type === 'create'}
        label="Postal Code:"
        labelFor="postalCode"
        dataId={`${page}-postal-code-input`}
        value={locationInfo.postalCode}
        onChange={e => handleChange('postalCode', e.target.value)}
        error={validationErrors.postalCode}
        type="text"
        pattern="^(\d{5}|\d{5}-\d{4}|[A-Z]\d[A-Z]-\d[A-Z]\d)$"
        title="Accepts formats like 12345, 12345-1234, or K1A-1A1"
      />
      <InputGroup
        required={type === 'create'}
        label="City:"
        labelFor="city"
        dataId={`${page}-city-input`}
        value={locationInfo.city}
        onChange={e => handleChange('city', e.target.value)}
        error={validationErrors.city}
        type="text"
        maxLength={30}
        pattern="^[a-zA-Z\s\-.]{0,30}$"
        title="Max 30 characters: letters, spaces, hyphen, and period. E.g., 'Salt Lake City'"
      />
      <InputGroup
        dataId={`${page}-dropdown-country`}
        dropdown
        options={countryOptions.map(opt => ({ key: opt.key, val: opt.val }))}
        selected={selectedCountry}
        updateItem={value => handleChange('countryCode', value)}
        error={validationErrors.countryCode}
        caption="Select a country..."
        labelFor="countryCode"
        label="Country:"
        required={type === 'create'}
      />
      {validationErrors.countryCode && (
        <p className="error-message">{validationErrors.countryCode}</p>
      )}

      <InputGroup
        dataId={`${page}-dropdown-state`}
        dropdown
        options={stateOptions.map(opt => ({ key: opt.key, val: opt.val }))}
        selected={selectedState}
        updateItem={value => handleChange('state', value)}
        error={validationErrors.state}
        caption="Select a state..."
        labelFor="state"
        label="State:"
        maxHeightPx={200}
        required={type === 'create'}
      />
      {validationErrors.state && (
        <p className="error-message">{validationErrors.state}</p>
      )}
      {type !== 'create' && (
        <input
          type="hidden"
          name="providerSpecifiedID"
          value={locationInfo.providerSpecifiedID}
        />
      )}
      {type !== 'create' && (
        <input type="hidden" name="internalID" value={locationInfo.id} />
      )}
    </ModalForm>
  );
};

export default LocationForm;
