import React, { useEffect, useState } from 'react';
// Import Shared Components
import {
  Title,
  Card,
  CardGroup,
  Details,
  DetailsGroup,
  Alert,
  Loader,
} from 'shared-components';
// Import Shared Components Util
import { formatCurrencyCSV } from 'shared-components/src/utils/formatCurrency';
import { getAccountStatus } from 'shared-components/src/utils/cardholders';
// Import Global Components
import ClientErrorComponent from '../../../components/ClientErrorComponent';
// Import Common Hooks
import useTellerApi from '../../../hooks/useTellerApi';
// Import Common Utils
import { get, post } from '../../../utils/http';
// Import Local Components
import Accounts from './components/Accounts';
// Import Local Misc
import {
  CARDHOLDERCOLUMNS,
  ADDRESSCOLUMNS,
} from './components/cardholderColumns';

export default ({
  match: {
    params: { id: cardholderID },
  },
}) => {
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const [orderStatus, setOrderStatus] = useState({});

  const [
    {
      data: { data: cardholder },
      isLoading,
      isError,
    },
    ,
    ,
    setCardholder,
  ] = useTellerApi(
    `/api/v1/client_portal/card_issuing/cardholders/${cardholderID}`,
    { data: {} }
  );

  const resendVirtualEmail = async(a) => {
    try {
      await post(`/api/v1/virtual_card/resend_virtual_redemption`, {
          account_id: a.id,
          program_id: a.program_id,
          processor_reference: a.processor_reference,
      });

      setSuccess('Successfuly sent email');
      setError(null);
    } catch (err) {
      setError('Failed to resend virtual card redemption notification: '.concat(err));
      setSuccess(null);
    }
  };

  const getOrderStatus = (idx, processorReference) => {
    if (!orderStatus[idx]) {
      get(
        `/api/v1/client_portal/card_issuing/accounts/${processorReference}/orderstatus`
      )
        .then(res => {
          if (res.data?.order_status) {
            if (res.data.order_status === 'Success') {
              res.data.order_status = 'Order Confirmed';
            } else if (res.data.order_status !== 'Shipped') {
              res.data.order_status = 'Order Placed';
            }
          }
          if (
            res.data?.order_shipping_method &&
            res.data.order_shipping_method.startsWith('UPS')
          ) {
            res.data.order_tracking_number = (
              <a
                href={`//wwwapps.ups.com/WebTracking/processInputRequest?TypeOfInquiryNumber=T&InquiryNumber1=${res.data.order_tracking_number}`}
              >
                {' '}
                {res.data.order_tracking_number}{' '}
              </a>
            );
          }
          setOrderStatus({ ...orderStatus, [idx]: res.data });
        })
        .catch(err => {
          if (
            err.message ===
            'Order Status is not available for Virtual Card Programs'
          ) {
            setError(err.message);
          } else {
            setError(
              `There was a problem retrieving the Order Status, please try again later.`
            );
          }
        });
    }
  };

  useEffect(() => {
    // Check if cardholder is fetched. If so, fetch balance of each account under cardholder
    if (!isLoading && cardholder.accounts) {
      const accountIds = cardholder.accounts.map(account => account.id);
      // Fetch the balance of each account and set balance state upon completion
      accountIds.map(accountId => {
        get(`/api/v1/client_portal/card_issuing/accounts/${accountId}/balance`)
          .then(res => {
            setCardholder(prev => {
              // since accounts is an array, need to find index of the desired account before adding balance
              const accountIndex = prev.data.accounts.findIndex(
                acc => acc.id === accountId
              );
              const newState = { ...prev };
              const { balance, currency } = res.data;
              newState.data.accounts[accountIndex].balance = formatCurrencyCSV(
                balance,
                currency
              );
              return newState;
            });
          })
          .catch(_ =>
            setError(prev => `${prev || ''}Trouble fetching account balance `)
          );

        return accountId;
      });

      // Fetch program information of an account
      // Add program name to cardholder state
      // And translate processor-specific account status to meaningful msg
      cardholder.accounts.map(account => {
        get(`/api/v1/client_portal/card_issuing/programs/${account.program_id}`)
          .then(res => {
            setCardholder(prev => {
              const accountIndex = prev.data.accounts.findIndex(
                acc => acc.id === account.id
              );
              const { name, program_type } = res.data;
              const newState = { ...prev };
              const accountState = newState.data.accounts[accountIndex];
              const newAccountState = {
                program_name: name,
                program_type: program_type,
                status_message: getAccountStatus(accountState.status_code),
                ...accountState,
              };
              newState.data.accounts[accountIndex] = newAccountState;
              return newState;
            });
          })
          .catch(_ =>
            setError(
              prev => `${prev || ''}Trouble fetching program information `
            )
          );
        return account;
      });
    }
  }, [isLoading, cardholder.accounts, setCardholder]);

  if (!cardholder || isLoading) return <Loader />;
  if (isError) return <ClientErrorComponent data={{}} />;

  return (
    <>
      {success && <Alert type="success">{success}</Alert>}
      {error && <Alert type="danger">{error}</Alert>}
      <CardGroup>
        <Card>
          <Title>Cardholder</Title>
          <DetailsGroup>
            <Details
              key="cardholder-details-profile"
              columns={CARDHOLDERCOLUMNS}
              data={cardholder}
              defaultValue="--"
            />
          </DetailsGroup>
        </Card>
        <Card>
          <Title>Address</Title>
          <DetailsGroup>
            <Details
              key="cardholder-details-address"
              columns={ADDRESSCOLUMNS}
              data={cardholder}
              defaultValue="--"
            />
          </DetailsGroup>
        </Card>
        <Card>
          <Title>Accounts</Title>
          <Accounts
            resendVirtualEmail={resendVirtualEmail}
            cardholder={cardholder}
            setError={setError}
            setSuccess={setSuccess}
            cardholderId={cardholderID}
            getOrderStatus={getOrderStatus}
            orderStatus={orderStatus}
          />
        </Card>
      </CardGroup>
    </>
  );
};
