import {
  isEmailValid,
  isNameValid,
} from 'shared-components/src/validations/accountHolderValidations';

export const validateEmail = (setValidationErrors, userInfo) =>
  setValidationErrors({
    key: 'email',
    value: !isEmailValid(userInfo.email),
  });

export const validateFirstName = (setValidationErrors, userInfo) =>
  setValidationErrors({
    key: 'firstName',
    value: !isNameValid(userInfo.firstName),
  });

export const validateLastName = (setValidationErrors, userInfo) =>
  setValidationErrors({
    key: 'lastName',
    value: !isNameValid(userInfo.lastName),
  });
