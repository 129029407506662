export const DEFAULT_RECIPIENT_ERROR = {
  emailError: false,
  firstNameError: false,
  lastNameError: false,
  amountError: false,
  accountRefError: false,
};

export const DEFAULT_RECIPIENT_DATA = {
  email: '',
  firstName: '',
  lastName: '',
  amount: '',
  accountRef: '',
  externalTag: '',
  currency: 'CAD',
};

export const COLUMNS = ['firstName', 'lastName', 'email', 'amount', 'accountRef'];
export const PREFIXES = [null, null, null, '$', null];
