import { withState } from 'recompose';

export default withState('validations', 'updateValidations', {
  name: false,
  expiry: false,
  transactions: [
    {
      id: 0,
      email: false,
      firstName: false,
      lastName: false,
      amount: false,
      notes: false,
    },
  ],
});
