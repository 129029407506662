// All Theme stuff goes here
import media from './media';
import colors from './colors';

// Styled Components Theme
export default {
  colors,
  media,
  buttons: {
    text: {
      danger: `
        color: ${colors.danger};
        background-color: transparent;
        border-color: transparent;
        border-style: solid;
      `,
      success: `
        color: ${colors.green};
        background-color: transparent;
        border-color: transparent;
        border-style: solid;
      `,
      default: `
        color: ${colors.default};
        background-color: transparent;
        border-color: transparent;
        border-style: solid;
      `,
    },
    default: {
      danger: `
        color: #fff;
        background-color: ${colors.danger};
        border-color: ${colors.danger};
        border-style: solid;
      `,
      success: `
        color: #fff;
        background-color: ${colors.green};
        border-color: ${colors.green};
        border-style: solid;
      `,
      default: `
        color: #fff;
        background-color: ${colors.default};
        border-color: ${colors.default};
        border-style: solid;
      `,
    },
    size: {
      mini: `
        border-width: 1px;
        padding: 2px 4px;
        font-size: .857142rem;
        border-radius: 4px;
      `,
      small: `
        border-width: 1px;
        padding: 4px 8px;
        font-size: .9285rem;
        border-radius: 4px;
      `,
      large: `
        border-width: 2px;
        padding: 8px 24px;
        border-radius: 4px;
        font-size: 1.07rem;
      `,
      action: `
        border-width: 1px;
        padding: 5px 16px;
        font-size: 1rem;
      `,
      default: `
        border-width: 1px;
        padding: 5px 16px;
        font-size: 1rem;
        border-radius: 4px;
      `,
    },
    disabled: `
      background-color: ${colors.disabled};
      border-color: ${colors.disabled};
      cursor: not-allowed;
    `,
  },
  tags: {
    primary: `
      background-color: ${colors.blue};
      color: #fff;
      border-color: ${colors.blue};`,
    secondary: `
      background-color: #fff;
      color: ${colors.blue};
      border-color: ${colors.blue}; `,
    success: `
      background-color: ${colors.green};
      color: #fff;
      border-color: ${colors.green}; `,
    danger: `
      background-color: ${colors.red};
      color: #fff;
      border-color: ${colors.red}; `,
    info: `
      background-color: ${colors.lightBlue};
      color: #fff;
      border-color: ${colors.lightBlue}; `,
    warning: `
      background-color: ${colors.orange};
      color: #fff;
      border-color: ${colors.orange}; `,
    processing: `
      background-color: ${colors.yellow};
      color: #fff;
      border-color: ${colors.yellow}; `,
    queued: `
      background-color: ${colors.lilac};
      color: #fff;
      border-color: ${colors.lilac}; `,
    default: `
      background-color: ${colors.default};
      color: #fff;
      border-color: ${colors.default}; `,
  },
  borders: {
    error: `1px solid ${colors.error};`,
    dotted: `1px dotted ${colors.border};`,
    default: `1px solid ${colors.border};`,
  },
  alerts: {
    success: `
      color: #3c763d;
      background-color: #dff0d8;
      border-color: #d6e9c6;`,
    warn: `
      color: #8a6d3b;
      background-color: #fcf8e3;
      border-color: #faebcc;`,
    danger: `
      color: #a94442;
      background-color: #f2dede;
      border-color: #ebccd1;`,
    info: `
      color: #31708f;
      background-color: #d9edf7;
      border-color: #bce8f1;`,
    default: `
      color: #31708f;
      background-color: #d9edf7;
      border-color: #bce8f1;`,
  },
  icons: {
    success: `${colors.green}`,
    danger: `${colors.danger}`,
    warning: `${colors.yellow}`,
    default: `${colors.lightBlue}`,
  },
};
