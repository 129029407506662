import React from 'react';
import { Tag } from 'shared-components';
import { capitalize } from 'shared-components/src/utils/string';

const AccountHolderTypeTag = ({ type }) => {
  const formattedType = capitalize(type);
  switch (type) {
  case 'individual':
    return <Tag type="primary">{formattedType}</Tag>;
  case 'business':
    return <Tag type="secondary">{formattedType}</Tag>;
  default:
    return null;
  }
};

export default AccountHolderTypeTag;
