export const provinces = [
  'AB',
  'BC',
  'MB',
  'NB',
  'NL',
  'NS',
  'ON',
  'PE',
  'QC',
  'SK',
  'NT',
  'NU',
  'YT',
];

export const states = [
  'AL',
  'AK',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
];

export const countries = ['CA', 'US'];

export function isProvince(province) {
  const formattedProvince = province.toUpperCase();
  return (
    provinces.includes(formattedProvince) || states.includes(formattedProvince)
  );
}

export function isCountry(country) {
  const formattedCountry = country.toUpperCase();
  return countries.includes(formattedCountry);
}

// validate program id
export const validateProgramId = val => /^\d{1,12}$/g.test(val);

// validate cardholder first or last name
export const validateName = name => {
  const nameCheck = /^[a-zA-ZÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝÞßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿ?@!#'~*;+. -]{1,40}$/g;
  return nameCheck.test(name);
};

// validate cardholder email address
export const validateEmail = email => {
  // HTML5 input type email regex
  // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/email#Validation
  const emailCheck = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
  return emailCheck.test(email);
};

// validate cardholder phone number
export const validatePhone = phone => {
  const phoneCheck = /^\d{10}$/g;
  return phoneCheck.test(phone);
};
//
// validate cardholder date of birth
export const validateDateOfBirth = dob => {
  if (!(/^\d{2}-\d{2}-\d{4}$/g.test(dob))) {
    return false;
  }

  const tokens = dob.split('-');

  return !Number.isNaN(Date.parse(`${tokens[2]}-${tokens[1]}-${tokens[0]}`));
};

// validate cardholder reference id
export const validateReferenceId = referenceId => {
  const referenceIdCheck = /^[a-zA-Z0-9]{1,30}$/g;
  return referenceIdCheck.test(referenceId);
};

// TODO Check for post office box
// export const validateNotPOBox = addressLine1 => {
//
// }

// validate cardholder or ship to address line 1
export const validateAddressLine1 = addressLine1 => {
  const addressLine1Check = /^[a-zA-Z0-9ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝÞßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿ ',-.]{1,40}$/g;
  return addressLine1Check.test(addressLine1);
};

// validate cardholder or ship to address line 2
export const validateAddressLine2 = addressLine2 => {
  const addressLine2Check = /^[a-zA-Z0-9ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝÞßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿ '#,-.]{1,30}$/g;
  return addressLine2Check.test(addressLine2);
};

// validate cardholder or ship to city
export const validateCity = city => {
  const cityCheck = /^[a-zA-Z0-9ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝÞßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿ ',.-]{1,20}$/g;
  return cityCheck.test(city);
};

// validate cardholder or ship to state or province
export const validateProvince = province => {
  const provinceCheck = /^[a-zA-Z]{2}$/g;
  const result = provinceCheck.test(province);
  if (result) {
    return isProvince(province);
  }
  return result;
};

// validate cardholder or ship to postal code
export const validatePostalCode = postalCode => {
  const postalCodeCheck = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/g;
  const zipCodeCheck = /^\d{5}(?:[-\s]\d{4})?$/g;
  return postalCodeCheck.test(postalCode) || zipCodeCheck.test(postalCode);
};

// validate cardholder or ship to country
export const validateCountry = country => {
  const countryCheck = /^[a-zA-Z]{2}$/g;
  const result = countryCheck.test(country);
  if (result) return isCountry(country);
  return result;
};

// validate shipping method
export const validateShippingMethod = shippingMethod => {
  // valid Arrow Eye Galileo Shippings Methods are 1, 2, 3, 4 as of July 2020
  const arrowEyeGalileoShippingMethods = ['1', '2', '3', '4'];
  // valid Arrow Eye Carta Shippings Methods are 3, 4, 8, 9 as of August 2020
  const arrowEyeCartaShippingMethods = ['3', '4', '8', '9'];

  return arrowEyeGalileoShippingMethods.includes(shippingMethod) || arrowEyeCartaShippingMethods.includes(shippingMethod);
};

// validate shipping_to field for Instant Issuance cards
export const validateShippingTo = shippingTo => {
  return shippingTo.length <= 150;
};

// validate load amount
export const validateLoadAmount = loadAmount => {
  const loadAmountCheck = /^[0-9]{1,}[.]?[0-9]{0,2}$/g;
  return loadAmountCheck.test(loadAmount) && loadAmount >= 0;
};

// validate load amount and ensure it's greater than zero
export const validateNonZeroLoadAmount = loadAmount => {
  return validateLoadAmount(loadAmount) && loadAmount > 0;
};

// validate emboss line 2
export const validateEmbossLine2 = embossLine2 => {
  const embossLineCheck = /^[a-zA-Z0-9ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝÞßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿ -]{1,23}$/g;
  return embossLineCheck.test(embossLine2);
};

// validate emboss line 2
export const validateEmbossLine4 = embossLine4 => {
  const embossLineCheck = /^[a-zA-Z0-9ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝÞßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿ -]{1,20}$/g;
  return embossLineCheck.test(embossLine4);
};

// validate SIN/SSN
export const validateSIN = sin => {
  const sinCheck = /^\d{9}$/g;
  return sinCheck.test(sin);
};

export const validateUsername = username => {
  const usernameCheck = /^(?=[^a-zA-Z]*[a-zA-Z])[\w]{3,30}$/g;
  return usernameCheck.test(username);
};

export const validatePassword = password => {
  const re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[~@#$%^&*+=`|{}:;!.?"()[\]-])[\w~@#$%^&*+=`|{}:;!.?"()[\]-]{8,25}$/g;
  return re.test(password);
};

export const verifyRegisterValidations = {
  username: validateUsername,
  password: validatePassword,
};

export const validateNumbersOnly = value => {
  const isAllNumbers = /^\d+$/;
  return isAllNumbers.test(value);
};
