import React, {useState, useEffect, useContext} from 'react';
// Import Shared Components
import {
  ActionBar,
  Button,
  Title,
  ButtonGroup,
  UploadButton,
  Modal,
  Alert,
  Input,
  Card,
  CardGroup,
  CSVButton,
} from 'shared-components';
// Import Global Components
import HeaderSection from '../../../components/HeaderSection';
import HeaderBar from '../../../components/HeaderBar';
import TransferTotal from '../../../components/TransferTotal';
// Import Local Components
import EscrowBalance from './components/EscrowBalance';
import ConfirmTransfer from './components/ConfirmTransferModal';
import LoadingTransfer from './components/LoadingTransferModal';
import CreateTransferIndividual from './components/CreateTransferIndividual';
import CreateTransferBusiness from './components/CreateTransferBusiness';
import HEADERS from './components/createTransferHeaders';
// Import Utils
import { getActiveTransferSubscription } from '../../../utils/subscriptions';
import {MAX_RECIPIENT_COUNT} from "../../../utils/config";

export default ({
  addData,
  name,
  onNameChange,
  subscriptionData: {
    company: { subscriptions },
  },
  limitsData: {
    company: {
      disbursementLimit
    }
  },
  updateCell,
  deleteData,
  parseCsv,
  sendTransfer,
  transactions,
  error,
  warning,
  confirmModal,
  toggleModal,
  accountHolderType,
  loadingModal,
  validations,
  validateTransferName,
  success,
  prefilAccountHolderFromRouterState,
  location,
}) => {
  const [uploadCSV, setUploadCSV] = useState(false);
  const [currency, setCurrency] = useState('CAD');

  useEffect(() => {
    if (location.state && location.state.accountHolder)
      return prefilAccountHolderFromRouterState(location.state.accountHolder);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const activeSubscription = getActiveTransferSubscription(subscriptions);
  const total = transactions
    .filter(item => item.amount !== '') // Filter out blank new lines
    .reduce((acc, { amount }) => parseFloat(amount) + acc, 0);

  // Disable button if any of the following are true
  const disabled = !activeSubscription || total === 0 || Number.isNaN(total);

  const escrowCurrency = (currencyValue) => setCurrency(currencyValue);

  return (
    <>
      {!activeSubscription && (
        <div style={{ marginBottom: '20px' }} key="disabled-block">
          <Alert type="warn">
            Please subscribe to a plan before creating a transfer
          </Alert>
        </div>
      )}
      {success && <Alert type="success">{success}</Alert>}
      {disbursementLimit != MAX_RECIPIENT_COUNT && (
          <Alert type="info">Your recipients limit is {disbursementLimit}</Alert>
      )}
      <CardGroup>
        <EscrowBalance escrowCurrency={escrowCurrency}/>
        <Card>
          <Title>Create Transfer</Title>
          <HeaderBar key="create-transfer-header">
            <HeaderSection spaceOut title="Name:">
              <Input
                className="transferName"
                value={name}
                onChange={onNameChange}
                onBlur={validateTransferName}
                error={validations.name}
                name="transfer-name"
              />
            </HeaderSection>
            <HeaderSection spaceOut title="Total:" alignItems="flex-end">
              <TransferTotal total={total} currency={currency} />
            </HeaderSection>
          </HeaderBar>
        </Card>

        <Card>
          {accountHolderType === 'individual' ? (
            <CreateTransferIndividual
              data={transactions}
              deleteData={deleteData}
              errors={validations.transactions}
              updateCell={updateCell}
            />
          ) : (
              <CreateTransferBusiness
                data={transactions}
                deleteData={deleteData}
                errors={validations.transactions}
                updateCell={updateCell}
              />
            )}
          {error && (
            <Alert type="danger">
              Error: 
              {error}
            </Alert>
          )}
          {warning && (
            <Alert type="warn">
              Warning:
              {warning}
            </Alert>
          )}
          <ButtonGroup justifyContent="flex-start">
            <Button
              type="primary"
              onClick={addData}
              disabled={transactions.length >= disbursementLimit}
            >
              Add Recipient
            </Button>
            <CSVButton
              onClick={() => setUploadCSV(true)}
              filename={`create-transfer-${new Date().toISOString().split('T')[0]
                }`}
              type="primary"
              data={[]}
              headers={HEADERS}
            >
              Add Multiple Payments
            </CSVButton>
            {uploadCSV && (
              <UploadButton
                type="secondary"
                onChange={parseCsv}
                accept=".csv"
                disabled={!activeSubscription}
              >
                Upload CSV
              </UploadButton>
            )}
          </ButtonGroup>
          <ActionBar>
            <Button
              type="primary"
              size="large"
              onClick={toggleModal}
              disabled={disabled}
            >
              Send Transfer
            </Button>
          </ActionBar>
        </Card>
      </CardGroup>

      {confirmModal && (
        <Modal key="confirmTransferModal">
          <ConfirmTransfer
            total={total}
            currency={currency}
            recipients={transactions.map(dataObj => dataObj.email)}
            sendTransfer={sendTransfer}
            closeModal={toggleModal}
            error={error}
          />
        </Modal>
      )}
      {loadingModal && (
        <Modal key="loadingTransferModal">
          <LoadingTransfer />
        </Modal>
      )}
    </>
  );
};
