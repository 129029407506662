import { useState, useEffect } from 'react';
import { injectTheme, getSubdomain, redirectToHostname} from '../utils/whitelabel';

export default (appName) => {
  const [loading, setLoading] = useState(true);
  const [theme, setTheme] = useState({});
  const [localization, setLocalization] = useState(null);
  const [error, setError] = useState(null);

  const {hostname} = window.location;

  const subdomain = getSubdomain(hostname);
  // If the appName is passed into the function then append it
  // as query parameters to the URL
  const appNameQuery = appName ? `app_name=${appName}` : '';

  useEffect(() => {
    async function fetchTheme() {
      try {
        const res = await fetch(`/api/v1/white_label/${subdomain}?${appNameQuery}`);
        if (res.ok) {
          const json = await res.json();
          const themeData = injectTheme(json.data.theme);
          setTheme(themeData);
          setLocalization(json.data.language_configuration);
          setLoading(false);
        } else {
          // Unable to retrieve a theme based on the current subdomain
          const result = redirectToHostname();
          setError(result);
          setLoading(false);
        }
      } catch (e) {
        const result = redirectToHostname();
        setError(result);
        setLoading(false);
      }
    }

    fetchTheme();
  }, []);

  return {
    loading,
    theme,
    localization,
    error,
  };
};
