import React from 'react';
// Import Shared Components
import { Button, ButtonGroup, Card, CardGroup } from 'shared-components';
import {
  removeSnakeCase,
  capitalize,
} from 'shared-components/src/utils/string';
import { formatCurrencyCSV } from 'shared-components/src/utils/formatCurrency';
// Import Global Components
import Header from '../../../components/Header';
import InfoTooltip from '../../../components/InfoTooltip';
// Import Local Components
import CompanyLimits from './components/CompanyLimits';
import GlobalLimits from './components/GlobalLimits';
import ExpanderIcon from './components/ExpanderIcon';
// Import Utils
import { getAmountValue } from '../../../utils/limits';

export default ({
  data: {
    viewer: {
      company: { transactionLimits, velocityLimits },
      globalTransactionLimits,
      globalVelocityLimits,
    },
  },
  tab,
  subtab,
  toggleTab,
  toggleSubtab,
}) => {
  const transactionColumns = [
    {
      Header: 'Product',
      accessor: 'product',
      Cell: ({ value }) => capitalize(value),
    },
    {
      Header: 'Network Type',
      accessor: 'networkType',
      Cell: ({ value }) => capitalize(value),
    },
    {
      Header: 'Transaction Type',
      accessor: 'transactionType',
      Cell: ({ value }) => capitalize(value),
    },
    {
      Header: 'Amount',
      id: 'amount',
      accessor: row => row,
      Cell: ({ value }) =>
        formatCurrencyCSV(value.amount / 100, value.currency),
    },
  ];

  const velocityColumns = [
    {
      Header: 'Type',
      accessor: 'type',
      Cell: ({ value }) => capitalize(removeSnakeCase(value)),
    },
    {
      Header: 'Product',
      accessor: 'product',
      Cell: ({ value }) => capitalize(value),
    },
    {
      Header: 'Network Type',
      accessor: 'networkType',
      Cell: ({ value }) => capitalize(value),
    },
    {
      Header: 'Transaction Type',
      accessor: 'transactionType',
      Cell: ({ value }) => capitalize(value),
    },
    {
      Header: 'Rolling Time',
      accessor: 'trailingTimeWindow',
      Cell: ({ value }) => (value > 1 ? `${value} days` : `${value} day`),
    },
    {
      Header: 'Amount',
      id: 'amount',
      accessor: row => row,
      Cell: ({ value }) =>
        formatCurrencyCSV(value.amount / 100, value.currency),
    },
    {
      Header: 'Status',
      id: 'status',
      expander: true,
      width: 60,
      getProps: () => {
        return {
          style: {
            margin: 'auto 0',
          },
        };
      },
      Expander: rowInfo => {
        if (
          rowInfo &&
          rowInfo.original.currentStanding &&
          rowInfo.original.amount
        ) {
          const { isExpanded, original } = rowInfo;
          const value = getAmountValue(
            original.currentStanding.sum,
            original.amount
          );
          return (
            <span>
              {isExpanded ? (
                <ExpanderIcon />
              ) : (
                <ExpanderIcon usedValue={value} />
              )}
            </span>
          );
        }
        return (
          <InfoTooltip
            position="left"
            content={`
              If you have any questions about the status of your Global
              Velocity Limit, please contact us at Berkeley Payment
              Solutions.
            `}
          />
        );
      },
    },
  ];

  return (
    <CardGroup>
      <Header>
        <Card>
          <ButtonGroup>
            <Button
              type="primary"
              variant={tab !== 'company' && 'text'}
              onClick={toggleTab('company')}
            >
              Company Limits
            </Button>
            <Button
              type="primary"
              variant={tab !== 'global' && 'text'}
              onClick={toggleTab('global')}
            >
              Global Limits
            </Button>
          </ButtonGroup>
        </Card>
        <Card>
          <ButtonGroup>
            <Button
              type="secondary"
              variant={subtab !== 'transaction' && 'text'}
              onClick={toggleSubtab('transaction')}
            >
              Transaction Limits
            </Button>
            <Button
              type="secondary"
              variant={subtab !== 'velocity' && 'text'}
              onClick={toggleSubtab('velocity')}
            >
              Velocity Limits
            </Button>
          </ButtonGroup>
        </Card>
      </Header>
      <Card>
        {tab === 'company' ? (
          <CompanyLimits
            subtab={subtab}
            toggleSubtab={toggleSubtab}
            transactionLimits={transactionLimits}
            transactionColumns={transactionColumns}
            velocityLimits={velocityLimits}
            velocityColumns={velocityColumns}
          />
        ) : (
          <GlobalLimits
            subtab={subtab}
            toggleSubtab={toggleSubtab}
            transactionLimits={globalTransactionLimits}
            transactionColumns={transactionColumns}
            velocityLimits={globalVelocityLimits}
            velocityColumns={velocityColumns}
          />
        )}
      </Card>
    </CardGroup>
  );
};
