import React, { useState, useEffect } from 'react';
// Import Shared Components
import {
  Card,
  CardGroup,
  // DatePicker,
  Loader,
  Alert,
} from 'shared-components';
import {
  getEndOfDay,
  subtractMonth,
  currentDateTime,
  // getStartAndEndDates,
} from 'shared-components/src/utils/dates';
import { useUpdateStateObject } from 'shared-components/src/hooks/useUpdateStateObject';
// Import Global Components
import ClientErrorComponent from '../../../components/ClientErrorComponent';
// Import Utils
import {
  getStartDate,
  // getDisabledDays,
  // getTimeRangeStartDate,
  programReportTypeOptions,
  reportingMethods,
} from '../../../utils/reports';
import { get } from '../../../utils/http';
// Import Local Components
import ReportSubscriptionForm from './components/ReportSubscriptionForm';

/* eslint react/prop-types: 0 */
// TODO: No ability to customize date for reporting.
// Commented out ability to customize date for reporting until the functions are available in the back end.
export default ({
  history,
  location: {
    state: { program },
  },
}) => {
  // Set States
  const initialReportFormState = {
    programID: program.id,
    programType: program.program_type,
    reportType: null,
    email: null,
    frequency: null,
    timeRange: null,
    reportingMethod: null,
    startDate: getStartDate(
      program.created_at,
      subtractMonth(currentDateTime(), 1)
    ),
    endDate: getEndOfDay(),
  };

  // Set States
  // const [programStartDate, setProgramStartDate] = useState(new Date(program.created_at));
  // const [disabledDays, setDisabledDays] = useState(null);
  const [loading, setLoading] = useState(null);
  const [error, setError] = useState(null);
  const [reportTypeOptions, setReportTypeOptions] = useState([]);
  const [reportingMethodOptions, setReportingMethodOptions] = useState(
    reportingMethods
  );
  const [submitError, setSubmitError] = useState(null);
  // Create Reducer
  const [reportInfo, setReportInfo] = useUpdateStateObject(
    initialReportFormState
  );

  // Get report configuration for program
  useEffect(() => {
    const getReportConfigs = async () => {
      setLoading(true);
      try {
        const data = await get(
          `/api/v1/client_portal/card_issuing/programs/${program.id}/reporting_config`
        );
        // Filter report options based on program's report configuration
        const options = programReportTypeOptions.filter(
          reportType => data[reportType.key]
        );
        setReportTypeOptions(options);
        setLoading(false);
      } catch (e) {
        setError(e);
        setLoading(false);
      }
    };
    const getSftpConfigs = async () => {
      try {
        const resp1 = await get(
          `/api/v1/client_portal/card_issuing/sftp?company_id=${program.company_id}`
        );
        const resp2 = await get(
          `/api/v1/client_portal/card_issuing/sftp_push?program_id=${program.id}`
        );
        if (resp1.data === null) {
          if (resp2.data.sftp_push_config_id === null){
            setReportingMethodOptions([{ key: 'email', val: 'Email' }]);
          }
          else{
            setReportingMethodOptions([{ key: 'email', val: 'Email' },{ key: 'sftp_push', val: 'SFTP Push' }]);
          }
        }
      } catch (e) {
        setError(e);
        setLoading(false);
      }
    };
    getSftpConfigs();
    getReportConfigs();
  }, [program.company_id, program.id]);

  // // Get days to disable for selected program
  // useEffect(() => {
  //   const disabledDays = getDisabledDays(programStartDate);
  //   setDisabledDays(disabledDays);
  // }, [programStartDate]);

  if (loading) return <Loader />;
  if (error) return <ClientErrorComponent data={{}} />;

  // // Handle Date Picker onChange date selections
  // const handleUpdateDates = (startDate, endDate) => {
  //   const { validStartDate, validEndDate } = getStartAndEndDates(
  //     startDate,
  //     endDate
  //   );
  //
  //   setReportInfo({
  //     key: 'startDate',
  //     value: getStartDate(programStartDate, validStartDate),
  //   });
  //   setReportInfo({ key: 'endDate', value: validEndDate });
  // };

  // Client's would not be able to create new report subscriptions without selecting report type.
  // So hide form if no report types available.
  const allowReportSubscriptionCreation = reportTypeOptions.length !== 0;

  return (
    <CardGroup>
      <Card data-id="reporting-form-container">
        {allowReportSubscriptionCreation ? (
          <ReportSubscriptionForm
            reportInfo={reportInfo}
            setLoading={setLoading}
            history={history}
            program={program}
            reportTypeOptions={reportTypeOptions}
            setReportInfo={setReportInfo}
            submitError={submitError}
            setSubmitError={setSubmitError}
            reportingMethodOptions={reportingMethodOptions}
          />
        ) : (
          <Alert type="info">
            There are currently no reports available for your program, please
            contact Berkeley Payments if you would like to receive reports.
          </Alert>
        )}
      </Card>
    </CardGroup>
  );
};

// TODO: Add back in customizing date range for report generation
// <DatePicker
//   dataIdFrom="reporting-form-date-input-from"
//   dataIdTo="reporting-form-date-input-to"
//   label
//   startDate={reportInfo.startDate}
//   endDate={reportInfo.endDate}
//   onDatesChange={({ startDate, endDate }) =>
//     handleUpdateDates(startDate, endDate)
//   }
//   dayPickerProps={{
//     fromMonth: disabledDays.before,
//     disabledDays: {
//       before: disabledDays.before,
//       after: disabledDays.after,
//     },
//   }}
//   errorTo={validationErrors.endDate}
//   disabled
// />
