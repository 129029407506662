import React, { useCallback, useState, useEffect, useContext } from 'react';
// Import Shared Components
import { Icon } from 'shared-components';
// Import Hooks
import { useEventListener } from '../../hooks/useEventListener';
// Import Contexts
import { ServiceConfigContext } from '../../context/ServiceConfigContext';
import { RoleContext } from '../../context/RoleContext';
import { ProgramContext } from '../../context/ProgramConfigContext';
// Import Local Components
import SectionIcon from './components/Icon';
// Import Styled Components
import {
  SidebarNav,
  Logout,
  NavLink,
  ExternalSite,
  SectionTitleLink,
  SectionTitle,
  SectionWrapper,
} from './styles';
// Import Utils
import { getSectionOpen } from '../../utils/sidebar';

import NAVITEMS from './navItems';

// Constructs the link for a given route based on the link type
function constructLink({ location, name, type }, pathname) {
  if (type === 'external') {
    // Construct link that opens a new window in the browser to a url that is outside of the web portal.
    return (
      <ExternalSite key={location} target="_blank" href={location}>
        {name}
      </ExternalSite>
    );
  }
  // Construct a link to a page within the web portal.
  return (
    <NavLink key={location} to={location} open={location === pathname}>
      {name}
    </NavLink>
  );
}

const Sidebar = ({ logout, pathname }) => {
  const serviceConfiguration = useContext(ServiceConfigContext);
  const userRole = useContext(RoleContext);

  const programContext = useContext(ProgramContext).programContext;
  const updateCardEnabled = programContext.card_assign_enabled;
  const activateLoadEnabled = programContext.allow_activate_load_card;
  const unloadByPrnEnabled = programContext.allow_value_unload_by_prn;

  // Set states
  const [marginTop, setMarginTop] = useState(90);
  const [sectionOpen, setSectionOpen] = useState(null);

  // Change margin top on sidebar when use scrolls on page
  //  eslint-disable-next-line react-hooks/exhaustive-deps
  const handler = useCallback(() => {
    const scroll = window.scrollY;
    if (scroll >= 90) return setMarginTop(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (scroll < 90 && scroll > 0) return setMarginTop(marginTop - scroll);
    if (scroll === 0) return setMarginTop(90);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEventListener('scroll', handler);

  useEffect(() => {
    const currentLocation = window.location.pathname;
    const sectionIdx = getSectionOpen(currentLocation);
    setSectionOpen(sectionIdx);
  }, []);

  const toggleSidebarSectionTitle = idx => setSectionOpen(idx);
  const toggleSidebarSectionLinks = location => pathname === location;

  let sections = NAVITEMS;
  if (!updateCardEnabled) {
    // remove card update tab if no program under a company has card update enabled
    sections[2].routes = sections[2].routes.filter(route => route.name !== 'Update Card');
  }

  if (!activateLoadEnabled) {
    sections[2].routes = sections[2].routes.filter(route => route.name !== 'Activate & Load Card');
  }

  if (!unloadByPrnEnabled) {
    sections[2].routes = sections[2].routes.filter(route => route.name !== 'Unload Card by Proxy');
  }

  // Create Sidebar Nav Sections
  sections = sections
    // Filter out Sections based on required role
    .filter(({ role }) => role.includes(userRole))
    .filter(({ service }) => {
      if (!service || serviceConfiguration[service]) {
        // if this route does not belong to a specific service
        // or this service is enabled for this company then render it
        return true;
      }
      return false;
    })
    .filter(({ isEnabled }) => {
      // isEnabled allows secondary check against feature flags on the section
      if (!isEnabled || serviceConfiguration[isEnabled]) {
        return true;
      }
      return false;
    })
    .map((props, idx) => {
      const { routes, location, name, icon } = props;
      // Construct the links for each section based on the routes in that section
      const links =
        routes && !location
          ? routes
            .filter(({ role }) => role.includes(userRole))
            .filter(({ isEnabled }) => {
              if (!isEnabled || serviceConfiguration[isEnabled]) {
                // if this route does not belong to a specific service
                // or this service is enabled for this company then render it
                return true;
              }
              return false;
            })
            .map((route, idx) => (
              <li
                className={`link-${idx}`}
                key={`${idx}-${route.location}`}
                onClick={() => toggleSidebarSectionLinks(route.location)}
              >
                {constructLink(route, pathname)}
              </li>
            ))
          : name;
      // There are two types of main sections that we render in the sidebar.
      // The first is a section that has no location (will not be a link) attached to it and has routes that are nested beneath it. The second section is one that has no nested routes and has a location (will be a link).
      const isOpen = sectionOpen === idx;

      return (
        <li key={`${userRole}-${name}`}>
          {routes && !location ? (
            <SectionWrapper open={isOpen}>
              <SectionTitle
                key={name}
                onClick={() => toggleSidebarSectionTitle(idx)}
                open={isOpen}
              >
                <SectionIcon key={`${icon}-container`} type={icon} size={18} />
                {name}
              </SectionTitle>
              <div
                className={`links-${
                  sectionOpen === idx ? 'open' : 'offscreen'
                }`}
              >
                <ul key={`${name}-links`}>{links}</ul>
              </div>
            </SectionWrapper>
          ) : (
            <SectionWrapper open={isOpen}>
              <SectionTitleLink
                key={name}
                to={location}
                onClick={() => toggleSidebarSectionTitle(idx)}
                open={isOpen}
              >
                <SectionIcon key={`${icon}-container`} type={icon} size={18} />
                {links}
              </SectionTitleLink>
            </SectionWrapper>
          )}
        </li>
      );
    });

  return (
    <SidebarNav marginTop={marginTop}>
      <div className="navTrigger">
        <Icon type="menu" />
      </div>
      <div className="nav">
        <ul>{sections}</ul>
        <Logout onClick={logout} tabIndex={0}>
          <SectionTitle>
            <SectionIcon key="power-container" type="power" />
            Log Out
          </SectionTitle>
        </Logout>
      </div>
    </SidebarNav>
  );
};

export default Sidebar;
