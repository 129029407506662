import { graphql } from 'react-apollo';

import { QUERY } from '../../../../../../../graphql/queries/accountHoldersQuery';
import { MUTATION } from '../../../../../../../graphql/mutations/createFinancialAccount';

export default graphql(MUTATION, {
  props: ({ mutate }) => ({
    createAccountHolderFundingSource: ({ token, id }) =>
      mutate({
        variables: {
          token,
          id
        }
      })
  }),
  options: {
    refetchQueries: [{ query: QUERY }],
    fetchPolicy: 'no-cache'
  }
});
