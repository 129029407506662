import { compose, lifecycle } from 'recompose';
import { parseURLSearchParams } from 'shared-components/src/utils/string';
import {
  renderWhileLoading,
  setRefetchProp,
  renderForError
} from '../../../state/apolloRecompose';

import Transfers from './Transfers';

// Enhancers
import withTransfersQuery from './enhancers/withTransfersQuery';
import withComponentSearchData from './enhancers/states/withComponentSearchData';
import withParamsState from './enhancers/states/withParamsState';
import withSearchByState from './enhancers/states/withSearchByState';
import withSearchFilterState from './enhancers/states/withSearchFilterState';
import withFilterButtonState from './enhancers/states/withFilterButtonState';
import withErrorState from './enhancers/states/withErrorState';
import withSuccessState from './enhancers/states/withSuccessState';
import withPaginationState from './enhancers/states/withPaginationState';

import withHandlers from './enhancers/withHandlers';


export default compose(
  withErrorState,
  withSuccessState,
  withComponentSearchData,
  withSearchByState,
  withSearchFilterState,
  withFilterButtonState,
  withParamsState,
  withPaginationState,
  withTransfersQuery,
  lifecycle({
    componentWillMount() {
      const {
        searchData,
        updateSearchData,
        updateSearchBy,
        updateParams,
        params,
        updateFilterButton
      } = this.props;
      const query = window.location.search;

      if (query) {
        const urlParams = parseURLSearchParams(query);
        updateSearchData({
          ...searchData,
          name: urlParams.name || searchData.name,
          status: urlParams.status || searchData.status,
          dateFrom:
            (urlParams.dateFrom && new Date(urlParams.dateFrom)) ||
            searchData.dateFrom,
          dateTo:
            (urlParams.dateTo && new Date(urlParams.dateTo)) ||
            searchData.dateTo,
          expirationDatetime:
            (urlParams.expiry && new Date(urlParams.expiry)) ||
            searchData.expirationDatetime,
          timeZone:
            (urlParams.timeZone) || searchData.timeZone
        });
        // visually affirm to the user the search terms they chose from the dashboard to view in more detail on the transfers page.
        if (urlParams.status) {
          updateSearchBy('status');
          updateParams({
            ...params,
            status: urlParams.status
          });
          updateFilterButton(true);
        }
      }
    }
  }),
  withHandlers,
  renderWhileLoading,
  setRefetchProp,
  renderForError
)(Transfers);
