import React from 'react';
import { Icon } from 'shared-components';
// Import Component Styles
import { Loader } from './styles';

export default () => (
  <Loader>
    <Icon type="loading" size={35} />
  </Loader>
);
