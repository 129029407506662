import React from 'react';
import { Alert, Title } from 'shared-components';

export default ({ error, transaction }) => (
  <>
    {transaction.support_company_name && transaction.support_email && transaction.support_phone_number && (
  
      <div>
        <Title>Error Redeeming Funds / Erreur de remboursement des fonds</Title>
        <Alert type="danger">
      Failed to Redeem Funds. Please contact {transaction.support_company_name} by phone at {transaction.support_phone_number} or by email at {transaction.support_email} to re-initiate payment.
      Échec du remboursement des fonds. Veuillez contacter {transaction.support_company_name} par e-mail à {transaction.support_email} ou par téléphone au {transaction.support_phone_number} pour réinitialiser le paiement.
        </Alert>
      </div>
    )}
    {transaction.support_company_name && transaction.support_email && !transaction.support_phone_number && (
  
      <div>
        <Title>Error Redeeming Funds / Erreur de remboursement des fonds</Title>
        <Alert type="danger">
      Failed to Redeem Funds. Please contact {transaction.support_company_name} by email at {transaction.support_email} to re-initiate payment.
      Échec du remboursement des fonds. Veuillez contacter {transaction.support_company_name} par e-mail à {transaction.support_email} pour réinitialiser le paiement.
        </Alert>
      </div>
    )}
    {transaction.support_company_name && !transaction.support_email && transaction.support_phone_number && (
  
      <div>
        <Title>Error Redeeming Funds / Erreur de remboursement des fonds</Title>
        <Alert type="danger">
      Failed to Redeem Funds. Please contact {transaction.support_company_name} by phone at {transaction.support_phone_number} to re-initiate payment.
      Échec du remboursement des fonds. Veuillez contacter {transaction.support_company_name} par téléphone au {transaction.support_phone_number} pour relancer le paiement.
        </Alert>
      </div>
    )}
    {(!transaction.support_company_name || (!transaction.support_email && !transaction.support_phone_number)) && (
  
      <div>
        <Title>Error Redeeming Funds / Erreur de remboursement des fonds</Title>
        <Alert type="danger">
      Failed to Redeem Funds. Please reach out by email at help@mycardholdersupport.com to re-initiate payment.
      Échec du remboursement des fonds. Veuillez contacter par e-mail à l'adresse help@mycardholdersupport.com pour relancer le paiement.
        </Alert>
      </div>
    )}
  </>
);
