import React from 'react';
// Import Global Components
import Header from '../../../../components/Header';
// Import Local Components
import DownloadCSV from '../components/DownloadCSV';
import SelectedProgramDetails from '../components/SelectedProgramDetails';
import CSVFieldDetails from '../components/CSVFieldDetails';
// Import Local Layouts
import CreateHeaderCard from './CreateHeaderCard';

export default ({ setChangeProgram, program, ...props }) => (
  <>
    <Header>
      <CreateHeaderCard>
        <SelectedProgramDetails
          changeProgram={setChangeProgram}
          program={program}
        />
      </CreateHeaderCard>
      <CreateHeaderCard>
        <DownloadCSV program={program} />
      </CreateHeaderCard>
    </Header>
    <CSVFieldDetails program={program} {...props} />
  </>
);
