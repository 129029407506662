import React from 'react';
// Import Shared Component
import { formatCurrencyV } from 'shared-components/src/utils/formatCurrency';
// Import Shared Constants
import { csvCardholderInputs } from 'shared-components/src/constants/propTypes';
import { CardholderDetail } from './styles';
// Import Utils
import { getCardholderDetails } from '../../../../utils/csv';

const CardholderDetails = ({ cardholders }) => {
  const cardholderDetails = getCardholderDetails(cardholders);

  return (
    <>
      <CardholderDetail>
        <p>Total Cardholders</p>
        <span>{cardholderDetails.cardholders}</span>
      </CardholderDetail>
      <CardholderDetail>
        <p>Total Load Amount</p>
        <span>{formatCurrencyV(cardholderDetails.amount)}</span>
      </CardholderDetail>
    </>
  );
};

CardholderDetails.propTypes = {
  cardholders: csvCardholderInputs.isRequired,
};

export default CardholderDetails;
