import React from 'react';
import PropTypes from 'prop-types';

import {
  Title,
  Alert,
  Button,
  ButtonGroup,
  ActionBar,
} from 'shared-components';
// Import Utils
import { formatCurrencySV } from 'shared-components/src/utils/formatCurrency';
// Import Component Styles
import { Details, TransferAmount, ConfirmTransferModal } from './styles';

const ConfirmCollectionModal = ({
  total,
  currency,
  recipients,
  sendCollection,
  closeModal,
  error,
}) => {
  const numRecipients = Object.keys(recipients).length;
  const renderRecipients =
    numRecipients > 1 ? (
      <>
        <h5>Sending</h5>
        <p>{numRecipients} Collections</p>
      </>
    ) : (
      Object.keys(recipients).map(id => (
        <>
          <h5>Sending to</h5>
          <p>{recipients[id]}</p>
        </>
      ))
    );

  return (
    <ConfirmTransferModal>
      <Details>
        <Title>Confirm Collections</Title>
        <TransferAmount>{formatCurrencySV(total, currency)}</TransferAmount>
        {renderRecipients}
      </Details>
      {error && (
        <Alert type="danger">
          Error: There are errors present in the form. Please go back to correct errors and retry.
        </Alert>
      )}
      <ActionBar>
        <ButtonGroup reverse>
          <Button onClick={sendCollection} size="large" type="success">
            Confirm
          </Button>
          <Button onClick={closeModal} variant="text" type="danger">
            Cancel
          </Button>
        </ButtonGroup>
      </ActionBar>
    </ConfirmTransferModal>
  );
};

ConfirmCollectionModal.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  recipients: PropTypes.object.isRequired,
  total: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
  sendCollection: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  error: PropTypes.func.isRequired,
};

export default ConfirmCollectionModal;